import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const StyledExemplarBlockWrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    border-radius: 10px;
    border: 2px solid ${theme.palette.background.level3};
    display: flex;
    padding: ${theme.spacing(3)}px;
  `
);

const StyledExemplarBlockText = styled(TextField)(
  ({ theme }) => `
    background-attachment: local;

    // This background creates the lines for the letter rows,
    // this way it makes the background scrollable
    background-image:
      repeating-linear-gradient(
        white,
        white 31px,
        #e1e1e1 31px,
        #e1e1e1 32px
      );
    background-position-y: -7px;
    
    border: none;
    color: #7d7d7d;
    display: flex;
    flex-direction: column;
    line-height: ${theme.spacing(4)}px;
    min-height: ${theme.spacing(16)}px;
    resize: none;
    width: 100%;

    .MuiInputBase-multiline {
      padding: 0;
    }

    .MuiInput-underline {
      border-bottom: none;

      &:hover::before {
        border-bottom: none;
      }

      &::before {
        border-bottom: none;
      }

      &::after {
        border-bottom: none;
      }
    }

    textarea {
      caret-color: transparent;
      color: #7d7d7d;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: ${theme.spacing(4)}px;
    }

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  `
);

export const ExemplarBlockContainer = ({ text }) => (
  <StyledExemplarBlockWrapper>
    <StyledExemplarBlockText multiline value={text} readOnly />
  </StyledExemplarBlockWrapper>
);

ExemplarBlockContainer.propTypes = {
  text: PropTypes.string,
};
