import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../../../../components/Text';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CriteriaStyles = styled.div(
  ({ theme }) => `
    .MuiAccordion-root {
      background-color: transparent;
      box-shadow: none;
    }
    
    .MuiAccordionSummary-root {
      background-color: ${theme.palette.background.level1};
      color: ${theme.palette.actions.main};
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: ${theme.spacing(4)}px;
      min-height: ${theme.spacing(7)}px;
      max-height: ${theme.spacing(7)}px;
      margin: ${theme.spacing(1)}px 0;
      
      .title {
        width: 100%;
      }
      
      .score {
        color: ${theme.palette.text.secondary};
        font-weight: 500;
      }
    }
    
    .MuiAccordionDetails-root {
      background-color: ${theme.palette.background.level3};
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 100%;

      // These prevent the contents from cutting into the frame card's border.
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    
    .component {
      flex-wrap: nowrap;
      margin: ${theme.spacing(1)}px 0;
      gap: ${theme.spacing(2)}px;
      
      .text {
        background-color: ${theme.palette.background.level1};
        color: ${theme.palette.text.secondary};
        padding: ${theme.spacing(2)}px;
        width: 100%;
      }
      
      .checkbox {
        background-color: ${theme.palette.background.level1};

        display: flex;
        align-items: center;
        justify-content: center;
     
        .MuiCheckbox-root.Mui-disabled {
          color: #dddddd;
        }
      }
    }
  `
);

export const CriteriaContainer = ({
  title,
  score,
  expanded,
  onClick,
  children,
}) => (
  <CriteriaStyles>
    <Accordion expanded={expanded}>
      <AccordionSummary onClick={onClick}>
        <div className="title">{title}</div>
        <div className="score">
          {Math.round(score.numerator)}/{score.denominator}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  </CriteriaStyles>
);
CriteriaContainer.propTypes = {
  title: PropTypes.string,
  score: PropTypes.shape({
    numerator: PropTypes.number,
    denominator: PropTypes.number,
  }),
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export const QuestionComponentContainer = ({
  question,
  value,
  onChange,
  isEditable,
}) => (
  <Grid container direction="row" className="component">
    <Grid item className="text">
      <TextBlockTypography dangerouslySetInnerHTML={{ __html: question }} />
    </Grid>
    <Grid item className="checkbox">
      <Checkbox
        checked={value ?? false}
        onChange={onChange}
        disabled={!isEditable}
      />
    </Grid>
  </Grid>
);
QuestionComponentContainer.propTypes = {
  question: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool,
};

export const RubricComponentContainer = ({
  requirements,
  value,
  onChange,
  isEditable,
}) => (
  <Grid container direction="column">
    {requirements.map(requirement => (
      <Grid
        key={requirement.id}
        container
        direction="row"
        className="component"
      >
        <Grid item className="text">
          <TextBlockTypography
            dangerouslySetInnerHTML={{ __html: requirement.text }}
          />
        </Grid>
        <Grid item className="checkbox">
          <Checkbox
            checked={value?.[requirement.id] ?? false}
            onChange={() => onChange(requirement)}
            disabled={!isEditable}
          />
        </Grid>
      </Grid>
    ))}
  </Grid>
);
RubricComponentContainer.propTypes = {
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  value: PropTypes.object,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool,
};

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    maxWidth: theme.spacing(22),
  },
}))(Tooltip);

export const MultipleChoiceComponentContainer = ({ index, value }) => {
  return (
    <Grid container direction="row" className="component">
      <Grid item className="text">
        <TextBlockTypography>Multiple Choice #{index}</TextBlockTypography>
      </Grid>
      <Grid item className="checkbox">
        <StyledTooltip title="Autograded">
          <span>
            <Checkbox checked={value ?? false} disabled={true} />
          </span>
        </StyledTooltip>
      </Grid>
    </Grid>
  );
};
MultipleChoiceComponentContainer.propTypes = {
  index: PropTypes.number,
  value: PropTypes.bool,
};
