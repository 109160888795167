import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useAudio } from '../../contexts/AudioContext';

const StyledAudioIcon = styled.div`
  background-image: url('/static/assets/audio/speaker.svg');
  background-position: center;
  background-repeat: no-repeat;
  bottom: -2px;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin-left: 12px;
  position: relative;
  width: 18px;
  &:hover {
    background-image: url('/static/assets/audio/speaker-hover.svg');
  }
  &.playing {
    background-image: url('/static/assets/audio/playing.gif');
    &:hover {
      background-image: url('/static/assets/audio/pause.svg');
    }
  }
  &.paused {
    background-image: url('/static/assets/audio/play.svg');
    &:hover {
      background-image: url('/static/assets/audio/play-hover.svg');
    }
  }
`;

const StyledTextWithAudio = styled.div(
  ({ $hideAudioIcon }) => `
    display: inline;
    line-height: 24px;
    width: 100%;

    // Hide the audio icon when it's stopped
    .audio {
      visibility: ${$hideAudioIcon ? 'hidden' : 'visible'} ;
    }

    // Whenever a user hovers over the container should show the audio icon.
    :hover {
      .audio {
        visibility: visible;
      }
    }
  `
);

export const AudioIcon = ({ url, status, setStatus }) => {
  const { play, pause, stop } = useAudio();

  // This will stop the current audio when the AudioIcon is unmounted
  // so it will work for all cases
  React.useEffect(() => {
    return () => stop();
  }, [stop]);

  const handleClick = () =>
    status === 'playing' ? pause() : play(url, setStatus);

  const getClasses = status => {
    switch (status) {
      case 'playing':
        return 'audio playing';
      case 'paused':
        return 'audio paused';
      default:
        return 'audio';
    }
  };

  return (
    <StyledAudioIcon className={getClasses(status)} onClick={handleClick} />
  );
};

AudioIcon.propTypes = {
  url: PropTypes.string,
  status: PropTypes.string,
  setStatus: PropTypes.func,
};

export const TextWithAudio = ({ audio, children }) => {
  const [status, setStatus] = React.useState('stopped');
  return (
    <StyledTextWithAudio $hideAudioIcon={status === 'stopped'}>
      {children}
      {audio && <AudioIcon url={audio} status={status} setStatus={setStatus} />}
    </StyledTextWithAudio>
  );
};

TextWithAudio.propTypes = {
  audio: PropTypes.string,
  children: PropTypes.node,
};
