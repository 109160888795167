import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  h1 {
    color: #c4c4c4;
    font-size: 52px;
    font-weight: 500;
    line-height: 52px;
  }

  img {
    width: 400px;
  }
`;

export const NoSubmissionsToScore = () => (
  <StyledDiv>
    <Typography variant="h1">No projects to score!</Typography>
    <img
      alt="no submissions to score"
      src="/static/assets/scoring/no-submissions-to-score.png"
    />
  </StyledDiv>
);
