import {
  BlockGroupContainer,
  LabelBlockContainer,
  MultipleChoiceCheckpoint,
  RichTextCheckpoint,
  ShortAnswerCheckpoint,
} from '../../../../components/Blocks';
import FileCopy from '@material-ui/icons/FileCopy';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { StepContent } from '../../../../components/Layout';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

//
// Contexts container
//

export const ContextsContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
  
    .context-selector {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      background-color: ${theme.palette.background.level3};
      border-bottom: 3px solid #b7b7b7;
      padding: ${theme.spacing(0, 1)};
      position: sticky;
      top: 0;
      z-index: 1; // Needed so frames don't render on top of the selector.
    }
  
    .link-area {
      display: flex;
      align-items: center;
      flex-shrink: 1;
      gap: ${theme.spacing(1)}px;
      height: 42px;
      overflow: hidden;
      
      a {
        color: ${theme.palette.actions.main};
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      
      .icon {
        display: flex;
        align-items: center;
        color: ${theme.palette.actions.main};
        flex-shrink: 0;
      }
    }

    .radio-buttons {
      display: flex;
      justify-content: flex-end;
    }
  `
);

//
// Context selector
//

export const ContextSelector = ({ contexts, selected, url, onChange }) => {
  const onRadioChange = React.useCallback(
    e => {
      const index = Number.parseInt(e.target.value);
      onChange(index);
    },
    [onChange]
  );

  // Only show a selector when there's more than 1 context, or there's a url
  // to show
  if (contexts.length < 2 && !url) {
    return null;
  }

  return (
    <div className="context-selector">
      <div className="link-area">
        <Link url={url} />
      </div>
      {contexts.length > 1 && (
        <RadioGroup className="radio-buttons" row onChange={onRadioChange}>
          {contexts.map((context, index) => (
            <FormControlLabel
              key={context.title}
              label={context.title}
              value={index}
              control={<Radio color="primary" checked={index === selected} />}
            />
          ))}
        </RadioGroup>
      )}
    </div>
  );
};
ContextSelector.propTypes = {
  contexts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  selected: PropTypes.number,
  url: PropTypes.string,
  onChange: PropTypes.func,
};

const Link = ({ url }) => {
  const [message, setMessage] = React.useState('Copy URL');

  const copyToClipboard = React.useCallback(
    async e => {
      e.preventDefault();
      await navigator.clipboard.writeText(url);
      setMessage('Copied!');
      setTimeout(() => setMessage('Copy URL'), 1000);
    },
    [url]
  );

  if (!url) {
    return null;
  }

  return (
    <>
      <StyledTooltip title="Open link">
        <a className="url" href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </StyledTooltip>

      <StyledTooltip title={message}>
        <a className="icon" href="#" onClick={copyToClipboard}>
          <FileCopy />
        </a>
      </StyledTooltip>
    </>
  );
};
Link.propTypes = {
  url: PropTypes.string,
};

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    maxWidth: theme.spacing(20),
    padding: theme.spacing(1),
  },
}))(Tooltip);

//
// Checkpoint context
//

export const CheckpointContextContainer = ({ label, checkpoint, answer }) => {
  return (
    <StepContent>
      <BlockGroupContainer>
        <LabelBlockContainer text={label} />
        {checkpoint.variant === 'multiple_choice' && (
          <MultipleChoiceCheckpoint
            checkpoint={checkpoint}
            answer={answer}
            readOnly
          />
        )}
        {checkpoint.variant === 'short_answer' && (
          <ShortAnswerCheckpoint
            checkpoint={checkpoint}
            answer={answer.value}
            readOnly
          />
        )}
        {checkpoint.variant === 'rich_text' && (
          <RichTextCheckpoint answer={answer.value} readOnly />
        )}
      </BlockGroupContainer>
    </StepContent>
  );
};
CheckpointContextContainer.propTypes = {
  label: PropTypes.string,
  checkpoint: PropTypes.shape({
    variant: PropTypes.string,
  }),
  answer: PropTypes.shape({
    value: PropTypes.oneOfType([
      // multiple choice
      PropTypes.shape({
        value: PropTypes.shape({
          selected: PropTypes.arrayOf(PropTypes.string),
          attempts_remaining: PropTypes.number,
        }),
        complete: PropTypes.bool,
      }),

      // short answer
      PropTypes.string,

      // rich text
      PropTypes.string,
    ]),
  }),
};
