import * as lodash from 'lodash';
import {
  BodyContainer,
  Layout,
  ResponsiveContainer,
} from '../../components/Layout';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import api from '../../api';
import AppBarHeader from '../../components/AppBarHeader';
import LoadingIndicator from '../../components/LoadingIndicator';
import NavTeacher from '../../components/NavTeacher';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const StyledSection = styled.a(
  ({ theme }) => `
    align-items: center;
    background: ${theme.palette.background.level1};
    border-radius: 5px;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    display: flex;
    gap: ${theme.spacing(2)}px;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2, 4)};
    &:link, &:visited {
      color: ${theme.palette.text.secondary};
      text-decoration: none;
    }
    :hover{
      border: 1px solid ${theme.palette.actions.main};
      color: ${theme.palette.actions.main};
    }
  `
);

const StyledStudents = styled.div(
  ({ theme }) => `
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    margin-left: ${theme.spacing(4)}px;
    width: ${theme.spacing(15)}px;
    h6 {
      line-height: 24px;
      font-weight: 400;
    }
  `
);

const StyledCourse = styled.div(
  () => `
    align-items: center;
    display: flex;
    flex-shrink: 0;
  `
);

const StyledCourseTitle = styled.div(
  () => `
    h6 {
      text-align: right;
    }
    @media (max-width: 1024px) {
      max-width: 158px;
    }
  `
);

const StyledSectionTitle = styled.div(
  ({ theme }) => `
    min-width: 0;
    p {
      font-weight: 600;
    }
    @media (max-width: 1280px) {
      max-width: ${theme.spacing(48)}px; 
    }
    @media (max-width: 1024px) {
      max-width: ${theme.spacing(38)}px; 
    }
  `
);

const Section = ({
  levelId,
  numStudents,
  sectionId,
  sectionTitle,
  courses,
}) => (
  <StyledSection
    href={`/teacher/dashboards/gradebook/section/${sectionId}/course/${courses[0].course_id}/level/${levelId}`}
  >
    <StyledSectionTitle>
      <Typography variant="body1">{sectionTitle}</Typography>
    </StyledSectionTitle>
    <StyledCourse>
      <StyledCourseTitle>
        {courses.length === 1 && (
          <Typography variant="h6">{courses[0].name}</Typography>
        )}
        {courses.length === 2 && (
          <Typography variant="h6">
            {courses[0].name},<br />
            {courses[1].name}
          </Typography>
        )}
        {courses.length > 2 && (
          <Tooltip
            title={courses
              .map(c => c.name)
              .join(', ')
              .replace(/, ([^,]*)$/, ' and $1')}
          >
            <Typography variant="h6" csx={{ whiteSpace: 'pre-line' }}>
              {courses[0].name},<br />
              {courses[1].name}, and {courses.length - 2} more
            </Typography>
          </Tooltip>
        )}
      </StyledCourseTitle>
      <StyledStudents>
        <AccountCircleIcon />
        <Typography variant="h6">
          {`${numStudents} ${numStudents === 1 ? 'student' : 'students'}`}
        </Typography>
      </StyledStudents>
    </StyledCourse>
  </StyledSection>
);

const StyledHeader = styled.div(
  ({ theme }) => `
    background: ${theme.palette.primary.gradient};
    padding: ${theme.spacing(3, 4)};
    h4 {
      color: ${theme.palette.text.primary};
      font-size: 24px;
      font-weight: 400;
    }
  `
);

const SectionsList = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing(2, 4, 0, 4)};

    .title {
      font-family: 'IBM Plex Mono', monospace;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin: ${theme.spacing(1, 0)};
      padding: ${theme.spacing(1, 0)};
    }
  `
);

const Sections = () => {
  const [loading, teacherEnrollments, allCourses] = api.load(
    api.roster.sections.getTeacherEnrollments(),
    api.curriculum.courses.get()
  );

  const [activeEnrollments, inactiveEnrollments] = React.useMemo(() => {
    if (loading) return [[], [], []];
    const courseMap = new Map();
    allCourses.forEach(course => {
      courseMap.set(course.id, course);
    });
    teacherEnrollments.forEach(enrollment => {
      enrollment.courses = lodash.orderBy(
        enrollment.courses.map(c =>
          courseMap.get(c.course_id)
            ? {
                course_id: c.course_id,
                name: courseMap.get(c.course_id)?.name,
                sort_key: courseMap.get(c.course_id)?.sort_key,
                first_level_id: courseMap.get(c.course_id)?.levels[0].id,
              }
            : {
                course_id: c.course_id,
                name: '',
                sort_key: '',
                first_level_id: '',
              }
        ),
        ['sort_key'],
        ['asc']
      );
      enrollment.level_id =
        localStorage.getItem(
          enrollment.courses[0].course_id + ':' + enrollment.id
        ) ?? enrollment.courses[0].first_level_id;
    });

    return [
      teacherEnrollments.filter(s => s.is_active),
      teacherEnrollments.filter(s => !s.is_active),
    ];
  }, [loading, allCourses, teacherEnrollments]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AppBarHeader
        menu={<NavTeacher selected="sections" />}
        title="Sections"
      />
      <Layout>
        <ResponsiveContainer>
          <BodyContainer noProgressBar>
            <StyledHeader>
              <Typography variant="h4">Sections</Typography>
            </StyledHeader>
            {activeEnrollments.length > 0 && (
              <SectionsList>
                <div className="title">Active Sections</div>
                {activeEnrollments.map(section => {
                  return (
                    <Section
                      key={section.id}
                      levelId={section.level_id}
                      numStudents={section.student_count}
                      sectionId={section.id}
                      sectionTitle={section.title}
                      courses={section.courses}
                    />
                  );
                })}
              </SectionsList>
            )}
            {inactiveEnrollments.length > 0 && (
              <SectionsList>
                <div className="title">Previous Sections</div>
                {inactiveEnrollments.map(section => {
                  return (
                    <Section
                      key={section.id}
                      levelId={section.level_id}
                      numStudents={section.student_count}
                      sectionId={section.id}
                      sectionTitle={section.title}
                      courses={section.courses}
                    />
                  );
                })}
              </SectionsList>
            )}
          </BodyContainer>
        </ResponsiveContainer>
      </Layout>
    </>
  );
};

Sections.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Sections;
