import { findCheckpoints } from '../../../utils/projects';

const isCategoryComplete = (category, progress, rubric) => {
  const requirements = rubric.find(c => c.type === category.type)?.requirements;
  if (!requirements) {
    throw new Error(`unable to find category '${category.type}' in rubric`);
  }

  const numRequired = category?.required ?? requirements.length;
  const numComplete = requirements.filter(r => !!progress?.[r.id]).length;
  return numComplete >= numRequired;
};

export const isRubricCheckpointComplete = (checkpoint, progress, rubric) => {
  const categories = checkpoint.categories ?? rubric;

  return categories.every(category =>
    isCategoryComplete(category, progress, rubric)
  );
};

export const isCategoryGraded = (category, rubric, scoredRequirements) => {
  const requirements = rubric.find(c => c.type === category.type)?.requirements;
  if (!requirements) {
    throw new Error(`unable to find category '${category.type}' in rubric`);
  }

  return requirements.every(
    req => typeof scoredRequirements[req.id] !== 'undefined'
  );
};

const isRubricCheckpointGraded = (checkpoint, rubric, scoredRequirements) => {
  const categories = checkpoint.categories ?? rubric;

  return categories.every(category =>
    isCategoryGraded(category, rubric, scoredRequirements)
  );
};

export const isEveryRubricCheckpointGraded = (project, progress) => {
  const rubricCheckpoints = findCheckpoints(project, 'rubric');

  return rubricCheckpoints.every(rubricCheckpoint =>
    isRubricCheckpointGraded(
      rubricCheckpoint,
      project.rubric,
      progress.grading?.checkpoints?.[rubricCheckpoint.id] || {}
    )
  );
};
