import * as axios from 'axios';
import { memoize, noeslint } from './client';
import config from '../config';

// The authentication methods all happen prior to a user being authenticated
// so need to use an axios client without authorization headers.  We'll create
// a single, unexpored client for all methods in this file to use.
const client = axios.create({
  baseURL: config.api.uri,
});

/**
 * Asynchronously retrieve metadata about a section with a given section code.
 */
const getSectionMetadata = memoize(code =>
  client
    .get(`/authentication/sections/${code}/metadata`)
    .then(response => response.data)
);

/**
 * Asynchronously retrieve the students eligible for simplified login in the
 * section with a given section code.
 */
const getSectionStudents = memoize(code =>
  client
    .get(`/authentication/sections/${code}/students`)
    .then(response => response.data)
);

/**
 * Asynchronously retrieve a student's password images.
 */
const getStudentPasswordImages = memoize(studentId =>
  client
    .get(`/authentication/students/${studentId}/images`)
    .then(response => response.data)
);

/**
 * Asynchronously attempt to authenticate a student with a password image
 * choice.
 */
const authenticateUsingPasswordImage = noeslint((studentId, imageId) =>
  client
    .post(`/authentication/students/${studentId}`, JSON.stringify(imageId))
    .then(response => response.data)
);

/**
 * Asynchronously create a new Hour of Code user.
 */
const createHourOfCodeUser = noeslint(() =>
  client
    .post(`/authentication/anonymous/hour-of-code`)
    .then(response => response.data)
);

/**
 * Asynchronously generate an auth token for an anonymous user.
 */
const getAnonymousUserAuthToken = noeslint(userId =>
  client
    .get(`/authentication/anonymous/${userId}/token`)
    .then(response => response.data)
);

export default {
  getSectionMetadata: getSectionMetadata,
  getSectionStudents: getSectionStudents,
  getStudentPasswordImages: getStudentPasswordImages,
  authenticateUsingPasswordImage: authenticateUsingPasswordImage,
  createHourOfCodeUser: createHourOfCodeUser,
  getAnonymousUserAuthToken: getAnonymousUserAuthToken,
};
