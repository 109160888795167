import React, { useEffect, useState } from 'react';
import api from '../api';
import find from 'lodash/find';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import StudentProjectCard from '../components/StudentProjectCard';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import useCourse from '../hooks/useCourse';
import { useParams } from 'react-router-dom';
import { useUser } from '../contexts/AuthContext';

const GridItem = styled.div`
  box-shadow: 0 0px 32px 0 rgba(0, 0, 0, 0.7);

  ${({ $index, $numItems, $isStacked }) => {
    if (!$isStacked) {
      return ``;
    }

    const scaleXY = 1 - 0.175 * $index;
    const dx = -238 * $index;
    const dy = -29 * $index;

    return `
      z-index: ${$numItems - $index};
      transition: transform 100ms ease;
      transform: matrix(${scaleXY}, 0, 0, ${scaleXY}, ${dx}, ${dy});
    `;
  }}
`;

const CardsContainer = styled.div(
  ({ $isStacked, theme }) => `
    overflow: ${$isStacked ? 'auto' : 'hidden'};
    width: 100%;
    margin-left: -${theme.spacing(6.25)}px;
    margin-top: -${theme.spacing(3)}px;
  
    ::-webkit-scrollbar {
      height: ${theme.spacing(1)}px;
      padding: ${theme.spacing(1)}px;
    }
  
    ::-webkit-scrollbar {
      width: 0;
    }
  
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  
    ::-webkit-scrollbar-thumb {
      border-radius: ${theme.spacing(3)}px;
      background: rgba(255, 255, 255, 0.3);
      cursor: pointer;
      border: ${theme.spacing(1)}px solid transparent;
    }
  `
);

const StyledGrid = styled(Grid)`
  width: 100%;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(1, 3, 6, 6)};

  ${GridItem} {
    margin: ${({ theme }) => theme.spacing(3, 3, 1, 1)};
  }

  ${({ $isStacked }) => ($isStacked ? `width: max-content;` : ``)}

  &:hover {
    & ${GridItem} {
      transition: transform 100ms ease;
      transform: matrix(1, 0, 0, 1, 0, 0);
      -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }
`;

const StyledProjectGroupTitle = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1.5)}px;
    margin-left: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(2.5)}px;

    .row1 {
      display: flex;
      gap: ${theme.spacing(2)}px;
    }
    
    .title {
      color: ${theme.palette.text.primary};
    }

    .subtitle {
      opacity: 0.7;
      text-transform: uppercase;
    }    

    .requirements {
      align-self: flex-end;
      color: #30dfff;
      font-family: IBM Plex Mono;
      font-size: 18px;
      line-height: 23px;
      opacity: 0.7;
    }
  `
);

const Title = ({ group }) => {
  const total = group.projects.length;
  const required = group.required ?? total;
  const showRequirements =
    group.show_requirements !== undefined
      ? group.show_requirements
      : required < total;

  return (
    <StyledProjectGroupTitle>
      <div className="row1">
        <Typography className="title" variant="h3">
          {group.title}
        </Typography>
        {showRequirements && (
          <Typography className="requirements">
            COMPLETE {required} OF {total}
          </Typography>
        )}
      </div>
      {group.subtitle && (
        <Typography className="subtitle" variant="subtitle1">
          {group.subtitle}
        </Typography>
      )}
    </StyledProjectGroupTitle>
  );
};
Title.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    required: PropTypes.number,
    ordered: PropTypes.bool,
    show_requirements: PropTypes.bool,
    projects: PropTypes.array,
  }),
};

const LevelContainer = styled.div(
  ({ theme }) => `
    overflow: auto;
    padding: ${theme.spacing(4, 6)};
    height: calc(100vh - ${theme.spacing(16)}px);
  `
);

const Level = ({ courseName, progress, sectionId }) => {
  const { currentLevelId, isLoading, course, levels = [] } = useCourse();
  const user = useUser();
  const { courseId, levelId } = useParams();
  const [currentLevelData, setCurrentLevelData] = useState();
  const { project_groups: projectGroups } = currentLevelData || {};
  const currentLevelName = levels?.find(
    ({ id }) => id === currentLevelId
  )?.name;

  useEffect(
    () => setCurrentLevelData(find(levels, { id: currentLevelId })),
    [currentLevelId, levels]
  );

  const [loading, projectsMetadata] = api.load(
    api.courses.getProjectsMetadata(courseId)
  );

  // Don't try to render if we haven't loaded all of our needed data.
  if (loading || isLoading || !progress || !projectGroups) {
    return null;
  }

  return (
    <LevelContainer>
      {projectGroups.map(group => {
        const projectIds = group.projects.map(p => p.id);
        const groupProgress = progress.project_groups[group.id];
        const isStacked = !groupProgress.playable && !groupProgress.complete;

        return (
          <React.Fragment key={group.id}>
            <Title group={group} />
            <CardsContainer $isStacked={isStacked}>
              <StyledGrid container $isStacked={isStacked}>
                {projectIds.map((projectId, i) => {
                  const projectProgress = groupProgress.projects[projectId];

                  return (
                    <GridItem
                      $index={i}
                      $numItems={projectIds?.length}
                      $isStacked={isStacked}
                      key={projectId}
                    >
                      <StudentProjectCard
                        courseId={courseId}
                        courseName={courseName}
                        currentLevelName={currentLevelName}
                        id={projectId}
                        isTeacher={user.isTeacherForCourse(course.id)}
                        levelId={levelId}
                        metadata={projectsMetadata?.[projectId]}
                        progress={projectProgress}
                        sectionId={sectionId}
                        user={user}
                      />
                    </GridItem>
                  );
                })}
              </StyledGrid>
            </CardsContainer>
          </React.Fragment>
        );
      })}
    </LevelContainer>
  );
};

Level.propTypes = {
  courseName: PropTypes.string,
  progress: PropTypes.shape({
    project_groups: PropTypes.object,
    status: PropTypes.string,
  }),
  sectionId: PropTypes.string,
};

export default Level;
