import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import styled from 'styled-components';

const ScoreSummaryStyles = styled.div(({ theme, $percent }) => {
  let color;
  if ($percent >= 90) color = '#d1ffc6';
  else if ($percent >= 80) color = '#eaffbc';
  else if ($percent >= 70) color = '#ffffac';
  else if ($percent >= 65) color = '#ffe8af';
  else color = '#ffcbca';

  return `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)}px;
  
    .row {
      display: flex;
      justify-content: space-between;
    
      color: ${theme.palette.text.secondary};
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: ${theme.spacing(4)}px;
    }
    
    .row.adjustment {
      svg {
        cursor: pointer;
      }
      
      input {
        border: 1px solid #00000019;
        border-radius: 3px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        padding: 4px;
        text-align: center;
        width: 40px;
      }
      
      &.out-of-bounds input {
        border: 1px solid #a32f2f;
        color: #a32f2f;
      }
      
      input[type=number]::-webkit-inner-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }
      
      .score {
        display: flex;
        align-items: center;
        gap: ${theme.spacing(1)}px;
      }
    }
    
    .row.error {
      color: #b84848;
      font-family: 'IBM Plex Mono';
      font-weight: 500;
      line-height: 21px;
    }
    
    .row.total {
      background-color: ${theme.palette.background.level3};
      padding: 0 ${theme.spacing(1)}px;
      
      .title,
      .score {
        font-weight: 600;
      }
      
      .score {
        display: flex;
        gap: ${theme.spacing(1)}px;
        padding: 2px 0;
        
        .percentage {
          padding-left: ${theme.spacing(1)}px;
          padding-right: ${theme.spacing(1)}px;
        }
      }
      
      .percentage {
        background-color: ${color};
      }
    }
  `;
});

const ScoreSummary = ({
  criteria,
  scores,
  adjustment,
  total,
  isOutOfBounds,
  isEditable,
  onChangeAdjustment,
}) => {
  const isAdjustmentTooSmall = isOutOfBounds && total.numerator < 0;
  const isAdjustmentTooLarge = isOutOfBounds && total.numerator > 0;
  const percent = Math.round((100.0 * total.numerator) / total.denominator);

  return (
    <ScoreSummaryStyles $percent={percent}>
      {criteria.map((c, index) => {
        const score = scores[index];
        return (
          <div key={index} className="row">
            <div className="title">{c.title}</div>
            <div className="score">
              {Math.round(score.numerator)}/{score.denominator}
            </div>
          </div>
        );
      })}
      <AdjustmentRow
        adjustment={adjustment}
        isOutOfBounds={isOutOfBounds}
        isEditable={isEditable}
        onChangeAdjustment={onChangeAdjustment}
      />
      {isAdjustmentTooSmall && (
        <div className="row error">Total score cannot be less than 0%.</div>
      )}
      {isAdjustmentTooLarge && (
        <div className="row error">
          Total cannot be more than 150% of the total points allowed.
        </div>
      )}
      <div className="row total">
        <div className="title">TOTAL SCORE</div>
        <div className="score">
          <span className="percentage">{percent}%</span>
          <span className="points">
            {Math.round(total.numerator)}/{total.denominator}
          </span>
        </div>
      </div>
    </ScoreSummaryStyles>
  );
};
ScoreSummary.propTypes = {
  criteria: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      numerator: PropTypes.number,
      denominator: PropTypes.number,
    })
  ),
  adjustment: PropTypes.number,
  total: PropTypes.shape({
    numerator: PropTypes.number,
    denominator: PropTypes.number,
  }),
  isOutOfBounds: PropTypes.bool,
  isEditable: PropTypes.bool,
  onChangeAdjustment: PropTypes.func,
};

const AdjustmentRow = ({
  adjustment,
  isEditable,
  isOutOfBounds,
  onChangeAdjustment,
}) => {
  if (!isEditable) {
    if (adjustment === 0) {
      // Don't show the row because the adjustment is 0.
      return null;
    }

    // Show a read-only version of the row displaying the adjustment's value.
    return (
      <div className="row adjustment">
        <div className="title">Points Adjustment</div>
        <div className="score">
          {adjustment >= 0 && '+'}
          {adjustment}
        </div>
      </div>
    );
  }

  // Show a fully functional version of the row.
  return (
    <div className={`row adjustment ${isOutOfBounds ? 'out-of-bounds' : ''}`}>
      <div className="title">Points Adjustment</div>
      <div className="score">
        <RemoveIcon
          fontSize="small"
          onClick={() => onChangeAdjustment(adjustment - 1)}
        />
        <input
          type="number"
          value={adjustment}
          onChange={e => {
            const value = parseInt(e.target.value);
            if (!Number.isNaN(value)) {
              onChangeAdjustment(value);
            }
          }}
        />
        <AddIcon
          fontSize="small"
          onClick={() => onChangeAdjustment(adjustment + 1)}
        />
      </div>
    </div>
  );
};
AdjustmentRow.propTypes = {
  adjustment: PropTypes.number,
  isOutOfBounds: PropTypes.bool,
  isEditable: PropTypes.bool,
  onChangeAdjustment: PropTypes.func,
};

export default ScoreSummary;
