import { memoize, useAxiosClient } from './client';

const getCourse = memoize(courseId => {
  const client = useAxiosClient();

  return client.get(`/courses/${courseId}`).then(response => response.data);
});

const getProjectsMetadata = memoize(courseId => {
  const client = useAxiosClient();

  return client
    .get(`/courses/${courseId}/projects/metadata`)
    .then(response => response.data);
});

export default {
  getCourse,
  getProjectsMetadata,
};
