import { memoize, useCurriculumApiClient } from '../client';

const get = memoize(() => {
  const client = useCurriculumApiClient();
  return client.get(`/courses`).then(response => response.data);
});

export default {
  get: get,
};
