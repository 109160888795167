import {
  Blocked,
  Checkmark,
  Clock,
  Excused,
  Locked,
  Play,
  SentBack,
  Star,
} from '../icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const ItemLabel = styled(Typography)(
  ({ $isCurrent, $inCurrentGroup, $isClickable, theme }) => `
    align-items: center;
    background: ${
      $isCurrent &&
      'linear-gradient(90deg, ' +
        theme.palette.primary.light +
        '80 27.08%, ' +
        theme.palette.primary.main +
        '80 93.12%)'
    };
    cursor: ${$isClickable ? 'pointer' : 'auto'};
    
    border-width: 0 0 0 4px;
    border-style: solid;
    border-color: ${
      $isCurrent
        ? theme.palette.primary.light
        : $inCurrentGroup
        ? theme.palette.primary.light + '25'
        : 'rgba(0, 0, 0, 0)'
    };
    
    color: ${$isCurrent ? '#ffffff' : theme.palette.text.hint};
    display: flex;
    font-size: 12px;
    font-weight: 400;
    height: ${theme.spacing(6)}px;
    justify-content: start;
    padding: ${theme.spacing(2.5, 1)};
    
    transition: all 100ms ease;
    width: 100%;

    .lockedIcon {
      visibility: hidden;
    }
    
    .playIcon {
      visibility: ${$isCurrent ? 'visible' : 'hidden'};
    }

    .checkmarkIcon {
      fill: ${theme.palette.text.icon};
    }
    
    .sentBackIcon {
      .circle {
        stroke: ${
          $isCurrent ? theme.palette.text.primary : theme.palette.text.icon
        };
      }
      .arrow {
        fill: ${
          $isCurrent ? theme.palette.text.primary : theme.palette.text.icon
        };
      }
    }
  
    :hover {
      color: ${theme.palette.text.primary};
      background-color: ${
        $isCurrent ? '#ffffff25' : $inCurrentGroup && '#ffffff15'
      };
      font-weight: ${$inCurrentGroup ? '500' : '400'};
      border-color: ${$isCurrent && '#ffffff'};

      .excusedIcon,
      .playIcon,
      .lockedIcon {
        visibility: visible;
      }

      .checkmarkIcon,
      .excusedIcon {
        fill: ${theme.palette.text.primary};
      }
      
      .sentBackIcon {
        .arrow {
          fill: ${theme.palette.text.primary};
        }
        .circle {
          stroke: ${theme.palette.text.primary};
        }
      }
      
      .starIcon {
        .circle {
          stroke: ${theme.palette.text.primary};
        }
        .star {
          fill: ${theme.palette.text.primary};
        }
      }
    }
  `
);

const TeacherOnlyIcon = styled(Typography)(
  () => `
    color: #30DFFF;
    font-size: 28px;
  `
);

const None = styled.div(
  ({ size }) => `
    display: inline-block;
    flex-shrink: 0;
    height: ${size}px;
    width: ${size}px;
    visibility: hidden;
  `
);

const ItemIcon = ({ name }) => {
  switch (name) {
    case 'blocked':
      return <Blocked className="blockedIcon" size={20} />;
    case 'checkmark':
      return <Checkmark className="checkmarkIcon" size={20} />;
    case 'clock':
      return <Clock className="clockIcon" size={20} />;
    case 'excused':
      return <Excused className="excusedIcon" size={20} />;
    case 'play':
      return <Play className="playIcon" size={20} />;
    case 'sent-back':
      return <SentBack className="sentBackIcon" size={20} />;
    case 'star':
      return <Star className="starIcon" size={20} />;
    case 'none':
      return <None className="noneIcon" size={20} />;
    default:
      return <Locked className="lockedIcon" size={20} />;
  }
};

ItemIcon.propTypes = {
  name: PropTypes.string,
};

const Name = styled.span(
  ({ theme }) => `
    flex-grow: 1;
    margin-left: ${theme.spacing(1)}px;
    user-select: none;
  `
);

const TipIcon = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.actions.secondary};
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    height: 12px;
    width: 12px;
  `
);

const NavMapItem = ({
  handleItemClick,
  inCurrentGroup,
  item,
  sectionId,
  mode,
}) => {
  const itemRef = React.useRef();

  // This will make the item to scroll into view
  // when it becomes the current one
  React.useEffect(() => {
    if (item.isCurrent) {
      setTimeout(
        () =>
          itemRef.current &&
          itemRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }),
        150
      );
    }
  }, [item.isCurrent]);

  const isClickable =
    mode === 'project'
      ? !item.isCurrent && (item.isComplete || item.isPlayable)
      : item.isCurrent || item.isComplete || item.isPlayable;

  return (
    <ItemLabel
      $isCurrent={item.isCurrent}
      $isClickable={isClickable}
      $inCurrentGroup={inCurrentGroup}
      $mode={mode}
      className={item.isCurrent && 'currentNavItem'}
      variant="h6"
      onClick={() => {
        if (isClickable) {
          handleItemClick(sectionId, item.id);
        }
      }}
      ref={itemRef}
    >
      <ItemIcon name={item.icon} />
      <Name>{item.title}</Name>
      {item.isTeacherOnly && <TeacherOnlyIcon>&#x25CF;</TeacherOnlyIcon>}
      {item.showTipIcon && <TipIcon />}
    </ItemLabel>
  );
};

NavMapItem.propTypes = {
  handleItemClick: PropTypes.func,
  inCurrentGroup: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    isComplete: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isPlayable: PropTypes.bool,
    icon: PropTypes.string,
    showTipIcon: PropTypes.bool,
  }),
  sectionId: PropTypes.string,
  mode: PropTypes.string,
};

export default NavMapItem;
