import * as lodash from 'lodash';
import * as projects from '../utils/projects';
import React from 'react';
import { useProject } from '../contexts/ProjectContext';

const getQuestionComponentScore = (component, value) => {
  return value ? component.weight : 0;
};

const getRubricComponentScore = (rubric, checkpoint, component, value) => {
  // Scoring a rubric can be a bit tricky due to the fact that we support
  // multiple categories in a rubric component as well as support categories
  // that require N or more of the requirements to be checked.  Because of this
  // scoring will work as follows.
  //
  // 1. Each category will be worth an equal number of points
  // (weight/#categories) regardless of how many requirements it contains.
  //
  // 2. Within a category that doesn't have a restriction on the minimum number
  // of requirements to select, each requirement will be worth (1/R)th of the
  // category's points (when there are R requirements in it).
  //
  // 3. Within a category that does have a restriction on the minimum number of
  // requirements to select the category will be scored all or nothing.  If the
  // minimum requirement count has been reached then all points will be awarded
  // otherwise no points will be awarded.

  // Identify each category that we need to score.
  const categories =
    component?.categories ??
    checkpoint?.categories?.map(c => c.type) ??
    rubric.map(c => c.type);

  // Calculate how many points is each category has available.
  const available = component.weight / categories.length;

  // Determine the requirments for each category.  This comes from the rubric.
  const requirements = Object.fromEntries(
    categories.map(c => [
      c,
      rubric?.find(cat => cat.type === c)?.requirements?.map(r => r.id) ?? [],
    ])
  );

  // Determine the minimum number of requirements that need to be selected from
  // a category to receive points.  If a category doesn't have a minimum then
  // it's value will be 0.  This information comes from the checkpoint.
  const required = Object.fromEntries(
    categories.map(c => [
      c,
      checkpoint?.categories?.find(cat => cat.type === c)?.required ?? 0,
    ])
  );

  return lodash.sum(
    // Determine the score for each category.
    categories.map(c => {
      const selected = requirements[c].filter(r => value?.[r]).length;

      if (required[c] === 0) {
        // This is a category that awards points for each selected requirement.
        return (available * selected) / requirements[c].length;
      } else {
        // This is an all or nothing category.
        return selected >= required[c] ? available : 0;
      }
    })
  );
};

const getMultipleChoiceComponentScore = (component, value) => {
  return value ? component.weight : 0;
};

/**
 * getCriteriaScore is a helper that returns the score for a specific criteria
 * given the project's rubric, any rubric checkpoints it contains, as well as
 * a student's progress object for the project.  The score is returned as a
 * rational number with a numerator and denominator.
 */
export const getCriteriaScore = (criteria, rubric, checkpoints, progress) => {
  const numerator = lodash.sum(
    criteria.components.map(component => {
      const value = progress?.scoring?.components?.[component.id];

      switch (component.type) {
        case 'question':
          return getQuestionComponentScore(component, value);

        case 'checkpoint':
          if (component?.variant === 'rubric') {
            const checkpoint = lodash.first(
              checkpoints.filter(c => c.id === component.id)
            );

            return getRubricComponentScore(
              rubric,
              checkpoint,
              component,
              value
            );
          }

          // This is a multiple choice component
          return getMultipleChoiceComponentScore(component, value);

        default:
          throw new Error(
            `Unsupported criteria component type: ${component.type}`
          );
      }
    })
  );

  const denominator = lodash.sum(criteria.components.map(c => c.weight));

  return {
    numerator: numerator,
    denominator: denominator,
  };
};

/**
 * useScoringSummary is a hook that provides a read-only, high-level view of the
 * overall score of a project given a progress and the adjustment that's to be
 * applied.
 */
export const useScoringSummary = (progress, adjustment) => {
  const project = useProject();

  const rubric = React.useMemo(() => {
    return project.rubric;
  }, [project]);

  const checkpoints = React.useMemo(() => {
    return projects.findCheckpoints(project, 'rubric');
  }, [project]);

  const criteria = React.useMemo(() => {
    return project?.scoring?.criteria ?? [];
  }, [project]);

  const scores = React.useMemo(() => {
    return criteria.map(c =>
      getCriteriaScore(c, rubric, checkpoints, progress)
    );
  }, [criteria, rubric, checkpoints, progress]);

  const total = React.useMemo(
    () =>
      scores.reduce(
        (sum, score) => ({
          numerator: sum.numerator + score.numerator,
          denominator: sum.denominator + score.denominator,
        }),
        { numerator: adjustment, denominator: 0 }
      ),
    [adjustment, scores]
  );

  const isOutOfBounds = React.useMemo(() => {
    return (
      total.numerator < 0 ||
      (total.denominator !== 0 && total.numerator / total.denominator > 1.5)
    );
  }, [total]);

  return {
    criteria: criteria,
    scores: scores,
    total: total,
    isOutOfBounds: isOutOfBounds,
  };
};
