import * as lodash from 'lodash';
import * as projects from '../utils/projects';
import React from 'react';
import { useProject } from '../contexts/ProjectContext';

export const getRubricCriteriaComponentRequirements = (project, component) => {
  const checkpoint = lodash.first(
    projects
      .findCheckpoints(project, 'rubric')
      .filter(cp => cp.id === component.id)
  );

  // Which categories are allowed to appear in the component.  This is the
  // intersection of the categories that the checkpoint shows and the
  // categories the component specifies.
  const allowed = lodash.intersection(
    (checkpoint?.categories ?? project?.rubric ?? []).map(c => c.type),
    component.categories ?? (project?.rubric ?? []).map(c => c.type)
  );

  // Only return requirements from the allowed categories.
  return project?.rubric
    .filter(c => allowed.includes(c.type))
    .flatMap(c => c.requirements);
};

export const useRubricCriteriaComponentRequirements = component => {
  const project = useProject();

  return React.useMemo(
    () => getRubricCriteriaComponentRequirements(project, component),
    [component, project]
  );
};
