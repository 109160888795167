import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import People from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Widgets from '@material-ui/icons/Widgets';

const options = [
  {
    id: 'sections',
    title: 'Sections',
    route: '/teacher/sections',
    icon: <People />,
  },
  {
    id: 'curriculum',
    title: 'Curriculum',
    route: '/courses',
    icon: <Widgets />,
  },
];

const StyledNavTeacher = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    border-radius: 4px;
    box-shadow: 4px 4px 38px rgba(0, 0, 0, 0.45);
    width: 356px;
  `
);

const StyledBar = styled.div(
  ({ theme }) => `
    align-items: center;
    border-bottom: 2px solid ${theme.palette.background.level3};
    display: flex; 
    height: 62px;
    padding: ${theme.spacing(2)}px;

    svg {
      background-color: #B0B0F94D;
      border-radius: 2px;
      color: ${theme.palette.primary.main};
      cursor: pointer;
    }
    img {
      margin-left: ${theme.spacing(3)}px;
    }
  `
);

const StyledList = styled(List)(
  () => `
    margin: 0;
    padding: 0 0 4px 0;
  `
);

const StyledListItem = styled(ListItem)(
  ({ $isSelected, theme }) => `
    background: ${$isSelected ? '#B0B0F94D' : 'none'};
    border-color: ${
      $isSelected ? theme.palette.primary.main : theme.palette.primary.light
    };
    border-style: solid;
    border-width: 0 0 0 4px;
    color: ${theme.palette.primary.main};
    height: ${theme.spacing(6)}px;
    padding: ${theme.spacing(1)}px;

    .MuiListItemIcon-root {
      min-width: 0;
    }
    .MuiListItemText-root {
      margin-left: ${theme.spacing(1)}px;
    }
    .MuiTypography-body1 {
      font-size: 14px;
      font-weight: ${$isSelected ? 600 : 400};
      :hover {
        font-weight: 600;
      }
    }
    &:hover {
      background-color: ${$isSelected ? '#B0B0F94D' : 'none'};
    }
    svg {
      color: ${theme.palette.primary.main};
    }
  `
);

const NavTeacher = ({ selected }) => {
  const history = useHistory();
  const handleNavClick = route => () => history.push(route);

  return (
    <StyledNavTeacher>
      <StyledBar>
        <MenuIcon />
        <img src="/static/assets/hello-world-logo-small-alt.png" />
      </StyledBar>
      <StyledList>
        {options.map(item => (
          <StyledListItem
            key={item.id}
            $isSelected={selected === item.id}
            button
            onClick={handleNavClick(item.route)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </StyledListItem>
        ))}
      </StyledList>
    </StyledNavTeacher>
  );
};

NavTeacher.propTypes = {
  selected: PropTypes.string,
};

export default NavTeacher;
