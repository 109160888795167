import Button from '@material-ui/core/Button';
import { FrameCard } from '../Frames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const CodeResponseContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(2)}px;
    max-width: ${theme.spacing(180)}px;
    width: 100%;
  `
);

const StyledFrameContent = styled.div(
  ({ theme, disabled }) => `
    display: flex;
    flex-direction: column;
    padding: 3px ${theme.spacing(3)}px 0;

    button {
      align-self: flex-end;
      width: ${theme.spacing(8)}px;
    }
    
    textarea {
      background-color: ${theme.palette.background.level1};
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: ${theme.palette.text.secondary};
      font-family: "Courier", monospace;
      font-size: 16px;
      font-weight: 400;
      height: 100%;
      line-height: 24px;
      resize: none;
      margin-bottom: ${theme.spacing(disabled ? 0 : 3)}px;
      outline: none;
      overflow: hidden;
      padding: ${theme.spacing(2)}px;
      
      &:focus {
        outline: none;
      }
    }
  `
);

const CodeResponse = ({ onChange, readOnly, text }) => {
  const [value, setValue] = React.useState(text || '');

  return (
    <CodeResponseContainer className="activity-container">
      <FrameCard title="Code Response" padding={{ top: 3, bottom: 3 }}>
        <StyledFrameContent disabled={readOnly}>
          <TextareaAutosize
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            disabled={readOnly}
            minRows={20}
            onChange={e => setValue(e.target.value)}
            placeholder={!value ? 'Type your code here' : null}
            spellCheck={false}
            value={value}
          />
          {!readOnly && (
            <Button
              color="primary"
              disabled={value === ''}
              onClick={() => onChange(value)}
              variant="outlined"
            >
              SAVE
            </Button>
          )}
        </StyledFrameContent>
      </FrameCard>
    </CodeResponseContainer>
  );
};

CodeResponse.propTypes = {
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  text: PropTypes.string,
};

export default CodeResponse;
