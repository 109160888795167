import FramedText from '../../../../components/FramedText';
import PropTypes from 'prop-types';
import React from 'react';

export const ScoringFeedbackContainer = ({
  feedback,
  isEditable,
  onChange,
}) => (
  <FramedText
    title="Feedback"
    placeholder="Give student feedback (optional)"
    text={feedback}
    readOnly={!isEditable}
    onChange={e => onChange(e.target.value)}
  />
);
ScoringFeedbackContainer.propTypes = {
  feedback: PropTypes.string,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
};
