import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg(
  ({ $active, $fill, theme }) => `
    height: 18px;
    width: 18px;
    fill: ${$active ? theme.palette.primary.main : $fill};
  `
);

const MoreIcon = ({ active, fill = '#C4C4C4' }) => (
  <StyledSvg
    $active={active}
    $fill={fill}
    height="18"
    viewBox="0 0 18 18"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="2" r="2" />
    <circle cx="9" cy="9" r="2" />
    <circle cx="9" cy="16" r="2" />
  </StyledSvg>
);

MoreIcon.propTypes = {
  active: PropTypes.bool,
  fill: PropTypes.string,
};

export default MoreIcon;
