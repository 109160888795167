import PropTypes from 'prop-types';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PDF = React.forwardRef((props, ref) => (
  <SvgIcon
    ref={ref}
    viewBox="0 0 33 32"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="BackCaret"
    style={{ height: `${props.width}px`, width: `${props.height}px` }}
    {...props}
  >
    <path
      d="M24.71 1V2.99658H28.4488L24 7.59241L25.4684 9L29.9172 4.40418V7.98805H32V1H24.71Z"
      fill="#F15642"
      className="arrow"
    />
    <path
      d="M9.875 3.5C8.98125 3.5 8.25 4.23125 8.25 5.125V27.875C8.25 28.7687 8.98125 29.5 9.875 29.5H26.125C27.0187 29.5 27.75 28.7687 27.75 27.875V10L21.25 3.5H9.875Z"
      fill="#E2E5E7"
    />
    <path
      d="M22.875 10H27.75L21.25 3.5V8.375C21.25 9.26875 21.9812 10 22.875 10Z"
      fill="#B0B7BD"
    />
    <path d="M27.75 14.875L22.875 10H27.75V14.875Z" fill="#CAD1D8" />
    <path
      d="M24.5 24.625C24.5 25.0719 24.1344 25.4375 23.6875 25.4375H5.8125C5.36562 25.4375 5 25.0719 5 24.625V16.5C5 16.0531 5.36562 15.6875 5.8125 15.6875H23.6875C24.1344 15.6875 24.5 16.0531 24.5 16.5V24.625Z"
      fill="#F15642"
    />
    <path
      d="M8.54187 18.8945C8.54187 18.68 8.71087 18.446 8.98306 18.446H10.4837C11.3287 18.446 12.0892 19.0115 12.0892 20.0954C12.0892 21.1224 11.3287 21.6944 10.4837 21.6944H9.39906V22.5524C9.39906 22.8384 9.21706 23.0001 8.98306 23.0001C8.76856 23.0001 8.54187 22.8384 8.54187 22.5524V18.8945ZM9.39906 19.2642V20.8827H10.4837C10.9192 20.8827 11.2637 20.4984 11.2637 20.0954C11.2637 19.6412 10.9192 19.2642 10.4837 19.2642H9.39906Z"
      fill="white"
    />
    <path
      d="M13.3616 23C13.1471 23 12.9131 22.883 12.9131 22.5978V18.9074C12.9131 18.6742 13.1471 18.5044 13.3616 18.5044H14.8493C17.8181 18.5044 17.7531 23 14.9078 23H13.3616ZM13.7711 19.2974V22.2078H14.8493C16.6035 22.2078 16.6815 19.2974 14.8493 19.2974H13.7711Z"
      fill="white"
    />
    <path
      d="M18.8062 19.3495V20.3822H20.4629C20.6969 20.3822 20.9309 20.6162 20.9309 20.8428C20.9309 21.0573 20.6969 21.2329 20.4629 21.2329H18.8062V22.597C18.8062 22.8245 18.6445 22.9992 18.417 22.9992C18.131 22.9992 17.9563 22.8245 17.9563 22.597V18.9067C17.9563 18.6735 18.1318 18.5037 18.417 18.5037H20.6977C20.9837 18.5037 21.1527 18.6735 21.1527 18.9067C21.1527 19.1147 20.9837 19.3487 20.6977 19.3487H18.8062V19.3495Z"
      fill="white"
    />
    <path
      d="M23.6875 25.4375H8.25V26.25H23.6875C24.1344 26.25 24.5 25.8844 24.5 25.4375V24.625C24.5 25.0719 24.1344 25.4375 23.6875 25.4375Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
));

PDF.displayName = 'PDF';

PDF.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default PDF;
