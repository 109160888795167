const statusNames = {
  not_started: 'Not Started',
  in_progress: 'In Progress',
  grading: 'Submitted',
  completed: 'Completed',
  sent_back: 'Sent Back',
  excused: 'Excused',
};

export const getStatusTitle = status => statusNames[status] || '';
