import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import UnderScoreFlicker from '../components/UnderScoreFlicker';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-repeat: repeat;
  background-size: cover;
  background-color: ${({ theme }) => theme.palette.background.nav};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin-top: ${({ theme }) => theme.spacing(8)}px;
    align-self: flex-end;
  }
`;

const LoginButton = styled(Button)`
  text-decoration: none;
  width: 100%;
`;

const App = () => {
  return (
    <Container>
      <Login>
        <img
          alt=""
          src="/static/assets/hello-world-logo-full.png"
          style={{ maxHeight: '300px' }}
        />
        <Link style={{ textDecoration: 'none', marginTop: '36px' }} to="/Login">
          <LoginButton
            color="primary"
            style={{ maxWidth: '100%' }}
            variant="outlined"
          >
            <Typography variant="button">
              &gt;<UnderScoreFlicker>_ </UnderScoreFlicker> Login
            </Typography>
          </LoginButton>
        </Link>
      </Login>
    </Container>
  );
};

export default App;
