import * as externalAccount from '../utils/externalAccountLogin';
import api from '../api';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Credential from '../components/Credential';
import { getAuth } from 'firebase/auth';
import NavTeacher from '../components/NavTeacher';
import Page from '../components/Page';
import React from 'react';
import SSOAuthButton from '../components/Button/OauthButton';
import styled from 'styled-components';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { useHistory } from 'react-router-dom';
import { useUser } from '../contexts/AuthContext';

const ProfileStyles = styled.div(
  ({ theme }) => `
    .back {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: ${theme.spacing(2, 0, 1, 4)};

      a {
        display: flex;
        align-items: center;
        
        color: ${theme.palette.text.hint};
        cursor: pointer;
        font-family: 'IBM Plex Mono', monospace;
        font-size: 15px;
        font-weight: 400;
        
        &:hover {
          color: ${theme.palette.text.primary};
        }
        
        svg {
          margin-right: ${theme.spacing(1)}px;
          height: 24px;
          width: 24px;
        }
      }
    }
  
    .section {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)}px;
      margin: 0 auto;
      padding: ${theme.spacing(2, 0)};
      width: 50%;
      
      .heading {
        color: ${theme.palette.actions.main};
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-weight: 500;
      }
      
      .subheading {
        color: ${theme.palette.text.primary};
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
      }
      
      .body {
        background-color: rgba(255, 255, 255, 0.04);
      }
    }
    
    .account {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing(2)}px;
      
      .user {
        display: grid;
        grid-gap: ${theme.spacing(1)}px;
        grid-template-columns: 100px auto;
      }
      
      .image {
        display: flex;
        align-items: center;
        gap: ${theme.spacing(3)}px;
        
        img {
          background-color: ${theme.palette.background.level1};
          width: 112px;
          height: 112px;
        }
      }
      
      .label, .value {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
      }
       
      .value {
        font-weight: 600;
      }
    }
    
    .credentials {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1.5)}px;
      padding: ${theme.spacing(2, 3)};
      
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${theme.spacing(1)}px;
      }

      button {
        height: 35px;
        color: white;
        font-size: 18px;
        font-weight: 400;
        
        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;
        }
      }
      
      .credential {
        border: 1px solid #7d7d7d;
      }
    }
  `
);

const BackButton = () => {
  const history = useHistory();
  const backTo = history.location.state?.backTo;

  const onClick = React.useCallback(
    e => {
      e.preventDefault();
      history.push(backTo.path, backTo?.state);
    },
    [history, backTo]
  );

  if (!backTo) {
    return null;
  }

  return (
    <div className="back">
      <a onClick={onClick}>
        <ArrowBackIcon />
        BACK
      </a>
    </div>
  );
};

const AccountBlock = () => {
  const user = useUser();
  const isTeacher = user.isTeacherForAnySections();
  const auth = getAuth();
  return (
    <div className="section">
      <div className="heading">Hello World CS</div>
      <div className="account body">
        <div className="user">
          <span className="label">First name:</span>
          <span className="value">{user.first_name}</span>
          <span className="label">Last name:</span>
          <span className="value">{user.last_name}</span>
          {user.username && <span className="label">Username:</span>}
          {user.username && <span className="value">{user.username}</span>}
        </div>
        {!isTeacher && user.password_image_url && (
          <div className="image">
            <span className="label">Login image:</span>
            <img alt="password image" src={user.password_image_url} />
          </div>
        )}
      </div>
      <Box display="flex" flexDirection="row">
        {useBooleanFlagValue('enable-google-single-sign-on', false) &&
          (auth.currentUser.providerData.filter(
            provider => provider.providerId === 'google.com'
          ).length === 0 ? (
            <SSOAuthButton provider="google" action="link" />
          ) : (
            <SSOAuthButton provider="google" action="unlink" />
          ))}
        {auth.currentUser.providerData.filter(
          provider => provider.providerId === 'oidc.clever'
        ).length === 0 ? (
          <SSOAuthButton provider="clever" action="link" />
        ) : (
          <SSOAuthButton provider="clever" action="unlink" />
        )}
      </Box>
    </div>
  );
};
const ExternalAccountsBlock = () => {
  const history = useHistory();
  const user = useUser();

  const [loading, cospaces] = api.load(
    api.external.account.get('cospaces', user.id)
  );

  const onOpenLoginSteps = React.useCallback(
    tool => {
      externalAccount.clearLoggedIn(tool);

      history.push('/account-login', {
        tool: tool,
        continueTo: {
          type: 'profile',
          path: '/profile',
          state: history.location.state,
        },
      });
    },
    [history]
  );

  if (loading) {
    return null;
  }

  if (!cospaces) {
    return null;
  }

  return (
    <div className="section">
      <div className="heading">Course Accounts</div>
      {cospaces && (
        <div className="credentials body">
          <div className="row">
            <div className="subheading">CoSpaces:</div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onOpenLoginSteps('cospaces')}
            >
              OPEN LOGIN STEPS
              <ChevronRightIcon />
            </Button>
          </div>
          <Credential
            className="credential"
            title="Username"
            content={cospaces.username}
          />
          <Credential
            className="credential"
            title="Password"
            content={cospaces.password}
          />
        </div>
      )}
    </div>
  );
};

const Profile = () => {
  const user = useUser();
  const isTeacher = user.isTeacherForAnySections();

  return (
    <Page menu={isTeacher && <NavTeacher />} includeProfileInHeader={false} qq>
      <ProfileStyles>
        <BackButton />
        <AccountBlock />
        <ExternalAccountsBlock />
      </ProfileStyles>
    </Page>
  );
};

export default Profile;
