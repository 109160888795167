import { BackCaret, LoadingIndicator } from '../components';
import { StyledDialogContent, StyledDialogTitle } from '../components/Dialog';
import { useHistory, useParams } from 'react-router-dom';
import api from '../api';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../contexts/AuthContext';

//
// Presentational Components - these have no logic in them, just styles.
//

const LoginSectionStyles = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.nav};
    height: 100vh;
    width: 100%;

    .header {
      display: flex;
      align-items: center;
      width: 100%;

      background-color: ${theme.palette.background.nav};
      filter: drop-shadow(0 2px 2px #000000);
      padding: ${theme.spacing(1, 4)};

      .logo {
        align-self: flex-start;
      }

      .title {
        text-align: center;
        width: 100%;
      }
    }

    .body {
      display: flex;
      padding: ${theme.spacing(4)}px;
    }

    .nav {
      padding-right: ${theme.spacing(12)}px;

      svg {
        margin-right: ${theme.spacing(1)}px;
      }

      .MuiButton-label {
        font-size: 18px;
        padding: ${theme.spacing(0, 1)};
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .error, .message {
      border-radius: 4px;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 14px;
      font-weight: 400;
      margin-left: ${theme.spacing(2)}px;
      padding: ${theme.spacing(0.5, 1)};
    }

    .student-selector {
      padding-bottom: ${theme.spacing(2)}px;

      .title {
        font-size: 19px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        line-height: 28px;

        // This aligns the left edge of the title with the student buttons.
        padding-left: ${theme.spacing(1)}px;
      }

      .error {
        background-color: #FBECEF;
        border: 1px solid #FBECEF;
        color: #B84848;
      }

      .students {
        display: flex;
        flex-wrap: wrap;
      }

      .student {
        padding: ${theme.spacing(1.5, 1)};

        .MuiButton-outlined {
          border: 1px solid #FFFFFF;
          background-color: ${theme.palette.background.paper};

          :hover {
            color: #242424;
            background-color: ${theme.palette.background.level1};
          }
        }

        .MuiButton-label {
          font-size: 14px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
        }

        &.selected {
          .MuiButton-outlined {
            background-color: ${theme.palette.background.level1};
            border: 1px solid ${theme.palette.actions.main};
          }

          .MuiButton-label {
            color: ${theme.palette.actions.main};
          }
        }
      }
    }

    .password-image-selector {
      .title {
        font-size: 19px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        line-height: 28px;

        // This aligns the left edge of the title with the student title.
        padding-left: ${theme.spacing(1)}px;
      }

      .message {
        background-color: #EFFEE5;
        border: 1px solid #517A35;
        color: #517A35;
      }

      .password-images {
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing(2)}px;

        // This aligns the left edge of the images with the title and leaves
        // some space between the images and the title.
        padding: ${theme.spacing(1.5, 0, 0, 1)};
      }

      .password-image {
        background-color: ${theme.palette.background.level1};
        border-radius: 6px;

        img {
          width: 72px;
          height: 72px;
        }

        .MuiButton-root {
          border: 4px solid ${theme.palette.background.level1};
          padding: 0;
        }

       .MuiButton-root:hover {
          border-color: ${theme.palette.actions.main};
        }

        &.selected .MuiButton-root {
          border-color: ${theme.palette.actions.main};
        }
      }
    }
  `
);

const Header = ({ sectionCode, title }) => (
  <div className="header">
    <img
      className="logo"
      src="/static/assets/hello-world-logo-small.png"
      alt="Hello World Logo"
    />
    <div className="title">
      Welcome to {title} - {sectionCode}
    </div>
  </div>
);
Header.propTypes = {
  sectionCode: PropTypes.string,
  title: PropTypes.string,
};

const Nav = ({ onBackClick }) => (
  <div className="nav">
    <Button color="primary" variant="outlined" onClick={onBackClick}>
      <BackCaret size={16} />
      BACK
    </Button>
  </div>
);
Nav.propTypes = {
  onBackClick: PropTypes.func,
};

const Student = ({ student, selected, onClick }) => (
  <div className={`student ${selected ? 'selected' : ''}`}>
    <Button variant="outlined" onClick={() => onClick(student.id)}>
      {student.short_name}
    </Button>
  </div>
);
Student.propTypes = {
  student: PropTypes.shape({
    id: PropTypes.string,
    short_name: PropTypes.string,
  }),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const PasswordImage = ({ image, selected, onClick }) => (
  <div className={`password-image ${selected ? 'selected' : ''}`}>
    <Button onClick={() => onClick(image.id)}>
      <img src={image.url} />
    </Button>
  </div>
);
PasswordImage.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  }),
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

//
// Container Components - these have logic but no presentation
//

const StudentSelector = ({ students, selected, error, onSelectStudent }) => {
  const onClick = React.useCallback(
    studentId => onSelectStudent(studentId !== selected ? studentId : null),
    [selected, onSelectStudent]
  );

  return (
    <div className="student-selector">
      <div>
        <Typography className="title" component="span">
          Select your name:
        </Typography>
        {error && (
          <Typography className="error" component="span">
            {error}
          </Typography>
        )}
      </div>
      <div className=" students">
        {students.map(student => (
          <Student
            key={student.id}
            student={student}
            selected={selected === student.id}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};
StudentSelector.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      short_name: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
  error: PropTypes.string,
  onSelectStudent: PropTypes.func,
};

const PasswordImageSelector = ({
  images,
  selected,
  message,
  onSelectImage,
}) => {
  const onClick = React.useCallback(
    imageId => {
      if (selected !== imageId) {
        onSelectImage(imageId);
      }
    },
    [selected, onSelectImage]
  );

  return (
    <div className=" password-image-selector">
      <div>
        <Typography className="title" component="span">
          Select your image:
        </Typography>
        {message && (
          <Typography className="message" component="span">
            {message}
          </Typography>
        )}
      </div>
      <div className=" password-images">
        {images.map(image => (
          <PasswordImage
            key={image.id}
            image={image}
            selected={selected === image.id}
            onClick={onClick}
          />
        ))}
      </div>
    </div>
  );
};
PasswordImageSelector.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
  message: PropTypes.string,
  onSelectImage: PropTypes.func,
};

//
// Page
//

const LoginSection = () => {
  const history = useHistory();
  const { sectionCode } = useParams();
  const { user, signinWithCustomToken } = useAuth();

  const [loading, metadata, students] = api.load(
    api.authentication.getSectionMetadata(sectionCode),
    api.authentication.getSectionStudents(sectionCode)
  );

  // The current state of the page.
  //   studentId = The selected student id
  //   images = The selected students password images
  //   imageId = The selected password image id
  //   error = The error message to show (incorrect image was selected)
  //   message = The success message to show (correct image is selected)
  const [state, setState] = React.useState({});

  // When we authenticate a user, redirect to a page for the course.  This will
  // also handle the case where a user comes to this page but is already
  // authenticated.
  React.useEffect(() => {
    if (loading || user === null) {
      return;
    }

    const url = metadata?.course_id
      ? `/section/${metadata.id}/course/${metadata.course_id}`
      : `/courses`;
    history.push(url);
  }, [history, user, loading, metadata]);

  const onBack = React.useCallback(() => {
    history.push('/login');
  }, [history]);

  const onSelectStudent = React.useCallback(async studentId => {
    if (studentId === null) {
      setState({});
      return;
    }

    const images = await api.authentication.getStudentPasswordImages(studentId);
    setState({
      studentId: studentId,
      images: images,
    });
  }, []);

  const onSelectImage = React.useCallback(
    async imageId => {
      setState(prev => ({
        ...prev,
        imageId: imageId,
      }));

      let token;
      try {
        token = await api.authentication.authenticateUsingPasswordImage(
          state.studentId,
          imageId
        );
      } catch {
        setState({ error: 'Invalid login. Please try again.' });
        return;
      }

      setState(prev => ({
        ...prev,
        message: 'Success!',
      }));
      await signinWithCustomToken(state.studentId, token);
    },
    [state.studentId, signinWithCustomToken]
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <LoginSectionStyles>
      <Header sectionCode={sectionCode} title={metadata.title} />
      <div className=" body">
        <Nav onBackClick={onBack} />
        <div className=" content">
          {metadata.image_login_enabled && (
            <StudentSelector
              students={students}
              selected={state?.studentId}
              error={state?.error}
              onSelectStudent={onSelectStudent}
            />
          )}
          {state?.images && (
            <PasswordImageSelector
              images={state.images}
              selected={state?.imageId}
              message={state?.message}
              onSelectImage={onSelectImage}
            />
          )}
        </div>
        {!metadata.image_login_enabled && (
          <Dialog open={true}>
            <StyledDialogTitle>
              Image Login is Disabled for this Section
            </StyledDialogTitle>
            <StyledDialogContent>
              <div>Please login using your username and password.</div>
              <div>
                <Button variant="contained" color="primary" onClick={onBack}>
                  Back to Login Page
                </Button>
              </div>
            </StyledDialogContent>
          </Dialog>
        )}
      </div>
    </LoginSectionStyles>
  );
};

LoginSection.propTypes = {};

export default LoginSection;
