const getProjectPlayability = (project, progress) => {
  // Determine which step is first not completed step.  That's the playable
  // step.  If all steps have been completed then this will be null.
  const playableStepId = project.section_groups
    .flatMap(group =>
      group.sections.flatMap(section =>
        section.steps.map(step => [step.id, progress.steps?.[step.id] ?? false])
      )
    )
    .filter(entry => !entry[1])
    .map(entry => entry[0])
    .reduce((current, next) => current ?? next, null);

  const groupsPlayability = {};
  for (const group of project.section_groups) {
    const sectionsPlayability = {};

    for (const section of group.sections) {
      const stepsPlayability = {};

      for (const step of section.steps) {
        stepsPlayability[step.id] = {
          isCurrent: step.id === progress.position.step,
          isComplete: progress.steps?.[step.id] ?? false,
          isPlayable: step.id === playableStepId,
        };
      }

      sectionsPlayability[section.id] = {
        id: section.id,
        isCurrent: Object.values(stepsPlayability).some(p => p.isCurrent),
        isComplete: Object.values(stepsPlayability).every(p => p.isComplete),
        steps: stepsPlayability,
      };
    }

    groupsPlayability[group.id] = {
      id: group.id,
      isCurrent: Object.values(sectionsPlayability).some(p => p.isCurrent),
      isComplete: Object.values(sectionsPlayability).every(p => p.isComplete),
      sections: sectionsPlayability,
    };
  }

  return {
    id: project.id,
    section_groups: groupsPlayability,
  };
};

export default getProjectPlayability;
