import * as projects from '../../../utils/projects';
import {
  CheckpointContextContainer,
  ContextsContainer,
  ContextSelector,
} from './components/context';
import {
  CoSpacesFrame,
  CoSpacesRenderActivity,
  IframeActivity,
  PickcodeActivity,
} from '../../../components/Activity';
import { useProgress, useRenderState } from '../contexts';
import api from '../../../api';
import CodeResponse from '../../../components/Activity/code-response';
import config from '../../../config';
import PropTypes from 'prop-types';
import React from 'react';
import { UnableToDisplayCoSpace } from './components/errors';
import { useCheckpointAnswer } from '../hooks';
import { useProject } from '../../../contexts/ProjectContext';
import { useUser } from '../../../contexts/AuthContext';
import WrittenResponse from '../../../components/Activity/written-response';

export const Contexts = () => {
  const render = useRenderState();
  const { progress } = useProgress();
  const [selected, setSelected] = React.useState(0);

  // When we switch the set of available contexts reset the selected context
  // back to the first one.
  React.useEffect(() => setSelected(0), [render.contexts]);

  if (!render.contexts || render.contexts.length === 0) {
    return null;
  }

  const context = render.contexts?.[selected];

  // Whenever we change the selected context, determine if it's a link context,
  // and if so, compute the URL so that we can pass it into the selector.  If
  // the selected context isn't a link context then the url will be null.
  const url = (function () {
    if (context?.type !== 'link') {
      return null;
    }

    return progress?.checkpoints?.[context.checkpoint]?.value;
  })();

  return (
    <ContextsContainer>
      <ContextSelector
        contexts={render.contexts}
        selected={selected}
        url={url}
        onChange={setSelected}
      />
      {context?.type === 'activity' && <ActivityContext context={context} />}
      {context?.type === 'checkpoint' && (
        <CheckpointContext context={context} />
      )}
      {context?.type === 'link' && <LinkContext url={url} />}
    </ContextsContainer>
  );
};

//
// Activity context
//

const ActivityContext = ({ context }) => {
  const project = useProject();
  const render = useRenderState();

  const activity = React.useMemo(() => {
    return project?.activities?.[context.activity];
  }, [project, context]);

  if (!activity) {
    return null;
  }

  switch (activity.tool) {
    case 'cospaces':
      if (activity.action === 'render') {
        return <CoSpacesRenderActivity activity={activity} />;
      }
      return <CoSpacesEditActivity activity={activity} />;

    case 'code-response':
      return <CodeResponseContext activity={activity} />;

    case 'pickcode':
      return (
        <PickcodeActivity
          activity={activity}
          projectId={project.id}
          ownerId={render.studentId}
          includeCode={render?.view?.guide ?? false}
        />
      );

    case 'written-response':
      return <WrittenResponseContext activity={activity} />;

    default:
      return null;
  }
};
ActivityContext.propTypes = {
  context: PropTypes.shape({
    activity: PropTypes.string,
  }),
};

const CoSpacesEditActivity = ({ activity }) => {
  // CoSpaces edit activities need to insert both teacher credentials and
  // class and assignment ids into the URL.
  const user = useUser();
  const render = useRenderState();
  const project = useProject();
  const { progress } = useProgress();
  const [loading, token, cospacesIds, account, scorable] = api.load(
    api.external.account.cospaces.token.get(user.id),

    // TODO: In the future remove the usage of render.sectionId once all progresses
    // always have a section id in them.
    api.section.getCoSpacesIds(progress?.section_id ?? render.sectionId),

    api.external.account.get('cospaces', render.studentId),
    api.teacher.getScorableCoSpaceClasses(user.id)
  );

  if (loading) {
    return null;
  }

  // If the teacher won't be able to score the submission then we have to show them
  // an error page.
  const classId = cospacesIds?.class_id;
  if (!scorable.includes(classId)) {
    return <UnableToDisplayCoSpace />;
  }

  const assignmentId =
    cospacesIds?.json?.assignment_ids?.[project.id]?.[activity.id];
  if (!classId || !assignmentId) {
    // TODO: Provide a better formatted error message.
    return <div>Failed to load cospaces ids, activity not available.</div>;
  }

  if (!token) {
    // TODO: Provide a better formatted error message.
    return (
      <div>
        Failed to load teacher cospaces authentication token, activity not
        available.
      </div>
    );
  }

  if (!account?.username) {
    return (
      <div>Failed to load student cospaces account, context unavailable.</div>
    );
  }

  let url = `${config.cospaces.base_url}/Studio/Assignment/${assignmentId}/${classId}?student=${account.username}`;
  url = `${url}&auth_basic=${encodeURIComponent(token.value)}`;

  return <CoSpacesFrame url={url} />;
};
CoSpacesEditActivity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const CodeResponseContext = ({ activity }) => {
  const { progress } = useProgress();
  const answer = useCheckpointAnswer(activity.id);

  // The code response component is going to capture the first value of
  // the answer we pass into it and then ignore any updates to it.  Make sure
  // that when we put the component on the page we've loaded the full progress
  // and aren't looking at the default value of the checkpoint answer that's
  // returned before the progress has fully loaded.
  if (!progress?.version) {
    return null;
  }

  return <CodeResponse key={activity.id} text={answer.value} readOnly />;
};
CodeResponseContext.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const WrittenResponseContext = ({ activity }) => {
  const { progress } = useProgress();
  const answer = useCheckpointAnswer(activity.id);

  // The written response component is going to capture the first value of
  // the answer we pass into it and then ignore any updates to it.  Make sure
  // that when we put the component on the page we've loaded the full progress
  // and aren't looking at the default value of the checkpoint answer that's
  // returned before the progress has fully loaded.
  if (!progress?.version) {
    return null;
  }

  return <WrittenResponse key={activity.id} text={answer.value} readOnly />;
};
WrittenResponseContext.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
};

//
// Checkpoint context
//

const CheckpointContext = ({ context }) => {
  const project = useProject();
  const { progress } = useProgress();

  // Each item we're going to render, in the order we'll render them.  An item
  // is a checkpoint along with the student's value for that checkpoint.
  const items = React.useMemo(() => {
    if (!progress.checkpoints) {
      return [];
    }

    const checkpoints = Object.fromEntries(
      projects.findCheckpoints(project).map(cp => [cp.id, cp])
    );

    return context.checkpoints.map(id => {
      const checkpoint = checkpoints[id];
      const value = progress.checkpoints[id];

      return {
        checkpoint: checkpoint,
        answer: value,
      };
    });
  }, [project, progress, context]);

  return (
    <>
      {items.map((item, index) => (
        <CheckpointContextContainer
          key={index}
          label={`${context.title} #${index + 1}`}
          checkpoint={item.checkpoint}
          answer={item.answer}
        >
          <h4>{index + 1}</h4>
          <div>{JSON.stringify(item)}</div>
        </CheckpointContextContainer>
      ))}
    </>
  );
};
CheckpointContext.propTypes = {
  context: PropTypes.shape({
    title: PropTypes.string,
    checkpoints: PropTypes.arrayOf(PropTypes.string),
  }),
};

//
// Link context
//

const LinkContext = ({ url }) => <IframeActivity url={url} />;
LinkContext.propTypes = {
  url: PropTypes.string,
};
