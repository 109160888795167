import * as lodash from 'lodash';
import { ButtonsContainer, SelectAllContainer } from './components/buttons';
import { getRubricCriteriaComponentRequirements } from '../../../hooks/useRubricCriteriaComponentRequirements';
import PropTypes from 'prop-types';
import React from 'react';
import { useProgress } from '../contexts';
import { useProject } from '../../../contexts/ProjectContext';
import { useScoringSummary } from '../../../hooks/useScoringSummary';

const GRADEABLE_STATUSES = new Set(['sent_back', 'grading', 'completed']);

export const Buttons = ({ adjustment, onSendBack, onFinalize }) => {
  const { progress, setProgress, isUpdating } = useProgress();
  const summary = useScoringSummary(progress, adjustment);

  const onEditScore = React.useCallback(() => {
    setProgress({
      ...progress,
      status: 'grading',
    });
  }, [progress, setProgress]);

  // Don't show any buttons if the project isn't in a gradeable state.
  const status = progress?.status;
  if (!GRADEABLE_STATUSES.has(status)) {
    return null;
  }

  return (
    <ButtonsContainer
      isEditing={status === 'grading'}
      isDisabled={summary.isOutOfBounds || isUpdating}
      onEditScore={onEditScore}
      onSendBack={onSendBack}
      onFinalize={onFinalize}
    />
  );
};
Buttons.propTypes = {
  adjustment: PropTypes.number,
  onSendBack: PropTypes.func,
  onFinalize: PropTypes.func,
};

const getQuestionComponentIds = project => {
  const criteria = project?.scoring?.criteria ?? [];

  return criteria
    .flatMap(c => c.components)
    .filter(c => c.type === 'question')
    .map(c => c.id);
};

const getRubricComponentRequirementIds = project => {
  const criteria = project?.scoring?.criteria ?? [];

  // A tuple of checkpoint id + requirement id for all rubric criteria
  // components.  If a rubric checkpoint is referenced in multiple criteria
  // its requirements could be duplicated, so this call is wrapped in uniq to
  // filter out these potential duplicates.
  return lodash.uniqBy(
    criteria
      .flatMap(c => c.components)
      .filter(c => c.type === 'checkpoint' && c?.variant === 'rubric')
      .flatMap(c =>
        getRubricCriteriaComponentRequirements(project, c).map(r => [
          c.id,
          r.id,
        ])
      ),
    a => a.join(':')
  );
};

export const SelectAll = () => {
  const project = useProject();
  const { progress, setProgress, isUpdating } = useProgress();

  const [question, rubric] = React.useMemo(
    () => [
      getQuestionComponentIds(project),
      getRubricComponentRequirementIds(project),
    ],
    [project]
  );

  // Determine if all components are checked.
  const all = React.useMemo(() => {
    const scoring = progress?.scoring?.components ?? {};
    return (
      question.map(id => scoring?.[id]).every(v => v) &&
      rubric.map(([cid, rid]) => scoring?.[cid]?.[rid]).every(v => v)
    );
  }, [question, rubric, progress]);

  // Determine if no components are checked.
  const none = React.useMemo(() => {
    const scoring = progress?.scoring?.components ?? {};
    return (
      !question.map(id => scoring?.[id]).some(v => v) &&
      !rubric.map(([cid, rid]) => scoring?.[cid]?.[rid]).some(v => v)
    );
  }, [question, rubric, progress]);

  const onChange = React.useCallback(() => {
    // Start by copying the scoring components portion of the progress.
    const components = lodash.cloneDeep(progress?.scoring?.components ?? {});

    // Now force the value of every question/rubric component to be either all
    // checked or all unchecked.
    for (const id of question) {
      components[id] = !all;
    }

    for (const [cid, rid] of rubric) {
      components[cid] = components?.[cid] ?? {};
      components[cid][rid] = !all;
    }

    // Finally, save this updated progress.
    setProgress({
      ...progress,
      scoring: {
        ...progress?.scoring,
        components: components,
      },
    });
  }, [question, rubric, all, progress, setProgress]);

  // If there are no component checkboxes that can change, then don't show the
  // select all button.
  if (!question && !rubric) {
    return null;
  }

  return (
    <SelectAllContainer
      state={all ? 'checked' : none ? 'unchecked' : 'indeterminate'}
      onChange={onChange}
      isEditing={progress?.status === 'grading'}
      isDisabled={isUpdating}
    />
  );
};
