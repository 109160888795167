import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';
import React from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    maxWidth: theme.spacing(20),
    padding: theme.spacing(2),
  },
}))(Tooltip);

const StyledIcon = withStyles(() => ({
  root: {
    width: '18px',
    height: '18px',
  },
}))(SvgIcon);

export const NotStartedIcon = ({ className = '' }) => (
  <StyledIcon className={className} viewBox="0 0 16 2">
    <path
      d="M 16.00,0.00
           C 16.00,0.00 16.00,2.00 16.00,2.00
             16.00,2.00 9.00,2.00 9.00,2.00
             9.00,2.00 9.00,0.00 9.00,0.00
             9.00,0.00 16.00,0.00 16.00,0.00 Z
           M 7.00,0.00
           C 7.00,0.00 7.00,2.00 7.00,2.00
             7.00,2.00 0.00,2.00 0.00,2.00
             0.00,2.00 0.00,0.00 0.00,0.00
             0.00,0.00 7.00,0.00 7.00,0.00 Z"
    />
  </StyledIcon>
);
NotStartedIcon.propTypes = {
  className: PropTypes.string,
};

const StyledScore = styled.div(
  ({ theme, flip = false }) => `
    align-items: center;
    display: flex;
    height: ${theme.spacing(5)}px;
    justify-content: center;
    user-select: none;
    width: ${theme.spacing(6)}px;
    svg {
      color: #908F8F;
      transform: scaleX(${flip ? -1 : 1});
    }
    h6 {
      font-weight: 400;
      text-decoration: none;
    }
    &.scored {
      color: #242424;
    }
    &.scoredA {
      background-color: #D1FFC6;
    }
    &.scoredB {
      background-color: #EAFFBC;
    }
    &.scoredC {
      background-color: #FFFFAC;
    }
    &.scoredD {
      background-color: #FFE8AF;
    }
    &.scoredF {
      background-color: #FFCBCA;
    }
    &:hover {
      svg {
        color: ${theme.palette.text.secondary};
      }
    }
  `
);

const ScoreAverageTypography = styled(Typography)(
  () => `
    &.not-available {
      color: #485DDE;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  `
);

export const StatusIcon = ({
  isGroupedScore,
  isNotAvailable,
  levelId,
  projectId,
  score,
  sectionId,
  courseId,
  status,
  studentId,
  tooltipMsg,
}) => {
  const history = useHistory();

  const handleIconClick = () =>
    history.push(
      `/teacher/scoring/section/${sectionId}/course/${courseId}/project/${projectId}`,
      {
        studentId,
        levelId,
      }
    );

  let scoredClasses = 'scored';
  switch (true) {
    case score >= 90:
      scoredClasses += ' scoredA';
      break;
    case score >= 80:
      scoredClasses += ' scoredB';
      break;
    case score >= 70:
      scoredClasses += ' scoredC';
      break;
    case score >= 65:
      scoredClasses += ' scoredD';
      break;
    default:
      scoredClasses += ' scoredF';
      break;
  }

  if (isNotAvailable) {
    return (
      <StyledTooltip title={tooltipMsg ?? 'Not Available'}>
        <StyledScore>
          <ScoreAverageTypography className="not-available">
            N/A
          </ScoreAverageTypography>
        </StyledScore>
      </StyledTooltip>
    );
  }

  if (isGroupedScore) {
    return (
      <StyledTooltip title={tooltipMsg ?? 'Score'}>
        <StyledScore className={scoredClasses}>
          <ScoreAverageTypography variant="h6">{score}%</ScoreAverageTypography>
        </StyledScore>
      </StyledTooltip>
    );
  }
  switch (status) {
    case 'in_progress':
      return (
        <StyledTooltip title="In progress">
          <StyledScore>
            <AccessTimeIcon size={20} />
          </StyledScore>
        </StyledTooltip>
      );
    case 'grading':
      return (
        <StyledTooltip title="Ready to score">
          <Button onClick={handleIconClick}>
            <StyledScore>
              <CreateIcon size={20} />
            </StyledScore>
          </Button>
        </StyledTooltip>
      );
    case 'completed':
      return (
        <StyledTooltip title="Scored">
          <Button onClick={handleIconClick}>
            <StyledScore className={scoredClasses}>
              <Typography variant="h6">{score}%</Typography>
            </StyledScore>
          </Button>
        </StyledTooltip>
      );
    case 'sent_back':
      return (
        <StyledTooltip title="Sent back">
          <Button onClick={handleIconClick}>
            <StyledScore>
              <ReplyIcon size={20} />
            </StyledScore>
          </Button>
        </StyledTooltip>
      );
    case 'blocked':
      return (
        <StyledTooltip title="Blocked">
          <StyledScore flip={true}>
            <BlockIcon size={20} />
          </StyledScore>
        </StyledTooltip>
      );
    case 'excused':
      return (
        <StyledTooltip title="Excused">
          <StyledScore>
            <HighlightOffIcon size={20} />
          </StyledScore>
        </StyledTooltip>
      );
    default:
      return (
        <StyledTooltip title="Not started">
          <StyledScore>
            <NotStartedIcon />
          </StyledScore>
        </StyledTooltip>
      );
  }
};

StatusIcon.propTypes = {
  isGroupedScore: PropTypes.bool,
  isNotAvailable: PropTypes.bool,
  levelId: PropTypes.string,
  projectId: PropTypes.string,
  score: PropTypes.number,
  sectionId: PropTypes.string,
  status: PropTypes.string,
  studentId: PropTypes.string,
  tooltipMsg: PropTypes.string,
};
