import * as lodash from 'lodash';
import api from '../../../api';
import { LoadingIndicator } from '../../../components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const PasswordImagesStyles = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding: ${theme.spacing(2, 4)};
    
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      
      thead {
        text-align: right;
        
        th {
          padding-bottom: ${theme.spacing(2)}px;
        }
        
        .image {
          display: flex;
          justify-content: flex-start;
          padding-left: ${theme.spacing(1)}px;
        }
        
        .title {
          color: ${theme.palette.text.secondary};
          font-weight: 600;
        }
        
        .subtitle {
          color: ${theme.palette.actions.main};
          font-weight: 400;
        }
      }
      
      tbody {
        td {
          border: 1px dashed black;
          padding: ${theme.spacing(2)}px;
        }
        
        .name {
          text-align: center;
          font-weight: 600;
          padding-bottom: ${theme.spacing(0.5)}px;
        }
        
        .url {
          font-weight: 600;
        }
        
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 40%;
        }
        
        .code {
          font-weight: 600;
        }
      }
    }
    
    @media print {
      font-size: 12px;
      padding: ${theme.spacing(1, 2)};
      
      table {
        break-inside: auto;
        
        tr { 
          break-inside: avoid;
          break-after: auto;
        }
      }
    }
  `
);

const Header = ({ section, onImageLoaded }) => (
  <thead>
    <tr>
      <th>
        <div className="image">
          <img
            src="/static/assets/hello-world-logo-small-alt.png"
            alt="logo"
            onLoad={onImageLoaded}
          />
        </div>
      </th>
      <th>
        <div className="title">{section.title}</div>
        <div className="subtitle">Login Cards</div>
      </th>
    </tr>
  </thead>
);
Header.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
  }),
  course: PropTypes.shape({
    title: PropTypes.string,
  }),
  onImageLoaded: PropTypes.func,
};

const ImageGrid = ({ code, sectionName, images, onImageLoaded }) => (
  <tbody>
    {lodash.chunk(images, 2).map((cells, row) => (
      <tr key={row}>
        {cells.map((image, col) => (
          <td key={col}>
            <div className="name">
              {image.student.first_name} {image.student.last_name}
            </div>
            <div className="name">{sectionName}</div>
            <div>
              <div>
                <span>1. Go to </span>
                <span className="url">{document.location.host}</span>
              </div>
              <div>
                <span>2. Enter your section code </span>
                <span className="code">{code}</span>
              </div>
              <div>
                <span>3. Click your name</span>
              </div>
              <div>
                <span>4. Click your image</span>
              </div>
            </div>
            <img
              alt="password image"
              src={image.password_image_url}
              onLoad={onImageLoaded}
            />
            <div>
              <span>Section code: </span>
              <span className="code">{code}</span>
            </div>
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);
ImageGrid.propTypes = {
  code: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      student: PropTypes.shape({
        id: PropTypes.string,
        username: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
      password_image_url: PropTypes.string,
    })
  ),
  onImageLoaded: PropTypes.func,
};

const PasswordImages = () => {
  const { sectionId } = useParams();

  const [loading, teacherEnrollments, images] = api.load(
    api.roster.sections.getTeacherEnrollments(),
    api.section.getSectionStudentsPasswordImages(sectionId)
  );

  // Counter representing the total number of images to be loaded.  It will be
  // set to a correct value once the student password images have been laoded
  // from the API.
  const [count, setCount] = React.useState(null);
  const [section, setSection] = React.useState(null);

  // Effect that determines how many images still need to be loaded.
  React.useEffect(() => {
    if (loading) {
      return;
    }

    // Number of images plus an extra one for the Hello World logo image.
    setCount(1 + images.length);

    const sections = teacherEnrollments.filter(s => s.id === sectionId);
    if (sections.length === 0) {
      history.push('/teacher/sections');
    } else {
      setSection(sections[0]);
    }
  }, [loading, images, teacherEnrollments, setCount, sectionId]);

  // Effect that determines when it's time to show the print dialog.  Only when
  // all images have been loaded will we trigger showing the print dialog.
  React.useEffect(() => {
    if (loading) {
      return;
    }

    if (count === null || count > 0) {
      return;
    }

    window.print();
  }, [loading, count]);

  const onImageLoaded = React.useCallback(() => {
    // prev shouldn't ever be null in this callback since it would mean that
    // we've put an image on the page and loaded it even before the API to
    // load the images has returned.  This includes the logo image because we
    // show the loading indicator until the API completes.
    setCount(prev => prev - 1);
  }, [setCount]);

  if (loading || !section) {
    return <LoadingIndicator />;
  }

  // We're going to build this page as a table instead of a flexbox or a css
  // grid.  This is done primarily because the intention is that this page will
  // be printed, and controlling page breaks and repeating headers appears to
  // not be as easy using modern CSS as it is using tables.
  return (
    <PasswordImagesStyles>
      <table>
        <Header section={section} onImageLoaded={onImageLoaded} />
        <ImageGrid
          code={section.code}
          sectionName={section.title}
          images={images}
          onImageLoaded={onImageLoaded}
        />
      </table>
    </PasswordImagesStyles>
  );
};

export default PasswordImages;
