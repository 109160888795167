import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../TeacherTip';
import TextBlockTypography from '../Text';
import { TextWithAudio } from '../Audio';
import Typography from '@material-ui/core/Typography';

//
// Generic FrameCards with a custom set of children
//

const StyledFrameCard = styled.div(
  () => `
    display: flex;
    flex-direction: column;
  `
);

const Title = styled(Typography)(
  ({ theme, $transparent }) => `
    align-self: flex-start;
    background-color: transparent;
    color: ${theme.palette.primary.main};
    font-size: 20px;
    font-weight: 800;
    left: ${theme.spacing(3)}px;
    padding: ${theme.spacing(0, 1)};
    position: relative;
    top: ${theme.spacing(1.5)}px;
    width: fit-content;
    z-index: 0;

    #titleBackground {
      background: ${
        $transparent
          ? theme.palette.background.level1
          : theme.palette.background.level3
      };
      
      // Position the background covering the bottom half of the title.  We
      // adjust it by 1px to handle the cases where on certain screen sizes a
      // fractional pixel can still cause the blue line to be drawn.
      position: absolute;
      bottom: 1px;
      left: 0;
      height: 50%;
      width: 100%;
      z-index: -1;
    }
  `
);

const Card = styled.div(
  ({ $padding, theme, $transparent }) => `
    background-color: ${
      $transparent ? 'transparent' : theme.palette.background.level3
    };
    border-radius: 10px;
    border: 3px solid ${theme.palette.primary.main};
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.25);
    flex-grow: 1;
    padding-top: ${theme.spacing($padding?.top ?? 4)}px;
    padding-bottom: ${theme.spacing($padding?.bottom ?? 4)}px;
    margin-bottom: ${theme.spacing(1.5)}px;
  `
);

export const FrameCard = ({ title, transparent, padding, children }) => {
  return (
    <StyledFrameCard>
      <Title variant="h3" $transparent={transparent}>
        {title}
        <span id="titleBackground" />
      </Title>
      <Card $padding={padding} $transparent={transparent}>
        {children}
      </Card>
    </StyledFrameCard>
  );
};

FrameCard.propTypes = {
  transparent: PropTypes.bool,
  children: PropTypes.any,
  title: PropTypes.string,
  padding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
  }),
};

//
// Framed table
//

// Inherit from TextBlockTypography to support vocab and language terms.
const Row = styled(TextBlockTypography)(
  ({ $isFirst, $isLast, theme }) => `
    background-color: ${theme.palette.background.level1};
    border-radius: ${
      $isLast ? `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px` : '0'
    };
    font-weight: 400;
    line-height: 32px;
    margin-top: ${$isFirst ? 0 : theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
  `
);

export const FramedTable = ({ title, table }) => (
  <FrameCard title={title} padding={{ bottom: 0 }}>
    <Grid container direction="column" spacing={0}>
      {table.map((row, index) => {
        return (
          <Row
            key={index}
            $isFirst={index === 0}
            $isLast={index === table.length - 1}
            variant="h5"
            dangerouslySetInnerHTML={{ __html: row }}
          />
        );
      })}
    </Grid>
  </FrameCard>
);

FramedTable.propTypes = {
  title: PropTypes.string,
  table: PropTypes.array,
};

//
// Framed text
//

const FramedTextContainer = styled.div(
  ({ theme }) => `
    padding-left: ${theme.spacing(4)}px;
    padding-right: ${theme.spacing(4)}px;
  `
);

// Inherit from TextBlockTypography to support vocab and language terms.
const FramedTextTypography = styled(TextBlockTypography)(
  () => `
    display: inline;
    font-family: 'IBM Plex Mono', monospaced;
    font-size: 18px;
  `
);

export const FramedText = ({ audio, teacherTip, text, title }) => (
  <FrameCard title={title}>
    <FramedTextContainer>
      <TextWithAudio audio={audio}>
        <FramedTextTypography dangerouslySetInnerHTML={{ __html: text }} />
        {teacherTip && <TeacherTip content={teacherTip} />}
      </TextWithAudio>
    </FramedTextContainer>
  </FrameCard>
);

FramedText.propTypes = {
  audio: PropTypes.string,
  teacherTip: PropTypes.array,
  text: PropTypes.string,
  title: PropTypes.string,
};
