import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg(
  ({ $active, $down, $fill, theme }) => `
    height: 10px;
    width: 10px;
    fill: ${$active ? theme.palette.primary.main : $fill};
    transform: ${$down ? 'rotate(180deg)' : 'none'};
  `
);

const ArrowIcon = ({ active, down, fill = '#C4C4C4' }) => (
  <StyledSvg
    $active={active}
    $down={down}
    $fill={fill}
    height="5"
    viewBox="0 0 10 5"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 5L5 0L10 5H0Z" />
  </StyledSvg>
);

ArrowIcon.propTypes = {
  active: PropTypes.bool,
  down: PropTypes.bool,
  fill: PropTypes.string,
};

export default ArrowIcon;
