import { MediaBlock } from './media';
import PropTypes from 'prop-types';
import React from 'react';
import { RubricBlockContainer as RubricBlock } from './rubric';
import styled from 'styled-components';
import { TextBlockContainer as TextBlock } from './text';

export * from './checkpoints';
export * from './code';
export * from './exemplar';
export * from './label';
export * from './media';
export * from './rubric';
export * from './text';

//
// Block Group
//

export const BlockGroupContainer = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: ${theme.spacing(2)}px;
    margin: ${theme.spacing(2, 0)};
    padding: ${theme.spacing(2)}px;

    // Variants
    &.transparent {
      background-color: transparent;
    }
    &.direction {
      background-color: #E4FCFC;
      padding: ${theme.spacing(0, 2)};
      p {
        color: #0C7D7D;
        font-weight: 600;
      }
    }
  `
);
BlockGroupContainer.displayName = 'BlockGroupContainer';

export const BlockGroup = ({ group }) => {
  return (
    <BlockGroupContainer className={group.variant}>
      {group.blocks.map(block => {
        return <Block key={block.id} block={block} />;
      })}
    </BlockGroupContainer>
  );
};

BlockGroup.propTypes = {
  group: PropTypes.shape({
    blocks: PropTypes.array,
    id: PropTypes.string,
    variant: PropTypes.string,
  }),
};

//
// Block
//

const Block = ({ block }) => {
  switch (block.type) {
    case 'media':
      return <MediaBlock block={block} />;

    case 'rubric':
      return <RubricBlock block={block} />;

    case 'text':
      return <TextBlock block={block} />;

    default:
      throw new Error(
        `unrecognized block type, id: ${block.id}, type: ${block.type}`
      );
  }
};

Block.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
};
