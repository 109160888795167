import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const StyledCard = styled(Card)(
  ({ theme }) => `
    cursor: pointer;
    background-color: ${theme.palette.background.paper}
    display: flex;
    flex-direction: column;
    width: ${theme.spacing(33)}px;
    height: ${theme.spacing(42.5)}px;
    justify-content: space-between;
    position: relative;
    transition: all 100ms ease;

    &.highlight {
      border: 1px solid ${theme.palette.primary.main};
    } 
    
    .locked {
      visibility: hidden;
    }
    
    & :hover {
      .locked {
        visibility: visible;
      }
    }
`
);

const GlowContainer = styled.div(
  ({ theme }) => `
    transition: box-shadow 100ms ease;
    width: 264px;
    &.highlight {
      box-shadow: 0 0 14px ${theme.palette.primary.main}d9;

      :hover {
        box-shadow: 0 0 16px ${theme.palette.primary.main};
      }
    }
  `
);

const StyledLink = styled.a(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)}px;
    text-decoration: none;
    top: ${theme.spacing(2)}px;
    z-index: 10;
  `
);

const StyledGuideButton = styled(Button)(
  ({ theme }) => `
    background-color: #242424E5;
    border: 1px solid #FFFFFFE5;
    padding: 4px 12px;

    svg {
      margin-right: 10px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-transform: none;
    }
    &:hover {
      background-color: #FFFFFFE5;
      color: ${theme.palette.background.paper};
    }
  `
);

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'Poppins',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: '18px',
    padding: theme.spacing(1),
    width: '118px',
  },
}))(Tooltip);

const StyledImageContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.spacing(24)}px;
  background-color: gray;
  background-image: ${({ image }) => image && `url(${image})`};
  background-size: cover;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -${({ theme }) => theme.spacing(8)}px;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: start;
  height: ${({ theme }) => theme.spacing(18)}px;
`;

const CardTitle = styled(Typography)`
  overflow: hidden;
  height: ${({ theme }) => theme.spacing(7)}px;
`;

const ProjectCard = React.forwardRef(
  (
    {
      children,
      image = '',
      isTeacher,
      name,
      onClick,
      progress,
      teacherGuide,
      ...other
    },
    ref
  ) => {
    // This will add the highlight effect to the card
    // only if the project is playable, not excused
    // and the user is not the course teacher
    const glowClass =
      !isTeacher && progress.playable && progress.status !== 'excused'
        ? 'highlight'
        : '';

    const handleClick = React.useCallback(() => {
      if (onClick) onClick();
    }, [onClick]);

    return (
      <GlowContainer className={glowClass}>
        <StyledCard
          className={glowClass}
          onClick={handleClick}
          ref={ref}
          {...other}
        >
          {!!teacherGuide && isTeacher && (
            <StyledTooltip
              placement="bottom-start"
              title="Download the Lesson Plan"
            >
              <StyledLink
                download
                href={teacherGuide}
                rel="noopener noreferrer"
                target="_blank"
              >
                <StyledGuideButton
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <PlaylistAddCheckIcon />
                  <Typography variant="button">Lesson Plan</Typography>
                </StyledGuideButton>
              </StyledLink>
            </StyledTooltip>
          )}
          <CardActionArea>
            <StyledImageContainer image={image} />
            <StyledCardContent>
              <CardTitle gutterBottom variant="h4">
                {name}
              </CardTitle>
            </StyledCardContent>
            <StyledCardActions>{children}</StyledCardActions>
          </CardActionArea>
        </StyledCard>
      </GlowContainer>
    );
  }
);

ProjectCard.propTypes = {
  children: PropTypes.any,
  image: PropTypes.string,
  isTeacher: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  progress: PropTypes.shape({
    complete: PropTypes.bool,
    playable: PropTypes.bool,
    status: PropTypes.string,
  }),
  status: PropTypes.node,
  teacherGuide: PropTypes.string,
};

ProjectCard.displayName = 'ProjectCard';

export default ProjectCard;
