import NavMap from '../../../components/NavMap';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../../contexts/AuthContext';

const getLevelIcon = progress => {
  if (progress.complete) return 'checkmark';
  if (progress.playable) return 'play';
  return null;
};

const getProjectIcon = progress => {
  if (progress.status === 'not_started' && progress.playable) return 'play';
  if (progress.status === 'not_started' && !progress.playable) return 'lock';
  if (progress.status === 'in_progress') return 'clock';
  if (progress.status === 'grading') return 'checkmark';
  if (progress.status === 'sent_back') return 'sent-back';
  if (progress.status === 'completed') return 'star';
  if (progress.status === 'excused') return 'excused';
  if (progress.status === 'blocked') return 'blocked';
  return 'lock';
};

const getGroupTitle = group => {
  return group.subtitle
    ? `${group.title} - ${group.subtitle}`
    : `${group.title}`;
};

const Nav = ({
  course,
  progress,
  projectTitles,
  currentLevelId,
  setCurrentLevelId,
  sectionId,
}) => {
  const user = useUser();
  const history = useHistory();

  const projects = Object.values(progress.levels).flatMap(level =>
    Object.values(level.project_groups).flatMap(group =>
      Object.values(group.projects)
    )
  );

  // A project is only considered in the progress calculation if it's not
  // excused.
  const numProjects = projects.filter(p => p.status !== 'excused').length;
  const numProjectsComplete = projects.filter(p => p.complete).length;

  // Transform the course hierarchy into nav map section/items
  const sections = course.levels.map(level => {
    const levelProgress = progress.levels[level.id];

    return {
      id: level.id,
      title: level.name,
      icon: getLevelIcon(levelProgress),
      isCurrent: level.id === currentLevelId,
      isComplete: levelProgress.complete,
      isPlayable: levelProgress.playable,
      groups: level.project_groups.map(group => {
        const groupProgress = levelProgress.project_groups[group.id];
        const isGroupCurrent =
          groupProgress.playable && !groupProgress.complete;

        return {
          id: group.id,
          title: getGroupTitle(group),
          isCurrent: isGroupCurrent,

          // NavMapGroup doesn't use isComplete in its rendering.  Because of
          // this the above isCurrent was altered to not be true with the
          // project group is complete.  A better approach might be to update
          // NavMapGroup to read isComplete and use it properly -- NavMapGroup
          // is shared with project view so we need to make sure there aren't
          // any unintended side effects by doing that.
          isComplete: groupProgress.complete,

          items: group.projects.map(({ id }) => {
            const projectProgress = groupProgress.projects[id];

            return {
              id: id,
              title: projectTitles[id],
              isCurrent:
                isGroupCurrent &&
                projectProgress.playable &&
                projectProgress.status !== 'excused',
              isComplete: projectProgress.complete,
              isPlayable: projectProgress.playable,
              icon: getProjectIcon(projectProgress),
            };
          }),
        };
      }),
    };
  });

  const handleProjectClick = (navSectionId, id) => {
    history.push(
      sectionId ? `/section/${sectionId}/project/${id}` : `/project/${id}`,
      {
        courseId: course.id,
        courseName: course.name,
        id,
        levelId: currentLevelId,
        levelName: course.levels.find(level => level.id === currentLevelId)
          ?.name,
        sectionId: navSectionId,
      }
    );
  };

  return (
    <NavMap
      handleItemClick={handleProjectClick}
      hideNavButtons={user.isTeacherForCourse(course.id)}
      mode="course"
      previous={{
        label: 'My Courses',
        url: '/courses',
      }}
      progressIndicator={{
        title: 'Course Progress',
        complete: numProjectsComplete,
        total: numProjects,
      }}
      sections={sections}
      setCurrentId={setCurrentLevelId}
    />
  );
};

Nav.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    levels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        project_groups: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            projects: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                weight: PropTypes.number,
              })
            ),
          })
        ),
      })
    ),
  }),
  progress: PropTypes.shape({
    levels: PropTypes.object,
    status: PropTypes.string,
  }),
  projectTitles: PropTypes.any,
  currentLevelId: PropTypes.string,
  levels: PropTypes.any,
  setCurrentLevelId: PropTypes.func,
  sectionId: PropTypes.string,
};

export default Nav;
