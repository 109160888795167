import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const Excused = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 20 20"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM9.99 19.0625C5.57 19.0625 0.9375 14.42 0.9375 10C0.9375 5.58 5.57 0.9375 9.99 0.9375C14.41 0.9375 19.0625 5.58 19.0625 10C19.0625 14.42 14.41 19.0625 9.99 19.0625Z" />
    <path d="M14.3302 7.28268L11.6129 10L14.3302 12.7173L12.9715 14.076L10.2542 11.3587L7.53691 14.076L6.17825 12.7173L8.89557 10L6.17825 7.28268L7.53691 5.92403L10.2542 8.64134L12.9715 5.92403L14.3302 7.28268Z" />
  </StyledSvgIcon>
));

Excused.displayName = 'Excused';

Excused.propTypes = {
  size: PropTypes.number,
};

export default Excused;
