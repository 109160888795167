import { memoize, noeslint, useAxiosClient } from './client';

const getSectionMetadata = memoize(sectionId => {
  const client = useAxiosClient();

  return client
    .get(`/sections/${sectionId}/metadata`)
    .then(response => response.data);
});

const getSectionCourseMetadata = memoize((sectionId, courseId) => {
  const client = useAxiosClient();

  return client
    .get(`/sections/${sectionId}/courses/${courseId}/metadata`)
    .then(response => response.data);
});

const getSectionTitle = memoize(sectionId => {
  const client = useAxiosClient();

  return client
    .get(`/sections/${sectionId}/title`)
    .then(response => response.data);
}, 2);

const getSectionCoSpacesIds = memoize(sectionId => {
  const client = useAxiosClient();

  return client
    .get(`/sections/${sectionId}/external-ids/cospaces`)
    .then(response => response.data);
}, 2);

const updateSectionSettings = noeslint(
  (sectionId, courseId, settings, version) => {
    const client = useAxiosClient();

    return client
      .put(`/sections/${sectionId}/courses/${courseId}/settings`, {
        settings,
        version,
      })
      .then(response => response.data);
  }
);

const updateSectionControls = noeslint(
  (sectionId, courseId, controls, version) => {
    const client = useAxiosClient();

    // Make sure to not save the version as part of the control.
    delete controls.version;

    return client
      .put(`/sections/${sectionId}/courses/${courseId}/controls`, {
        controls,
        version,
      })
      .then(response => response.data);
  }
);

const getSectionStudentsPasswordImages = memoize(sectionId => {
  const client = useAxiosClient();

  return client
    .get(`/sections/${sectionId}/students/password-images`)
    .then(response => response.data);
});

export default {
  getMetadata: getSectionMetadata,
  getSectionCourseMetadata: getSectionCourseMetadata,
  getTitle: getSectionTitle,
  getCoSpacesIds: getSectionCoSpacesIds,
  updateSettings: updateSectionSettings,
  updateControls: updateSectionControls,
  getSectionStudentsPasswordImages: getSectionStudentsPasswordImages,
};
