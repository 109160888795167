import React from 'react';
import styled from 'styled-components';
import { TextBlock as TextContent } from '../../components/InlineBlocks';
import WarningIcon from '@material-ui/icons/Warning';

const StyledAlertIcon = styled(WarningIcon)(
  ({ theme }) => `
      margin-right: ${theme.spacing(2)}px; 
    `
);

const StyledAlertBar = styled.div(
  ({ theme }) => `
    align-items: center;
    background: #fffbe4;
    color: #8C7E2C;
    display: flex;
    height: ${theme.spacing(6)}px;
    justify-content: center;
    padding: ${theme.spacing(1)}px;
`
);

const StyledLink = styled.a`
  color: inherit; /* Inherit color from parent */
  text-decoration: underline; /* Remove underline */
  cursor: pointer; /* Optional: change cursor to pointer */
`;

const ArchivedEntityAlertBar = ({ pathTo, entity }) => (
  <StyledAlertBar>
    <StyledAlertIcon />
    <TextContent>
      This {entity} is archived.{' '}
      <StyledLink href={pathTo}>Click here</StyledLink> to see your active{' '}
      {entity}s.
    </TextContent>
  </StyledAlertBar>
);

export default ArchivedEntityAlertBar;
