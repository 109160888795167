import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

// These are general styling rules that are common across all code blocks.
const CodeBlockTypography = styled(Typography)(
  ({ theme }) => `
    background-color: ${theme.palette.background.code};
    border-radius: 5px;
    filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.2));
    font-family: "Ubuntu Mono", monospace;
    font-size: 16px;
    font-weight: 400;
    line-height: 133%;
    user-select: none;
    margin: 0 auto;
    max-width: 90%;
    padding: ${theme.spacing(3, 1)};
    width: 100%;
    
    .snippet-row {
      display: flex;
      flex-direction: row;
      flex-wrap: no-wrap;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      width: 100%;
      word-break: break-word;
    }
    
    .line-number {
      color: rgb(171, 184, 195);
      min-width: 30px;
      padding-right: 8px;
      text-align: right;
      width: 30px;
    }
    
    .code {
      color: rgb(4, 3, 3);
      padding-left: 8px;
    }
    
    .filename {
      color: rgb(171, 184, 195);
    }
  `
);

// Specific styling for Console output code blocks.
const ConsoleTypography = styled(CodeBlockTypography)`
  background-color: rgb(13, 17, 30);
  color: rgb(251, 251, 251);
`;

// Specific styling for HTML code blocks.
const HtmlTypography = styled(CodeBlockTypography)`
  .html-attribute {
    color: rgb(27, 132, 19);
  }
  .html-code {
    color: rgb(4, 3, 3);
  }
  .html-comment {
    color: rgb(107, 107, 109);
    font-style: italic;
  }
  .html-tag {
    color: rgb(4, 111, 199);
  }
  .html-value {
    color: rgb(107, 8, 149);
  }
`;

// Specific styling for CSS code blocks.
const CssTypography = styled(CodeBlockTypography)`
  .css-code {
    color: rgb(4, 3, 3);
  }
  .css-comment {
    color: rgb(107, 107, 109);
    font-style: italic;
  }
  .css-number {
    color: rgb(139, 19, 217);
  }
  .css-property {
    color: rgb(27, 132, 19);
  }
  .css-pseudoclass {
    color: rgb(160, 202, 85);
  }
  .css-string {
    color: rgb(2, 34, 255);
  }
`;

// Specific styling for Java code blocks.
const JavaTypography = styled(CodeBlockTypography)`
  .java-attribute {
    color: rgb(130, 53, 235);
  }
  .java-code {
    color: rgb(4, 3, 3);
  }
  .java-comment {
    color: rgb(107, 107, 109);
    font-style: italic;
  }
  .java-definition {
    color: rgb(16, 152, 38);
  }
  .java-keyword {
    color: rgb(24, 69, 223);
  }
  .java-number {
    color: rgb(12, 157, 2);
  }
  .java-property {
    color: rgb(213, 132, 15);
  }
  .java-string {
    color: rgb(193, 44, 91);
  }
`;

// Specific styling for Python code blocks.
const PythonTypography = styled(CodeBlockTypography)`
  .python-code {
    color: rgb(4, 3, 3);
  }
  .python-comment {
    color: rgb(107, 107, 109);
    font-style: italic;
  }
  .python-keyword {
    color: rgb(16, 152, 38);
  }
  .python-number {
    color: rgb(201, 108, 46);
  }
  .python-property {
    color: rgb(204, 65, 160);
  }
  .python-string {
    color: rgb(2, 34, 255);
  }
  .python-variable {
    color: rgb(12, 157, 2);
  }
`;

const CollegeBoardTypography = styled(CodeBlockTypography)`
  background-color: white;
  filter: none;
  font-family: 'Courier Prime', monospace;
  font-size: 16px;
  line-height: 150%;
  .java-comment {
    font-family: 'Tinos', serif;
  }
`;

export const CodeBlockContainer = ({ code, language }) => {
  if (language === 'console') {
    return (
      <ConsoleTypography
        dangerouslySetInnerHTML={{ __html: code }}
        variant="body1"
      />
    );
  } else if (language === 'css') {
    return (
      <CssTypography
        dangerouslySetInnerHTML={{ __html: code }}
        variant="body1"
      />
    );
  } else if (language === 'html') {
    return (
      <HtmlTypography
        dangerouslySetInnerHTML={{ __html: code }}
        variant="body1"
      />
    );
  } else if (language === 'java') {
    return (
      <JavaTypography
        dangerouslySetInnerHTML={{ __html: code }}
        variant="body1"
      />
    );
  } else if (language === 'python') {
    return (
      <PythonTypography
        dangerouslySetInnerHTML={{ __html: code }}
        variant="body1"
      />
    );
  } else if (language === 'college-board') {
    return (
      <CollegeBoardTypography
        dangerouslySetInnerHTML={{ __html: code }}
        variant="body1"
      />
    );
  } else {
    throw new Error(`unsupported language: ${language}`);
  }
};

CodeBlockContainer.propTypes = {
  code: PropTypes.string,
  language: PropTypes.string,
};
