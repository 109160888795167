import { Button, Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../../../components/Text';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = withStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.level1,
    borderRadius: '20px',
    maxWidth: `${theme.spacing(66)}px`,
    width: '75vw',
  },
}))(
  styled(Dialog)(
    ({ theme }) => `
      .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: ${theme.spacing(3, 5)};

        .title {
          color: ${theme.palette.text.secondary};
          font-size: 22px;
          font-weight: 500;
        }
        .text {
          color: ${theme.palette.text.secondary};
          margin-top: ${theme.spacing(2)}px;
          .bold {
            font-weight: 600;
          }
        }
      }

      #button {
        white-space: nowrap;
        width: 178px;
        
        &.Mui-disabled {
          opacity: 30%;
        }
        
        .MuiSvgIcon-root {
          transform: rotate(180deg);
        }

        .label {
          margin-right: ${theme.spacing(1)}px;
          width: 100%;
        }
        
        &.primary {
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.text.primary};

          :hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;
          }
        }

        &.secondary {
          background-color: #ffffff;
          box-shadow: none;
          border: 1px solid ${theme.palette.primary.main};
          color: ${theme.palette.primary.main};

          :hover {
            background-color: #b0b0f91f;
          }
        }

        &.standalone {
          margin-bottom: ${theme.spacing(2)}px;
          margin-top: ${theme.spacing(3)}px;
        }
      }
      
      .button-container {
        display: flex;
        justify-content: center; /* Centers the buttons horizontally */
        gap: 30px; /* Adds space between the buttons */
      }

      .image {
        align-items: center;
        background: ${theme.palette.background.level3};
        display: flex;
        height: 109px;
        justify-content: center;
      }
    `
  )
);

export const ArchivedCoursesModal = ({ settings, onClose }) => {
  const history = useHistory();

  const CustomButton = ({ text, className, onClick }) => (
    <Button
      className={className}
      id="button"
      variant="contained"
      onClick={() => onClick()}
    >
      {text}
    </Button>
  );
  CustomButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
  };

  return (
    <StyledDialog open={settings.show} scroll="paper" onClose={onClose}>
      <div className="image">
        <img src="/static/assets/project-summary/review-modal.svg" />
      </div>
      <div className="content">
        <TextBlockTypography className="title" variant="body2">
          {`This Course is Archived`}
        </TextBlockTypography>
        <TextBlockTypography className="text">
          {`Your teacher will not be reviewing this course.`}
        </TextBlockTypography>

        <div className="button-container">
          <CustomButton
            className="standalone secondary"
            text="Continue to course"
            onClick={onClose}
          />
          <CustomButton
            className="standalone primary"
            text="Back to courses"
            onClick={() => history.push(`/courses`)}
          />
        </div>
      </div>
    </StyledDialog>
  );
};
ArchivedCoursesModal.propTypes = {
  settings: PropTypes.shape({
    show: PropTypes.bool,
  }),
  onClose: PropTypes.func,
};
