import * as lodash from 'lodash';
import * as projects from '../../utils/projects';
import { usePosition, useProgress } from './contexts';
import React from 'react';
import { useProject } from '../../contexts/ProjectContext';

/**
 * useCheckpointAnswer extracts the answer for the specified checkpoint from
 * the student's progress and provides a helper to update the answer to a new
 * value.
 */
export const useCheckpointAnswer = id => {
  const { progress, setProgress } = useProgress();

  const answer = progress?.checkpoints?.[id] ?? {
    value: null,
    complete: false,
  };

  const save = React.useCallback(
    (value, complete) => {
      setProgress({
        ...progress,
        checkpoints: {
          ...progress?.checkpoints,
          [id]: {
            value: value,
            complete: complete,
          },
        },
      });
    },
    [id, progress, setProgress]
  );

  return {
    answer: answer,
    save: save,
  };
};

/**
 * useFeedback extracts the feedback from the student's progress and provides
 * a helper to save a new value.
 */
export const useFeedback = () => {
  const { progress, setProgress } = useProgress();

  const value = progress?.feedback ?? { status: 'not_started' };
  const save = React.useCallback(
    value =>
      setProgress({
        ...progress,
        feedback: value,
      }),
    [progress, setProgress]
  );

  return {
    value: value,
    save: save,
  };
};

/**
 * useTeacherProgress is used to save a teacher's progress
 * with all the necessary correct answers in checkpoints
 * when a teacher is viewing a project.
 */
export const useTeacherProgress = () => {
  const project = useProject();
  const { progress, setProgress } = useProgress();

  const status = progress?.status ?? { status: 'in_progress' };

  // Building a steps object with all steps as completed
  const steps = projects
    .getSteps(project)
    .reduce((acc, step) => ({ ...acc, [step.id]: true }), {});

  const projectCheckpoints = projects.findCheckpoints(project);

  // Building a checkpoints progress object
  // with all correct answers for multiple choice checkpoints
  // and placeholders for text checkpoints
  const checkpoints = {};
  for (const projectCheckpoint of projectCheckpoints) {
    let value = null;
    switch (projectCheckpoint.variant) {
      case 'multiple_choice':
        value = {
          selected: projectCheckpoint.choices
            .filter(choice => choice.correct)
            .map(ch => ch.id),
          attempts_remaining: 0,
        };
        break;
      case 'short_answer':
      case 'rich_text':
      case 'code-response':
      case 'written-response':
        value = 'skipped';
        break;
      case 'link':
        value = `data:text/html;base64,${window.btoa(
          '<html><body><h2>No project link submitted</h2></body></html>'
        )}`;
        break;
      case 'rubric':
        {
          const categories = projectCheckpoint.categories
            ? projectCheckpoint.categories.map(cat =>
                project.rubric.find(rubricCat => rubricCat.type === cat.type)
              )
            : project.rubric;
          const requirements = categories.flatMap(
            cat =>
              project.rubric.find(rubricCat => rubricCat.type === cat.type)
                .requirements
          );
          value = requirements.reduce(
            (acc, req) => ({ ...acc, [req.id]: true }),
            {}
          );
        }
        break;
      default:
        break;
    }

    checkpoints[projectCheckpoint.id] = {
      value,
      complete: true,
    };
  }

  const saveFullProgress = () =>
    setProgress({
      ...progress,
      status,
      steps,
      checkpoints: {
        ...checkpoints,
        ...progress?.checkpoints,
      },
    });

  return {
    saveFullProgress,
  };
};

const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];

export const useTextBlockFormats = () => {
  const project = useProject();
  const { position } = usePosition();

  // Index the steps in the current project by their id so that we can easily
  // lookup the current step.
  const steps = React.useMemo(() => {
    return Object.fromEntries(
      project.section_groups.flatMap(section_group =>
        section_group.sections.flatMap(section =>
          section.steps.map(step => [step.id, step])
        )
      )
    );
  }, [project]);

  const step = steps[position.step];

  const blocksFormat = {};
  let numbersIndex = 0;
  let lettersIndex = 0;
  for (const block_group of step.block_groups) {
    for (const block of block_group.blocks) {
      if (block.type !== 'text') {
        continue;
      }

      if (block.variant === 'numbered') {
        lettersIndex = 0;
        blocksFormat[block.id] = {
          bullet: ++numbersIndex,
          indentation: 'numbered',
        };
        continue;
      }

      if (block.variant === 'lettered') {
        blocksFormat[block.id] = {
          bullet: LETTERS[lettersIndex++],
          indentation: 'lettered',
        };
        continue;
      }

      if (lettersIndex > 0) {
        blocksFormat[block.id] = { indentation: 'lettered' };
      }

      if (numbersIndex > 0 && lettersIndex === 0) {
        blocksFormat[block.id] = { indentation: 'numbered' };
      }
    }
  }

  return blocksFormat;
};

export const useRubricCategoryWeights = () => {
  const project = useProject();

  return React.useMemo(() => {
    if (!project?.scoring?.criteria) {
      return {};
    }

    const allCategories = project?.rubric?.map(c => c.type);

    // Find all rubric checkpoint criteria components.
    const components =
      project?.scoring?.criteria
        .flatMap(criteria => criteria?.components ?? [])
        .filter(component => component.type === 'checkpoint')
        .filter(component => component?.variant === 'rubric') ?? [];

    // Find the categories of each checkpoint that's mentioned by a component.
    const checkpoints = Object.fromEntries(
      projects
        .findCheckpoints(project, 'rubric')
        .filter(cp => lodash.find(components, c => c.id === cp.id))
        .map(cp => [cp.id, cp?.categories?.map(c => c.type) ?? allCategories])
    );

    // For each component, divide its weight evenly among the categories
    // specified by the component, or the categories of the checkpoint if the
    // component doesn't specify any.  This will produce a list of tuples of
    // [category, weight].
    const tuples = components.flatMap(component => {
      const categories = component.categories ?? checkpoints[component.id];
      return categories.map(category => [
        category,
        component.weight / categories.length,
      ]);
    });

    // Group the tuples together into a map indexed by category.  If there are
    // multiple tuples for the same category, then the weight for that category
    // will be the sum.
    return tuples.reduce((prev, [category, weight]) => {
      prev[category] = (prev?.[category] ?? 0) + weight;
      return prev;
    }, {});
  }, [project]);
};
