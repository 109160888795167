import {
  MultipleChoiceComponentIndexProvider,
  useMultipleChoiceComponentIndex,
} from '../../contexts/MultipleChoiceComponentIndexContext';
import { useProgress, useSection } from './contexts';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import api from '../../api';
import ArchivedEntityAlertBar from '../../components/AlertBar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { FrameCard } from '../../components/Frames';
import FramedText from '../../components/FramedText';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ScoreSummary from '../../components/ScoreSummary';
import { StepHeader } from '../../components/Layout';
import styled from 'styled-components';
import TextBlockTypography from '../../components/Text';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useProject } from '../../contexts/ProjectContext';
import { useRubricCriteriaComponentRequirements } from '../../hooks/useRubricCriteriaComponentRequirements';
import { useScoringSummary } from '../../hooks/useScoringSummary';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

//
// Score Box
//

const ScoreBoxContainer = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    margin-top: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    width: 100%;
  `
);

const ScoreBoxSummary = () => {
  const { progress } = useProgress();
  const adjustment = progress?.scoring?.adjustment ?? 0;
  const summary = useScoringSummary(progress, adjustment);

  if (summary.criteria.length === 0) {
    return null;
  }

  return (
    <ScoreBoxContainer>
      <ScoreSummary
        adjustment={adjustment}
        criteria={summary.criteria}
        scores={summary.scores}
        total={summary.total}
        isEditable={false}
      />
    </ScoreBoxContainer>
  );
};

ScoreBoxSummary.propTypes = {
  adjustment: PropTypes.number,
  list: PropTypes.array,
  showResults: PropTypes.bool,
};

//
// Feedback
//

const FeedbackContainer = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    margin-top: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    width: 100%;
  `
);

const Feedback = () => {
  const { progress } = useProgress();

  const feedback = progress?.scoring?.feedback;
  if (!feedback) {
    return null;
  }

  return (
    <FeedbackContainer>
      <FramedText text={feedback} readOnly />
    </FeedbackContainer>
  );
};

//
// Criteria
//

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  color: #dddddd;
`;

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(2),
  },
}))(Tooltip);

const QuestionComponent = ({ component }) => {
  const { progress } = useProgress();
  const showCheckbox =
    progress?.status === 'sent_back' || progress?.status === 'completed';

  const value = React.useMemo(() => {
    if (!progress.version) {
      return null;
    }

    return progress?.scoring?.components?.[component.id];
  }, [component, progress]);

  return (
    <Grid container className="component">
      <Grid item className="text">
        <TextBlockTypography
          dangerouslySetInnerHTML={{ __html: component.question }}
        />
      </Grid>
      <Grid item className="status">
        {showCheckbox && <Checkbox checked={value} disabled />}
        {!showCheckbox && (
          <StyledTooltip title="Scoring in progress">
            <StyledAccessTimeIcon />
          </StyledTooltip>
        )}
      </Grid>
    </Grid>
  );
};
QuestionComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string,
    question: PropTypes.string,
  }),
};

const MultipleChoiceCheckpointComponent = ({ component }) => {
  const { progress } = useProgress();
  const index = useMultipleChoiceComponentIndex(component.id);
  const showCheckbox =
    progress?.status === 'sent_back' || progress?.status === 'completed';

  const value = React.useMemo(() => {
    if (!progress.version) {
      return null;
    }

    return progress?.scoring?.components?.[component.id];
  }, [component, progress]);

  return (
    <Grid container className="component">
      <Grid item className="text">
        <TextBlockTypography>Multiple choice #{index}</TextBlockTypography>
      </Grid>
      <Grid item className="status">
        {showCheckbox && <Checkbox checked={value} disabled />}
        {!showCheckbox && (
          <StyledTooltip title="Scoring in progress">
            <StyledAccessTimeIcon />
          </StyledTooltip>
        )}
      </Grid>
    </Grid>
  );
};
MultipleChoiceCheckpointComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const RubricCheckpointComponent = ({ component }) => {
  const { progress } = useProgress();
  const requirements = useRubricCriteriaComponentRequirements(component);
  const showCheckbox =
    progress?.status === 'sent_back' || progress?.status === 'completed';

  const value = React.useMemo(() => {
    if (!progress.version) {
      return null;
    }

    return progress?.scoring?.components?.[component.id];
  }, [component, progress]);

  return (
    <>
      {requirements.map(requirement => (
        <Grid container className="component" key={requirement.id}>
          <Grid item className="text">
            <TextBlockTypography
              dangerouslySetInnerHTML={{ __html: requirement.text }}
            />
          </Grid>
          <Grid item className="status">
            {showCheckbox && (
              <Checkbox checked={value?.[requirement.id]} disabled />
            )}
            {!showCheckbox && (
              <StyledTooltip title="Scoring in progress">
                <StyledAccessTimeIcon />
              </StyledTooltip>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};
RubricCheckpointComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
  }),
};

const Criteria = ({ criteria }) => {
  const weight = criteria.components
    .map(component => component.weight)
    .reduce((prev, weight) => prev + weight, 0);
  const unit = weight !== 1 ? 'points' : 'point';

  return (
    <Grid container item xs={12} className="row">
      <Grid item xs={4} className="header">
        <div className="title">{criteria.title}</div>
        <div className="weight">
          {weight} {unit}
        </div>
      </Grid>
      <Grid container item xs={8}>
        {criteria.components.map((component, index) => {
          switch (component.type) {
            case 'question':
              return <QuestionComponent key={index} component={component} />;

            case 'checkpoint':
              if (component?.variant === 'rubric') {
                return (
                  <RubricCheckpointComponent
                    key={index}
                    component={component}
                  />
                );
              }

              return (
                <MultipleChoiceCheckpointComponent
                  key={index}
                  component={component}
                />
              );

            default:
              return <div>unrecognized component type: {component.type}</div>;
          }
        })}
      </Grid>
    </Grid>
  );
};
Criteria.propTypes = {
  criteria: PropTypes.shape({
    title: PropTypes.string,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        variant: PropTypes.string,
      })
    ),
  }),
};

const ScoringCriteriaStyles = styled.div(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    margin-top: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    width: 100%;
    
    .frame-contents {
      background: transparent;
    }
    
    .row {
      border-top: 2px solid rgba(0, 0, 0, 0.08);
      border-bottom: 2px solid rgba(0, 0, 0, 0.08);

      &:first-child {
        border-top: none;
      }
      
      &:last-child {
        border-bottom: none;
      }
    }
    
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: ${theme.palette.background.level1};
      border-right: 4px solid rgba(0, 0, 0, 0.08);
      
      // This prevents the border from overflowing on top of the frame.
      border-radius: 0 0 0 ${theme.spacing(1)}px;
      
      .title {
        color: ${theme.palette.actions.main};
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        padding: ${theme.spacing(1)}px;
      }
      
      .weight {
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
      }
    }
    
    .component {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      
      .text {
        display: flex;
        align-items: center;

        background-color: ${theme.palette.background.level1};
        border-right: 4px solid rgba(0, 0, 0, 0.08);
        color: ${theme.palette.text.secondary};
        padding: ${theme.spacing(2)}px;
        text-align: left;
        height: 100%;
        width: 100%;
      }
      
      .status {
        background-color: ${theme.palette.background.level1};

        display: flex;
        align-items: center;
        justify-content: center;
        
        // Force the element to be a fixed width.  This is necessary because
        // the checkboxes and clock icons have different widths.
        min-width: 42px;
        max-width: 42px;
        width: 42px;
        height: 100%;
     
        .MuiCheckbox-root.Mui-disabled {
          color: #dddddd;
        }
        
        // This prevents the border from overflowing on top of the frame.
        border-radius: 0 0 ${theme.spacing(1)}px 0;
      }
    }
  `
);

const ScoringCriteria = () => {
  const project = useProject();
  const criteria = project?.scoring?.criteria;

  if (!criteria) {
    return null;
  }

  return (
    <ScoringCriteriaStyles>
      <FrameCard title="Scoring Criteria" padding={{ top: 2, bottom: 0 }}>
        <Grid container className="frame-contents">
          {criteria.map((criteria, index) => (
            <MultipleChoiceComponentIndexProvider
              key={index}
              criteria={criteria}
            >
              <Criteria criteria={criteria} />
            </MultipleChoiceComponentIndexProvider>
          ))}
        </Grid>
      </FrameCard>
    </ScoringCriteriaStyles>
  );
};

//
// Project Summary - Main
//

const ProjectSummaryStyles = styled.div(
  ({ theme }) => `
    position: relative;
    background: #f8f8f8;
    color: ${theme.palette.text.secondary};
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    text-align: center;

    h3 {
      font-weight: 500;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    }

    .status-title {
      font-size: 52px;
      font-weight: 500;
      line-height: 52px;
      margin-top: ${theme.spacing(14)}px;
    }
    
    .status-image {
      margin-top: ${theme.spacing(2)}px;
    }

    .info-text {
      width: 100%;
      margin-top: ${theme.spacing(3)}px;
    }
    
    .button-list {
      display: flex;
      gap: ${theme.spacing(4)}px;
    }

    #continue {
      white-space: nowrap;
      width: 178px;
      
      &.Mui-disabled {
        opacity: 30%;
      }
      
      .MuiSvgIcon-root {
        transform: rotate(180deg);
      }

      .label {
        margin-right: ${theme.spacing(1)}px;
        width: 100%;
      }
      
      &.primary {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.text.primary};

        :hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;
        }
      }

      &.secondary {
        background-color: #ffffff;
        box-shadow: none;
        border: 1px solid ${theme.palette.primary.main};
        color: ${theme.palette.primary.main};

        :hover {
          background-color: #b0b0f91f;
        }
      }

      &.standalone {
        margin-bottom: ${theme.spacing(2)}px;
        margin-top: ${theme.spacing(3)}px;
      }
    }
  `
);

const AlertBar = styled.div(
  ({ theme }) => `
    align-items: center;
    background: #fffbe4;
    color: #8C7E2C;
    display: flex;
    height: ${theme.spacing(6)}px;
    justify-content: center;
    padding: ${theme.spacing(1)}px;
  `
);

const StyledAlertIcon = styled(WarningIcon)(
  ({ theme }) => `
    margin-right: ${theme.spacing(2)}px; 
  `
);

const ProjectSummaryContent = styled.div(
  ({ theme }) => `
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: ${theme.spacing(92)}px;
  `
);

const ProjectSummary = ({ control, onClose }) => {
  const history = useHistory();
  const project = useProject();
  const sectionInfo = useSection();
  const { progress } = useProgress();
  const status = control === 'excused' ? control : progress.status;

  const [
    { alert, image, info, showScoreBox, showFeedback, showCriteria, title },
    setPageContent,
  ] = React.useState({
    alert: '',
    image: '',
    info: '',
    showScoreBox: false,
    showFeedback: false,
    showCriteria: false,
    title: '',
  });

  const [bannerSettings, setBannerSettings] = React.useState({ show: false });

  const [loading, section] = api.load(api.section.getTitle(sectionInfo.id));

  React.useEffect(() => {
    if (loading) {
      return;
    }

    setBannerSettings({
      show: section.is_active === false,
    });
  }, [loading, section]);

  React.useEffect(() => {
    switch (status) {
      case 'in_progress':
        setPageContent({
          image: '/static/assets/project-summary/in-progress.svg',
          info: (
            <>
              <Typography className="info-text" variant="body1">
                {`Your project is still in progress. This is where you will find your score after your project has been submitted.`}
              </Typography>
              <ReturnToProjectButton
                className="standalone primary"
                text="VIEW THIS PROJECT"
              />
            </>
          ),
          title: 'In Progress',
        });
        break;
      case 'grading':
        setPageContent({
          image: '/static/assets/project-summary/submitted.svg',
          info: (
            <>
              <Typography className="info-text" variant="body1">
                {`Your project is currently being reviewed. Check back soon for evaluation details.`}
              </Typography>
              <div className="button-list">
                <ReturnToProjectButton
                  className="standalone secondary"
                  text="VIEW THIS PROJECT"
                />
                <ReturnToLevelButton className="standalone primary" />
              </div>
            </>
          ),
          title: 'Submitted!',
        });
        break;
      case 'sent_back':
        setPageContent({
          image: '/static/assets/project-summary/sent-back.svg',
          info: (
            <Typography className="info-text" variant="body1">
              {`This project was sent back. You must resubmit this project before you can begin a new project. Click `}
              <ReturnToProjectButton
                text="CONTINUE PROJECT"
                className="primary"
              />
              {` to review your work and resubmit.`}
            </Typography>
          ),
          title: 'Sent Back',
          alert: 'This score is not final',
          showScoreBox: true,
          showFeedback: true,
        });
        break;
      case 'excused':
        setPageContent({
          image: '/static/assets/project-summary/excused.svg',
          info: (
            <>
              <Typography className="info-text" variant="body1">
                {`This project has been excused. You may continue, but this project will not be scored.`}
              </Typography>
              <div className="button-list">
                <ReturnToProjectButton
                  className="standalone secondary"
                  text="VIEW THIS PROJECT"
                />
                <ReturnToLevelButton className="standalone primary" />
              </div>
            </>
          ),
          title: 'Excused',
          alert: 'This project is excused and will not be scored',
        });
        break;
      case 'completed': {
        const hasCriteria = project?.scoring?.criteria;
        if (hasCriteria) {
          setPageContent({
            image: '/static/assets/project-summary/scored.svg',
            info: (
              <>
                <Typography className="info-text" variant="body1">
                  {`Your project has been reviewed and scored.`}
                </Typography>
                <div className="button-list">
                  <ReturnToProjectButton
                    className="standalone secondary"
                    text="VIEW THIS PROJECT"
                  />
                  <ReturnToLevelButton className="standalone primary" />
                </div>
              </>
            ),
            title: 'Scored!',
            showScoreBox: true,
            showFeedback: true,
            showCriteria: true,
          });
        } else {
          setPageContent({
            image: '/static/assets/project-summary/submitted.svg',
            info: (
              <>
                <Typography className="info-text" variant="body1">
                  {`Your project has been submitted. Have fun on your next project!`}
                </Typography>
                <div className="button-list">
                  <ReturnToProjectButton
                    className="standalone secondary"
                    text="VIEW THIS PROJECT"
                  />
                  <ReturnToLevelButton className="standalone primary" />
                </div>
              </>
            ),
            title: 'Submitted!',
            showScoreBox: false,
            showFeedback: true,
            showCriteria: false,
          });
        }
        break;
      }
      default:
        break;
    }
  }, [status, project]);

  const ReturnToProjectButton = ({ text, className }) => (
    <Button
      className={className}
      id="continue"
      variant="contained"
      onClick={() => onClose()}
    >
      {text}
    </Button>
  );
  ReturnToProjectButton.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
  };

  const ReturnToLevelButton = ({ className }) => {
    const courseId = history?.location?.state?.courseId;
    const levelId = history?.location?.state?.levelId;
    const section = useSection();

    // We may not always have the state necessary to construct the URL to the
    // level.  In that situation, we'll have to return the student to the
    // courses page so that they can reenter the course they care about.
    if (!courseId || !levelId) {
      return (
        <Button
          className={className}
          id="continue"
          variant="contained"
          onClick={() => history.push(`/courses`)}
        >
          VIEW COURSES
        </Button>
      );
    }

    const url = section
      ? `/section/${section.id}/course/${courseId}/level/${levelId}`
      : `/course/${courseId}/level/${levelId}`;

    return (
      <Button
        className={className}
        id="continue"
        variant="contained"
        onClick={() => history.push(url)}
      >
        NEXT PROJECT
      </Button>
    );
  };
  ReturnToLevelButton.propTypes = {
    className: PropTypes.string,
  };

  return (
    <ProjectSummaryStyles>
      {bannerSettings.show && (
        <ArchivedEntityAlertBar pathTo={`/courses`} entity={`course`} />
      )}
      <StepHeader step="Project Summary" />
      {alert && (
        <AlertBar>
          <StyledAlertIcon />
          <Typography variant="body1">{alert}</Typography>
        </AlertBar>
      )}
      <ProjectSummaryContent>
        <Typography className="status-title" variant="body1">
          {title}
        </Typography>
        <img className="status-image" src={image} />
        {info}
        {showScoreBox && <ScoreBoxSummary />}
        {showFeedback && <Feedback />}
        {showCriteria && <ScoringCriteria />}
      </ProjectSummaryContent>
    </ProjectSummaryStyles>
  );
};

ProjectSummary.propTypes = {
  control: PropTypes.string,
  onClose: PropTypes.func,
};

export default ProjectSummary;
