import api from '../../api';
import { getOrderedSubmissions } from './utils/submissions';
import { LoadingIndicator } from '../../components';
import PropTypes from 'prop-types';
import React from 'react';
import { RubricModal } from './components';
import { useProject } from '../../contexts/ProjectContext';

//
// Submissions Context
//

const SubmissionsContext = React.createContext(undefined);
export const useSubmissions = () => {
  const context = React.useContext(SubmissionsContext);
  if (context === undefined) {
    throw new Error('useSubmissions must be used within a SubmissionsContext');
  }
  return context;
};

export const SubmissionsProvider = ({
  children,
  sectionId,
  courseId,
  projectId,
}) => {
  const [loading, loadedSubmissions, metadata] = api.load(
    api.teacher.scoring.getStudents(sectionId, projectId),
    api.section.getSectionCourseMetadata(sectionId, courseId)
  );

  // Clear the API call cache when the provider is unmounted.
  React.useEffect(() => {
    return () => {
      if (loadedSubmissions) {
        loadedSubmissions.cleanup();
      }
      if (metadata) {
        metadata.cleanup();
      }
    };
  }, [loadedSubmissions, metadata]);

  // The current, in-memory copy of the submissions.  This will be updated as
  // submissions are transitioned between states.
  const [submissions, setSubmissions] = React.useState([]);

  const isFirstLoad = React.useRef(true);
  React.useEffect(() => {
    if (loading || !isFirstLoad.current) {
      return;
    }

    isFirstLoad.current = false;

    // Propagate the controls into each submission.
    setSubmissions(
      (loadedSubmissions ?? []).map(s => {
        const controls = metadata?.section?.controls;
        const perStudentControl =
          controls?.['per-student']?.[s.student_id]?.[projectId];
        const globalControl = controls?.['global']?.[projectId];
        s.control = perStudentControl ?? globalControl ?? 'none';
        return s;
      })
    );
  }, [loading, loadedSubmissions, metadata, projectId]);

  if (isFirstLoad.current) {
    return <LoadingIndicator />;
  }

  const value = {
    submissions: submissions,
    setSubmissions: setSubmissions,
  };

  return (
    <SubmissionsContext.Provider value={value}>
      {children}
    </SubmissionsContext.Provider>
  );
};

SubmissionsProvider.propTypes = {
  children: PropTypes.node,
  sectionId: PropTypes.string,
  courseId: PropTypes.string,
  projectId: PropTypes.string,
};

const useSubmission = studentId => {
  const { submissions, setSubmissions } = useSubmissions();
  const setStatus = newStatus => {
    setSubmissions(prev =>
      prev.map(s =>
        s.student_id !== studentId ? s : { ...s, project_status: newStatus }
      )
    );
  };

  return {
    submission: submissions.find(s => s.student_id === studentId),
    updateStatus: setStatus,
  };
};

//
// Render State Context
//

const RenderStateContext = React.createContext(undefined);
export const useRenderState = () => {
  const context = React.useContext(RenderStateContext);
  if (context === undefined) {
    throw new Error('useRenderState must be used within a RenderStateContext');
  }
  return context;
};

/**
 * A RenderStateProvider encapsulates the current state of what's being rendered
 * on the teacher scoring page.  This state includes what type of data is
 * currently being shown to the teacher as well as which scoring step and
 * student they are working on.  It also includes metadata such as whether or
 * not there is something to render before or after the currently active item.
 */
export const RenderStateProvider = ({ sectionId, children }) => {
  const project = useProject();
  const { submissions } = useSubmissions();

  // Calculate the full list of scoring steps in the order that they appear.
  // If the project template doesn't have a scoring section group then this
  // will be an empty array.
  const steps = React.useMemo(() => {
    const group =
      project?.scoring_guide_section_group ||
      project?.scoring?.guide?.section_group;
    if (!group) {
      return [];
    }

    return group?.sections.flatMap(section =>
      section.steps.map(step => step.id)
    );
  }, [project]);

  // Calculate the full list of submissions that are graded or able to be
  // graded.  These submissions will be ordered in the same way that they appear
  // in the NavMap.
  const subs = React.useMemo(() => {
    return getOrderedSubmissions(
      submissions.filter(
        s =>
          s.project_status === 'grading' ||
          s.project_status === 'completed' ||
          s.project_status === 'sent_back'
      )
    ).map(s => s.student_id);
  }, [submissions]);

  // Together the steps and submissions comprise the list of valid positions we
  // can be in.  Enumerate them and generate the stepId or studentId that
  // corresponds to each.
  const links = React.useMemo(() => {
    const links = {};

    // First link together the steps.
    for (let i = 0; i < steps.length; i++) {
      const prev = steps?.[i - 1] ?? null;
      const next = steps?.[i + 1] ?? null;
      links[steps[i]] = {
        prev: prev != null ? { stepId: prev } : null,
        next: next != null ? { stepId: next } : null,
      };
    }

    // Next link together the submissions.
    for (let i = 0; i < subs.length; i++) {
      const prev = subs?.[i - 1] ?? null;
      const next = subs?.[i + 1] ?? null;
      links[subs[i]] = {
        prev: prev != null ? { studentId: prev } : null,
        next: next != null ? { studentId: next } : null,
      };
    }

    // Next, join together the lists, keeping in mind that they may be empty.
    const lastStep = steps?.[steps.length - 1];
    const firstSub = subs?.[0];
    if (lastStep && firstSub) {
      links[lastStep].next = { studentId: firstSub };
      links[firstSub].prev = { stepId: lastStep };
    }

    return links;
  }, [steps, subs]);

  // Build an index of contexts for the scoring guide steps.  This will wrap
  // a step's activity, if present, into an activity context.
  const stepContexts = React.useMemo(() => {
    const group =
      project?.scoring_guide_section_group ||
      project?.scoring?.guide?.section_group;
    if (!group) {
      return [];
    }

    return Object.fromEntries(
      group?.sections
        .flatMap(section => section.steps)
        .filter(step => !!step?.activity_id)
        .map(step => [
          step.id,
          {
            type: 'activity',
            title: 'Example',
            activity: step.activity_id,
          },
        ])
    );
  }, [project]);

  // Build an index of the criteria in the project.  Criteria don't have IDs so
  // we index them in an array in the order they appear in the project.
  const criteria = React.useMemo(() => {
    return project?.scoring?.criteria ?? [];
  }, [project]);

  // Build an index of student names.
  const names = React.useMemo(() => {
    return Object.fromEntries(
      submissions.map(s => {
        const name = {
          first_name: s.student_first_name,
          last_name: s.student_last_name,
        };
        return [s.student_id, name];
      })
    );
  }, [submissions]);

  const [state, setState] = React.useState(() => {
    const hasGuide = steps.length > 0;
    const hasSubmissions = subs.length > 0;

    return {
      // What view should we be showing?
      view: {
        guide: hasGuide,
        submissions: !hasGuide && hasSubmissions,
        nothing_to_show: !hasGuide && !hasSubmissions,
      },

      // What section we're working on.
      sectionId: sectionId,

      // What activity should we be showing?  One of guide, submission or none.
      // TODO: Look into whether or not this can be removed.
      activity: hasGuide ? 'guide' : hasSubmissions ? 'submission' : 'none',

      // What's our current scoring step?  May be null when there are no scoring
      // steps in the project.  Only valid to read when the scoring guide view
      // is being shown.
      stepId: steps?.[0],

      // What's our current student id?  May be null if there are no student
      // submissions to grade.  Only valid to read when the submissions view is
      // being shown.
      studentId: subs?.[0],

      // Which criteria are we showing context(s) for.
      criteriaIndex: 0,

      // What contexts/activities are able to be shown.  When showing a
      // submission this will be the context(s) associated with the currently
      // selected criteria.  When showing a scoring guide step, and not showing
      // a toggle -- meaning the user didn't get to this scoring guide step from
      // a submission, this will be the activity associated with the current
      // step id (if there is one).  Finally, when showing a scoring guide step
      // along with the submission toggle this will be the context(s) associated
      // with the currently selected criteria.
      contexts: null,

      // Whether or not the toggle between scoring step and submission should be
      // shown in the step header.
      showToggle: false,
    };
  });

  // Which step we're currently showing.
  const current = state.view.guide ? state.stepId : state.studentId;

  const showScoringStep = (id, showToggle) => {
    setState(old => {
      const context = stepContexts?.[id];

      return {
        ...old,
        view: {
          nothing_to_show: false,
          guide: true,
          submissions: false,
        },
        // When we're showing the toggle that means we have a submission to
        // go back to.  When that happens we want to keep whatever activity
        // we were already showing and not change to the guide's activity.
        activity: showToggle ? old.activity : 'guide',
        stepId: id,
        contexts: showToggle ? old.contexts : context ? [context] : [],
        showToggle: showToggle,
      };
    });
  };

  const showStudentSubmission = (id, showToggle) => {
    setState(old => {
      // When switching students, always reset back to the first criteria.
      const criteriaIndex = id === old.studentId ? old.criteriaIndex : 0;

      return {
        ...old,
        view: {
          nothing_to_show: false,
          guide: false,
          submissions: true,
        },
        activity: 'submission',
        studentId: id,
        criteriaIndex: criteriaIndex,
        contexts: criteria[criteriaIndex]?.contexts ?? [],
        showToggle: showToggle,
      };
    });
  };

  const showCriteria = index => {
    setState(old => {
      if (index === old.criteriaIndex) {
        return old;
      }

      return {
        ...old,
        criteriaIndex: index,
        contexts: criteria[index]?.contexts ?? [],
      };
    });
  };

  const value = {
    ...state,

    // Switch to showing scoring steps.  This is used by the toggle to switch
    // from grading a student submission to showing scoring steps.
    showScoringSteps: () => {
      showScoringStep(state.stepId, true);
    },

    // Switch to showing submissions.  This is used by the toggle to switch
    // from showing scoring steps to grading a student submission.
    showSubmissions: () => {
      showStudentSubmission(state.studentId, true);
    },

    // Switch to showing a specific scoring step.  Switches both the mode and
    // stepId.  This is used by the NavMap to jump to a specific scoring step.
    showScoringStep: id => showScoringStep(id, false),

    // Switch to showing a specific student submission.  Switches both the mode
    // and studentId.  This is used by the NavMap to jump to a specific student
    // submission.
    showStudentSubmission: id => showStudentSubmission(id, steps.length > 0),

    // Switch to showing a specific criteria within the current student
    // submission.
    showCriteria: showCriteria,

    // Is there a previous position that can be navigated to?
    hasPrev: links[current]?.prev !== null,

    // Navigate to the previous position.
    prev: () => {
      const prev = links[current].prev;
      if (!prev) {
        return;
      }

      if (prev.stepId) {
        // If we transition from a submission to a scoring step then remove the
        // toggle, otherwise preserve the current state of the toggle.
        const transitioned = current === state.studentId;

        showScoringStep(prev.stepId, !transitioned && state.showToggle);
      } else if (prev.studentId) {
        showStudentSubmission(prev.studentId, steps.length > 0);
      }
      // TODO: Handle 3rd case.
    },

    // Is there a next position that can be navigated to?
    hasNext: links[current]?.next !== null,

    // Navigate to the next position.
    next: () => {
      const next = links[current].next;
      if (!next) {
        return;
      }

      if (next.stepId) {
        // Preserve whether or not we're showing the toggle.
        showScoringStep(next.stepId, state.showToggle);
      } else if (next.studentId) {
        // Always show the toggle as long as we have steps in the scoring guide.
        showStudentSubmission(next.studentId, steps.length > 0);
      }
      // TODO: Handle 3rd case.
    },

    getCurrentStudentName: () => {
      return names?.[state.studentId];
    },
  };

  return (
    <RenderStateContext.Provider value={value}>
      {children}
    </RenderStateContext.Provider>
  );
};

RenderStateProvider.propTypes = {
  sectionId: PropTypes.string,
  children: PropTypes.node,
  submissions: PropTypes.arrayOf(
    PropTypes.shape({
      project_status: PropTypes.string,
      student_id: PropTypes.string,
      student_first_name: PropTypes.string,
      student_last_name: PropTypes.string,
    })
  ),
};

//
// Progress Context (Teacher Experience)
//

const ProgressContext = React.createContext(undefined);
export const useProgress = () => {
  const context = React.useContext(ProgressContext);
  if (context === undefined) {
    throw new Error('useProgress must be used within a ProgressProvider');
  }
  return context;
};

/**
 * A ProgressProvider provides the means to read and update the student's
 * current progress on a project.
 */
export const ProgressProvider = ({ studentId, children }) => {
  const project = useProject();
  const { updateStatus: updateSubmissionStatus } = useSubmission(studentId);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [progress, setProgress] = React.useState();

  // TODO: Prevent making a request if the studentId is null/undefined.
  const [loading, loaded] = api.load(
    api.project.progress.get(studentId, project.id)
  );

  React.useEffect(() => {
    if (loading) {
      return;
    }

    setProgress(loaded);
  }, [loading, loaded]);

  // Show the loading indicator while request is in progress or if the cached progress state is from the previously
  // selected student submission
  if (loading || !progress || studentId !== progress.studentId) {
    return <LoadingIndicator />;
  }

  // Insert or update a progress object.
  const upsert = async newProgress => {
    setIsUpdating(true);

    try {
      let version;
      if (progress.version === null) {
        version = await api.project.progress.insert(
          studentId,
          project.id,
          newProgress
        );
      } else {
        version = await api.project.progress.update(
          studentId,
          project.id,
          newProgress,
          progress.version
        );
      }

      // TODO: If version is null when we get here that means we failed to
      // insert/update the progress.  This usually happens when the version we
      // have in memory is no longer the same version that it's in the database.
      // We should figure out how we want to handle this case.

      updateSubmissionStatus(newProgress.status);

      setProgress({
        ...newProgress,
        version: version,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const value = {
    progress,
    setProgress: upsert,
    isUpdating: isUpdating,
  };

  return (
    <ProgressContext.Provider value={value}>
      {children}
    </ProgressContext.Provider>
  );
};

ProgressProvider.propTypes = {
  studentId: PropTypes.string,
  children: PropTypes.node,
};

//
// Rubric Modal Context
//

const RubricModalContext = React.createContext(undefined);

export const RubricModalProvider = ({ children }) => {
  const { rubric = [] } = useProject();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [featuredReq, setFeaturedReq] = React.useState(null);

  const onOpenRubricModal = requirementId => {
    // When clicking a scoring step rubric link
    setFeaturedReq(requirementId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const value = {
    onOpenRubricModal,
  };

  return (
    <RubricModalContext.Provider value={value}>
      {children}
      <RubricModal
        rubric={rubric}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        reqId={featuredReq}
      />
    </RubricModalContext.Provider>
  );
};

RubricModalProvider.propTypes = {
  children: PropTypes.node,
};

export const useRubricModal = () => {
  const context = React.useContext(RubricModalContext);
  if (context === undefined) {
    throw new Error(
      'useRubricModal should be used within a RubricModalProvider'
    );
  }
  return context;
};

//
// Step Context
//

const StepContext = React.createContext(undefined);
export const useCurrentStep = () => {
  const context = React.useContext(StepContext);
  if (context === undefined) {
    throw new Error('useCurrentStep must be used within a StepProvider');
  }
  return context;
};

export const StepProvider = ({ step, children }) => {
  return <StepContext.Provider value={step}>{children}</StepContext.Provider>;
};

StepProvider.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    block_groups: PropTypes.array,
  }),
  children: PropTypes.node,
};
