import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  fill: ${({ fill }) => fill};
`;

const BackCaret = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 24 24"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="BackCaret"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <path d="M16.3837 2.97945C15.8937 2.48945 15.1037 2.48945 14.6137 2.97945L6.30368 11.2895C5.91368 11.6795 5.91368 12.3095 6.30368 12.6995L14.6137 21.0095C15.1037 21.4995 15.8937 21.4995 16.3837 21.0095C16.8737 20.5195 16.8737 19.7295 16.3837 19.2395L9.14368 11.9895L16.3937 4.73945C16.8737 4.25945 16.8737 3.45945 16.3837 2.97945Z" />
  </StyledSvgIcon>
));

BackCaret.displayName = 'BackCaret';

BackCaret.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default BackCaret;
