import { Button, Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = withStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.level1,
    borderRadius: '20px',
    maxWidth: `${theme.spacing(56)}px`,
    width: '75vw',
  },
}))(
  styled(Dialog)(
    ({ theme }) => `
      #title {
        background: ${theme.palette.primary.dark};
        color: ${theme.palette.text.primary};
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        padding: ${theme.spacing(3, 4)};
      }
      
      #body {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)}px;
      
        color: ${theme.palette.text.secondary};
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        padding: ${theme.spacing(3, 4)};
        
        b {
          font-weight: 600;
        }
            
        .buttons {
          display: flex;
          gap: ${theme.spacing(4)}px;
          justify-content: space-around;
          margin-top: ${theme.spacing(1)}px;
    
          .MuiButton-root {
            border-radius: ${theme.spacing(1)}px;
            padding: ${theme.spacing(1)}px;
            width: 100%;
    
            &.primary {
              color: ${theme.palette.text.primary};
            }
    
            &.secondary {
              background-color: ${theme.palette.background.level3};
              color: ${theme.palette.text.secondary};
            }
          }
    
          .MuiButton-label {
            font-size: 14px;
            line-height: 18px;
            text-align: left;
          }
        }
      }
    `
  )
);

export const CoSpacesMigrationModal = ({
  oldSectionMetadata,
  newSectionMetadata,
  onClose,
  onMigrate,
}) => (
  <StyledDialog open={true} scroll="paper">
    <div id="title">Your CoSpace is from a different class.</div>
    <div id="body">
      <div>
        We found your CoSpace in a different class:{' '}
        <b>{oldSectionMetadata.title}</b>
      </div>
      <div>
        Do you want to <b>copy</b> your previous work in CoSpaces to{' '}
        <b>{newSectionMetadata.title}</b>?
      </div>
      <div className="buttons">
        <Button
          className="secondary"
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          no, I want to keep my current work
        </Button>
        <Button
          className="primary"
          color="primary"
          variant="contained"
          onClick={onMigrate}
        >
          yes, I want to copy my work
        </Button>
      </div>
    </div>
  </StyledDialog>
);
CoSpacesMigrationModal.propTypes = {
  oldSectionMetadata: PropTypes.shape({
    title: PropTypes.string,
  }),
  newSectionMetadata: PropTypes.shape({
    title: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onMigrate: PropTypes.func,
};
