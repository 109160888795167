import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const UserName = styled(Typography)(
  ({ theme }) => `
    padding-left: ${theme.spacing(4)};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  
    #arrowIconUp {
      animation: 0.5s 1 forwards rotateUp;
    }
  
    #arrowIconDown {
      animation: 0.5s 1 forwards rotateDown;
    }
  
    @keyframes rotateUp {
      from {
        transform: rotate(0deg);
      }
  
      to {
        transform: rotate(180deg);
      }
    }
  
    @keyframes rotateDown {
      from {
        transform: rotate(180deg);
      }
  
      to {
        transform: rotate(0deg);
      }
    }
  `
);

const LogOutDropDown = ({ options, username }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowClass, setArrowClass] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setArrowClass('arrowIconUp');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowClass('arrowIconDown');
  };

  return (
    <React.Fragment>
      <UserName onClick={handleClick} variant="body1">
        {username}
        <ArrowDropDownIcon id={arrowClass} />
      </UserName>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {options.map(({ name, action }) => (
          <MenuItem key={name} onClick={action}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

LogOutDropDown.displayName = 'LogOutDropDown';
LogOutDropDown.propTypes = {
  options: PropTypes.array,
  username: PropTypes.string,
};

export default LogOutDropDown;
