import * as lodash from 'lodash';
import api from '../../api';
import { getStepActivity } from '../../utils/projects';
import { LoadingIndicator } from '../../components';
import PropTypes from 'prop-types';
import React from 'react';
import { useProject } from '../../contexts/ProjectContext';
import { useUser } from '../../contexts/AuthContext';
import { VocabModal } from './components';

//
// Section Context
//

const SectionContext = React.createContext(undefined);
export const useSection = () => {
  const context = React.useContext(SectionContext);
  if (context === undefined) {
    throw new Error('useSection must be used within a SectionProvider');
  }

  return context.section;
};

export const useSectionIdChooser = () => {
  const context = React.useContext(SectionContext);
  if (context === undefined) {
    throw new Error(
      'useSectionIdChooser must be used within a SectionProvider'
    );
  }

  return courseId => context.sectionIdByCourseId?.[courseId] ?? null;
};

/**
 * A SectionProvider provides the means to read the section id that the
 * project is being worked on in the context of as well as the section
 * ids to use for a course.
 */
export const SectionProvider = ({ sectionId, children }) => {
  const user = useUser();

  const [loading, sections] = api.load(api.users.getSectionsMetadata(user.id));

  const sectionIdByCourseId = React.useMemo(() => {
    if (loading) {
      return {};
    }

    const groups = lodash.groupBy(
      sections.filter(s => s?.is_student),
      s => s.course.id
    );

    const sectionIds = {};
    for (const [courseId, sections] of Object.entries(groups)) {
      // This is the same ordering from pages/Courses/index.js.
      const ordered = lodash.orderBy(
        sections,
        ['is_active', 'end_date', 'start_date', 'id'],
        ['desc', 'desc', 'desc', 'asc']
      );

      sectionIds[courseId] = ordered[0].id;
    }

    return sectionIds;
  }, [loading, sections]);

  if (loading) {
    return <LoadingIndicator />;
  }

  const value = {
    section: {
      id: sectionId ?? null,
    },
    sectionIdByCourseId: sectionIdByCourseId,
  };

  return (
    <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
  );
};
SectionProvider.propTypes = {
  sectionId: PropTypes.string,
  children: PropTypes.node,
};

//
// Progress Context
//

const ProgressContext = React.createContext(undefined);
export const useProgress = () => {
  const context = React.useContext(ProgressContext);
  if (context === undefined) {
    throw new Error('useProgress must be used within a ProgressProvider');
  }
  return context;
};

/**
 * A ProgressProvider provides the means to read and update the student's
 * current progress on a project.
 */
export const ProgressProvider = ({ progress: init, studentId, children }) => {
  const project = useProject();
  const section = useSection();
  const [isUpdating, setIsUpdating] = React.useState(false);

  const [progress, setProgress] = React.useState(() => {
    if (init.version !== null) {
      // It's possible to have a progress object in the database without a
      // position when the project is excused.  When that happens, default the
      // position to the first step of the project.
      if (!init.position) {
        init.position = {
          section: project.section_groups[0].sections[0].id,
          step: project.section_groups[0].sections[0].steps[0].id,
        };
      }

      return init;
    }

    const defaultProgress = {
      status: 'in_progress',
      position: {
        section: project.section_groups[0].sections[0].id,
        step: project.section_groups[0].sections[0].steps[0].id,
      },

      // This progress object didn't come from the database, so it doesn't have a
      // corresponding version.  This will indicate to the methods that write to
      // the database that an insert needs to happen instead of an update.
      version: null,
    };

    // TODO: Remove this if statement once we always require a section id to be
    // specified.
    if (section) {
      defaultProgress['section_id'] = section.id;
    }

    return defaultProgress;
  });

  // Insert or update a progress object.
  const upsert = async newProgress => {
    setIsUpdating(true);

    try {
      let version;
      if (progress.version === null) {
        version = await api.project.progress.insert(
          studentId,
          project.id,
          newProgress
        );
      } else {
        version = await api.project.progress.update(
          studentId,
          project.id,
          newProgress,
          progress.version
        );
      }

      setProgress({
        ...newProgress,
        version: version,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const value = {
    progress: progress,
    setProgress: upsert,
    isUpdating: isUpdating,
  };

  return (
    <ProgressContext.Provider value={value}>
      {children}
    </ProgressContext.Provider>
  );
};

ProgressProvider.propTypes = {
  progress: PropTypes.shape({
    checkpoints: PropTypes.object,
    grading: PropTypes.object,
    steps: PropTypes.object,
    version: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  }),
  studentId: PropTypes.string,
  children: PropTypes.node,
};

//
// Position Context
//

const PositionContext = React.createContext(undefined);
export const usePosition = () => {
  const context = React.useContext(PositionContext);
  if (context === undefined) {
    throw new Error('usePosition must be used within a PositionProvider');
  }
  return context;
};

/**
 * A PositionProvider provides the means to read and update the student's
 * current position within a project.
 */
export const PositionProvider = ({ children }) => {
  const project = useProject();
  const { progress, setProgress } = useProgress();
  const position = progress.position;

  // For every step in the project compute the next and previous position.  If
  // a step is the first or last step then it may have a null previous or next
  // position.
  const links = React.useMemo(() => {
    // Navigate through the project steps building an in-order list of the
    // position object for each section/step.
    const positions = project.section_groups.flatMap(section_group => {
      return section_group.sections.flatMap(section =>
        section.steps.map(step => {
          return {
            section: section.id,
            step: step.id,
          };
        })
      );
    });

    // Build a mapping for every step id to the position of its previous and
    // next steps.
    return Object.fromEntries(
      positions.map((position, i) => [
        position.step,
        {
          prev: positions?.[i - 1] ?? null,
          next: positions?.[i + 1] ?? null,
        },
      ])
    );
  }, [project]);

  // Navigate to a specific step in the project.
  const goTo = React.useCallback(
    position => {
      if (position !== null) {
        setProgress({
          ...progress,
          position: position,
        });
      }
    },
    [progress, setProgress]
  );

  // Navigate to the previous step in the project.
  const prev = React.useCallback(() => {
    const newPosition = links[position.step]?.prev;
    goTo(newPosition);
  }, [links, position, goTo]);

  // Navigate to the next step in the project.
  const next = React.useCallback(() => {
    const newPosition = links[position.step]?.next;
    setProgress({
      ...progress,
      position: newPosition,
      steps: {
        ...progress?.steps,
        [position.step]: true,
      },
    });
  }, [links, position, progress, setProgress]);

  // Submit a project.
  const submit = React.useCallback(
    progress => {
      setProgress({
        ...progress,
        steps: {
          ...progress?.steps,
          [position.step]: true,
        },
      });
    },
    [position, setProgress]
  );

  const value = {
    position: position,
    prev: prev,
    next: next,
    submit: submit,
    goTo: goTo,
    hasPrev: links[position.step]?.prev !== null,
    hasNext: links[position.step]?.next !== null,
    hasSubmit: links[position.step]?.next === null,
  };

  return (
    <PositionContext.Provider value={value}>
      {children}
    </PositionContext.Provider>
  );
};

PositionProvider.propTypes = {
  children: PropTypes.node,
};

//
// Gates Context
//

const GatesContext = React.createContext(undefined);
export const useGates = () => {
  const context = React.useContext(GatesContext);
  if (context === undefined) {
    throw new Error('useGates must be used within a GatesProvider');
  }
  return context;
};

/**
 * A GatesProvider provides the means to know whether or not a user can move
 * on to the next part of the project.
 */
export const GatesProvider = ({ children }) => {
  const project = useProject();
  const { isUpdating, progress } = useProgress();

  // Determine whether or not we should require the last block on the page to
  // be visible.  For example, we don't require the user to see the last block
  // on the page if they've already completed this step.
  const skipLastVisible = progress?.steps?.[progress.position.step] ?? false;
  const [isLastVisible, setLastVisible] = React.useState(skipLastVisible);
  React.useEffect(() => {
    setLastVisible(skipLastVisible);
  }, [skipLastVisible, progress.position]);

  // For the current step determine all of the gates that need to be met before
  // the student can proceed forward.  Examples of gates are: checkpoint
  // questions that need to be answered, blocks on the page that need to be
  // made visible, etc.  The key for a gate can be any string, but that string
  // must be unique (block ids are a good choice, but not required).
  const gates = React.useMemo(() => {
    const position = progress.position;

    // Getting the current step position
    const steps = project.section_groups.flatMap(sg =>
      sg.sections
        .filter(s => s.id === position.section)
        .flatMap(section => section.steps.filter(s => s.id === position.step))
    );

    const step = steps[0];

    // Return no gates if the current step cannot be found
    if (!step) {
      return {};
    }

    const gates = {};

    // Gates built from checkpoints that are part of this step.
    if (step.block_groups) {
      step.block_groups.map(bg =>
        bg.blocks
          .filter(b => b.type === 'checkpoint')
          .forEach(block => {
            gates[block.id] =
              progress.checkpoints?.[block.id]?.complete ?? false;
          })
      );
    }

    // Gates built from activities that are part of this step.
    const activity = getStepActivity(project, step);
    if (activity?.tool === 'written-response') {
      gates[activity.id] =
        progress.checkpoints?.[activity.id]?.complete ?? false;
    }
    if (activity?.tool === 'checkpoint-set') {
      activity.checkpoints.forEach(cp => {
        gates[cp.id] = progress.checkpoints?.[cp.id]?.complete ?? false;
      });
    }

    // Check if the step has blocks
    const blocks = step.block_groups?.flatMap(bg => bg.blocks);
    if (blocks?.length > 0) {
      // Gates built from whether the last block on the page is visible or not.
      gates['is-last-block-visible'] = isLastVisible;
    }

    // We're not allowed to be currently saving data
    gates['is-update-in-progress'] = !isUpdating;

    return gates;
  }, [project, progress, isLastVisible, isUpdating]);

  // Whether or not the required gates are all cleared and we can proceed to
  // the next step.
  const canMoveForward = Object.values(gates).every(complete => complete);

  const value = {
    canMoveBackward: !isUpdating,
    canMoveForward: canMoveForward,
    setLastVisible: setLastVisible,
  };

  return (
    <GatesContext.Provider value={value}>{children}</GatesContext.Provider>
  );
};

GatesProvider.propTypes = {
  children: PropTypes.node,
};

//
// A Vocabulary Provider provides the list of vocabulary terms
// and the ability to show the vocabulary modal
// with the information about a specific term
const VocabularyContext = React.createContext(undefined);

export const VocabularyProvider = ({ children }) => {
  const { vocabulary = [] } = useProject();

  const [isModalOpen, setShowModal] = React.useState(false);
  const [modalTerm, setModalTerm] = React.useState({});

  const terms = {};
  for (const category of vocabulary) {
    terms[category.id] = {};

    for (const term of category.terms) {
      terms[category.id][term.id] = term;
    }
  }

  // Creates an object with the vocabulary categories by id
  const categories = {};
  for (const { id, title, icon, variant } of vocabulary) {
    categories[id] = {
      title,
      ...(icon && { icon }),
      ...(variant && { variant }),
    };
  }

  const onOpenModal = (categoryId, termId) => {
    // When clicking the NabBar Vocabulary Button
    if (!categoryId && !termId) {
      setModalTerm({});
      setShowModal(true);
      return;
    }

    // When clicking a term in the Vocabulary Block or a linked term
    const selectedTerm = (terms[categoryId] || {})[termId];
    if (!selectedTerm) {
      return;
    }
    setModalTerm({
      term: selectedTerm,
      category: categories[categoryId],
    });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const value = {
    onOpenModal,
    categories,
    terms,
  };

  return (
    <VocabularyContext.Provider value={value}>
      {children}
      <VocabModal
        category={modalTerm.category}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        term={modalTerm.term}
      />
    </VocabularyContext.Provider>
  );
};

VocabularyProvider.propTypes = {
  children: PropTypes.node,
};

export const useVocabulary = () => {
  const context = React.useContext(VocabularyContext);
  if (context === undefined) {
    throw new Error('useVocabulary should be used within a VocabularyProvider');
  }
  return context;
};
