import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = withStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.level1,
    borderRadius: '20px',
    maxWidth: `${theme.spacing(56)}px`,
    width: '75vw',
  },
}))(
  styled(Dialog)(
    ({ theme }) => `
      #title {
        background: ${theme.palette.primary.dark};
        color: ${theme.palette.text.primary};
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        padding: ${theme.spacing(3, 4)};
      }
      
      #body {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)}px;
        padding: ${theme.spacing(3, 4)};
        
        div {
          display: flex;
          align-items: center;
          min-height: ${theme.spacing(8)}px;
          padding: ${theme.spacing(1, 2)};
          
          border: 1px solid #c4c4c4;
          border-radius: 8px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          color: ${theme.palette.text.secondary};
          
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          
          :hover {
            border-color: ${theme.palette.actions.main};
            color: ${theme.palette.actions.main};
            cursor: pointer;
            font-weight: 600;
          }
        }
      }
    `
  )
);

export const SectionSelectionModal = ({ settings, onClick, onClose }) => {
  return (
    <StyledDialog open={settings.show} scroll="paper" onClose={onClose}>
      <div id="title">Choose the class you want to work in:</div>
      <div id="body">
        {settings?.course?.sections
          .filter(section => section.is_active)
          .map(section => (
            <div
              key={section.id}
              onClick={() => onClick(settings.course, section.id)}
            >
              {section.title}
            </div>
          ))}
      </div>
    </StyledDialog>
  );
};
SectionSelectionModal.propTypes = {
  settings: PropTypes.shape({
    show: PropTypes.bool,
    course: PropTypes.shape({
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          is_active: PropTypes.bool,
        })
      ),
    }),
  }),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};
