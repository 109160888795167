import {
  BlockGroupContainer,
  NumberedTextBlock,
  TextBlock,
} from '../../../../components/InlineBlocks';
import { MediaBlock } from '../../../../components/Blocks';
import React from 'react';
import { StepContent } from '../../../../components/Layout';
import styled from 'styled-components';

//
// Unable to display CoSpace error dialog
//

const UnableToDisplayCoSpaceStyles = styled.div(
  ({ theme }) => `
    h1 {
      font-weight: 500;
      font-size: 40px;
      text-align: center;
    }

    img.inline {
      height: 24px;
      padding: 0 4px;
      vertical-align: middle;
    }

    ol.alpha {
      counter-reset: li;
      list-style: none;
      margin-bottom: 0;
      margin-left: 1.5em;
      padding: 0;

      li::before {
        content: counter(li, lower-alpha) ".";
        color: ${theme.palette.actions.main};
        display: inline-block;
        font-weight: 500;
        margin-left: -1.5em;
        margin-right: 0.5em;
        width: 1em;
      }

      li {
        counter-increment: li;
      }
    }

    video {
      padding: ${theme.spacing(2)}px;
    }
  `
);

export const UnableToDisplayCoSpace = () => {
  return (
    <UnableToDisplayCoSpaceStyles>
      <StepContent>
        <BlockGroupContainer className="transparent">
          <TextBlock>
            <h1>Unable to display</h1>
          </TextBlock>
        </BlockGroupContainer>

        <BlockGroupContainer className="transparent">
          <TextBlock>
            <b>
              We’re unable to show this student&apos;s submission because this
              project is from a different class that you don&apos;t have access
              to. Here&apos;s how you can view this project with the
              student&apos;s share link:
            </b>
          </TextBlock>
        </BlockGroupContainer>

        <BlockGroupContainer>
          <NumberedTextBlock number={1}>
            <TextBlock>
              Ask your student to enable sharing and remixing for their project.
              They will need to:
              <ol className="alpha">
                <li>
                  Click the
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/share-icon.png"
                    alt="share icon"
                  />
                  share button and click
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/share-button.png"
                    alt="share button"
                  />
                  . Select &ldquo;Share Unlisted&rdquo; and check the Remixing
                  box to allow others to get a copy of this CoSpace. Then click
                  the
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/share-unlisted-button.png"
                    alt="share unlisted button"
                  />
                  button.
                  <MediaBlock
                    block={{
                      media: {
                        type: 'video',
                        url: '/static/assets/scoring/cospaces-unable-to-display/share-project-with-remixing-enabled.mp4',
                      },
                    }}
                  />
                </li>
                <li>
                  Click
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/share-link-button.png"
                    alt="share link button"
                  />
                  and then click the
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/copy-button.png"
                    alt="copy button"
                  />
                  share link button to copy your project&quot;s share link.
                  <MediaBlock
                    block={{
                      media: {
                        type: 'video',
                        url: '/static/assets/scoring/cospaces-unable-to-display/copy-share-link.mp4',
                      },
                    }}
                  />
                </li>
              </ol>
            </TextBlock>
          </NumberedTextBlock>
        </BlockGroupContainer>

        <BlockGroupContainer>
          <NumberedTextBlock number={2}>
            <TextBlock>
              Now, you can open the link to review the project.
              <MediaBlock
                block={{
                  media: {
                    type: 'video',
                    url: '/static/assets/scoring/cospaces-unable-to-display/remix-project.mp4',
                  },
                }}
              />
              <ol className="alpha">
                <li>
                  Click the
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/remix-icon.png"
                    alt="remix icon"
                  />
                  remix button, then click
                  <img
                    className="inline"
                    src="/static/assets/scoring/cospaces-unable-to-display/remix-button.png"
                    alt="remix button"
                  />
                  . Click &ldquo;To my CoSpaces&rdquo; to find the project, then
                  open it.
                </li>
                <li>Play the project and review the code.</li>
              </ol>
            </TextBlock>
          </NumberedTextBlock>
        </BlockGroupContainer>
      </StepContent>
    </UnableToDisplayCoSpaceStyles>
  );
};
