import { FrameCard } from '../../components/Frames';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../TeacherTip';
import TextBlockTypography from '../Text';
import { TextWithAudio } from '../Audio';
import Typography from '@material-ui/core/Typography';

const Category = styled.div(
  ({ $isLast, theme }) => `
    background: ${theme.palette.background.level1};
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;

    // This ensures the bottom left corner of the last row doesn't appear on
    // top of the frame's border.
    border-radius: 0 0 0 ${theme.spacing($isLast ? 1 : 0)}px;

    // Ensure that the children are horizontally and vertically centered.
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `
);

const TitleTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    color: ${theme.palette.actions.main};
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    padding: ${theme.spacing(1)}px;
  `
);

const WeightTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
  `
);

const SubtitleRequirementTypography = styled(TextBlockTypography)(
  ({ theme }) => `
    font-size: 16px;
    padding: ${theme.spacing(3)}px ${theme.spacing(2)}px;
  `
);

const RequirementContainer = styled.div(
  ({ $isBottomRow, $isLast, theme }) => `
    background: ${theme.palette.background.level1};

    // This ensures the bottom right corner of the last row doesn't appear on
    // top of the frame's border.
    border-radius: 0 0 ${theme.spacing($isBottomRow && $isLast ? 1 : 0)}px 0;

    margin-bottom: ${theme.spacing(!$isLast ? 2 : 0)}px;
    padding: ${theme.spacing(2)}px;
    width: 100%;
  `
);

const RequirementTypography = styled(TextBlockTypography)`
  display: inline;
  font-size: 16px;
`;

/**
 * Create a Grid that has all of the needed inner borders.
 */
const BorderedGrid = styled(Grid)(
  ({ $isLeft, $isTop, $isBottom, theme }) => `
    border-bottom: ${!$isBottom ? '4px solid rgba(0, 0, 0, 0.08)' : 'none'};
    border-right: ${$isLeft ? '4px solid rgba(0, 0, 0, 0.08)' : 'none'};
    padding-bottom: ${!$isBottom ? theme.spacing(1) : 0}px;
    padding-left: ${!$isLeft ? theme.spacing(1) : 0}px;
    padding-right: ${$isLeft ? theme.spacing(1) : 0}px;
    padding-top: ${!$isTop ? theme.spacing(1) : 0}px;
  `
);

const Row = ({ title, subtitle, weight, requirements, isFirst, isLast }) => (
  <Grid container item xs={12}>
    <BorderedGrid
      container
      item
      xs={4}
      $isLeft={true}
      $isTop={isFirst}
      $isBottom={isLast}
    >
      <Category $isLast={isLast}>
        <TitleTypography>{title}</TitleTypography>
        <WeightTypography>{weight}</WeightTypography>
      </Category>
    </BorderedGrid>
    <BorderedGrid
      container
      item
      xs={8}
      $isLeft={false}
      $isTop={isFirst}
      $isBottom={isLast}
    >
      {subtitle && (
        <SubtitleRequirementTypography
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      {requirements.map((requirement, index) => (
        <RequirementContainer
          key={requirement.id}
          $isBottomRow={isLast}
          $isLast={index === requirements.length - 1}
        >
          <TextWithAudio audio={requirement.audio}>
            <RequirementTypography
              dangerouslySetInnerHTML={{ __html: requirement.text }}
            />
            {requirement.tip && <TeacherTip content={requirement.tip} />}
          </TextWithAudio>
        </RequirementContainer>
      ))}
    </BorderedGrid>
  </Grid>
);

Row.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  weight: PropTypes.string,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export const RubricBlockContainer = ({ title, rubric, weights }) => {
  if (!rubric) {
    return (
      <FrameCard title={title ?? 'Scoring Rubric'}>
        <div>This project has no rubric</div>
      </FrameCard>
    );
  }

  return (
    <FrameCard
      title={title ?? 'Scoring Rubric'}
      padding={{ top: 2, bottom: 0 }}
    >
      <Grid container>
        {rubric.map((category, index) => {
          let weight;
          if (weights?.[category.type] !== undefined) {
            const w = weights[category.type];
            const u = `point${w !== 1 ? 's' : ''}`;
            weight = `${w} ${u}`;
          }

          return (
            <Row
              key={index}
              title={category.title}
              subtitle={category?.subtitle}
              weight={weight}
              requirements={category.requirements}
              isFirst={index === 0}
              isLast={index === rubric.length - 1}
            />
          );
        })}
      </Grid>
    </FrameCard>
  );
};

RubricBlockContainer.propTypes = {
  title: PropTypes.string,
  rubric: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      requirements: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ),
  weights: PropTypes.object,
};
