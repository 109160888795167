import { useLocation, useParams } from 'react-router-dom';
import api from '../../../api';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import LoadingIndicator from '../../../components/LoadingIndicator';
import logo from './assets/hello-world-logo-alt.png';
import { ProjectProvider } from '../../../contexts/ProjectContext';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../../../components/Text';
import Typography from '@material-ui/core/Typography';
import { useRubricCategoryWeights } from '../hooks';

//
// Header
//

const StyledHeader = styled.div(
  ({ theme }) => `
    align-items: center;
    color: ${theme.palette.text.secondary};
    display: flex;
    justify-content: space-between;

    .header-text {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    .subtitle {
      color: ${theme.palette.primary.main};
      font-weight: 400;
    }
  `
);

const Header = ({ title }) => (
  <StyledHeader>
    <img src={logo} />
    <div className="header-text">
      <Typography variant="body1">{title}</Typography>
      <Typography className="subtitle">Project Rubric</Typography>
    </div>
  </StyledHeader>
);

Header.propTypes = {
  title: PropTypes.string,
};

//
// Frame
//

const FrameTitle = styled(Typography)(
  ({ theme }) => `
    align-self: flex-start;
    background-color: transparent;
    color: ${theme.palette.primary.main};
    font-size: 20px;
    font-weight: 800;
    left: ${theme.spacing(3)}px;
    padding: ${theme.spacing(0, 1)};
    position: relative;
    top: ${theme.spacing(1.5)}px;
    width: fit-content;
    z-index: 0;

    #titleBackground {
      background: ${theme.palette.background.level3};
      bottom: 1px;
      height: 50%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
    @media print {
      font-size: 18px;
      font-weight: 400;
      top: 10px;
    }
  `
);

const FrameContent = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level3};
    border-radius: 10px;
    border: 3px solid ${theme.palette.primary.main};
    flex-grow: 1;
    padding: ${theme.spacing(4, 0)}px;
    width: 100%;
    @media print {
      border: 2px solid ${theme.palette.primary.main};
    }
  `
);

const StyledFrame = styled.div(
  ({ theme }) => `
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(3, 3, 2, 3)};
    position: absolute;
    top: 0;
    width: 100%;

    @media print {
      position: fixed;
      z-index: 1;
    }
  `
);

const Frame = ({ title }) => (
  <StyledFrame>
    <Header title={title} />
    <FrameTitle variant="h3">
      Scoring Rubric
      <span id="titleBackground" />
    </FrameTitle>
    <FrameContent />
  </StyledFrame>
);

Frame.propTypes = {
  title: PropTypes.string,
};

//
// Category Info Row
//

const StyledCategoryRow = styled.div(
  ({ theme }) => `
    align-items: center;
    background: ${theme.palette.background.level1};
    break-inside: avoid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing(1, 2)};
    margin:  ${theme.spacing(0.5, 0)};
    text-align: center;
    width: 100%;

    .title {
      background: ${theme.palette.background.level1};
      color: ${theme.palette.actions.main};
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
    }

    .weight {
      color: ${theme.palette.text.secondary};
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .subtitle {
      color: ${theme.palette.text.secondary};
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
    }
    @media print {
      .title {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
      }
  
      .weight {
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
      }
  
      .subtitle {
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  `
);

const CategoryRow = ({ category, weight }) => (
  <StyledCategoryRow>
    <Typography className="title">{category.title}</Typography>
    {weight && <Typography className="weight">{weight}</Typography>}
    {category.subtitle && (
      <Typography
        className="subtitle"
        dangerouslySetInnerHTML={{ __html: category.subtitle }}
      />
    )}
  </StyledCategoryRow>
);

CategoryRow.propTypes = {
  category: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  weight: PropTypes.string,
};

//
// Requirement Row
//

const PaddedGrid = styled(Grid)(
  ({ $padding, theme }) => `
    // This will avoid a page break inside the element
    break-inside: avoid;
    padding-top: ${theme.spacing($padding?.top ?? 0)}px;
    padding-bottom: ${theme.spacing($padding?.bottom ?? 0)}px;
    padding-left: ${theme.spacing($padding?.left ?? 0)}px;
    padding-right: ${theme.spacing($padding?.right ?? 0)}px;
  `
);

const RequirementContainer = styled.div(
  ({ theme }) => `
    display: flex;
    align-items: center;
    background: ${theme.palette.background.level1};
    color: ${theme.palette.text.secondary};
    height: 100%;
    padding: ${theme.spacing(1)}px;
    width: 100%;
  `
);

const CheckboxContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    background: ${theme.palette.background.level1};
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${theme.spacing(0.5)}px;
    width: 100%;
    .MuiCheckbox-root {
      color: #c4c4c4;
      cursor: default;
      padding: 0;
    }
  `
);

const StyledRequirementText = styled(TextBlockTypography)(
  () => `
    @media print {
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
    }
  `
);

const RequirementRow = ({ requirement }) => (
  <PaddedGrid container $padding={{ top: 0.5, bottom: 0.5 }}>
    <PaddedGrid item xs={10} $padding={{ right: 0.5 }}>
      <RequirementContainer>
        <StyledRequirementText
          dangerouslySetInnerHTML={{ __html: requirement.text }}
        />
      </RequirementContainer>
    </PaddedGrid>
    <PaddedGrid item xs={2} $padding={{ left: 0.5 }}>
      <CheckboxContainer>
        <Checkbox checked={false} color="secondary" />
      </CheckboxContainer>
    </PaddedGrid>
  </PaddedGrid>
);

RequirementRow.propTypes = {
  requirement: PropTypes.shape({
    text: PropTypes.string,
  }),
};

//
// Category Block
//

const CategoryBlock = ({ category }) => {
  const weights = useRubricCategoryWeights();
  const weight = weights?.[category?.type];
  const unit = `point${weight !== 1 ? 's' : ''}`;

  return (
    <>
      <CategoryRow
        category={category}
        weight={weight !== undefined ? `${weight} ${unit}` : null}
      />
      {category.requirements.map(requirement => (
        <RequirementRow key={requirement.id} requirement={requirement} />
      ))}
    </>
  );
};

CategoryBlock.propTypes = {
  category: PropTypes.shape({
    type: PropTypes.string,
    requirements: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, text: PropTypes.string })
    ),
  }),
};

//
// Main Rubric Components
//

const StyledPrintRubric = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    position: relative;

    @media print {
      // This will force Chrome to print images and background
      color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
  `
);

const StyledTable = styled.table(
  ({ theme }) => `
    padding: ${theme.spacing(3)}px;
    position: relative;
    width: 100%;
    z-index: 1000;

    .header-space {
      height: ${theme.spacing(9)}px;
      width: 100%;
    }
    .footer-space {
      height: 0;
      width: 100%;
    }

    @media print {
      height: 100%;
      padding-bottom: ${theme.spacing(2)}px;
      padding-top: 0;

      // This creates some spacing in all pages
      // at the top and the bottom to make room 
      // for the header and frame
      .header-space {
        height: ${theme.spacing(12)}px;
      }
      .footer-space {
        height: ${theme.spacing(1.5)}px;
      }
    }

    @page {
      margin: 10mm 5mm 10mm 5mm;
      size: auto;
    }
  `
);

// This table structure is needed for the print version
// because it adds the necessary spacing at the top and bottom
// in every page
const PrintRubricWrapper = ({ children, title }) => (
  <StyledPrintRubric>
    <Frame title={title} />
    <StyledTable>
      <thead>
        <tr>
          <th>
            <div className="header-space" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="content">{children}</div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className="footer-space" />
          </td>
        </tr>
      </tfoot>
    </StyledTable>
  </StyledPrintRubric>
);

PrintRubricWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

//
// Main PrintRubric Component
//

export const PrintRubric = () => {
  const { projectId } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const courseName = query.get('course-name');

  const [loading, project] = api.load(api.project.get(projectId));

  const title = `${courseName ? `${courseName}: ` : ``}${project?.title}`;

  React.useEffect(() => {
    if (!loading) {
      // This will set the document title
      // and also the suggested name for the pdf file
      document.title = `${project?.title} - Project Rubric`;
      // Triggers the print dialog
      window.print();
    }
  }, [loading, project]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!project) {
    history.push(`/error`);
    return;
  }

  if (!project.rubric) {
    return null;
  }

  return (
    <ProjectProvider project={project}>
      <PrintRubricWrapper title={title}>
        {project.rubric.map(category => (
          <CategoryBlock key={category.type} category={category} />
        ))}
      </PrintRubricWrapper>
    </ProjectProvider>
  );
};
