import { memoize, noeslint, useAxiosClient } from './client';

/**
 * Asynchronously load a project given a project id.
 */
const getProject = memoize(projectId => {
  const client = useAxiosClient();

  return client.get(`/projects/${projectId}`).then(response => {
    return response.data;
  });
});

/**
 * Asynchronously load a project progress for a given user and project.
 */
const getProjectProgress = memoize((userId, projectId) => {
  const client = useAxiosClient();

  return client.get(`/users/${userId}/projects/${projectId}/progress`).then(
    response => ({
      ...response.data.json,
      version: response.data.version ?? null,
      studentId: userId,
    }),
    () => ({ version: null })
  );
});

/**
 * Asynchronously inserts the provided progress object.
 */
const insertProjectProgress = noeslint((userId, projectId, progress) => {
  const client = useAxiosClient();
  // Make sure to not save the version as part of the progress.
  delete progress.version;

  return client
    .post(`/users/${userId}/projects/${projectId}/progress`, progress)
    .then(response => response.data);
});

/**
 * Asynchronously updates the specified student progress record.
 */
const updateProjectProgress = noeslint(
  (userId, projectId, progress, version) => {
    const client = useAxiosClient();
    // Make sure to not save the version as part of the progress.
    delete progress.version;

    return client
      .put(`/users/${userId}/projects/${projectId}/progress`, {
        progress,
        version,
      })
      .then(response => response.data);
  }
);

/**
 * Asynchronously determine if a student's work needs to be migrated
 * in a 3rd party platform.
 */
const get3rdPartyMigrationNeeded = memoize((userId, projectId, sectionId) => {
  const client = useAxiosClient();

  // TODO: This can probably be removed once we always have section ids in project view.
  // Right now as it is if there isn't a section id available then this method is called
  // with an undefined value for sectionId which causes an API error.  This short circuits
  // that and doesn't make an API call at all.  The result is that no migrations are
  // considered.
  if (!userId || !projectId || !sectionId) {
    return Promise.resolve(null);
  }

  return client
    .get(
      `/users/${userId}/projects/${projectId}/progress/sections/${sectionId}/migrations`
    )
    .then(response => response.data);
});

export default {
  get: getProject,
  progress: {
    get: getProjectProgress,
    insert: insertProjectProgress,
    update: updateProjectProgress,
    migrations: {
      get: get3rdPartyMigrationNeeded,
    },
  },
};
