import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

/*
  ids that are animatable:
  #mouth
  #rightEyebrow 
  #leftEyebrow 
  #rightEye 
  #leftEye
  #leftFace
  #rightFace 
*/

const CongratulationsAnimation = css`
  svg {
    animation: up 1s 2.04;
  }

  #rightEye {
    animation: closeEye 1s 2.04 alternate;
  }

  #leftEye {
    animation: closeEye 1s 2.04 alternate;
  }

  @keyframes up {
    0% {
      transform: translateY(-5px);
    }
    20% {
      transform: translateY(5px);
    }
    55% {
      transform: translateY(-5px);
    }
    70% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  @keyframes closeEye {
    0% {
      transform: translate(-3.93 -19.79) scaleY(0.25);
    }
    20% {
      transform: translate(-3.93px, 25px) scaleY(0.65);
    }
    55% {
      transform: translate(-3.93 -19.79) scaleY(0.25);
    }
    70% {
      transform: translate(-3.93px, 25px) scaleY(0.65);
    }
    100% {
      transform: translate(-3.93 -19.79) scaleY(0.25);
    }
  }
`;

const WrongAnswerAnimation = css`
  svg {
    animation: leftToRight 0.4s linear infinite alternate-reverse;
  }

  #rightEyebrow {
    animation: raiseEyebrow 1s linear infinite alternate-reverse;
  }

  @keyframes leftToRight {
    0% {
      transform: translate(-5px, 1px);
    }
    100% {
      transform: translate(2px, 1px);
    }
  }

  @keyframes raiseEyebrow {
    0% {
      transform: translate(1px, -5px);
    }
    100% {
      transform: translate(1px, 2px);
    }
  }
`;

const LookLeft = css`
  #rightEyebrow {
    transform: translate(0px, -8px);
  }

  #leftEyebrow {
    transform: translate(0px, -8px);
  }

  #rightEye {
    transform: translate(-20px, -24px);
  }

  #leftEye {
    transform: translate(-20px, -24px);
  }
`;

const LookDown = css`
  #rightEyebrow {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(0px, -3.93px);
  }

  #leftEyebrow {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(0px, -3.93px);
  }

  #rightEye {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(-10px, -8px);
  }

  #leftEye {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate(-10px, -8px);
  }

  #mouth {
    transition: all 0.2s;
  }
`;

const CloseEyes = css`
  #rightEyebrow {
    transition: all 0.2s;
    transform: translateY(6px);
  }

  #leftEyebrow {
    transition: all 0.2s;
    transform: translateY(6px);
  }

  #rightEye {
    transition: all 0.2s;
    transform-origin: inherit;
    transform: scaleY(0.25) translateX(-3.93px);
  }

  #leftEye {
    transition: all 0.2s;
    transform-origin: inherit;
    transform: scaleY(0.25) translateX(-3.93px);
  }

  #mouth {
    transition: all 0.2s;
    transform: translate(-3.93px, -26px);
  }
`;

const ShakeFace = css`
  animation: shake 0.75s linear;

  @keyframes shake {
    8%,
    41% {
      transform: translateX(-10px);
    }
    25%,
    58% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-5px);
    }
    92% {
      transform: translateX(5px);
    }
    0%,
    100% {
      transform: translateX(0);
    }
  }
`;

const DefaultFace = css`
  #mouth {
    transition: all 0.3s;
  }
  #rightEyebrow {
    transition: all 0.3s;
  }
  #leftEyebrow {
    transition: all 0.3s;
  }
  #rightEye {
    transition: all 0.3s;
  }
  #leftEye {
    transition: all 0.3s;
  }
  #leftFace {
    transition: all 0.3s;
  }
  #rightFace {
    transition: all 0.3s;
  }
`;

const Container = styled.div(({ animationType = {}, height, theme, width }) => {
  const whichAnimation = () => {
    if (animationType === 'wrongAnswer') {
      return WrongAnswerAnimation;
    }
    if (animationType === 'congratulations') {
      return CongratulationsAnimation;
    }
    if (animationType === 'lookLeft') {
      return LookLeft;
    }
    if (animationType === 'lookDown') {
      return LookDown;
    }
    if (animationType === 'closeEyes') {
      return CloseEyes;
    }
    if (animationType === 'shake') {
      return ShakeFace;
    }

    return DefaultFace;
  };

  return `

  margin: ${theme.spacing(2)}px; 

  svg {
    width: ${width || 200}px;
    max-height: ${height || 200}px;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }

  path {
    fill: ${theme.palette.primary.main};
  }

  ${whichAnimation()}

  `;
});

const HWFace = ({ animationType, height, width }) => (
  <Container animationType={animationType} height={height} width={width}>
    <svg viewBox="0 0 391 251.98" xmlns="http://www.w3.org/2000/svg">
      <path
        className="cls-1"
        d="M319.74 270.29q19.36-24.52 32.81-57.29a178.47 178.47 0 0013.39-68.06 181.35 181.35 0 00-3.62-36.37H338.8a216.28 216.28 0 013.11 36.5q0 62.7-39 125.26zM355.76 85q-11.75-32.76-36-65.24h-16.84c10.5 18.07 17.49 31 20.87 38.72A209.48 209.48 0 01333.44 85z"
        id="rightFace"
        transform="translate(-3.93 -19.79)"
      />
      <path
        className="cls-1"
        d="M80.11 270.29Q60.76 245.77 47.32 213a178.29 178.29 0 01-13.4-68.06 180.62 180.62 0 013.62-36.37h23.52A216.28 216.28 0 0058 145q0 62.7 39 125.26zM44.1 85q11.76-32.76 36-65.24h16.84Q81.18 46.9 76.07 58.51A209.48 209.48 0 0066.42 85z"
        id="leftFace"
        transform="translate(-3.93 -19.79)"
      />
      <path
        className="cls-1"
        d="M391 58.34H222v51.82h19.49V77.13h130.02v33.03H391V58.34z"
        id="rightEyebrow"
      />
      <path
        className="cls-1"
        d="M169 58.34H0v51.82h19.49V77.13h130.02v33.03H169V58.34z"
        id="leftEyebrow"
      />
      <path
        className="cls-2"
        d="M259.49 260.75a146.6 146.6 0 01-104.16 2.82q-27-9.58-53.69-29.6v-13.75q22.36 13 32 17.16a168.14 168.14 0 0030.68 10.12 175.07 175.07 0 0039.68 4.57q51.24 0 102.36-31.85V234a195.29 195.29 0 01-46.87 26.75z"
        id="mouth"
        transform="translate(-3.93 -19.79)"
      />
      <path
        className="cls-2"
        d="M308.4 157h-29.94v-29.93h29.94z"
        id="rightEye"
        transform="translate(-3.93 -19.79)"
      />
      <path
        className="cls-2"
        d="M128.4 157H98.46v-29.93h29.94z"
        id="leftEye"
        transform="translate(-3.93 -19.79)"
      />
    </svg>
  </Container>
);

HWFace.propTypes = {
  animationType: PropTypes.oneOf([
    'closeEyes',
    'congratulations',
    'lookDown',
    'lookLeft',
    'shake',
    'wrongAnswer',
    '',
  ]),
  height: PropTypes.number,
  width: PropTypes.number,
};

export default HWFace;
