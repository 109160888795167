import * as lodash from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StudentProjectTableStyles = styled.div(
  ({ theme }) => `
    .MuiTableContainer-root {
      overflow: auto;

      // 248px = 64px (app bar) + 112px (dashboard header) + 
      //         56px (title) + 16px (bottom padding)
      max-height: calc(100vh - 248px);
      
      // Styles to force the scrollbars to be visible and prevent macOS from 
      // hiding them.  This works in Chrome and Safari, we'll probably need a js 
      // based solution for Firefox.
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 8px;
        width: 8px;
      }
      ::-webkit-scrollbar-track {
        background: white;
        :hover {
          background: rgba(0,0,0,0.08);
        }
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #c4c4c4;
        :hover {
          background-color: #908F8F;
        }
      }
    }
    
    // These styles make horizontal scrolling work properly and ensures the
    // first column stays on the screen.  This works by ensuring that z-indices
    // are set properly so that normal cells flow behind the first column and
    // the header, and that the first column flows behind the header.  Since
    // things are flowing behind other elements it's also important that cells
    // have a background so they're opaque.
    .MuiTableRow-root {
      .MuiTableCell-head {
        z-index: 5;

        :first-child {
          z-index: 10;
        }
      }

      .MuiTableCell-body {
        background: white;
        z-index: 1;

        :first-child {
          position: sticky;
          left: 0;
        }
      }
    }

    .MuiTableCell-head {
      background: white;
      border-bottom: 6px solid ${theme.palette.background.level3};
      color: ${theme.palette.text.secondary};
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 600;
      min-width: 160px;
      padding: ${theme.spacing(1.5)}px;
      text-align: center;
      vertical-align: top;
      user-select: none;
      
      &:first-child {
        text-align: left;
      }
      
      .MuiInputBase-input {
        box-sizing: border-box;
        color: ${theme.palette.text.secondary};
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        height: 28px;
        line-height: 20px;
        transition: width 300ms;
        width: 134px;      
      }
    }
    
    .MuiTableCell-body {
      border-bottom: 1px solid #E1E1E1;
      color: ${theme.palette.text.secondary};
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      width: 250px;
      padding: ${theme.spacing(3, 2)};
    }
    
    table {
      width: auto;
    }
  `
);

const ProjectHeader = ({
  projects,
  num_students,
  searchText,
  onStudentSearchChanged,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <div>Students ({num_students})</div>
          <InputBase
            onChange={e => onStudentSearchChanged(e.target.value)}
            placeholder="Search…"
            value={searchText}
          />
        </TableCell>
        {projects.map(project => (
          <TableCell key={project.id}>{project.title}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
ProjectHeader.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  num_students: PropTypes.number,
  searchText: PropTypes.string,
  onStudentSearchChanged: PropTypes.func,
};
export const StudentProjectTable = ({
  students: allStudents,
  projects,
  getCell,
}) => {
  const [students, setStudents] = React.useState(allStudents);
  const [search, setSearch] = React.useState('');

  const onSearchChanged = React.useCallback(
    text => {
      setSearch(text);

      const regex = new RegExp(lodash.escapeRegExp(text.trim()), 'i');
      setStudents(allStudents.filter(s => regex.test(s.title)));
    },
    [allStudents, setSearch, setStudents]
  );

  return (
    <StudentProjectTableStyles>
      <TableContainer>
        <Table stickyHeader>
          <ProjectHeader
            projects={projects}
            num_students={students?.length ?? 0}
            search={search}
            onStudentSearchChanged={onSearchChanged}
          />
          <TableBody>
            {students &&
              students.map(student => (
                <TableRow key={student.id}>
                  {/* Student */}
                  <TableCell>{student.title}</TableCell>

                  {/* Projects */}
                  {projects &&
                    projects.map(project => (
                      <TableCell key={project.id}>
                        {getCell(student.id, project.id)}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StudentProjectTableStyles>
  );
};
StudentProjectTable.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  getCell: PropTypes.func,
};
