import { CodeBlockContainer, MediaBlock } from '../../../components/Blocks';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Close from '@material-ui/icons/Close';
import CollegeBoardIcon from '../../../components/icons/CollegeBoardIcon';
import Dialog from '@material-ui/core/Dialog';
import { FrameCard } from '../../../components/Frames';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextBlockTypography from '../../../components/Text';
import { TextWithAudio } from '../../../components/Audio';
import Typography from '@material-ui/core/Typography';
import { useVocabulary } from '../contexts';

// Vocabulary Search Styled Components

const StyledSearchContainer = styled.div(
  ({ theme }) => `
    flex-grow: 1;
    height: 28px;
    margin-right: ${theme.spacing(1)}px;
    position: relative;
  `
);

const StyledSearchBox = styled.div(
  ({ theme, $isListShown }) => `
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: ${theme.spacing(2)}px;
    margin-left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    &:focus-within {
      background-color: #ffffff;
      box-shadow: ${$isListShown ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'}}
    }
    &:focus-within svg{
        color: ${theme.palette.text.secondary};
        opacity: 0.5;
    }
    & div {
      display: flex;
    }
  `
);

const StyledSearchIcon = styled(SearchIcon)(
  () => `
    left: 6px;
    position: absolute;
    top: 2px;
  `
);

const StyledInputBase = styled(InputBase)(
  ({ theme }) => `
    & input {
      box-sizing: border-box;
      color: ${theme.palette.background.level1};
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      height: 28px;
      line-height: 20px;
      padding: 6px 0px 6px 36px;
      transition: width 300ms;
      width: 134px;
      &:focus {
          color: ${theme.palette.text.secondary};
          width: 228px;
      }
    }
    & root {
        display: flex;
        font-size: 14px;
        height: 28px;
        width: 100%;
    }
  `
);

const StyledTermsList = styled.ul(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    font-size: 14px;
    list-style: none;
    margin-bottom: 0px;
    margin-top: 6px;
    max-height: 100%;
    overflow: auto;
    padding: 0;
    top: 32px;
    width: 228px;
    & li:last-child {
      margin-bottom: ${theme.spacing(1)}px;;
    }
    & li.selected {
      background: ${theme.palette.background.level3};
    }
  `
);

const StyledTerm = styled(ButtonBase)(
  ({ theme }) => `
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    font-weight: 400;
    height: ${theme.spacing(6)}px;
    justify-content: space-between;
    padding: 8px 12px 8px 36px;
    width: 100%;
    .term-title {
      font-size: 14px;
    }
    .category-title {
      color: ${theme.palette.actions.main};
      font-size: 11px;
      &.secondary {
        color: ${theme.palette.actions.secondary};
      }
      &.college-board {
        color: #8461eb;
      }
    }
    &.no-results {
      height: auto;
    }
  `
);

// Vocabulary Modal Styled Components

const StyledMainPageSearch = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: ${theme.spacing(12)}px;
    div.search-text-bg {
      color: rgba(77,77,77,0.3);
      font-family: 'Poppins';
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 38px;
      padding: ${theme.spacing(0, 12)};
      text-align: center;
    }
  `
);

const StyledVocabModal = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 672px;
    width: 480px;
    background: ${theme.palette.background.level1};
    border-radius: 5px;
    overflow: hidden;
    .panel-title {
      font-weight: 600;
      margin-bottom: ${theme.spacing(3)}px;
    }
    .panel-subtitle {
      font-weight: 400;
      opacity: 0.6;
      margin-bottom: ${theme.spacing(1)}px;
    }
    .panel-text {
      display: inline;
      font-weight: 400;
      line-height: ${theme.spacing(4)}px;
    }
  `
);

const StyledHeader = styled.div(
  ({ theme }) => `
    align-items: center;
    background: ${theme.palette.actions.main};
    display: flex;
    flex-shrink: 0;
    height: ${theme.spacing(5)}px;
    justify-content: space-between;
    padding: 0 ${theme.spacing(1)}px;
    z-index: 1200;

    & > svg {
      fill: ${theme.palette.background.level1};
      margin-right: ${theme.spacing(1)}px;
    }

    &.secondary {
      background: ${theme.palette.actions.secondary};
    }
    &.college-board {
      background: #8461eb;
    }

    .title {
      font-weight: 500;
    }
    .close-icon {
      padding: 0;
    }
  `
);

const StyledModalBar = styled(AppBar)(
  ({ theme }) => `
    background: #ffffff;
    height: 48px;
    color: ${theme.palette.text.secondary};
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .text-selected {
      color: ${theme.palette.actions.main};
      &.secondary {
        color: ${theme.palette.actions.secondary};
      }
      &.college-board {
        color: #8461eb;
      }
    }
    .indicator-selected {
      background-color: ${theme.palette.actions.main};
      &.secondary {
        background-color: ${theme.palette.actions.secondary};
      }
      &.college-board {
        background-color: #8461eb;
      }
    }
  `
);

const StyledVocabPanel = styled.div(
  ({ theme }) => `
    overflow-y: scroll;
    .content {
      padding: ${theme.spacing(3)}px 40px;
      color: ${theme.palette.text.secondary}
    }
  `
);

const StyledTextBlockTypography = styled(TextBlockTypography)(
  ({ theme }) => `
    // Table styles
    table {
      border-collapse: collapse;
      border-radius: 5px;
      font-size: 16px;
      margin-top: ${theme.spacing(3)}px;
      overflow: hidden;
      width: 100%;
    }
    
    th {
      background-color: ${theme.palette.background.level3};
      font-weight: 600;
    }
    
    th, td {
      border: 8px solid ${theme.palette.background.level3};
      padding: ${theme.spacing(2)}px;
      &.centered{
        text-align: center;
      }
    }
  `
);

// Vocabulary Block Styled Components

const VocabularyTerm = styled(Typography)(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: ${theme.spacing(1)}px;
    padding: 13px ${theme.spacing(4)}px;
    text-align: left;
    width: 100%;
    &:hover {
      color: ${theme.palette.actions.main};
    }
    &.secondary {
      font-family: 'IBM Plex Mono', monospace;
      &:hover {
        color: ${theme.palette.actions.secondary}
      }
    }
    &.college-board {
      font-family: 'IBM Plex Mono', monospace;
      &:hover {
        color: #8461eb;
      }
    }
  `
);

const StyledAppBar = styled(AppBar)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    box-shadow: none;
    color: ${theme.palette.text.secondary};
    height: ${theme.spacing(8)}px;
    position: relative;
    z-index: auto;
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.12);
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
    }

    .tab {
      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 400;
      height: ${theme.spacing(8)}px;
      line-height: ${theme.spacing(3)}px;
      max-height: ${theme.spacing(8)}px;
      padding: ${theme.spacing(0, 1)};
      text-transform: none;

      .MuiTab-wrapper {
        display: inline;
      }

      svg {
        fill: ${theme.palette.text.secondary};
        margin-right: 6px;
      }

      &:not(.selected):hover {
        color: ${theme.palette.actions.main};
        &.secondary {
          color: ${theme.palette.actions.secondary};
        }
        &.college-board {
          color: #8461eb;
          svg {
            fill: #8461eb;
          }
        }
      }

      &.selected {
        color: ${theme.palette.actions.main};
        font-weight: 700;
        &.secondary {
          color: ${theme.palette.actions.secondary};
        }
        &.college-board {
          color: #8461eb;
          svg {
            fill: #8461eb;
          }
        }
      }
    }

    .indicator-selected {
      background-color: ${theme.palette.actions.main};
      height: 4px;
      &.secondary {
        background-color: ${theme.palette.actions.secondary};
      }
      &.college-board {
        background-color: #8461eb;
      }
    }
  `
);

const TermsList = ({ onMouseOver, onSelectTerm, selected, suggestions }) => (
  <StyledTermsList>
    {suggestions.length > 0 ? (
      suggestions.map(
        ({ categoryId, categoryTitle, termId, termTitle, variant }, index) => (
          <li
            key={index}
            className={selected === index ? 'selected' : ''}
            onMouseMove={onMouseOver(index)}
          >
            <StyledTerm onClick={onSelectTerm(categoryId, termId)}>
              <div className="term-title">{termTitle}</div>
              <div className={`category-title ${variant}`}>{categoryTitle}</div>
            </StyledTerm>
          </li>
        )
      )
    ) : (
      <li>
        <StyledTerm className="no-results" disabled>
          <div className="term-title">No Results</div>
        </StyledTerm>
      </li>
    )}
  </StyledTermsList>
);

TermsList.propTypes = {
  onMouseOver: PropTypes.func,
  onSelectTerm: PropTypes.func,
  selected: PropTypes.number,
  suggestions: PropTypes.array,
};

// Vocabulary Search Component

const Search = () => {
  const { onOpenModal, categories, terms } = useVocabulary();
  const [searchText, setSearchText] = React.useState('');
  const [suggestions, setSuggestions] = React.useState([]);
  const [selected, setSelected] = React.useState(0);

  const handleSelectTerm = (categoryId, termId) => () => {
    onOpenModal(categoryId, termId);
    setSearchText('');
    setSuggestions([]);
  };

  const handleKeyDown = event => {
    if (suggestions.length === 0) {
      return;
    }

    switch (event.key) {
      case 'ArrowDown':
        setSelected(sel => (sel < suggestions.length - 1 ? sel + 1 : sel));
        break;
      case 'ArrowUp':
        setSelected(sel => (sel ? sel - 1 : sel));
        break;
      case 'Enter':
        handleSelectTerm(
          suggestions[selected].categoryId,
          suggestions[selected].termId
        )();
        break;
      default:
        return;
    }
  };

  const findMatches = text => {
    const regex = new RegExp(text, 'gi');
    const matches = [];
    Object.entries(terms).forEach(([categoryId, category]) =>
      Object.entries(category).forEach(([termId, term]) => {
        const termTitle = term.title;
        if (termTitle.match(regex)) {
          const categoryTitle = categories[categoryId].title;
          const variant = categories[categoryId].variant ?? '';
          matches.push({
            categoryId,
            categoryTitle,
            termId,
            termTitle,
            variant,
          });
        }
      })
    );
    return matches;
  };

  const handleSearchChange = event => {
    const { value } = event.target;
    setSearchText(value);
    setSelected(0);
    if (value.length < 3) {
      setSuggestions([]);
      return;
    }
    const matchTermsList = findMatches(value);
    setSuggestions(matchTermsList);
  };

  const handleOnSearchBlur = event => {
    if (event.currentTarget.contains(event.relatedTarget)) {
      return;
    }
    setSearchText('');
    setSuggestions([]);
  };

  const handleMouseOver = index => () => setSelected(index);

  return (
    <StyledSearchContainer>
      <StyledSearchBox
        $isListShown={searchText.length >= 3}
        onBlur={handleOnSearchBlur}
      >
        <StyledSearchIcon />
        <StyledInputBase
          classes={{
            root: 'root',
            input: 'input',
          }}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          placeholder="Search…"
          value={searchText}
        />
        {searchText.length >= 3 ? (
          <TermsList
            onMouseOver={handleMouseOver}
            onSelectTerm={handleSelectTerm}
            selected={selected}
            suggestions={suggestions}
          />
        ) : null}
      </StyledSearchBox>
    </StyledSearchContainer>
  );
};

// Vocabulary Modal Tab Panel Component

const VocabPanel = ({
  audio,
  code,
  index,
  media,
  tabTitle,
  text,
  title,
  value,
  variant,
}) =>
  value === index ? (
    <StyledVocabPanel>
      <Box className="content">
        <Typography className="panel-title" variant="h2">
          {title}
        </Typography>
        <Typography className="panel-subtitle" variant="h5">
          {tabTitle}
        </Typography>
        <TextWithAudio audio={audio}>
          {text && (
            <StyledTextBlockTypography
              className="panel-text"
              dangerouslySetInnerHTML={{ __html: text }}
              variant="h5"
            />
          )}
        </TextWithAudio>
        {media && <MediaBlock block={{ media }} />}
        {code && <CodeBlockContainer code={code} language={variant} />}
      </Box>
    </StyledVocabPanel>
  ) : null;

VocabPanel.propTypes = {
  audio: PropTypes.string,
  code: PropTypes.string,
  index: PropTypes.number.isRequired,
  media: PropTypes.shape({ url: PropTypes.string, type: PropTypes.string }),
  tabTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  variant: PropTypes.string,
};

// Vocabulary Modal Header Component

const VocabHeader = ({ title, onClose, variant }) => (
  <StyledHeader className={variant}>
    {variant === 'college-board' && <CollegeBoardIcon />}
    <Typography className="title" variant="h6">
      {title}
    </Typography>
    <Search />
    <IconButton classes={{ root: 'close-icon' }} onClick={onClose}>
      <Close />
    </IconButton>
  </StyledHeader>
);

VocabHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  variant: PropTypes.string,
};

// Vocabulary Modal Component

export const VocabModal = ({ category = {}, isOpen, onClose, term = {} }) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => setValue(0), [term]);

  const variant = category.variant ?? '';

  const handleCloseDialog = () => {
    setValue(0);
    onClose();
  };

  const handleChange = (_, newValue) => setValue(newValue);

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog}>
      <StyledVocabModal>
        <VocabHeader
          onClose={handleCloseDialog}
          title={category.title || 'Vocabulary'}
          variant={variant}
        />
        {term.groups ? (
          <>
            <StyledModalBar position="static">
              <Tabs
                classes={{
                  indicator: `indicator-selected ${variant}`,
                }}
                value={value}
                variant="fullWidth"
                onChange={handleChange}
              >
                {term.groups.map(({ title }) => (
                  <Tab
                    key={title}
                    label={title}
                    classes={{
                      selected: `text-selected ${variant}`,
                    }}
                  />
                ))}
              </Tabs>
            </StyledModalBar>
            {term.groups.map(
              ({ audio, code, title, text, media, variant }, i) => (
                <VocabPanel
                  key={title}
                  audio={audio}
                  code={code}
                  index={i}
                  media={media}
                  tabTitle={title}
                  text={text}
                  title={term.title}
                  value={value}
                  variant={variant}
                />
              )
            )}
          </>
        ) : (
          <StyledMainPageSearch>
            <div className="search-text-bg">search for a vocabulary term</div>
            <img
              alt="background"
              src="/static/assets/vocabulary/modal-background.svg"
            />
          </StyledMainPageSearch>
        )}
      </StyledVocabModal>
    </Dialog>
  );
};

VocabModal.propTypes = {
  category: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  term: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        code: PropTypes.string,
        type: PropTypes.string,
        variant: PropTypes.string,
        media: PropTypes.shape({
          url: PropTypes.string,
          type: PropTypes.string,
        }),
      })
    ),
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

const TabLabel = ({ icon, label }) => (
  <>
    {icon === 'college-board' && <CollegeBoardIcon />}
    {label}
  </>
);

TabLabel.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
};

// List of category terms inside Vocabulary Block

const VocabListPanel = ({ categoryId, isVisible, list, variant }) => {
  const { onOpenModal, terms } = useVocabulary();

  const handleOpenModal = (category, id) => () => {
    onOpenModal(category, id);
  };

  return isVisible ? (
    <>
      {list.map((item, index) => {
        const termTitle = (terms[categoryId][item] || {}).title;
        return (
          <ButtonBase key={index} onClick={handleOpenModal(categoryId, item)}>
            <VocabularyTerm className={variant ?? ''} variant="body1">
              {termTitle}
            </VocabularyTerm>
          </ButtonBase>
        );
      })}
    </>
  ) : null;
};

VocabListPanel.propTypes = {
  categoryId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  variant: PropTypes.string,
};

// Vocabulary Block Component

export const Vocabulary = ({ title, vocabulary }) => {
  const { categories } = useVocabulary();
  const defaultTabSelected = vocabulary[0].id;
  const [{ tabSelected, tabVariant }, setTabSelected] = React.useState({
    tabSelected: defaultTabSelected,
    tabVariant: categories[defaultTabSelected].variant,
  });

  const handleChangeTab = (_, selected) =>
    setTabSelected({
      tabSelected: selected,
      tabVariant: categories[selected].variant,
    });

  return (
    <FrameCard title={title} padding={{ bottom: 0 }}>
      <StyledAppBar position="static">
        <Tabs
          classes={{
            indicator: `indicator-selected ${tabVariant ?? ''}`,
          }}
          value={tabSelected}
          variant="fullWidth"
          onChange={handleChangeTab}
        >
          {vocabulary.map(({ id }) => {
            const { title, variant } = categories[id];
            return (
              <Tab
                key={id}
                label={<TabLabel icon={variant} label={title} />}
                classes={{
                  root: `tab ${variant}`,
                  selected: `selected ${variant}`,
                }}
                value={id}
              />
            );
          })}
        </Tabs>
      </StyledAppBar>
      <Grid container direction="column" spacing={0}>
        {vocabulary.map(({ id, terms }) => (
          <VocabListPanel
            key={id}
            categoryId={id}
            isVisible={tabSelected === id}
            list={terms}
            variant={categories[tabSelected].variant}
          />
        ))}
      </Grid>
    </FrameCard>
  );
};

Vocabulary.propTypes = {
  vocabulary: PropTypes.array,
  title: PropTypes.string,
};
