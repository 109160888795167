import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  filter: drop-shadow(0px 0px 56px rgba(0, 0, 0, 0.502));

  .a {
    fill: none;
    stroke-width: 2px;
  }
  .a,
  .c {
    stroke: ${({ $hovering, theme }) =>
      $hovering ? theme.palette.text.primary : theme.palette.text.icon};
  }
  .b {
    fill: none;
  }
  .c {
    fill: ${({ $hovering, theme }) =>
      $hovering ? theme.palette.text.primary : theme.palette.text.icon};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
  .d {
    stroke: none;
  }
`;

const Submitted = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 40 40"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <g className="a">
      <circle className="d" cx="20" cy="20" r="20" />
      <circle className="b" cx="20" cy="20" r="18.5" />
    </g>
    <g transform="translate(10.55 13.621)">
      <rect
        className="b"
        width={props.size}
        height={props.size}
        transform="translate(1.45)"
      />
      <path
        className="c"
        d="M7.495,13.035,0,5.54,1.521,4.019,7.495,9.885,17.38,0,18.9,1.521Z"
      />
    </g>
  </StyledSvgIcon>
));

Submitted.displayName = 'Submitted';

Submitted.propTypes = {
  size: PropTypes.number,
};

export default Submitted;
