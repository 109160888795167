import React, { useEffect, useState } from 'react';
import api from '../../api';
import config from '../../config';
import LoadingIndicator from '../LoadingIndicator';
import PropTypes from 'prop-types';
import { useUser } from '../../contexts/AuthContext';

export const IframeActivity = ({ url, allow = null }) => {
  const args = {
    height: '100%',
    width: '100%',
    src: url,
    scrolling: 'yes',
    frameBorder: 'no',
  };

  if (allow) {
    args['allow'] = allow;
  }

  return (
    <iframe
      {...args}
      onMouseOver={() => {
        document.getElementsByTagName('iframe')[0].focus();
      }}
    />
  );
};

IframeActivity.propTypes = {
  url: PropTypes.string,
  allow: PropTypes.string,
};

export const CoSpacesActivity = ({ activity, sectionId, projectId }) => {
  if (activity.action === 'edit') {
    return (
      <CoSpacesEditActivity
        activity={activity}
        sectionId={sectionId}
        projectId={projectId}
      />
    );
  }
  if (activity.action === 'render') {
    return <CoSpacesRenderActivity activity={activity} />;
  }
};

CoSpacesActivity.propTypes = {
  activity: PropTypes.shape({
    action: PropTypes.string,
  }),
  sectionId: PropTypes.string,
  projectId: PropTypes.string,
};

export const getCoSpacesEditUrl = (
  token,
  cospacesIds,
  projectId,
  activityId
) => {
  const classId = cospacesIds?.class_id;
  const assignmentId = cospacesIds?.assignment_ids?.[projectId]?.[activityId];
  if (!classId || !assignmentId) {
    // TODO: Provide a better formatted error message.
    return {
      error: `Failed to load cospaces ids, activity not available.`,
    };
  }

  const url = new URL(
    `/Studio/Assignment/${assignmentId}/${classId}`,
    config.cospaces.base_url
  );
  if (token) {
    url.searchParams.append('auth_basic', token.value);
  }

  return {
    url: url.toString(),
  };
};

async function create_assignment(sectionId, projectId) {
  try {
    return await api.external.account.cospaces.create_assignment.create(
      sectionId,
      projectId
    );
  } catch (error) {
    console.error('Error creating cospaces assignment:', error);
  }
}

async function get_default_cospaces_ids(userId, projectId) {
  try {
    // TODO: Remove this once we know we always will have a section id.
    // Until then this will ensure we always have a default set of ids
    // to use, even if they're potentially incorrect.
    return await api.external.account.cospaces.ids.get(userId, projectId);
  } catch (error) {
    console.error('Error fetching default CoSpaces IDs:', error);
  }
}

export const CoSpacesEditActivity = ({ activity, sectionId, projectId }) => {
  // CoSpaces edit activities need to insert both user credentials along
  // with class and assignment ids into the URL.
  const user = useUser();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [sectionCospacesIds, setSectionCospacesIds] = useState(null);

  useEffect(() => {
    const loadAssignmentsAndTokens = async () => {
      let token = null;
      let sectionCospacesIds = [];

      // When sectionId is null the system will try to use the default cospaces ids.
      if (sectionId != null) {
        // First, try to create the assignment and wait for it to complete
        sectionCospacesIds = await create_assignment(sectionId, projectId);
      } else {
        sectionCospacesIds = await get_default_cospaces_ids(user.id, projectId);

        // If there is no assignment for this project and user, we
        // will create one using one of its sections ordered by class_id.
        if (JSON.stringify(sectionCospacesIds.json) === '{}') {
          sectionCospacesIds = await create_assignment(
            sectionCospacesIds.section_id,
            projectId
          );
        }
      }

      // This code is required because python-api is not accessible for hour-of-code.
      if (!sectionCospacesIds || sectionCospacesIds.length === 0) {
        sectionCospacesIds = await api.section.getCoSpacesIds(sectionId);
      }

      try {
        token = await api.external.account.cospaces.token.get(user.id);
      } catch (error) {
        console.error('Error fetching token:', error);
      }

      setToken(token);
      setSectionCospacesIds(sectionCospacesIds);
      setLoading(false);
    };
    loadAssignmentsAndTokens();
  }, [sectionId, projectId, user.id]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!token) {
    return <div>Failed to load cospaces account, activity not available.</div>;
  }

  const ids = {
    class_id: sectionCospacesIds?.class_id,
    assignment_ids: sectionCospacesIds?.json?.assignment_ids,
  };

  const edit = getCoSpacesEditUrl(token, ids, projectId, activity.id);
  if (edit.error) {
    return <div>{edit.error}</div>;
  }

  return <CoSpacesFrame url={edit.url} />;
};

CoSpacesEditActivity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
  sectionId: PropTypes.string,
  projectId: PropTypes.string,
};

export const CoSpacesFrame = ({ url }) => {
  return (
    <IframeActivity
      url={url}
      allow={`microphone ${config.cospaces.base_url}`}
    />
  );
};
CoSpacesFrame.propTypes = {
  url: PropTypes.string,
};

export const CoSpacesRenderActivity = ({ activity }) => {
  // CoSpaces render activities need to insert user credentials.
  const user = useUser();
  const [loading, token] = api.load(
    api.external.account.cospaces.token.get(user.id)
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  const url = new URL(activity.url);
  if (url.origin === config.cospaces.base_url) {
    if (token) {
      url.searchParams.append('auth_basic', token.value);
    }
  }

  return <CoSpacesFrame url={url.toString()} />;
};

CoSpacesRenderActivity.propTypes = {
  activity: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export const PickcodeActivity = ({
  activity,
  projectId,
  ownerId,
  includeCode = false,
}) => {
  const user = useUser();
  const [loading, account, token, lessonIds] = api.load(
    api.external.account.get('pickcode', ownerId),
    api.external.account.pickcode.token.get(user.id),
    api.external.account.pickcode.ids.get(projectId)
  );

  React.useEffect(() => {
    if (loading) {
      return;
    }

    return token.cleanup;
  }, [loading, token]);

  if (loading) {
    return <LoadingIndicator />;
  }

  // If we failed to load the owner's pickcode account, then show a placeholder
  // activity with an error message letting them know what went wrong.
  if (account === undefined) {
    // TODO: Provide a better formatted error message.
    return (
      <div>Failed to load owner pickcode account, activity not available.</div>
    );
  }

  // If we failed to generate a Pickcode token for the user, then show a placeholder
  // activity with an error message letting them know what went wrong.
  if (!token) {
    // TODO: Provide a better formatted error message.
    return (
      <div>Failed to generate pickcode auth token, activity not available.</div>
    );
  }

  const lessonId = lessonIds?.[activity.id];
  if (!lessonId) {
    // TODO: Provide a better formatted error message.
    return (
      <div>Failed to load pickcode lesson id, activity not available.</div>
    );
  }

  if (activity.action === 'edit') {
    return (
      <PickcodeEditActivity
        accountId={account.id}
        token={token.value}
        lessonId={lessonId}
      />
    );
  }

  if (activity.action === 'render') {
    return (
      <PickcodeRenderActivity
        token={token.value}
        lessonId={lessonId}
        includeCode={includeCode}
      />
    );
  }
};
PickcodeActivity.propTypes = {
  activity: PropTypes.shape({
    action: PropTypes.string,
  }),
  projectId: PropTypes.string,
  ownerId: PropTypes.string,
  includeCode: PropTypes.bool,
};

export const PickcodeEditActivity = ({ accountId, token, lessonId }) => {
  const url = new URL(
    `/project/embed?lessonId=${lessonId}&ownerId=${accountId}#&token=${token}`,
    config.pickcode.base_url
  );
  return <IframeActivity url={url.toString()} />;
};
PickcodeEditActivity.propTypes = {
  accountId: PropTypes.string,
  token: PropTypes.string,
  lessonId: PropTypes.string,
};

export const PickcodeRenderActivity = ({ token, lessonId, includeCode }) => {
  const accountId = config.pickcode.render_activity_user_id;

  let url;
  if (!includeCode) {
    url = new URL(
      `/share/embed?lessonId=${lessonId}&ownerId=${accountId}#&token=${token}`,
      config.pickcode.base_url
    );
  } else {
    url = new URL(
      `/project/embed?lessonId=${lessonId}&ownerId=${accountId}#&token=${token}`,
      config.pickcode.base_url
    );
  }

  return <IframeActivity url={url.toString()} />;
};
PickcodeRenderActivity.propTypes = {
  token: PropTypes.string,
  lessonId: PropTypes.string,
  includeCode: PropTypes.bool,
};
