import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const BlockGroupContainer = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    color: ${theme.palette.text.secondary};
    margin: ${theme.spacing(1)}px;
    padding: ${theme.spacing(1, 2)};
    
    &.transparent {
      background-color: transparent;
    }
  `
);

const NumberedTextBlockContainer = styled.div`
  align-items: baseline;
  display: flex;
  margin: ${({ theme }) => theme.spacing(1, 0)};

  .content {
    width: 100%;
  }
`;

const TextBlockNumber = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.palette.background.level2};
    border-radius: 50%;
    color: ${theme.palette.primary.main};
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px; 
    font-weight: 500;
    justify-content: center;
    margin-right: ${theme.spacing(2)}px;
    min-height: ${theme.spacing(3)}px;
    min-width: ${theme.spacing(3)}px;
  `
);

export const NumberedTextBlock = ({ children, number }) => (
  <NumberedTextBlockContainer>
    <TextBlockNumber>{number}</TextBlockNumber>
    <div className="content">{children}</div>
  </NumberedTextBlockContainer>
);

NumberedTextBlock.propTypes = {
  children: PropTypes.node,
  number: PropTypes.number,
};

export const TextBlock = styled.div(
  ({ theme }) => `
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    
    b {
      font-weight: 600;
    }
    
    br {
      content: "";
      display: block;
      margin-bottom: ${theme.spacing(1)}px;
    }
  `
);
