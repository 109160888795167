import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const Blocked = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 32 32"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16 0C7.168 0 0 7.168 0 16C0 24.832 7.168 32 16 32C24.832 32 32 24.832 32 16C32 7.168 24.832 0 16 0ZM3.2 16C3.2 8.928 8.928 3.2 16 3.2C18.96 3.2 21.68 4.208 23.84 5.904L5.904 23.84C4.208 21.68 3.2 18.96 3.2 16ZM16 28.8C13.04 28.8 10.32 27.792 8.16 26.096L26.096 8.16C27.792 10.32 28.8 13.04 28.8 16C28.8 23.072 23.072 28.8 16 28.8Z" />
  </StyledSvgIcon>
));

Blocked.displayName = 'Blocked';

Blocked.propTypes = {
  size: PropTypes.number,
};

export default Blocked;
