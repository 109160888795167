import FileCopy from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    maxWidth: theme.spacing(20),
    padding: theme.spacing(1),
  },
}))(Tooltip);

const CredentialStyles = styled.div(
  ({ theme }) => `
    background: rgba(255, 255, 255, 0.22);
    border-radius: 5px;
    color: ${theme.palette.text.primary};
    padding: ${theme.spacing(1, 2)};
    user-select: none;
    white-space: nowrap;
    
    span {
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      padding: 0;
    }
    
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      color: inherit;
      margin-bottom: auto;
      margin-top: auto;
      text-decoration: none;
    }
    
    svg {
      width: 20px;
    }
  `
);

const Credential = ({ title, content, className }) => {
  const [position, setPosition] = React.useState({});
  const [message, setMessage] = React.useState('Copy');

  return (
    <CredentialStyles className={className}>
      {/* TODO: If/when we upgrade to MUI 5.x or later use followCursor. */}
      <StyledTooltip
        title={message}
        onMouseMove={e => setPosition({ x: e.pageX, y: e.pageY })}
        PopperProps={{
          anchorEl: {
            clientHeight: 0,
            clientWidth: 0,
            getBoundingClientRect: () => ({
              top: position?.y,
              left: position?.x,
              right: position?.x,
              bottom: position?.y + 10,
              width: 0,
              height: 0,
            }),
          },
        }}
      >
        <a
          href="#"
          onClick={async e => {
            e.preventDefault();
            await navigator.clipboard.writeText(content);
            setMessage('Copied!');
            setTimeout(() => setMessage('Copy'), 1000);
          }}
        >
          <span>
            {title}: {content}
          </span>
          <FileCopy />
        </a>
      </StyledTooltip>
    </CredentialStyles>
  );
};
Credential.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
};

export default Credential;
