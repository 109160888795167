import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  fill: ${({ color }) => color};
`;

const Checkmark = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 40 40"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <path d="M 17.834 27.2 L 9.556 18.922 L 11.235 17.241 L 17.834 23.722 L 28.754 12.8 L 30.443 14.481 L 17.834 27.2 Z" />
  </StyledSvgIcon>
));

Checkmark.displayName = 'Checkmark';

Checkmark.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Checkmark;
