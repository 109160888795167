import { Grid, Typography } from '@material-ui/core';
import { FrameCard } from '../../../components/Frames';
import { PDF } from '../../../components/icons';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../../../components/TeacherTip';
import { TextWithAudio } from '../../../components/Audio';

const CategoryTypography = styled(Typography)(
  ({ $isLast, theme }) => `
    background: ${theme.palette.background.level1};
    font-size: 18px;
    font-weight: 600;
    height: 100%;
    line-height: 24px;
    padding: ${theme.spacing(1)}px;

    // This ensures the bottom left corner of the last row doesn't appear on
    // top of the frame's border.
    border-radius: 0 0 0 ${theme.spacing($isLast ? 1 : 0)}px;

    // Ensure text is horizontally and vertically centered.    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  `
);

const TitleTypography = styled(Typography)(
  ({ $inline, theme }) => `
    display: ${$inline ? 'inline' : 'block'};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-decoration: underline;

    a {    
      color: ${theme.palette.actions.main};
    }
  `
);

const SubtitleTypography = styled(Typography)`
  display: inline;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const ArtifactCellContainer = styled.div(
  ({ $isBottomRow, $isLast, theme }) => `
    background: ${theme.palette.background.level1};
    margin-bottom: ${theme.spacing(!$isLast ? 2 : 0)}px;
    padding: ${theme.spacing(2)}px;
    width: 100%;
    
    // This ensure that the title/subtitle end up anchored to the left edge of
    // the cell while the icon ends up anchored to the right edge of the cell.
    display: flex;
    justify-content: space-between;
    
    // Make sure the titles are vertically centered.  This usually isn't needed,
    // but when there is no subtitle this ensures the title stays vertically
    // centered.
    div.title-container {
      align-self: center;
      display: inline;
    }
    
    // This ensures the bottom right corner of the last row doesn't appear on
    // top of the frame's border.
    border-radius: 0 0 ${theme.spacing($isBottomRow && $isLast ? 1 : 0)}px 0;
    
    // By default don't show the arrow that's in the PDF icon.
    svg.pdf .arrow {
      visibility: hidden;
    }
    
    // If the PDF icon is part of an anchor, then show the arrow when the icon
    // is hovered over.
    a:hover {
      svg.pdf .arrow {
        visibility: visible;
      }
    }
  `
);

/**
 * Helper function to wrap an element with an anchor tag if a URL is provided,
 * otherwise the element is returned unwrapped.
 */
const wrap = (elem, url) => {
  if (!url) {
    return elem;
  }
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {elem}
    </a>
  );
};

/**
 * Render the title for the artifact optionally wrapping it in an anchor tag if
 * a URL for the title was provided.
 */
const Title = ({ title, inline }) => {
  const typography = (
    <TitleTypography
      dangerouslySetInnerHTML={{ __html: title.text }}
      $inline={inline}
    />
  );

  return wrap(typography, title?.url);
};

Title.propTypes = {
  inline: PropTypes.bool,
  title: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

/**
 * Render the icon for the artifact optionally wrapping it in an anchor tag if
 * a URL for the icon was provided.
 */
const Icon = ({ icon }) => {
  let svg = icon.type;
  if (icon.type === 'pdf') {
    svg = <PDF className="pdf" height={32} width={33} />;
  }

  return wrap(svg, icon?.url);
};

Icon.propTypes = {
  icon: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
  }),
};

const ArtifactCell = ({
  audio,
  icon,
  isBottomRow,
  isLast,
  subtitle,
  teacherTip,
  title,
}) => (
  <ArtifactCellContainer $isBottomRow={isBottomRow} $isLast={isLast}>
    <TextWithAudio audio={audio}>
      <div className="title-container">
        <Title title={title} inline={(audio || teacherTip) && !subtitle} />
        {subtitle && (
          <SubtitleTypography dangerouslySetInnerHTML={{ __html: subtitle }} />
        )}
      </div>
      {teacherTip && <TeacherTip content={teacherTip} />}
    </TextWithAudio>
    {icon && <Icon icon={icon} />}
  </ArtifactCellContainer>
);

ArtifactCell.propTypes = {
  audio: PropTypes.string,
  icon: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
  }),
  isBottomRow: PropTypes.bool,
  isLast: PropTypes.bool,
  subtitle: PropTypes.string,
  teacherTip: PropTypes.array,
  title: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

/**
 * Create a Grid that has all of the needed inner borders.
 */
const BorderedGrid = styled(Grid)(
  ({ $isLeft, $isTop, $isBottom, theme }) => `
    border-bottom: ${!$isBottom ? '4px solid rgba(0, 0, 0, 0.08)' : 'none'};
    border-right: ${$isLeft ? '4px solid rgba(0, 0, 0, 0.08)' : 'none'};
    padding-bottom: ${!$isBottom ? theme.spacing(1) : 0}px;
    padding-left: ${!$isLeft ? theme.spacing(1) : 0}px;
    padding-right: ${$isLeft ? theme.spacing(1) : 0}px;
    padding-top: ${!$isTop ? theme.spacing(1) : 0}px;
  `
);

/**
 * Renders a complete row of the artifacts grid.  This includes sub-rows for
 * each artifact and a category title that spans multiple rows.
 */
const Row = ({ row, isFirst, isLast }) => {
  return (
    <Grid container item xs={12}>
      <BorderedGrid
        item
        xs={3}
        $isLeft={true}
        $isTop={isFirst}
        $isBottom={isLast}
      >
        <CategoryTypography $isLast={isLast}>{row.category}</CategoryTypography>
      </BorderedGrid>
      <BorderedGrid
        container
        item
        xs={9}
        $isLeft={false}
        $isTop={isFirst}
        $isBottom={isLast}
      >
        {row.artifacts.map((artifact, index) => (
          <ArtifactCell
            key={index}
            audio={artifact.audio}
            icon={artifact.icon}
            isBottomRow={isLast}
            isLast={index === row.artifacts.length - 1}
            subtitle={artifact.subtitle}
            teacherTip={artifact.tip}
            title={artifact.title}
          />
        ))}
      </BorderedGrid>
    </Grid>
  );
};

Row.propTypes = {
  row: PropTypes.shape({
    category: PropTypes.string,
    artifacts: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string,
        }),
        subtitle: PropTypes.string,
        icon: PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string,
        }),
      })
    ),
  }),
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export const Artifacts = ({ title, rows }) => {
  return (
    <FrameCard title={title ?? 'Artifacts'} padding={{ top: 2, bottom: 0 }}>
      <Grid container>
        {rows.map((row, index) => (
          <Row
            key={index}
            row={row}
            isFirst={index === 0}
            isLast={index === rows.length - 1}
          />
        ))}
      </Grid>
    </FrameCard>
  );
};

Artifacts.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      subtitle: PropTypes.string,
      icon: PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
};
