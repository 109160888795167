import { BackArrow, BackCaret, LoadingIndicator } from '../../components';
import { CoSpacesFrame, getCoSpacesEditUrl } from '../../components/Activity';
import { Step1, Step2, Step3, Step4, Step5 } from './steps';
import api from '../../api';
import AppBarHeader from '../../components/AppBarHeader';
import Button from '@material-ui/core/Button';
import NavTeacher from '../../components/NavTeacher';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useUser } from '../../contexts/AuthContext';

const CoSpacesMigrationStyles = styled.div(
  ({ theme }) => `
    width: 100%;
    
    color: ${theme.palette.text.primary};
    
    #body {
      display: flex;
      
      // Top bar is 64px (8 units) tall, button bar is 56px (7units) tall.
      // In total the body needs to leave 15 units of spacing. 
      height: calc(100vh - ${theme.spacing(15)}px);
      width: 100%;
      
      #instructions {
        background: linear-gradient(180deg, #B0B0F9 -166.81%, #5C71F2 148.12%);
        overflow: scroll;
        width: 50%;
        
        #return {
          color: ${theme.palette.text.primary};
          padding: ${theme.spacing(1, 2, 0, 2)};
          
          svg {
            margin-right: ${theme.spacing(1)}px;
          }
        }
        
        #step {
          padding: ${theme.spacing(2.5, 8)};
          
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          width: 100%;
          
          b {
            font-weight: 600;
          }
          
          img.inline {
            height: 32px;
            top: 12px;
            position: relative;
          }
          
          video {
            margin: ${theme.spacing(1)}px auto;
          }
        }
      }
      
      #frame {
        width: 50%;
      }
    }
    
    #buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${theme.spacing(7)}px;
      padding: ${theme.spacing(0, 4)};
    }
    
    .back {
      color: ${theme.palette.text.primary};

      :hover {
        background-color: ${theme.palette.background.level1};
        color: ${theme.palette.primary.contrastText};
        
        .MuiSvgIcon-root {
          fill: ${theme.palette.primary.contrastText};
        }
      }
      
      svg {
        margin-right: ${theme.spacing(0.5)}px;
      }
    }
    
    .next, .open, .finish {
      color: ${theme.palette.text.primary};

      :hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;   
      }

      img {
        margin-left: ${theme.spacing(0.5)}px;
      }
      
      svg {
        margin-left: ${theme.spacing(0.5)}px;
        transform: rotate(180deg);
      }
    }
  `
);

const CoSpacesMigration = () => {
  const history = useHistory();
  const user = useUser();
  const project = history.location.state?.project;
  const progress = history.location.state?.progress;
  const oldSection = history.location.state?.oldSection;
  const newSection = history.location.state?.newSection;

  const [loading, token] = api.load(
    api.external.account.cospaces.token.get(user.id)
  );

  const steps = React.useMemo(() => {
    return [Step1, Step2, Step3, Step4, Step5];
  }, []);
  const [step, setStep] = React.useState(0);

  const back = React.useCallback(
    () => setStep(prev => (prev > 0 ? prev - 1 : prev)),
    []
  );

  const next = React.useCallback(
    () => setStep(prev => (prev < steps.length - 1 ? prev + 1 : prev)),
    [steps]
  );

  const finish = React.useCallback(async () => {
    await api.project.progress.update(
      user.id,
      project.id,
      { ...progress, section_id: newSection.id },
      progress?.version
    );

    history.push(`/section/${newSection.id}/project/${project.id}`);
  }, [history, user, project, progress, newSection]);

  const onReturn = React.useCallback(() => {
    history.push(`/section/${newSection.id}/project/${project.id}`);
  }, [history, project, newSection]);

  const oldUrl = React.useMemo(() => {
    if (loading) {
      return null;
    }

    if (!oldSection) {
      return null;
    }

    const assignmentIds = oldSection?.cospacesIds?.assignment_ids;
    if (!assignmentIds) {
      return null;
    }

    const activityIds = Object.keys(assignmentIds?.[project.id] ?? {});

    // We currently don't support the case where a project has multiple CoSpaces edit
    // activities.
    if (activityIds.length !== 1) {
      return null;
    }

    const activityId = activityIds[0];
    const edit = getCoSpacesEditUrl(
      token,
      oldSection?.cospacesIds,
      project.id,
      activityId
    );
    if (edit.error) {
      return null;
    }

    return edit.url;
  }, [loading, oldSection, project, token]);

  const newUrl = React.useMemo(() => {
    if (loading) {
      return null;
    }

    if (!newSection) {
      return null;
    }

    const assignmentIds = newSection?.cospacesIds?.assignment_ids;
    if (!assignmentIds) {
      return null;
    }

    const activityIds = Object.keys(assignmentIds?.[project.id] ?? {});

    // We currently don't support the case where a project has multiple CoSpaces edit
    // activities.
    if (activityIds.length !== 1) {
      return null;
    }

    const activityId = activityIds[0];
    const edit = getCoSpacesEditUrl(
      token,
      newSection?.cospacesIds,
      project.id,
      activityId
    );
    if (edit.error) {
      return null;
    }

    return edit.url;
  }, [loading, newSection, project, token]);

  if (loading) {
    return <LoadingIndicator />;
  }

  // Since different steps may need different parameters, we bundle everything
  // we know up into a single state object that is passed into each step.  The
  // individual steps can destructure this state object into just the parameters
  // they need.
  const state = {
    back: back,
    next: next,
    finish: finish,

    project: project,
    oldSection: oldSection,
    newSection: newSection,
    oldCoSpaceUrl: oldUrl,
    newCoSpaceUrl: newUrl,
  };

  return (
    <>
      <AppBarHeader
        menu={user.isTeacherForAnySections() && <NavTeacher />}
        title="Copy a CoSpaces Project"
        includeProfile={false}
      />
      <CoSpacesMigrationStyles>
        <div id="body">
          <div id="instructions">
            <Button
              id="return"
              variant="text"
              color="primary"
              disableRipple
              disableFocusRipple
              onClick={onReturn}
            >
              <BackArrow fill="white" id="nextCaret" size={16} />
              <span>back</span>
            </Button>
            <div id="step">{steps[step](state)}</div>
          </div>
          <div id="frame">
            <CoSpacesFrame url={oldUrl} />
          </div>
        </div>
        <div id="buttons">
          <div>
            {step !== 0 && (
              <Button className="back" variant="outlined" onClick={back}>
                <BackCaret fill="white" size={16} />
                <span>back</span>
              </Button>
            )}
          </div>
          <div>
            {step < steps.length - 1 && (
              <Button
                className="next"
                variant="contained"
                color="primary"
                onClick={next}
              >
                <span>next</span>
                <BackCaret fill="white" size={16} />
              </Button>
            )}
            {step === steps.length - 1 && (
              <Button
                className="finish"
                variant="contained"
                color="primary"
                onClick={finish}
              >
                <span>continue to project</span>
                <BackCaret fill="white" size={16} />
              </Button>
            )}
          </div>
        </div>
      </CoSpacesMigrationStyles>
    </>
  );
};
export default CoSpacesMigration;
