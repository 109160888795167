import {
  BodyContainer,
  Layout,
  ResponsiveContainer,
  SectionGroupContainer,
  StepContainer,
  StepContent,
  StepHeader,
} from '../../../components/Layout';
import { Buttons, SelectAll } from './buttons';
import {
  ProgressProvider,
  RenderStateProvider,
  RubricModalProvider,
  SubmissionsProvider,
  useProgress,
  useRenderState,
} from '../contexts';
import { ProjectProvider, useProject } from '../../../contexts/ProjectContext';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../api';
import AppBarHeader from '../../../components/AppBarHeader';
import { AudioProvider } from '../../../contexts/AudioContext';
import { BlockGroupContainer } from '../../../components/Blocks';
import { Contexts } from './context';
import { Criteria } from './criteria';
import { FrameCard } from '../../../components/Frames';
import { getStatusTitle } from '../../../utils/statusNames';
import LoadingIndicator from '../../../components/LoadingIndicator';
import NavTeacher from '../../../components/NavTeacher';
import { NoSubmissionsToScore } from '../components';
import { ProgressNav } from './progress-nav';
import PropTypes from 'prop-types';
import React from 'react';
import { ScoringFeedback } from './feedback';
import { ScoringSummary } from './summary';
import { SideNav } from './side-nav';
import { StepGuide } from './guide';
import { useGrading } from '../hooks';

const Submission = ({ progress }) => {
  const project = useProject();
  const render = useRenderState();
  const { setGrading } = useGrading();

  // The index of which criteria, if any, are expanded.  If no criteria are
  // expanded then the index will be -1.  Ideally we'd use the criteria index
  // in the render state to control this, but that comes already initialized,
  // and wouldn't provide a way to collapse an expanded criteria.
  const [expanded, setExpanded] = React.useState(-1);

  // TODO: Lift adjustment/feedback into caller of this so that their values
  // aren't lost when we switch to the scoring guide.
  const [adjustment, setAdjustment] = React.useState(0);
  const [feedback, setFeedback] = React.useState('');

  const setAdjustmentInProgressObject = adjustment => {
    setAdjustment(adjustment);
    if (progress) {
      if (!progress.scoring) {
        progress.scoring = {};
      }
      progress.scoring.adjustment = adjustment;
    }
  };

  const setFeedbackInProgressObject = feedback => {
    setFeedback(feedback);
    if (progress) {
      if (!progress.scoring) {
        progress.scoring = {};
      }
      progress.scoring.feedback = feedback;
    }
  };

  React.useEffect(() => {
    setAdjustment(progress?.scoring?.adjustment ?? 0);
    setFeedback(progress?.scoring?.feedback ?? '');
  }, [progress]);

  const criteria = project?.scoring?.criteria;
  if (!criteria) {
    return <div>no scoring criteria</div>;
  }

  return (
    <>
      <BlockGroupContainer>
        <FrameCard title="Scoring Criteria" padding={{ top: 2, bottom: 0 }}>
          <SelectAll />
          {criteria.map((criteria, index) => (
            <Criteria
              key={index}
              criteria={criteria}
              expanded={expanded === index}
              onClick={() => {
                setExpanded(old => {
                  if (old === index) {
                    return -1;
                  }
                  return index;
                });
                render.showCriteria(index);
              }}
            />
          ))}
        </FrameCard>
      </BlockGroupContainer>

      <BlockGroupContainer>
        <ScoringSummary
          criteria={criteria}
          adjustment={adjustment}
          onChangeAdjustment={setAdjustmentInProgressObject}
        />
      </BlockGroupContainer>

      <BlockGroupContainer>
        <ScoringFeedback
          feedback={feedback}
          onChange={setFeedbackInProgressObject}
        />
      </BlockGroupContainer>

      <Buttons
        adjustment={adjustment}
        onSendBack={() => setGrading('sent_back', adjustment, feedback)}
        onFinalize={() => setGrading('completed', adjustment, feedback)}
      />
    </>
  );
};
Submission.propTypes = {
  progress: PropTypes.shape({
    scoring: PropTypes.shape({
      adjustment: PropTypes.number,
      feedback: PropTypes.string,
    }),
  }),
};

const Scoring = () => {
  const project = useProject();
  const { progress } = useProgress();
  const render = useRenderState();

  const status = progress.status;
  const stepBodyRef = React.useRef(null);

  // This will scroll the instructional area to top
  // whenever something changes in the state
  // (saving score, switching students, etc.)
  // React.useEffect(() => {
  //   stepBodyRef.current.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth',
  //   });
  // }, [render]);

  const student = render.getCurrentStudentName();

  // SCORING STEPS GUIDE

  const guideScoringGroup = React.useMemo(() => {
    return (
      project?.scoring_guide_section_group ||
      project?.scoring?.guide?.section_group
    );
  }, [project]);

  const guideSections = React.useMemo(() => {
    if (!guideScoringGroup) {
      return {};
    }

    return Object.fromEntries(
      guideScoringGroup.sections.flatMap(section =>
        section.steps.map(step => [step.id, section])
      )
    );
  }, [guideScoringGroup]);

  const guideSteps = React.useMemo(() => {
    if (!guideScoringGroup) {
      return {};
    }

    return Object.fromEntries(
      guideScoringGroup.sections.flatMap(section =>
        section.steps.map(step => [step.id, step])
      )
    );
  }, [guideScoringGroup]);

  const guideSection = guideSections[render.stepId];
  const guideStep = guideSteps[render.stepId];

  const StepHeaderContainer = () => (
    <StepHeader
      section={render.view.guide ? guideSection.title : getStatusTitle(status)}
      step={
        render.view.guide
          ? guideStep.title
          : `${student.first_name} ${student.last_name}`
      }
      showSwitch={render.showToggle}
      switchChecked={render.view.guide}
      onSwitchChange={
        render.view.guide ? render.showSubmissions : render.showScoringSteps
      }
    />
  );

  const isActivityShown = !!render.contexts && render.contexts.length > 0;
  return (
    <BodyContainer isActivityShown={isActivityShown}>
      <SectionGroupContainer
        isActivityShown={isActivityShown}
        ref={stepBodyRef}
      >
        <StepContainer>
          <StepHeaderContainer />
          <StepContent $hasActivity={isActivityShown}>
            {render.view.guide && <StepGuide step={guideStep} />}
            {render.view.submissions && <Submission progress={progress} />}
          </StepContent>
        </StepContainer>
      </SectionGroupContainer>
      <Contexts />
    </BodyContainer>
  );
};

const ScoringPageBody = ({ sectionId, courseId }) => {
  const project = useProject();
  const render = useRenderState();
  const history = useHistory();
  const studentId = history.location.state?.studentId;

  // If theres a studentId in the history location state
  // it will execute showStudentSubmission
  // and will show the selected student to be graded
  const isFirstLoad = React.useRef(true);
  React.useEffect(() => {
    if (!isFirstLoad.current) {
      return;
    }
    isFirstLoad.current = false;

    if (!studentId) {
      history.push(
        `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}`
      );
      return;
    }

    render.showStudentSubmission(studentId);
  }, [history, render, sectionId, courseId, studentId]);

  return (
    <>
      <AppBarHeader
        menu={<NavTeacher selected="sections" />}
        title={project.name}
      />
      <Layout>
        <SideNav sectionId={sectionId} courseId={courseId} />
        <ResponsiveContainer>
          {(render.view.guide || render.view.submissions) && (
            <>
              <ProgressProvider studentId={render.studentId}>
                <Scoring />
              </ProgressProvider>

              <ProgressNav />
            </>
          )}
          {render.view.nothing_to_show && <NoSubmissionsToScore />}
        </ResponsiveContainer>
      </Layout>
    </>
  );
};

ScoringPageBody.propTypes = {
  sectionId: PropTypes.string,
};

const ScoringPage = () => {
  const { sectionId, courseId, projectId } = useParams();
  const [loading, project] = api.load(api.project.get(projectId));

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <AudioProvider>
      <ProjectProvider project={project}>
        <SubmissionsProvider
          sectionId={sectionId}
          courseId={courseId}
          projectId={projectId}
        >
          <RenderStateProvider sectionId={sectionId}>
            <RubricModalProvider>
              <ScoringPageBody sectionId={sectionId} courseId={courseId} />
            </RubricModalProvider>
          </RenderStateProvider>
        </SubmissionsProvider>
      </ProjectProvider>
    </AudioProvider>
  );
};

export default ScoringPage;
