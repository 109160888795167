import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const SentBack = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 36 36"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="circle"
      d="M17.9995 35C27.3884 35 34.9995 27.3888 34.9995 18C34.9995 8.61116 27.3884 1 17.9995 1C8.61067 1 0.999512 8.61116 0.999512 18C0.999512 27.3888 8.61067 35 17.9995 35Z"
      fill="none"
      stroke="white"
      strokeWidth="2"
    />
    <path
      className="arrow"
      d="M15.9998 12.8948V8.99988L9 15.2515L15.9998 21.561V17.6177C22.2096 18.1759 25.4961 22.0014 26.7152 26.9999C26.901 26.2376 26.9997 25.4481 26.9997 24.6395C26.9997 18.5343 23.1583 13.5377 15.9998 12.8948Z"
      fill="white"
    />
  </StyledSvgIcon>
));

SentBack.displayName = 'SentBack';

SentBack.propTypes = {
  size: PropTypes.number,
};

export default SentBack;
