import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const pxToRem = size => `${(size / 16) * 1}rem`;

function ThemeProvider(props) {
  const { children } = props;

  const theme = React.useMemo(() => {
    const nextTheme = createTheme({
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            color: '#ffffff',
            backgroundColor: '#242424',
            boxShadow: 'none',
          },
        },
        MuiButton: {
          outlinedPrimary: {
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
          },
          outlinedSecondary: {
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
          },
        },
        MuiDialogTitle: {
          root: {
            fontFamily: "'Poppins', sans-serif",
            fontSize: pxToRem(20),
          },
        },
      },
      palette: {
        actions: {
          main: '#5C71F2',
          secondary: '#46C6D0',
          success: '#61F700',
          graded: '#fff61c',
          error: '#D43E64',
        },
        background: {
          nav: '#141313',
          paper: '#242424',
          default: '#242424',
          level1: '#ffffff',
          level2: '#D7DBF4',
          level3: '#F2F2f2',
          level4: '#E5E5E5',
          code: '#ECEBEB',
        },
        primary: {
          dark: 'linear-gradient(180deg, #B0B0F9 -166.81%, #5C71F2 148.12%)',
          gradient:
            'linear-gradient(180deg, #B0B0F9 -166.81%, #5C71F2 148.12%)',
          light: '#B0B0F9',
          main: '#5C71F2',
          contrastText: '#000',
        },
        secondary: {
          dark: '#242424;',
          light:
            'conic-gradient(from 179.94deg at 49.94% 50.09%, rgba(92, 113, 242, 0.46) -105deg, rgba(102, 216, 225, 0.34) 105deg, rgba(176, 176, 249, 0.51) 180.21deg, rgba(92, 113, 242, 0.46) 255deg, rgba(102, 216, 225, 0.34) 465deg)',
          main: '#66D8E1;',
        },
        text: {
          code: '#0d818a',
          primary: '#ffffff',
          secondary: '#4D4D4D',
          disabled: 'rgba(0, 0, 0, 0.5)',
          hint: 'rgba(255, 255, 255, 0.5)',
          icon: 'rgba(255, 255, 255, 0.5)',
          divider: 'rgba(255, 255, 255, 0.12)',
        },
        type: 'dark',
      },
      typography: {
        body1: {
          fontFamily: "'Poppins', sans-serif",
          fontSize: pxToRem(16),
          lineHeight: pxToRem(32),
          fontWeight: '500',
        },
        body2: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(24),
          fontWeight: '500',
        },
        button: {
          fontFamily: "'IBM Plex Mono', monospace",
        },
        caption: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(12),
        },
        code: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(16),
          lineHeight: 1,
        },
        h1: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '300',
          fontSize: pxToRem(44),
        },
        h2: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '300',
          fontSize: pxToRem(32),
        },
        h3: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(24),
          fontWeight: '500',
          lineHeight: 1.2,
        },
        h4: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '500',
          fontSize: pxToRem(22),
        },
        h5: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '300',
          fontSize: pxToRem(16),
        },
        h6: {
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '300',
          fontSize: pxToRem(14),
        },
        subtitle1: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontWeight: '500',
          fontSize: pxToRem(18),
        },
        subtitle2: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontWeight: '500',
          fontSize: pxToRem(15),
        },
      },
    });

    return nextTheme;
  }, []);

  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
