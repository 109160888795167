import React, { useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../api';
import PropTypes from 'prop-types';

export const CourseContext = React.createContext({});

function CourseProvider(props) {
  const { children } = props;
  const { courseId } = useParams();
  const history = useHistory();

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      const { payload, type } = action;

      switch (type) {
        case 'REQUESTING_COURSE':
          return {
            ...prevState,
            isLoading: true,
          };
        case 'REQUESTING_COURSE_ERROR':
          history.push(`/error`);
          return {
            ...prevState,
            error: payload,
            isLoading: false,
          };
        case 'REQUESTING_COURSE_SUCCESS':
          return {
            ...prevState,
            course: { ...payload },
            currentLevelId: null,
            levels: payload.levels,
            isLoading: false,
          };

        case 'UPDATE_CURRENT_LEVEL':
          return {
            ...prevState,
            currentLevelId: payload,
          };
        default:
          return { ...prevState };
      }
    },
    {
      course: {},
      currentLevelId: null,
      error: null,
      isLoading: false,
      levels: [],
    }
  );

  useEffect(() => {
    dispatch({ payload: null, type: 'REQUESTING_COURSE' });
    api.courses
      .getCourse(courseId)
      .then(data =>
        dispatch({
          payload: data || {},
          type: 'REQUESTING_COURSE_SUCCESS',
        })
      )
      .catch(error =>
        dispatch({ payload: error, type: 'REQUESTING_COURSE_ERROR' })
      );
  }, [courseId]);

  const setCurrentLevelId = React.useCallback(
    id => dispatch({ payload: id, type: 'UPDATE_CURRENT_LEVEL' }),
    [dispatch]
  );

  return (
    <CourseContext.Provider
      value={{
        ...state,
        setCurrentLevelId: setCurrentLevelId,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
}

CourseProvider.propTypes = {
  children: PropTypes.object,
};

export default CourseProvider;
