import * as lodash from 'lodash';
import { CoursesGrid, Heading } from './components/grid';
import api from '../../api';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import LoadingIndicator from '../../components/LoadingIndicator';
import NavTeacher from '../../components/NavTeacher';
import Page from '../../components/Page';
import React from 'react';
import { SectionSelectionModal } from './components/modal';
import { useHistory } from 'react-router';
import { useUser } from '../../contexts/AuthContext';

const COURSE_SORT_FIELDS = ['sort_key', 'title', 'id'];

// These sort fields are used to determine which section should be chosen
// by the system when there are multiple choices, but we've decided to
// not show the user a section selection modal.
const SECTION_SORT_FIELDS = ['is_active', 'end_date', 'start_date', 'id'];
const SECTION_SORT_ORDERS = ['desc', 'desc', 'desc', 'asc'];

const Courses = () => {
  const history = useHistory();
  const user = useUser();
  const [modalSettings, setModalSettings] = React.useState({ show: false });

  const [loading, sections, allCourses] = api.load(
    api.roster.sections.getStudentEnrollments(),
    api.curriculum.courses.get()
  );

  const [activeCourses, inactiveCourses] = React.useMemo(() => {
    if (loading || !sections || !allCourses) {
      return [[], []];
    }

    const courseMap = new Map();
    allCourses.forEach(course => {
      courseMap.set(course.id, course);
    });
    const courseCards = new Map();
    sections.forEach(section => {
      section.courses.forEach(sectionCourse => {
        const course = courseMap.get(sectionCourse.course_id);
        let courseCard = courseCards.get(course.id);

        if (!courseCard) {
          courseCard = {
            id: course.id,
            title: course.name,
            chips: course.chips,
            media: { url: course.media_url },
            sort_key: course.sort_key,
            sections: [],
          };
          courseCards.set(course.id, courseCard);
        }
        courseCard.sections.push({
          id: section.id,
          title: section.title,
          is_active: section.is_active,
          start_date: section.start_date,
          end_date: section.end_date,
        });
      });
    });
    const courseList = Array.from(courseCards, ([, value]) => value);
    courseList.forEach(courseCard => {
      courseCard.sections = lodash.orderBy(
        courseCard.sections,
        SECTION_SORT_FIELDS,
        SECTION_SORT_ORDERS
      );
    });

    // Partition the courses into two groups based on if there is an active
    // section or not.
    const predicate = c => c.sections.some(s => s.is_active);

    return lodash.partition(
      lodash.sortBy(courseList, COURSE_SORT_FIELDS),
      predicate
    );
  }, [loading, sections, allCourses]);

  const onClickCourseCard = React.useCallback(
    course => {
      // There's no need to show a section selection modal if there isn't more
      // than one active section for the course.
      const numActiveSections = course.sections.filter(s => s.is_active).length;
      if (numActiveSections <= 1) {
        // The way the sections are sorted ensures that the first one is always
        // the one we want to choose when there's zero or one active sections.
        history.push(`/section/${course.sections[0].id}/course/${course.id}`);
        return;
      }

      // Otherwise, show the modal so the user can select which section they
      // want to work in.  Make sure we only show active sections in the
      // modal.
      setModalSettings({
        show: true,
        course: course,
      });
    },
    [history, setModalSettings]
  );

  const onClickSection = React.useCallback(
    (course, sectionId) => {
      history.push(`/section/${sectionId}/course/${course.id}`);
    },
    [history]
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Page
      menu={
        user.isTeacherForAnySections() && <NavTeacher selected="curriculum" />
      }
      title={`Welcome, ${capitalizeFirstLetter(user.first_name)}!`}
    >
      {activeCourses.length > 0 && (
        <>
          <Heading>Active Courses</Heading>
          <CoursesGrid courses={activeCourses} onClick={onClickCourseCard} />
        </>
      )}
      {inactiveCourses.length > 0 && (
        <>
          <Heading>Archived Courses</Heading>
          <CoursesGrid courses={inactiveCourses} onClick={onClickCourseCard} />
        </>
      )}
      <SectionSelectionModal
        settings={modalSettings}
        onClick={onClickSection}
        onClose={() => setModalSettings(() => ({ show: false }))}
      />
    </Page>
  );
};

export default Courses;
