import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import { CodeBlockContainer } from '../code';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import { FrameCard } from '../../Frames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../../TeacherTip';
import TextBlockTypography from '../../Text';
import { TextWithAudio } from '../../Audio';
import Typography from '@material-ui/core/Typography';

//
// Multiple choice checkpoints
//

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];

const MCContainer = styled.div(
  ({ theme }) => `
    padding-bottom: ${theme.spacing(3)}px;

    .MuiFormControl-root{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow: hidden;
      width: 100%;

      // This style will turn the list to a 2x2 grid
      // Will apply just for image choices
      &.grid {
        flex-direction: row;
        justify-content: space-around;
        & > div.image {
          width: calc(50% - ${theme.spacing(3)}px);
        }
      }
    }

    .Mui-disabled {
      color: ${theme.palette.text.disabled};
    }
`
);

const MCCTitle = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top:  ${theme.spacing(1)}px;
    padding: ${theme.spacing(0, 3)};
  `
);

MCCTitle.displayName = 'MCCTitle';

const MCCStemTextBlockTypography = styled(TextBlockTypography)(
  ({ theme }) => `
    display: inline;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    
    // Table styles
    table {
      border-collapse: collapse;
      border-radius: 5px;
      font-size: 16px;
      overflow: hidden;
      width: 100%;
    }
    
    th {
      background-color: ${theme.palette.background.level3};
      font-weight: 600;
    }
    
    th, td {
      border: 8px solid ${theme.palette.background.level3};
      padding: ${theme.spacing(2)}px;
      text-align: left;
      &.centered {
        text-align: center;
      }
    }
  `
);

const PartWrapper = styled.div(
  ({ theme }) => `
    display: flex;
    margin-bottom: ${theme.spacing(2)}px;
    width: 100%;
  `
);

// TODO: should we allow audio with images? code blocks?
// TODO: video doesn't work, is that okay?
const MCCStem = ({ audio, parts, teacherTip }) => {
  // TODO: Remove this once all of the projects in the database use the new
  // question list syntax.
  if (typeof parts === 'string') {
    parts = [
      {
        type: 'text',
        text: parts,
      },
    ];

    if (audio) {
      parts[0]['audio'] = audio;
    }
    if (teacherTip) {
      parts[0]['tip'] = teacherTip;
    }
  }

  return (
    <MCCTitle>
      {parts.map((p, index) => {
        switch (p.type) {
          case 'text':
            return (
              <PartWrapper key={index}>
                <TextWithAudio audio={p?.audio}>
                  <MCCStemTextBlockTypography
                    dangerouslySetInnerHTML={{ __html: p.text }}
                  />
                  {p.tip && <TeacherTip content={p.tip} />}
                </TextWithAudio>
              </PartWrapper>
            );

          case 'media':
            return (
              <PartWrapper key={index}>
                <ImageContainer media={p.media} />
              </PartWrapper>
            );

          case 'code':
            return (
              <PartWrapper key={index}>
                <CodeBlockContainer code={p.code} language={p.language} />
              </PartWrapper>
            );

          default:
            throw new Error(`unsupported question type: ${p.type}`);
        }
      })}
    </MCCTitle>
  );
};

MCCStem.propTypes = {
  // TODO: Remove the audio and oneOfType in 'parts' in the future
  // when we only support arrays.
  audio: PropTypes.string,
  parts: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        // Text + HTML
        PropTypes.shape({
          audio: PropTypes.string,
          type: PropTypes.string,
          text: PropTypes.string,
        }),

        // Media
        PropTypes.shape({
          type: PropTypes.string,
          media: PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string,
            height: PropTypes.string,
          }),
        }),

        // Code Snippet
        PropTypes.shape({
          type: PropTypes.string,
          code: PropTypes.string,
          language: PropTypes.string,
        }),
      ])
    ),
    PropTypes.string,
  ]),
  teacherTip: PropTypes.array,
};

const MCCResult = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: ${theme.spacing(0.5, 2)};
    width: 130px;
    h6 {
      font-weight: 600;
      line-height: 24px;
    }
    &.incorrect {
      background-color: #fbecf0;
      color: #e05a5a;
    }
    &.correct {
      background-color: #effee5;
      color: #517a35;
    }
  `
);

const MCCAnswerExplanation = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level3};
    margin: ${theme.spacing(1, 3, 4)};
    padding: ${theme.spacing(2, 0)};
    h6.expl-title {

      color: ${theme.palette.background.nav};
      font-weight: 600;
      line-height: 28px;
      margin-left: ${theme.spacing(3)}px;
      margin-bottom: ${theme.spacing(1)}px;
    }
  `
);

const MCCAttempts = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing(0, 3)};
    margin-bottom: ${theme.spacing(2)}px;
    p {
      background-color: #FFFBE4;
      color: #8C7E2C;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
    }
  `
);

const StyledTextChoice = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(0.5)}px;
    padding: ${theme.spacing(1, 0)};
    width: 100%;

    &.correct {
      background-color: #effee5;
    }
    &.incorrect {
      background-color: ${theme.palette.background.level3};
    }
  `
);

StyledTextChoice.displayName = 'StyledTextChoice';

const StyledLabel = styled.div(
  ({ $image, theme }) => `
    align-items: center;
    background-color: transparent;
    border-color: #7d7d7d;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    color: #7d7d7d;
    display: flex;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    height: ${theme.spacing(3)}px;
    justify-content: center;
    line-height: 18px;
    margin: ${theme.spacing(1, 3, 1, $image ? 0 : 3)};
    user-select: none;
    width: ${theme.spacing(3)}px;

    &.selected {
      background-color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
      color: ${theme.palette.background.level1};
    }
    &.complete {
      background-color: transparent;
      border-color: #908F8F;
      color: #908F8F;
    }
    &.correct {
      background-color: transparent;
      border-color: #7d7d7d;
      color: #7d7d7d;
    }
    &.selected.correct {
      background-color: #517a35;
      border-color: #517a35;
      color: ${theme.palette.background.level1};
    }
    &.selected.incorrect {
      background-color: #7d7d7d;
      border-color: #7d7d7d;
      color: ${theme.palette.background.level1};
    }

    // Hover effects when the checkpoint is not complete
    // or is incorrect (one correct answer)
    &:not(.complete):not(.incorrect):hover {
      border-color: ${theme.palette.primary.main};
      cursor: pointer;

      // Hover effects for Text Choices
      &.selected {
        background-color: #D7DBF4;
      }
      color: ${theme.palette.primary.main};
      & + div {
        h6 {
          color: ${theme.palette.primary.main};
        }
      }

      // Hover effects for Image Choices
      & + div {
        border-color: ${theme.palette.primary.main};
      }
    }
  `
);

const StyledCircularProgress = styled(CircularProgress)(
  ({ $image, theme }) => `
    margin: ${theme.spacing(1, 3, 1, $image ? 0 : 3)};
  `
);

const StyledText = styled.div(
  () => `
    flex-grow: 1;
  `
);

const StyledTypography = styled(TextBlockTypography)(
  ({ theme }) => `
    font-weight: 400;

    &.selected {
      color: ${theme.palette.primary.main};
    }
    &.complete {
      color: #908F8F;
    }
    &.correct {
      color: ${theme.palette.text.secondary};
    }
    &.selected.correct {
      color: #517A35;
    }
    &.selected.incorrect {
      color: ${theme.palette.text.secondary};
    }
  `
);

const StyledTextCheckMark = styled.div(
  ({ theme }) => `
    height: ${theme.spacing(3)}px;
    margin: ${theme.spacing(1, 3)};
    width: ${theme.spacing(3)}px;

    &.correct {
      svg {
        fill: #517A35;
      }
    }
    &.incorrect {
      svg {
        fill: #E05A5A;
      }
    }
  `
);

const TextChoice = ({
  classes,
  choice,
  icon,
  index,
  isChoiceUpdating,
  onChoiceClick,
}) => (
  <StyledTextChoice className={classes}>
    {isChoiceUpdating ? (
      <StyledCircularProgress size={24} />
    ) : (
      <StyledLabel className={classes} onClick={onChoiceClick}>
        {letters[index]}
      </StyledLabel>
    )}
    <StyledText>
      <StyledTypography
        className={classes}
        dangerouslySetInnerHTML={{ __html: choice.text }}
        variant="h6"
      />
    </StyledText>
    <StyledTextCheckMark className={classes}>
      {icon === 'correct' && <CheckIcon />}
      {icon === 'incorrect' && <CloseIcon />}
    </StyledTextCheckMark>
  </StyledTextChoice>
);

TextChoice.propTypes = {
  classes: PropTypes.string,
  choice: PropTypes.shape({
    text: PropTypes.string,
  }),
  icon: PropTypes.oneOf(['correct', 'incorrect']),
  index: PropTypes.number,
  isChoiceUpdating: PropTypes.bool,
  onChoiceClick: PropTypes.func,
};

const StyledImageChoice = styled.div(
  ({ theme }) => `
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    margin: ${theme.spacing(0, 1.5, 2)};

    &.correct {
      background-color: #effee5;
    }
    &.incorrect {
      background-color: ${theme.palette.background.level3};
    }
  `
);

StyledImageChoice.displayName = 'StyledImageChoice';

const StyledImageChoiceContainer = styled.div(
  ({ theme }) => `
    display: flex;
    padding: ${theme.spacing(3, 2)};
  `
);

const StyledImageCheckMark = styled.div(
  () => `
    display: none;

    &.correct {
      display: block;
      svg {
        fill: #517A35;
      }
    }
    &.incorrect {
      display: block;
      svg {
        fill: #E05A5A;
      }
    }
  `
);

const StyledImageChoiceWrapper = styled.div(
  () => `
    display: flex;
    flex-direction: column;

    &.correct, &.incorrect {
      padding-top: 0;
    }
  `
);

const StyledImageContainer = styled.div(
  ({ $maxheight, theme }) => `
    margin: 0 auto;
    overflow: hidden;
    
    img {
      border-radius: 8px;
      cursor: zoom-in;
      filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.2));
      max-height: ${$maxheight ?? 'none'};
      max-width: 100%;
    }

    .selected {
      border-color: ${theme.palette.primary.main};
    }
  `
);

const ImageContainer = ({ classes, media }) => {
  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const showDialog = e => {
    setDialogOpen(true);
    e.preventDefault();
  };

  const closeDialog = () => setDialogOpen(false);

  return (
    <StyledImageContainer
      $maxheight={media.height}
      className={classes}
      onClick={e => e.stopPropagation()}
    >
      <img alt="multiple choice image" src={media.url} onClick={showDialog} />
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        open={isDialogOpen}
        onClose={closeDialog}
      >
        <img
          alt="zoomed multiple choice image"
          style={{ objectFit: 'scale-down', borderRadius: '20px' }}
          src={media.url}
          onClick={closeDialog}
        />
      </Dialog>
    </StyledImageContainer>
  );
};

ImageContainer.propTypes = {
  classes: PropTypes.string,
  media: PropTypes.shape({ height: PropTypes.string, url: PropTypes.string }),
};

const ImageChoice = ({
  classes,
  choice,
  icon,
  index,
  isChoiceUpdating,
  onChoiceClick,
}) => (
  <StyledImageChoice className={`image ${classes}`}>
    <StyledImageChoiceContainer>
      <StyledImageChoiceWrapper className={classes}>
        {isChoiceUpdating ? (
          <StyledCircularProgress size={24} $image />
        ) : (
          <StyledLabel className={classes} onClick={onChoiceClick} $image>
            {letters[index]}
          </StyledLabel>
        )}
        <StyledImageCheckMark className={classes}>
          {icon === 'correct' && <CheckIcon />}
          {icon === 'incorrect' && <CloseIcon />}
        </StyledImageCheckMark>
      </StyledImageChoiceWrapper>
      <ImageContainer classes={classes} media={choice.media} />
    </StyledImageChoiceContainer>
  </StyledImageChoice>
);

ImageChoice.propTypes = {
  classes: PropTypes.string,
  choice: PropTypes.shape({
    media: PropTypes.shape({
      url: PropTypes.string,
      height: PropTypes.string,
    }),
  }),
  icon: PropTypes.oneOf(['correct', 'incorrect']),
  index: PropTypes.number,
  isChoiceUpdating: PropTypes.bool,
  onChoiceClick: PropTypes.func,
};

const StyledCodeChoice = styled.div(
  ({ theme }) => `
    align-items: flex-start;
    background-color: transparent;
    display: flex;
    margin: ${theme.spacing(0, 1.5, 2)};
    padding: ${theme.spacing(2)}px;
    width: 100%;

    &.correct {
      background-color: #effee5;
    }
    &.incorrect {
      background-color: ${theme.palette.background.level3};
    }
  `
);

const StyledCodeChoiceWrapper = styled.div(
  () => `
    margin: 0;
    width: 100%;
  `
);

const CodeSnippetChoice = ({
  classes,
  choice,
  icon,
  index,
  isChoiceUpdating,
  onChoiceClick,
}) => (
  <StyledCodeChoice className={classes}>
    <StyledImageChoiceWrapper className={classes}>
      {isChoiceUpdating ? (
        <StyledCircularProgress size={24} $image />
      ) : (
        <StyledLabel className={classes} onClick={onChoiceClick} $image>
          {letters[index]}
        </StyledLabel>
      )}
      <StyledImageCheckMark className={classes}>
        {icon === 'correct' && <CheckIcon />}
        {icon === 'incorrect' && <CloseIcon />}
      </StyledImageCheckMark>
    </StyledImageChoiceWrapper>
    <StyledCodeChoiceWrapper>
      <CodeBlockContainer code={choice.code} language={choice.language} />
    </StyledCodeChoiceWrapper>
  </StyledCodeChoice>
);

CodeSnippetChoice.propTypes = {
  classes: PropTypes.string,
  choice: PropTypes.shape({
    code: PropTypes.string,
    language: PropTypes.string,
  }),
  icon: PropTypes.oneOf(['correct', 'incorrect']),
  index: PropTypes.number,
  isChoiceUpdating: PropTypes.bool,
  onChoiceClick: PropTypes.func,
};

const Choice = ({
  choice,
  classes,
  icon,
  index,
  isUpdating,
  onChange,
  readOnly,
}) => {
  const [isClicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    if (!isUpdating) {
      setClicked(false);
    }
  }, [isUpdating]);

  const onChoiceClick = () => {
    if (isUpdating || readOnly) {
      return;
    }
    setClicked(true);
    onChange(choice);
  };

  const props = {
    choice,
    classes,
    icon,
    index,
    isChoiceUpdating: isUpdating && isClicked,
    onChoiceClick,
  };

  if (choice.text !== undefined) {
    return <TextChoice {...props} />;
  } else if (choice.media !== undefined && choice.media.type === 'image') {
    return <ImageChoice {...props} />;
  } else if (choice.code !== undefined && choice.language !== undefined) {
    return <CodeSnippetChoice {...props} />;
  } else {
    throw new Error(`unsupported checkpoint choice: ${choice}`);
  }
};

export const MultipleChoiceCheckpoint = ({
  checkpoint,
  answer,
  isUpdating,
  onChange,
  readOnly = false,
}) => {
  const [rowGrid, setRowGrid] = React.useState(false);
  const choicesRef = React.useRef();

  // This will trigger when the choicesRef element (the choices list container)
  // changes its width, to be able to switch between a 4x1 and 2x2 grid
  // This will only apply to the images choices
  React.useEffect(() => {
    // This is the breakpoint for the container width
    // to change the list list from 4x1 to a 2x2 grid
    const breakpoint = 600;

    const resizeObserver = new ResizeObserver(([container]) => {
      setRowGrid(container.contentRect.width > breakpoint);
    });

    resizeObserver.observe(choicesRef.current);
    return () => {
      // Remove resizeObserver when Component unmounts
      resizeObserver.disconnect();
    };
  }, []);

  const numAttemptsAllowed = checkpoint?.attempts ?? 1;
  const numAttemptsRemaining =
    answer.value?.attempts_remaining ?? numAttemptsAllowed;

  // Number of attempts left to show
  const attemptsMsg =
    numAttemptsAllowed > numAttemptsRemaining && !answer.complete
      ? numAttemptsRemaining
      : null;

  const numChoicesNeeded = checkpoint.choices.filter(c => c.correct).length;
  const selected = answer.value?.selected ?? [];

  // Determine which choices should be flagged as incorrect.
  //
  // In the case where there are multiple choices required for an attempt we
  // should only flag choices if the checkpoint is complete -- this is to avoid
  // confusing a student when a prior attempt had some correct choices.  The
  // choices that should be flagged are the ones in the last attempt that are
  // incorrect.
  //
  // In the case where there is only one choice required for an attempt we
  // should flag all prior choices that were made that were incorrect.
  let previousChoices = [];
  if (numChoicesNeeded > 1 && answer.complete) {
    previousChoices = selected;
  } else if (numChoicesNeeded === 1) {
    const previous = answer.value?.previous?.flatMap(c => c) ?? [];
    previousChoices = selected.concat(previous);
  }

  const incorrectChoices = checkpoint.choices
    .filter(c => previousChoices.includes(c.id))
    .filter(c => !c.correct)
    .map(c => c.id);

  // Check if checkpoint answer is correct
  const isCheckpointCorrect = checkpoint.choices
    .filter(c => selected.includes(c.id))
    .every(c => c.correct);

  return (
    <FrameCard
      title={checkpoint?.title ?? 'Checkpoint'}
      padding={{ bottom: 0 }}
      transparent
    >
      <MCContainer>
        {answer.complete && (
          <>
            <MCCResult
              className={isCheckpointCorrect ? 'correct' : 'incorrect'}
            >
              <Typography variant={'subtitle1'}>
                {isCheckpointCorrect ? 'Correct!' : 'Incorrect'}
              </Typography>
            </MCCResult>
            {checkpoint.explanation && (
              <MCCAnswerExplanation>
                <Typography className="expl-title" variant="h6">
                  Answer Explanation:
                </Typography>
                <MCCStem parts={checkpoint.explanation} />
              </MCCAnswerExplanation>
            )}
          </>
        )}
        <MCCStem
          audio={checkpoint.audio}
          parts={checkpoint.question}
          teacherTip={checkpoint?.tip}
        />
        {!!attemptsMsg && (
          <MCCAttempts>
            <Typography>
              {`${attemptsMsg} more attempt${attemptsMsg > 1 ? 's' : ''}`}
            </Typography>
          </MCCAttempts>
        )}
        <FormControl ref={choicesRef} className={rowGrid ? 'grid' : ''}>
          {checkpoint.choices.map((choice, index) => {
            const isSelected = selected.includes(choice.id);
            const isCorrect = answer.complete && choice.correct;
            const isIncorrect = incorrectChoices.includes(choice.id);

            // A choice is clickable if all of the following conditions are met.
            // - The checkpoint is not yet complete.
            // - The choice isn't currently being shown as incorrect.
            const isClickable = !answer.complete && !isIncorrect;

            let icon = null;
            if (isCorrect) {
              icon = 'correct';
            } else if (isIncorrect) {
              icon = 'incorrect';
            }

            // This will create a list of classes to assign
            // to inner components so they can apply the right styling
            // based on the status of the choice
            const classes = (
              `${isSelected ? 'selected ' : ''}` +
              `${answer.complete ? 'complete ' : ''}` +
              `${isCorrect ? 'correct ' : ''}` +
              `${isIncorrect ? 'incorrect' : ''}`
            ).trim();

            return (
              <Choice
                key={choice.id}
                choice={choice}
                classes={classes}
                icon={icon}
                index={index}
                isUpdating={isUpdating && !answer.complete}
                onChange={onChange}
                readOnly={readOnly || !isClickable}
              />
            );
          })}
        </FormControl>
      </MCContainer>
    </FrameCard>
  );
};

MultipleChoiceCheckpoint.propTypes = {
  checkpoint: PropTypes.shape({
    title: PropTypes.string,
    // TODO: Remove the oneOfType in the future when we only support arrays.
    question: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    choices: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        correct: PropTypes.bool,
      })
    ),
    attempts: PropTypes.number,

    // TODO: Remove the oneOfType in the future when we only support arrays.
    explanation: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),

    // TODO: Remove this in the future when audio snippets
    // are inside of question parts.
    audio: PropTypes.string,
    tip: PropTypes.array,
  }),
  answer: PropTypes.shape({
    value: PropTypes.shape({
      previous: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      selected: PropTypes.arrayOf(PropTypes.string),
      attempts_remaining: PropTypes.number,
    }),
    complete: PropTypes.bool,
  }),
  readOnly: PropTypes.bool,
  attemptsMsg: PropTypes.number,
  choicesNeeded: PropTypes.number,
  complete: PropTypes.bool,
  isCheckpointCorrect: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  teacherTip: PropTypes.array,
};
