import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import LogOutDropDown from '../../components/LogOutDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../../contexts/AuthContext';
import { withRouter } from 'react-router-dom';

const StyledAppBar = styled(AppBar)(
  () => `
    z-index: 25;
  `
);

const StyledToolBar = styled(Toolbar)(
  ({ theme }) => `
    align-items: center;
    border-bottom: 2px solid #12121250;
    display: flex;
    flex-direction: row;
    height: ${theme.spacing(8)}px;
    justify-content: space-between;
    padding-left: ${theme.spacing(2)}px;
    button {
      padding: 0;
    }
    svg:hover {
      background: #FFFFFF33;
      border-radius: 2px;
    }
    img {
      margin-left: ${theme.spacing(3)}px;
    }
  `
);

const StyledTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.primary};
    font-size: 20px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  `
);

const StyledBackdrop = styled(Backdrop)(
  () => `
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  `
);

const HeaderBackdrop = ({ showBackdrop, closeBackdrop, content }) => (
  <StyledBackdrop open={showBackdrop} onClick={closeBackdrop}>
    {content}
  </StyledBackdrop>
);

HeaderBackdrop.propTypes = {
  closeBackdrop: PropTypes.func,
  content: PropTypes.node,
  showBackdrop: PropTypes.bool,
};

const AppBarHeader = ({ history, menu, title, includeProfile = true }) => {
  const { user, signout } = useAuth();
  const [isBackdropOpen, setBackdropOpen] = React.useState(false);

  const options = [];
  if (includeProfile) {
    options.push({
      name: 'Profile',
      action: () =>
        history.push(`/profile`, {
          backTo: {
            path: history.location.pathname,
            state: history.location.state,
          },
        }),
    });
  }
  options.push({
    name: 'Support',
    action: () => {
      window.open(
        'https://www.helloworldcs.org/knowledge-base',
        '_blank',
        'noopener,noreferrer'
      );
    },
  });
  options.push({
    name: 'Logout',
    action: () => signout(),
  });

  return (
    <StyledAppBar position="relative">
      <HeaderBackdrop
        closeBackdrop={() => setBackdropOpen(false)}
        content={menu}
        showBackdrop={isBackdropOpen}
      />
      <StyledToolBar variant="dense">
        {!!menu && (
          <IconButton onClick={() => setBackdropOpen(true)}>
            <MenuIcon />
          </IconButton>
        )}
        <img src="/static/assets/hello-world-logo-small.png" />
        <StyledTypography variant="body2">{title}</StyledTypography>
        <LogOutDropDown username={user.username} options={options} />
      </StyledToolBar>
    </StyledAppBar>
  );
};

AppBarHeader.propTypes = {
  history: PropTypes.object,
  menu: PropTypes.node,
  title: PropTypes.string,
  includeProfile: PropTypes.bool,
};

export default withRouter(AppBarHeader);
