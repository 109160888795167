import * as lodash from 'lodash';
import * as projects from '../../utils/projects';

/**
 * Determine the grade for a multiple choice checkpoint given the value of the
 * checkpoint in the student's progress object.
 */
const getMultipleChoiceCheckpointGrade = (checkpoint, selected) => {
  // Selected represents the ids of the choices selected.  Use it to find the
  // selected choice.
  const choices = checkpoint.choices.filter(c => selected.includes(c.id));
  return choices.every(c => c.correct);
};

/**
 * Given a project and student's progress in the project, perform all
 * auto-scoring steps for the project and return an updated progress object.
 *
 * Currently supported auto-scoring steps:
 *
 * 1. Scoring any mutiple choice checkpoint components.
 *
 * 2. Updating the project status to completed if there are no actions that a
 *    teacher can take.
 */
export const grade = (project, progress) => {
  progress = lodash.cloneDeep(progress);
  if (!progress?.scoring) {
    progress.scoring = {};
  }

  const scoring = progress.scoring;
  if (!scoring.components) {
    scoring.components = {};
  }

  const criteria = project?.scoring?.criteria ?? [];
  const components = criteria.flatMap(criteria => criteria.components) ?? [];

  // Keep a counter of how many components we weren't able to auto-score.
  let unscored = 0;

  for (const component of components) {
    // multiple choice checkpoint components
    if (component.type === 'checkpoint' && !component?.variant) {
      const checkpoint = projects.findCheckpoint(project, component.id);
      const value = progress.checkpoints?.[component.id].value;

      scoring.components[component.id] = getMultipleChoiceCheckpointGrade(
        checkpoint,
        value.selected
      );
      continue;
    }

    unscored++;
  }

  // Determine if a teacher needs to touch this submission or not.  If there's
  // nothing that hasn't been scored then there's nothing for them to do.
  progress.status = unscored > 0 ? 'grading' : 'completed';

  return progress;
};
