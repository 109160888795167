import { useHistory, useLocation, useParams } from 'react-router-dom';
import api from '../api';
import AppBar from '@material-ui/core/AppBar';
import { BackCaret } from '../components';
import { Button } from '@material-ui/core';
import config from '../config';
import React from 'react';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import { useAuth } from '../contexts/AuthContext';

const HourOfCodeStyles = styled.div(
  ({ theme }) => `
    .appbar {
      z-index: 25;
      position: relative;
      padding-bottom: ${theme.spacing(2.5)}px;
    }
    
    .toolbar {
      display: flex;
      justify-content: space-between;
      
      border-bottom: 2px solid #12121250;
      padding: ${theme.spacing(0, 5)};
      
      a {
        color: ${theme.palette.text.primary};
        font-family: 'Poppins',sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
      }
    }
    
    .content {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2.5)}px;
      
      padding: ${theme.spacing(0, 20)};
    }
    
    .logos {
      display: flex;
      height: 100px;
      justify-content: space-between;
      
      img {
        aspect-ratio: 1 / auto;
        height: 100%;
      }
    }
    
    .box {
      display: flex;
      gap: ${theme.spacing(4)}px;

      background: rgba(255, 255, 255, 0.08);
      padding: ${theme.spacing(4)}px;
      
      .title {
        font-family: 'IBM Plex Mono', monospace;
        font-size: 24px;
        font-weight: 600;
      }
    }
    
    .theme.box {
      overflow: auto;
    }
    
    .theme.box .left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      gap: ${theme.spacing(2)}px;

      .theme {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2.5)}px;
        
        background: rgba(255, 255, 255, 0.10);
        padding: ${theme.spacing(2)}px;
      
        .contents {
          font-family: 'Poppins',sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
        }
        
        .chips {
          display: flex;
          gap: ${theme.spacing(2)}px;
          
          .chip {
            border-radius: 5px;
            background: rgba(255, 255, 255, 0.12);
            font-family: 'IBM Plex Mono', monospace;
            font-size: 14px;
            font-weight: 400;
            padding: ${theme.spacing(1)}px;
          }
        }
      }
      
      .buttons {
        display: flex;
        align-content: stretch;
        gap: ${theme.spacing(2)}px;
        justify-content: space-between;
        
        background: rgba(255, 255, 255, 0.10);
        padding: ${theme.spacing(2)}px;
        
        .button {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: ${theme.spacing(1)}px;
          
          background: rgba(255, 255, 255, 0.11);
          border-radius: 6px;
          color: ${theme.palette.text.primary};
          font-family: 'Poppins',sans-serif;
          padding: ${theme.spacing(1)}px;
          text-decoration: none;
          width: 100%;
          
          .icon {
            height: 32px;
            width: 32px;
          }
          
          .title {
            display: flex;
            align-items: center;
            flex-direction: column;
            
            div {
              font-size: 14px;
              font-weight: 600;
            }
            
            .subtitle {
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
        
        .button:hover {
          background: rgba(255, 255, 255, 1);
          
          .icon {
            // This filter changes the white SVG to the proper blue color.
            // The filter was generated by https://codepen.io/sosuke/pen/Pjoqqp
            filter: brightness(0) saturate(100%) invert(41%) sepia(74%) saturate(2641%) hue-rotate(217deg) brightness(98%) contrast(94%);
          }
          
          .title {
            color: ${theme.palette.actions.main};
          }
        }
      }
    }
    
    .theme.box .right {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      gap: ${theme.spacing(3)}px;
      
      .card {
        height: 340px;
        width: 260px;
        
        img {
          height: 192px;
        }
        
        div {
          font-size: 22px;
          padding: ${theme.spacing(2)}px;
        }
      }
      
      button {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), ${
          theme.palette.actions.main
        };
        border: 1px solid ${theme.palette.primary.light};
        border-radius: 4px;
        color: ${theme.palette.text.primary};
        
        &:hover {
          background: white;
          color: black;
        }
      }
      
      button.creating {
        background: white;
        border: 1px solid ${theme.palette.background.paper};
        color: #6D6D6D;
      }
      
      button.failed {
        background: ${theme.palette.actions.error};
        border: 1px solid ${theme.palette.background.paper};
        color: white;
      }
    }
    
    .projects.box {
      display: flex; 
      flex-direction: column;
      gap: ${theme.spacing(4)}px;
      
      .cards {
        display: flex;
        gap: ${theme.spacing(3)}px;
        overflow: auto;
        
        .card {
          cursor: pointer;
          height: 212px;
          width: 164px;
          
          img {
            height: 120px;
          }
          
          div {
            font-size: 14px;
            padding: ${theme.spacing(1)}px;
          }
        }
      }
    }
    
    .card {
      display: flex;
      flex-direction: column;

      background: ${theme.palette.background.paper};
      border: 2px solid ${theme.palette.primary.light};
      border-radius: 4px;
                  
      img {
        aspect-ratio: auto / 1;
      }
      
      div {
        font-family: 'Poppins',sans-serif;
        font-weight: 500;
      }
    }
    
    .footer {
      font-family: 'Poppins',sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      padding: ${theme.spacing(4)}px;
    }
  `
);

const SECTION_ID = '91619b80-924b-404a-b0cc-02ce222ec60c';
const PROJECTS = [
  {
    id: 'c8a8f14d-cdf8-4634-a490-18557f343847',
    title: 'Floor is Lava',
    card: '/static/assets/hour-of-code/floor-is-lava-card.gif',
    theme: {
      text: 'In this project, you will design and build a floor is lava game! While players race through the game avoiding the lava, they will also be trying to collect different objects in order to finish the game.',
      chips: ['Block-based', '2nd-12th grades', 'Browser-enabled'],
    },
    lessonPlan:
      'https://drive.google.com/file/d/1jllt4JulC64-JmmTxP2pxw9KRRnWtTKv/view?usp=drive_link',
    trackingPixel: 'https://code.org/api/hour/begin_hw_lava.png',
  },
  {
    id: '6a20fae3-bd80-4a05-85ac-ed022d4ef4eb',
    title: 'Escape Room: Tower Conquest',
    card: '/static/assets/hour-of-code/escape-room-tower-conquest-card.gif',
    theme: {
      text: "In this project, you'll create a tower for other players to escape from! The tower will contain three floors and each floor will require players to solve a riddle or puzzle in order to unlock the door to the next floor.",
      chips: ['Block-based', '6th-12th grades', 'Browser-enabled'],
    },
    lessonPlan:
      'https://drive.google.com/file/d/1MtwvSIjTBgbfAQGoXrVlSPz4G0ZAt1Ak/view?usp=drive_link',
    trackingPixel: 'https://code.org/api/hour/begin_hw_escape.png',
  },
  {
    id: '0fab8750-c991-474d-8d3b-dc14bd65210a',
    title: 'Fan Page',
    card: '/static/assets/hour-of-code/fan-page-card.gif',
    theme: {
      text: "In this project, you'll create a webpage for a review of a movie, show, book, or something else you like.",
      chips: ['HTML/CSS', '2nd-12th grades', 'Browser-enabled'],
    },
    lessonPlan:
      'https://drive.google.com/file/d/1OFqRlohfgOonfe0WuE8JJnERVyM4WVO1/view?usp=drive_link',
    trackingPixel: 'https://code.org/api/hour/begin_hw_fanpage.png',
  },
  {
    id: '13ccd365-5173-443d-886c-faef86ebb5ac',
    title: 'Color Illusion Challenge',
    card: '/static/assets/hour-of-code/color-illusion-challenge-card.gif',
    theme: {
      text: "In this project, you'll build a game where the user must correctly name the color of a word before their time is up.",
      chips: ['Python', '6th-12th grades', 'Browser-enabled'],
    },
    lessonPlan:
      'https://drive.google.com/file/d/1DAv6KM0tQLfYJSnLImuqGVSX12AkljsB/view?usp=drive_link',
    trackingPixel: 'https://code.org/api/hour/begin_hw_illusion.png',
  },
  {
    id: '55c69339-63ad-4be5-a2e8-e54f903a94f1',
    title: 'Show & Tell Gallery',
    card: '/static/assets/hour-of-code/show-and-tell-gallery-card.gif',
    theme: {
      text: "In this project, you'll design a gallery that's all about you! Your gallery will have three rooms and each room will show something about yourself that you want to share. You will design rooms about your family, your hobbies, and something unique that you can choose!",
      chips: ['Block-based', '2nd-12th grades', 'Browser-enabled'],
    },
    lessonPlan:
      'https://drive.google.com/file/d/1SxkE2KkEtaoBymb9r6MhZ1uqQ97yeZMe/view?usp=drive_link',
    trackingPixel: 'https://code.org/api/hour/begin_hw_gallery.png',
  },
];

const States = {
  Initial: 'initial',
  Creating: 'creating',
  Created: 'created',
  LoadingPixel: 'loading',
  Failed: 'failed',
  Refused: 'refused',
};

const loadImage = src =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve());
    img.addEventListener('error', () => reject());
    img.src = src;
  });

const HourOfCode = () => {
  const history = useHistory();
  const params = useParams();
  const { user } = useAuth();
  const location = useLocation();

  const [project, setProject] = React.useState(() => {
    const projectId = params?.projectId;
    for (let p of PROJECTS) {
      if (p.id === projectId) {
        return p;
      }
    }

    return PROJECTS[0];
  });

  const [state, setState] = React.useState(States.Initial);
  React.useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (state !== States.Initial) {
      return;
    }

    const search = new URLSearchParams(location.search);
    if (config.env !== 'production' && !search.get('onboarding')) {
      setState(States.Refused);
      return;
    }

    setState(States.Creating);
    api.users
      .onboardForHourOfCode(user.id)
      .then(() => setState(States.Created))
      .catch(() => setState(States.Failed));
  }, [user, location, state, setState]);

  const onGoToProject = React.useCallback(() => {
    setState(States.LoadingPixel);

    loadImage(project.trackingPixel).finally(() => {
      history.push(`/section/${SECTION_ID}/project/${project.id}`);
    });
  }, [history, project]);

  return (
    <HourOfCodeStyles>
      <AppBar className="appbar">
        <Toolbar className="toolbar">
          <img
            src="/static/assets/hello-world-logo-small.png"
            alt="hello world logo"
          />
          <a
            href="https://www.helloworldcs.org/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact us
          </a>
        </Toolbar>
      </AppBar>

      <div className="content">
        <div className="logos">
          <img
            src="/static/assets/hello-world-logo-full.png"
            alt="hello world logo"
          />
          <img
            src="/static/assets/hour-of-code/hour-of-code-logo.svg"
            alt="hour of code logo"
          />
        </div>

        <div className="theme box">
          <div className="left">
            <div className="theme">
              <div className="title">Project Theme</div>
              <div className="contents">{project.theme.text}</div>
              <div className="chips">
                {project.theme.chips.map(chip => (
                  <div key={chip} className="chip">
                    {chip}
                  </div>
                ))}
              </div>
            </div>

            <div className="buttons">
              <a
                href={project.lessonPlan}
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                <img
                  className="icon"
                  src="/static/assets/hour-of-code/teacher-resources.svg"
                  alt="contact us"
                />
                <div className="title">
                  <div>Lesson Plan</div>
                  <div className="subtitle">for educators</div>
                </div>
              </a>
              <a
                href="https://www.helloworldcs.org/contact"
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                <img
                  className="icon"
                  src="/static/assets/hour-of-code/contact-us.svg"
                  alt="contact us"
                />
                <div className="title">
                  <div>Contact Us</div>
                  <div className="subtitle">for more projects and lessons</div>
                </div>
              </a>
            </div>
          </div>

          <div className="right">
            <div className="card">
              <img src={project.card} alt="project card" />
              <div>{project.title}</div>
            </div>
            {state === States.Creating && (
              <Button
                className="creating"
                variant="contained"
                color="primary"
                endIcon={<img src="/static/assets/hour-of-code/loading.gif" />}
                disabled
              >
                Building Your Project
              </Button>
            )}
            {(state === States.Created || state === States.LoadingPixel) && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<BackCaret transform="scale(-1,1) scale(.7)" />}
                onClick={onGoToProject}
                disabled={state === States.LoadingPixel}
              >
                Open My Project
              </Button>
            )}
            {state === States.Failed && (
              <Button
                className="failed"
                variant="contained"
                color="primary"
                disabled
              >
                Failed to build project
              </Button>
            )}
            {state === States.Refused && (
              <Button
                className="failed"
                variant="contained"
                color="primary"
                disabled
              >
                No Onboarding In This Env
              </Button>
            )}
          </div>
        </div>

        <div className="projects box">
          <div className="title">More Projects</div>
          <div className="cards">
            {PROJECTS.map(p => (
              <div key={p.id} className="card" onClick={() => setProject(p)}>
                <img src={p.card} alt="project card" />
                <div>{p.title}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="footer">
          <div>
            Hour of Code® and Hora del Código® are registered trademarks of
            Code.org
          </div>
          <div>
            The Hour of Code® is a nationwide initiative by Code.org to
            introduce millions of students to one hour of computer science and
            computer programming.
          </div>
        </div>
      </div>
    </HourOfCodeStyles>
  );
};

export default HourOfCode;
