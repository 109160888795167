import api from '../../api';
import { CoSpacesMigrationModal } from './components/migration';
import { LoadingIndicator } from '../../components';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import { useProgress } from './contexts';
import { useProject } from '../../contexts/ProjectContext';

export const MigrationModal = ({
  close,
  migrations,
  workSectionId,
  newSectionId,
}) => {
  const history = useHistory();
  const project = useProject();
  const { progress } = useProgress();

  const [
    loading,
    oldSectionMetadata,
    oldSectionCoSpacesIds,
    newSectionMetadata,
    newSectionCoSpacesIds,
  ] = api.load(
    api.section.getTitle(workSectionId),
    api.section.getCoSpacesIds(workSectionId),
    api.section.getTitle(newSectionId),
    api.section.getCoSpacesIds(newSectionId)
  );

  const onMigrateCoSpaces = React.useCallback(() => {
    const state = {
      project: {
        id: project.id,
        title: project.title,
      },

      // This is a one-liner that removes the cleanup function since that is a function that doesn't
      // serialize properly in history state.
      progress: (({ cleanup, ...rest }) => rest)(progress),

      // Merge the section metadata and cospaces ids together into one
      // unified object.
      oldSection: {
        id: oldSectionMetadata.id,
        title: oldSectionMetadata.title,
        cospacesIds: {
          class_id: oldSectionCoSpacesIds?.class_id,
          assignment_ids: oldSectionCoSpacesIds?.json?.assignment_ids,
        },
      },
      newSection: {
        id: newSectionMetadata.id,
        title: newSectionMetadata.title,
        cospacesIds: {
          class_id: newSectionCoSpacesIds?.class_id,
          assignment_ids: newSectionCoSpacesIds?.json?.assignment_ids,
        },
      },
    };
    history.push('/migrations/cospaces', state);
  }, [
    history,
    project,
    progress,
    oldSectionMetadata,
    oldSectionCoSpacesIds,
    newSectionMetadata,
    newSectionCoSpacesIds,
  ]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (migrations.cospaces) {
    return (
      <CoSpacesMigrationModal
        oldSectionMetadata={oldSectionMetadata}
        oldSectionCoSpacesIds={oldSectionCoSpacesIds}
        newSectionMetadata={newSectionMetadata}
        newSectionCoSpacesIds={newSectionCoSpacesIds}
        onClose={close}
        onMigrate={onMigrateCoSpaces}
      />
    );
  }

  return null;
};
MigrationModal.propTypes = {
  close: PropTypes.func,
  migrations: PropTypes.shape({
    cospaces: PropTypes.bool,
  }),
  workSectionId: PropTypes.string,
  newSectionId: PropTypes.string,
};
