import { CodeBlockContainer, MediaBlock } from '../../components/Blocks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../../components/Text';

const StyledModal = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    border-radius: 20px;
    overflow: hidden;
    width: ${theme.spacing(56)}px;
  `
);

const StyledButton = styled(Button)(
  ({ theme }) => `
  background-color: ${theme.palette.primary.main};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding-left: ${theme.spacing(2)}px;
  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;
  }
  &.back {
    background-color: #4d4d4d;
    padding: ${theme.spacing(1, 2, 1, 1)};
    &:hover {
      background-color: ${theme.palette.background.level1};
      color: ${theme.palette.primary.contrastText};
      
      .MuiSvgIcon-root {
        fill: ${theme.palette.primary.contrastText};
      }
    }
  }
  `
);

const StyledButtons = styled.div(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacing(3, 4)};
  `
);

const StyledTextPart = styled(TextBlockTypography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    padding: ${theme.spacing(1, 5)};
  `
);

const StyledMediaPart = styled.div(
  ({ theme }) => `
  background-color: ${theme.palette.background.level3};
  padding: ${theme.spacing(2)}px;
  `
);

const StyledCodePart = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing(1, 0)};
  `
);

const ModalPart = ({ part }) => {
  switch (part.type) {
    case 'text':
      return <StyledTextPart dangerouslySetInnerHTML={{ __html: part.text }} />;

    case 'media':
      return (
        <StyledMediaPart>
          <MediaBlock block={part} />
        </StyledMediaPart>
      );

    case 'code':
      return (
        <StyledCodePart>
          <CodeBlockContainer code={part.code} language={part.language} />;
        </StyledCodePart>
      );

    default:
      throw new Error(`unsupported modal part type: ${part.type}`);
  }
};

const Modal = ({ content = [], isSubmitStep, onClose, onConfirm, show }) => (
  <Dialog
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: 'none',
      },
    }}
    open={show}
    onClose={onClose}
  >
    <StyledModal>
      {content.map((part, i) => (
        <ModalPart key={i} part={part} />
      ))}
      <StyledButtons>
        <StyledButton className="back" onClick={onClose}>
          <KeyboardArrowLeftIcon />
          Back to Project
        </StyledButton>
        <StyledButton onClick={onConfirm}>
          {isSubmitStep ? 'Submit' : 'Next'}
          <KeyboardArrowRightIcon />
        </StyledButton>
      </StyledButtons>
    </StyledModal>
  </Dialog>
);

Modal.propTypes = {
  content: PropTypes.array,
  isSubmitStep: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
};

export default Modal;
