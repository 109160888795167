import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { FrameCard } from '../../../components/Frames';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../../../components/TeacherTip';
import TextBlockTypography from '../../../components/Text';
import TextField from '@material-ui/core/TextField';
import { TextWithAudio } from '../../../components/Audio';
import Typography from '@material-ui/core/Typography';

//
// Link Submission checkpoint
//

const LinkSubmissionContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(0, 4)};

    .MuiFormControl-root {
      width: 100%;
    }

    textarea {
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: 2;
      overflow: hidden;
    }

    .error {
      color: #D52828;
      margin-bottom: ${theme.spacing(2)}px;
    }

    button {
      align-self: flex-end;
      margin-top: ${theme.spacing(2)}px;
      width: ${theme.spacing(8)}px;
    }
  `
);

const LinkSubmissionTitle = styled.div(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(3)}px;
    .question {
      display: inline;
      font-weight: 400;
      line-height: 24px;
    }
  `
);

const LinkSubmissionTextField = styled(TextField)(
  ({ theme }) => `
    .MuiInput-multiline {
      background-color: ${theme.palette.background.level1};
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: ${theme.palette.text.secondary};
      margin-bottom: ${theme.spacing(1)}px;
      padding: ${theme.spacing(2)}px;
    }

    .MuiInput-underline {
      border-bottom: none;
      &:hover::before {
        border-bottom: none;
      }
      &::before {
        border-bottom: none;
      }
      &::after {
        border-bottom: none;
      }
    }
  `
);

export const LinkSubmissionCheckpoint = ({
  audio,
  errorMsg,
  isUpdating,
  link,
  onChange,
  placeholder,
  question,
  teacherTip,
  title,
  validation,
}) => {
  const [linkText, setLinkText] = React.useState(link ?? '');
  const [isInvalidUrl, setIsInvalidUrl] = React.useState(false);
  const isButtonDisabled =
    isUpdating || isInvalidUrl || !linkText || linkText === link;

  // Here we can use predefined validators or use the generic one
  // Generic URL does not start with "." or ":" or whitespace or "\",
  //         but does contain at least one "." for the top-level domain
  const REGEXP_GENERIC_URL = /^https?:\/\/[^.\s:\\]+[^\s\\]+\.[^\s\\]+$/;
  const REGEXP_COSPACES_URL = /^https:\/\/edu.cospaces.io\/\w{3}-\w{3}$/;

  const handleSetValue = event => {
    setIsInvalidUrl(false);
    setLinkText(event.target.value);
  };

  const handleSaveClick = () => {
    const variant = validation ? validation.variant : '';
    const validator =
      (variant === 'cospaces' && REGEXP_COSPACES_URL) || REGEXP_GENERIC_URL;

    // URL validation
    if (validator.test(linkText)) {
      onChange(linkText);
      return;
    }
    setIsInvalidUrl(true);
  };

  return (
    <FrameCard title={title ?? 'Checkpoint'} padding={{ top: 4, bottom: 3 }}>
      <LinkSubmissionContainer>
        <LinkSubmissionTitle>
          <TextWithAudio audio={audio}>
            <Typography className="question" variant="subtitle1">
              {question}
            </Typography>
            {teacherTip && <TeacherTip content={teacherTip} />}
          </TextWithAudio>
        </LinkSubmissionTitle>
        <LinkSubmissionTextField
          multiline
          onChange={handleSetValue}
          placeholder={placeholder}
          minRows={2}
          value={linkText}
        />
        {isInvalidUrl && (
          <Typography className="error" variant="caption">
            {errorMsg || 'Invalid URL.'}
          </Typography>
        )}
        {!isButtonDisabled && (
          <Button color="primary" onClick={handleSaveClick} variant="outlined">
            SAVE
          </Button>
        )}
      </LinkSubmissionContainer>
    </FrameCard>
  );
};

LinkSubmissionCheckpoint.propTypes = {
  audio: PropTypes.string,
  errorMsg: PropTypes.string,
  isUpdating: PropTypes.bool,
  link: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  question: PropTypes.string,
  teacherTip: PropTypes.array,
  title: PropTypes.string,
  validation: PropTypes.shape({
    type: PropTypes.string,
    variant: PropTypes.string,
  }),
};

//
// Rubric checkpoint
//

const PaddedGrid = styled(Grid)(
  ({ $padding, theme }) => `
    padding-top: ${theme.spacing($padding?.top ?? 0)}px;
    padding-bottom: ${theme.spacing($padding?.bottom ?? 0)}px;
    padding-left: ${theme.spacing($padding?.left ?? 0)}px;
    padding-right: ${theme.spacing($padding?.right ?? 0)}px;
  `
);

const CategoryTitleTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    color: ${theme.palette.actions.main};
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    padding-top: ${theme.spacing(2)}px;
    text-align: center;
  `
);

const CategoryWeightTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding-bottom: ${theme.spacing(2)}px;
    text-align: center;
  `
);

const CategorySubtitleTypography = styled(TextBlockTypography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding-bottom: ${theme.spacing(2)}px;
    text-align: center;
  `
);

const CategoryRow = ({ category, weight }) => {
  const unit = `point${weight !== 1 ? 's' : ''}`;

  return (
    <PaddedGrid item xs={12} $padding={{ top: 1, bottom: 1 }}>
      <CategoryTitleTypography>{category.title}</CategoryTitleTypography>
      <CategoryWeightTypography>
        {weight !== undefined && `${weight} ${unit}`}
      </CategoryWeightTypography>
      {category.subtitle && (
        <CategorySubtitleTypography
          dangerouslySetInnerHTML={{ __html: category.subtitle }}
        />
      )}
    </PaddedGrid>
  );
};

CategoryRow.propTypes = {
  category: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  weight: PropTypes.number,
};

const RequirementContainer = styled.div(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    height: 100%;
    padding: ${theme.spacing(2)}px;
    width: 100%;
  `
);

const CheckboxContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    background: ${theme.palette.background.level1};
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  `
);

const ReviewModal = styled.div(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    border-radius: 20px;
    color: ${theme.palette.text.secondary};
    overflow: hidden;
    width: 500px;

    .image {
      align-items: center;
      background: ${theme.palette.background.level3};
      display: flex;
      height: 109px;
      justify-content: center;
    }
    .content {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing(3, 5)};
      .title {
        font-size: 22px;
        font-weight: 500;
      }
      .text {
        margin-top: ${theme.spacing(2)}px;
        .bold {
          font-weight: 600;
        }
      }
      button {
        color: #fff;
        margin-top: ${theme.spacing(4)}px;
        &:hover {
          background-color: ${theme.palette.primary.main};
        }
      }
    }
  `
);

const RequirementTypography = styled(TextBlockTypography)(
  () => `
    display: inline;
    font-size: 16px;
  `
);

const RequirementRow = ({ requirement, checked, isUpdating, onChange }) => {
  const [isClicked, setClicked] = React.useState(false);

  React.useEffect(() => {
    if (!isUpdating) {
      setClicked(false);
    }
  }, [isUpdating]);

  const onChoiceClick = e => {
    if (isUpdating) {
      return;
    }
    setClicked(true);
    onChange(requirement.id, e.target.checked);
  };

  return (
    <PaddedGrid container $padding={{ top: 1, bottom: 1 }}>
      <PaddedGrid item xs={10} $padding={{ right: 1 }}>
        <RequirementContainer>
          <TextWithAudio audio={requirement.audio}>
            <RequirementTypography
              dangerouslySetInnerHTML={{ __html: requirement.text }}
            />
          </TextWithAudio>
        </RequirementContainer>
      </PaddedGrid>
      <PaddedGrid item xs={2} $padding={{ left: 1 }}>
        <CheckboxContainer>
          {isClicked && isUpdating ? (
            <CircularProgress size={20} />
          ) : (
            <Checkbox checked={checked} onChange={onChoiceClick} />
          )}
        </CheckboxContainer>
      </PaddedGrid>
    </PaddedGrid>
  );
};

RequirementRow.propTypes = {
  checked: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onChange: PropTypes.func,
  requirement: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    audio: PropTypes.string,
  }),
};

export const RubricCheckpoint = ({
  categories,
  weights,
  choices,
  isUpdating,
  onChange,
  resetModal,
  showModal = false,
  title,
}) => (
  <>
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: 0,
        },
      }}
      open={showModal}
      onClose={resetModal}
    >
      <ReviewModal>
        <div className="image">
          <img src="/static/assets/project-summary/review-modal.svg" />
        </div>
        <div className="content">
          <TextBlockTypography className="title" variant="body2">
            Checking Project Requirements
          </TextBlockTypography>
          <TextBlockTypography className="text" variant="h5">
            {`Review your `}
            <span className="bold">Scoring Rubric</span>
            {` to see how your teacher scored your project so far.`}
          </TextBlockTypography>
          <Button color="primary" onClick={resetModal} variant="contained">
            CONTINUE PROJECT
          </Button>
        </div>
      </ReviewModal>
    </Dialog>
    <FrameCard
      title={title ?? 'Scoring Rubric'}
      padding={{ top: 2, bottom: 0 }}
    >
      <Grid container>
        {categories &&
          categories.map(category => (
            <Grid container key={category.type}>
              <CategoryRow
                category={category}
                weight={weights?.[category.type]}
              />
              {category.requirements.map(requirement => (
                <RequirementRow
                  key={requirement.id}
                  checked={choices?.[requirement.id] ?? false}
                  isUpdating={isUpdating}
                  onChange={(id, checked) =>
                    onChange({ ...choices, [id]: checked })
                  }
                  requirement={requirement}
                />
              ))}
            </Grid>
          ))}
      </Grid>
    </FrameCard>
  </>
);

RubricCheckpoint.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      requirements: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ),
  weights: PropTypes.object,
  choices: PropTypes.object,
  isUpdating: PropTypes.bool,
  onChange: PropTypes.func,
  resetModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
};
