import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import config from '../../../config';
import CreateIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Info from '@material-ui/icons/Info';
import { ListSubheader } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { NotStartedIcon } from './status-icon';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import ReplyIcon from '@material-ui/icons/Reply';
import Select from '@material-ui/core/Select';
import StarIcon from '@material-ui/icons/Star';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { useUser } from '../../../contexts/AuthContext';
import { withStyles } from '@material-ui/core/styles';

const StyledHeader = styled.div(
  ({ theme, $isControls }) => `
    align-items: center;
    background: ${!$isControls ? theme.palette.primary.gradient : '#36b5bf'};
    display: flex;
    height: ${theme.spacing(14)}px;
    justify-content: space-between;
    padding: ${theme.spacing(2, 4)};
    div {
      display: flex;
    }
    h4, h6 {
      color: ${theme.palette.text.primary};
    }
  `
);

const StyledHeaderImg = styled.div(
  ({ $imageUrl, theme }) => `
    background: ${
      $imageUrl ? `url(${$imageUrl})` : theme.palette.background.level1
    };
    background-size: cover;
    border-radius: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    height: ${theme.spacing(10)}px;
    width: ${theme.spacing(13)}px;
  `
);

const StyledHeaderData = styled.div(
  ({ theme }) => `
    flex-grow: 1;
    gap: ${theme.spacing(2)}px;
    height: 100%;
    margin-left: ${theme.spacing(2)}px;
    max-width: 100%;
    overflow: hidden;
  `
);

const StyledSectionText = styled.div(
  ({ theme }) => `
    align-items: center;
    width: 100%;
    &.section {
      h6 {
        font-size: 20px;
      }
    }
    .dropdown {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      flex-grow: 1;
      margin-left: ${theme.spacing(1)}px;
      padding: ${theme.spacing(0, 2)};
      h6 {
        font-weight: 600;
      }
    }
  `
);

const StyledTextBlocksContainer = styled.div(
  ({ theme }) => `
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: hidden;
    :last-child {
      flex-shrink: 0;
      margin-left: ${theme.spacing(1)}px;
    }

    &.stats {
      margin-left: auto;
    }
  `
);

const StyledTextBox = styled.div(
  ({ theme }) => `
    cursor: default;
    height: 32px;
    div.title {
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 5px 0 0 5px;
      padding: 6px;
      width: 154px;
      h6 {
        font-weight: 400;
        margin-left: ${theme.spacing(1)}px;
      }
    }
    div.data {
      align-items: center;
      background: ${theme.palette.background.level1};
      border-radius: 0 5px 5px 0;
      justify-content: center;
      padding: 4px;
      width: 56px;
      h6 {
        font-weight: 600;
        line-height: 14px;
        color: ${theme.palette.text.secondary};
      };
    }
    svg {
      color: ${theme.palette.text.primary};
    }
  `
);

const StyledFormControl = styled(FormControl)(
  ({ theme }) => `
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    flex-grow: 1;
    margin-left: ${theme.spacing(2)}px;
    .MuiInputBase-input {
      padding: ${theme.spacing(0, 4, 0, 1)};
    }
    .MuiInputBase-root {
      align-self: flex-end;
      height: ${theme.spacing(4)}px;
      padding: 0;
      width: 100%;
      :before,
      :after {
        border-bottom: none;
      }
    }
    .MuiSelect-select:focus {
      background: none;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    h6 {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  `
);

const StyledButton = styled(Button)(
  ({ theme }) => `
    color: ${theme.palette.text.primary};
    height: 32px;
    padding-right: 0;
    
    .MuiTypography-root {
      font-size: 16px;
      font-weight: 400;
      overflow: hidden;
      padding-right: ${theme.spacing(6)}px;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
    }
  `
);

const StyledNavButton = styled(Button)(
  ({ $isControls }) => `
    background-color: white;
    color: ${!$isControls ? 'rgb(72, 93, 222)' : 'rgb(54, 181, 191)'};
    height: 32px;
    padding-right: 8px;
    
    &:hover {
      background-color: white;
    }
    
    .MuiTypography-root {
      font-size: 16px;
      font-weight: 400;
      overflow: hidden;
      padding-right: 0px;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
    }
  `
);

const StyledIcon = styled.div(
  ({ theme }) => `
    color: white;
    padding-left: ${theme.spacing(2)}px;
    
    svg {
      height: 32px;
      
      :hover {
        cursor: pointer;
      }
    }
  `
);

const StyledSectionInfoPopover = styled(Popover)(
  ({ theme }) => `
    .MuiPopover-paper {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)}px;

      padding: ${theme.spacing(1)}px;
    }

    div {
      background-color: ${theme.palette.background.level1};
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 400;

      .heading {
        color: #485DDE;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
      
      .label {
        color: #908F8F;
        padding-right: ${theme.spacing(1)}px;
      }


      .value {
        color: ${theme.palette.text.secondary};
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  `
);

const StyledIconMessagingPopover = styled(Popover)(
  ({ theme }) => `
    .MuiPopover-paper {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)}px;
      padding: ${theme.spacing(2)}px;
      
      background-color: rgba(77, 77, 77, 0.9);
      max-width: 375px;
    }
    
    .row {
      display: flex;
      flex-direction: row;
      gap: ${theme.spacing(1)}px;
      
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      
      .icon {
        cursor: auto;
        width: 16px;
        height: 16px;
        margin-right: ${theme.spacing(1)}px;
        
        &.percent {
          background: white;
          color: ${theme.palette.secondary.dark};
          justify-content: center;
          user-select: none;
        }
      }
      
      .cell {
        display: flex;
        flex-direction: column;
        gap: 3px;
        
        div:first-child {
          font-weight: 600;
        }
      }
    }
  `
);

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'Poppins',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: '18px',
    padding: theme.spacing(1),
    width: '158px',
  },
  tooltipPlacementBottom: {
    margin: theme.spacing(0.5),
  },
}))(Tooltip);

const getRosterChangeUrl = (sectionId, sectionTitle, user) => {
  const base = {
    local:
      'https://docs.google.com/forms/d/e/1FAIpQLSdpgmsBU1Dk7LNr_aMpqrAxruAkgkWNpe4V8lHqlV3BQY65Aw/viewform?usp=pp_url',
    development:
      'https://docs.google.com/forms/d/e/1FAIpQLSdpgmsBU1Dk7LNr_aMpqrAxruAkgkWNpe4V8lHqlV3BQY65Aw/viewform?usp=pp_url',
    demo: 'https://docs.google.com/forms/d/e/1FAIpQLSe6YfhBi3Nl8ZkpjSgfh4STDQGFY_JKIM4pcjgXzTYWSctn7g/viewform?usp=pp_url',
    production:
      'https://docs.google.com/forms/d/e/1FAIpQLScPW1dfTg9m5LYqbe6wGpho3PRwBPFEmWZyQXDw7i2wjbWJ2Q/viewform?usp=pp_url',
  }?.[config.env];

  const params = {
    local: {
      'entry.776317408': sectionTitle,
      'entry.2023388150': sectionId,
      'entry.1521381886': user.username,
      'entry.1028259957': `${user.first_name} ${user.last_name}`,
    },
    development: {
      'entry.776317408': sectionTitle,
      'entry.2023388150': sectionId,
      'entry.1521381886': user.username,
      'entry.1028259957': `${user.first_name} ${user.last_name}`,
    },
    demo: {
      'entry.776317408': sectionTitle,
      'entry.2023388150': sectionId,
      'entry.1521381886': user.username,
      'entry.1028259957': `${user.first_name} ${user.last_name}`,
    },
    production: {
      'entry.776317408': sectionTitle,
      'entry.2023388150': sectionId,
      'entry.1521381886': user.username,
      'entry.1028259957': `${user.first_name} ${user.last_name}`,
    },
  }?.[config.env];

  if (!base || !params) {
    return null;
  }

  return (
    base +
    '&' +
    Object.entries(params)
      .map(kv => kv.map(encodeURIComponent).join('='))
      .join('&')
  );
};

const PercentIcon = ({ className }) => (
  <div className={`${className} percent`}>%</div>
);
PercentIcon.propTypes = {
  className: PropTypes.string,
};

export const DashboardHeader = ({
  image,
  levelId,
  levels,
  numScore = 'N/A',
  numStudents = 'N/A',
  onChangeLevel,
  onChangeSection,
  onChangeCourse,
  sectionId,
  sectionMetadata,
  sections,
  isControls = false,
  courseId,
  courses,
}) => {
  const history = useHistory();
  const user = useUser();
  const [levelSelected, setLevelSelected] = React.useState('all');
  const activeSections = sections.filter(section => section.section_is_active);
  const inactiveSections = sections.filter(
    section => !section.section_is_active
  );

  // This useEffect checks if the current levelId value is available in the
  // current levels list. This is necessary because when changing sections
  // in the sections dropdown there's an api request to get the levels list
  // and it causes an unsync between levelId and levels list
  React.useEffect(() => {
    if (levels.find(l => l.id === levelId)) {
      setLevelSelected(levelId);
    } else {
      setLevelSelected('all');
    }
  }, [levelId, levels]);

  const handleChangeLevel = e => {
    const { value } = e.target;
    setLevelSelected(value);
    onChangeLevel(value);
  };

  const handleChangeSection = e => {
    const { value } = e.target;
    const selected = sections.find(s => s.section_id === value);
    if (selected) {
      onChangeSection(
        value,
        selected.course_id,
        selected.course_first_level_id
      );
    }
  };

  const handleChangeCourse = e => {
    const { value } = e.target;
    const selected = courses.find(c => c.course_id === value);
    if (selected) {
      onChangeCourse(selected.course_id, selected.first_level_id);
    }
  };

  const handleRosterChangeClick = React.useCallback(() => {
    const url = getRosterChangeUrl(sectionId, sectionMetadata.title, user);
    if (url) {
      window.open(url, '_blank');
    }
  }, [user, sectionId, sectionMetadata]);

  const handleBackToGradebookClick = React.useCallback(() => {
    if (levelId) {
      history.push(
        `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}/level/${levelId}`
      );
      return;
    }
    history.push(
      `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}`
    );
  }, [history, sectionId, courseId, levelId]);

  const handleAccessControlsClick = React.useCallback(() => {
    if (levelId) {
      history.push(
        `/teacher/dashboards/controls/section/${sectionId}/course/${courseId}/level/${levelId}`
      );
      return;
    }
    history.push(
      `/teacher/dashboards/controls/section/${sectionId}/course/${courseId}`
    );
  }, [history, sectionId, courseId, levelId]);

  const [sectionLoginPopoverState, setSectionLoginPopoverState] =
    React.useState({ open: false });

  const handleSectionLoginClick = React.useCallback(e => {
    setSectionLoginPopoverState({
      open: true,
      anchor: e.currentTarget,
    });
  }, []);

  const handleSectionLoginClose = React.useCallback(() => {
    setSectionLoginPopoverState({
      open: false,
    });
  }, []);

  const [iconMessagingPopoverState, setIconMessagingPopoverState] =
    React.useState({ open: false });

  const handleIconMessagingIconClick = React.useCallback(e => {
    setIconMessagingPopoverState({
      open: true,
      anchor: e.currentTarget,
    });
  }, []);

  const handleIconMessagingPopoverClose = React.useCallback(() => {
    setIconMessagingPopoverState({
      open: false,
    });
  }, []);

  const inactiveSectionBlock =
    inactiveSections.length > 0 ? (
      <>
        <Divider />
        <ListSubheader>Previous Sections</ListSubheader>
        {inactiveSections.map(s => (
          <MenuItem key={s.section_id} value={s.section_id}>
            <Typography variant="h6">
              <span style={{ fontStyle: 'italic' }}>{s.section_title}</span>
            </Typography>
          </MenuItem>
        ))}
      </>
    ) : (
      <></>
    );

  return (
    <StyledHeader $isControls={isControls}>
      <StyledHeaderImg $imageUrl={image.url} />
      <StyledHeaderData>
        <StyledTextBlocksContainer>
          <StyledSectionText>
            <StyledFormControl>
              <Select value={sectionId} onChange={handleChangeSection}>
                {activeSections.map(s => (
                  <MenuItem key={s.section_id} value={s.section_id}>
                    <Typography variant="h6">{s.section_title}</Typography>
                  </MenuItem>
                ))}
                {inactiveSectionBlock.props.children}
              </Select>
            </StyledFormControl>
          </StyledSectionText>
          {isControls && (
            <>
              <StyledSectionText className="section">
                <StyledFormControl>
                  <StyledNavButton
                    $isControls={isControls}
                    onClick={handleBackToGradebookClick}
                    disableFocusRipple
                    disableRipple
                  >
                    <Typography>Back to Gradebook</Typography>
                  </StyledNavButton>
                </StyledFormControl>
              </StyledSectionText>
            </>
          )}
          {!isControls && (
            <StyledSectionText className="section">
              <StyledFormControl>
                <StyledTooltip title="Choose which projects your students can see and access.">
                  <StyledNavButton
                    $isControls={isControls}
                    onClick={handleAccessControlsClick}
                    disableFocusRipple
                    disableRipple
                  >
                    <Typography>Access controls</Typography>
                  </StyledNavButton>
                </StyledTooltip>
              </StyledFormControl>
            </StyledSectionText>
          )}
        </StyledTextBlocksContainer>
        <StyledTextBlocksContainer>
          <StyledSectionText>
            <StyledFormControl>
              <Select value={courseId} onChange={handleChangeCourse}>
                {courses.map(c => (
                  <MenuItem key={c.course_id} value={c.course_id}>
                    <Typography variant="h6">{c.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledSectionText>

          <StyledSectionText className="section" />
          <StyledIcon>
            <Info onClick={handleIconMessagingIconClick} />
            <StyledIconMessagingPopover
              open={iconMessagingPopoverState.open}
              onClose={handleIconMessagingPopoverClose}
              anchorEl={iconMessagingPopoverState.anchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              disablePortal
            >
              <div className="row">
                <NotStartedIcon className="icon" />
                <div className="cell">
                  <div>Not Started</div>
                  <div>
                    The student will be able to access this project when they
                    reach it.
                  </div>
                </div>
              </div>
              <div className="row">
                <AccessTimeIcon className="icon" />
                <div className="cell">
                  <div>In Progress</div>
                  <div>The student has already started this project.</div>
                </div>
              </div>
              <div className="row">
                <CreateIcon className="icon" />
                <div className="cell">
                  <div>Ready to Score</div>
                  <div>
                    The student has submitted this project and it is ready to
                    score.
                  </div>
                </div>
              </div>
              <div className="row">
                <PercentIcon className="icon" />
                <div className="cell">
                  <div>Scored</div>
                  <div>This project has been submitted and scored.</div>
                </div>
              </div>
              <div className="row">
                <ReplyIcon className="icon" />
                <div className="cell">
                  <div>Sent Back</div>
                  <div>
                    This project has been sent back to the student to be
                    resubmitted.
                  </div>
                </div>
              </div>
              <div className="row">
                <HighlightOffIcon className="icon" />
                <div className="cell">
                  <div>Excused</div>
                  <div>
                    This project can be reviewed or completed, but not
                    submitted.
                  </div>
                </div>
              </div>
              <div className="row">
                <BlockIcon className="icon" />
                <div className="cell">
                  <div>Blocked</div>
                  <div>
                    The student will not be able to access this project.
                  </div>
                </div>
              </div>
              <div className="row">
                <PlayCircleOutlineOutlinedIcon className="icon" />
                <div className="cell">
                  <div>Available</div>
                  <div>
                    The student will be able to access this project and submit
                    it, even if they haven’t reached it yet.
                  </div>
                </div>
              </div>
            </StyledIconMessagingPopover>
          </StyledIcon>
        </StyledTextBlocksContainer>
        <StyledTextBlocksContainer>
          <StyledSectionText>
            <StyledFormControl>
              <Select
                defaultValue=""
                onChange={handleChangeLevel}
                value={levelSelected}
              >
                <MenuItem value="all">
                  <Typography variant="h6">All Levels</Typography>
                </MenuItem>
                {levels.map(l => (
                  <MenuItem key={l.id} value={l.id}>
                    <Typography variant="h6">{l.title}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledSectionText>
        </StyledTextBlocksContainer>
        <StyledTextBlocksContainer className="stats">
          {!isControls && (
            <StyledSectionText className="section">
              <StyledFormControl>
                <StyledButton
                  onClick={handleSectionLoginClick}
                  disableFocusRipple
                  disableRipple
                >
                  <AccountCircleIcon />
                  <Typography>&nbsp; Section info</Typography>
                  {sectionLoginPopoverState.open ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </StyledButton>
                <StyledSectionInfoPopover
                  open={sectionLoginPopoverState.open}
                  onClose={handleSectionLoginClose}
                  anchorEl={sectionLoginPopoverState.anchor}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  disablePortal
                >
                  <div>
                    <span className="heading">{sectionMetadata.title}</span>
                  </div>
                  <div>
                    <span className="label">Students:</span>
                    <span className="value">{numStudents}</span>
                  </div>
                  <div>
                    <span className="heading">Login Information</span>
                  </div>
                  <div>
                    <span className="label">Section URL:</span>
                    <span className="value">
                      <a href={`/login/section/${sectionMetadata.code}`}>
                        {`${document.location.host}/login/section/${sectionMetadata.code}`}
                      </a>
                    </span>
                  </div>
                  <div>
                    <span className="label">Section Code:</span>
                    <span className="value">{sectionMetadata.code}</span>
                  </div>
                  <div>
                    <span className="label">Login Cards:</span>
                    <span className="value">
                      <a
                        href={`/teacher/dashboards/password-images/section/${sectionId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Print section code with login images
                      </a>
                    </span>
                  </div>
                  <div>
                    <span className="heading">Roster</span>
                  </div>
                  <div>
                    <span className="value">
                      <a href={'#'} onClick={handleRosterChangeClick}>
                        Update My Roster
                      </a>
                    </span>
                  </div>
                </StyledSectionInfoPopover>
              </StyledFormControl>
            </StyledSectionText>
          )}
          {!isControls && (
            <StyledSectionText>
              <StyledFormControl>
                <StyledTooltip title="Average completed project score across all levels.">
                  <StyledTextBox>
                    <div className="title">
                      <StarIcon />
                      <Typography variant="h6">Average Score</Typography>
                    </div>
                    <div className="data">
                      <Typography variant="h6">{numScore}</Typography>
                    </div>
                  </StyledTextBox>
                </StyledTooltip>
              </StyledFormControl>
            </StyledSectionText>
          )}
        </StyledTextBlocksContainer>
      </StyledHeaderData>
    </StyledHeader>
  );
};

DashboardHeader.propTypes = {
  courseId: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  levelId: PropTypes.string,
  levels: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
  ),
  numScore: PropTypes.number,
  numStudents: PropTypes.number,
  onChangeLevel: PropTypes.func,
  onChangeSection: PropTypes.func,
  onChangeCourse: PropTypes.func,
  sectionId: PropTypes.string,
  sectionMetadata: PropTypes.shape({
    code: PropTypes.string,
    title: PropTypes.string,
  }),
  sections: PropTypes.array,
  isControls: PropTypes.bool,
};
