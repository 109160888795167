import AppBarHeader from '../AppBarHeader';
import { Layout } from '../../components/Layout';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Content = styled.div(
  () => `
    overflow-y: auto;
    width: 100%;
  `
);

const Page = ({
  children,
  menu,
  sideNav,
  title = '',
  includeProfileInHeader = true,
}) => (
  <>
    <AppBarHeader
      menu={menu}
      title={title}
      includeProfile={includeProfileInHeader}
    />
    <Layout>
      {sideNav && sideNav}
      <Content>{children}</Content>
    </Layout>
  </>
);

Page.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.node,
  sideNav: PropTypes.object,
  title: PropTypes.string,
  includeProfileInHeader: PropTypes.bool,
};

export default Page;
