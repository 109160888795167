import { FrameCard } from '../Frames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const LinkSubmissionContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(0, 3)};

    .question {
      font-weight: 400;
      line-height: 24px;
      margin-bottom: ${theme.spacing(3)}px;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    textarea {
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      min-height: 160px;
      overflow: hidden;
      resize: vertical;
    }
  `
);

const LinkSubmissionTextField = styled(TextField)(
  ({ theme }) => `
    .MuiInput-multiline {
      background-color: ${theme.palette.background.level1};
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: ${theme.palette.text.secondary};
      margin-bottom: ${theme.spacing(1)}px;
      padding: ${theme.spacing(2)}px;
    }

    .MuiInput-underline {
      border-bottom: none;
      &:hover::before {
        border-bottom: none;
      }
      &::before {
        border-bottom: none;
      }
      &::after {
        border-bottom: none;
      }
    }
  `
);

const FramedText = ({
  onChange,
  placeholder,
  question,
  readOnly,
  text,
  title = 'Feedback',
}) => (
  <FrameCard title={title} padding={{ top: 4, bottom: 3 }}>
    <LinkSubmissionContainer>
      {question && (
        <Typography className="question" variant="subtitle1">
          {question}
        </Typography>
      )}
      <LinkSubmissionTextField
        multiline
        onChange={onChange}
        placeholder={placeholder}
        minRows={2}
        value={text}
        disabled={readOnly}
      />
    </LinkSubmissionContainer>
  </FrameCard>
);

FramedText.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  question: PropTypes.string,
  readOnly: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default FramedText;
