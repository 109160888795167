import Button from '@material-ui/core/Button';
import { FrameCard } from '../Frames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const WrittenResponseContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing(2)}px;
    max-width: ${theme.spacing(180)}px;
    width: 100%;

  `
);

const StyledFrameContent = styled.div(
  ({ theme, disabled }) => `
    display: flex;
    flex-direction: column;
    padding: 3px ${theme.spacing(3)}px 0;

    button {
      align-self: flex-end;
      width: ${theme.spacing(8)}px;
    }
    
    textarea {
      background-color: ${theme.palette.background.level1};
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: ${theme.palette.text.secondary};
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 400;
      height: 100%;
      line-height: 22px;
      resize: none;
      margin-bottom: ${theme.spacing(disabled ? 0 : 3)}px;
      outline: none;
      overflow: hidden;
      padding: ${theme.spacing(2)}px;
      
      &:focus {
        outline: none;
      }
    }
  `
);

const WrittenResponse = ({ onChange, readOnly, text }) => {
  const [value, setValue] = React.useState(text || '');

  return (
    <WrittenResponseContainer className="activity-container">
      <FrameCard title="Written Response" padding={{ top: 3, bottom: 3 }}>
        <StyledFrameContent>
          <TextareaAutosize
            disabled={readOnly}
            onChange={event => setValue(event.target.value)}
            placeholder={value !== '' ? value : 'Type your response here'}
            minRows={20}
            value={value}
          />
          {!readOnly && (
            <Button
              color="primary"
              disabled={value === ''}
              onClick={() => onChange(value)}
              variant="outlined"
            >
              SAVE
            </Button>
          )}
        </StyledFrameContent>
      </FrameCard>
    </WrittenResponseContainer>
  );
};

WrittenResponse.propTypes = {
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  text: PropTypes.string,
};

export default WrittenResponse;
