import { CodeBlockContainer, MediaBlock } from '../Blocks';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../Text';
import Typography from '@material-ui/core/Typography';

const StyledTeacherTip = styled.div(
  () => `
    display: inline-block;
    opacity: 0.7;
    position: relative;
    z-index: 1;
    &:hover {
      opacity: 1;
    }
  `
);

const StyledTeacherTipIcon = styled.div(
  () => `
    background: linear-gradient(223.53deg, #1AAEBA -1.07%, #66D8E1 39.58%);
    cursor: pointer;
    height: 18px;
    margin-left: 8px;
    position: relative;
    width: 18px;
    &:after {
      background: rgba(0, 0, 0, 0.4);
      content: '';
      filter: blur(1px);
      height: 17px;
      left: 1px;
      position: absolute;
      top: 3px;
      width: 17px;
      z-index: -1;
    }
  `
);

const StyledDialog = styled(Dialog)(
  () => `
  .MuiDialog-paper {
    background-color: transparent;
    margin: 0;
    max-height: 65%;
    max-width: 768px;
    min-width: 500px;
    width: 40%;
  }
  `
);

const StyledModal = styled.div(
  ({ theme }) => `
    background: ${theme.palette.background.level1};  
    border-radius: 5px;
    box-shadow: 0px 10px 114px rgba(0, 0, 0, 0.52);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  `
);

const StyledModalHeader = styled.div(
  ({ theme }) => `
    align-items: center;
    flex-shrink: 0;
    background-color: ${theme.palette.actions.secondary};
    display: flex;
    height: ${theme.spacing(4)}px;
    justify-content: space-between;
    padding: ${theme.spacing(0, 1, 0, 2)};
    h6 {
      font-weight: 600;
    }
    .MuiIconButton-root {
      padding: 0;
    }
  `
);

const StyledModalBody = styled.div(
  () => `
    flex-grow: 1;
    overflow: scroll;
  `
);

const StyledModalContent = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level3};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)}px;
    padding: ${theme.spacing(1)}px;
  `
);

const StyledTipGroup = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)}px;
    padding: ${theme.spacing(2)}px;
  `
);

const StyledTextTip = styled(TextBlockTypography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary}
  `
);

const TipGroup = ({ tipGroup }) => (
  <StyledTipGroup>
    {tipGroup.map((tip, i) => {
      switch (tip.type) {
        case 'text':
          return (
            <StyledTextTip
              key={i}
              dangerouslySetInnerHTML={{ __html: tip.text }}
            />
          );

        case 'media':
          return <MediaBlock key={i} block={tip} />;

        case 'code':
          return (
            <CodeBlockContainer
              key={i}
              code={tip.code}
              language={tip.language}
            />
          );

        default:
          throw new Error(`unsupported tip type: ${tip.type}`);
      }
    })}
  </StyledTipGroup>
);

TipGroup.propTypes = {
  tipGroup: PropTypes.array,
};

const TeacherTip = ({ content }) => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const showModal = () => setModalOpen(true);

  const closeModal = () => setModalOpen(false);

  return (
    <StyledTeacherTip>
      <StyledTeacherTipIcon onClick={showModal} />
      <StyledDialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        open={isModalOpen}
        onClose={closeModal}
      >
        <StyledModal>
          <StyledModalHeader>
            <Typography variant="h6">Teacher Tip</Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </StyledModalHeader>
          <StyledModalBody>
            <StyledModalContent>
              {content.map((tipGroup, i) => (
                <TipGroup key={i} tipGroup={tipGroup} />
              ))}
            </StyledModalContent>
          </StyledModalBody>
        </StyledModal>
      </StyledDialog>
    </StyledTeacherTip>
  );
};

TeacherTip.propTypes = {
  content: PropTypes.array,
};

export default TeacherTip;
