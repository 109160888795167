import * as externalAccount from '../../utils/externalAccountLogin';
import * as lodash from 'lodash';
import * as projects from '../../utils/projects';
import {
  Artifacts,
  CodeBlockContainer,
  LinkSubmissionCheckpoint,
  Nav,
  RubricCheckpoint,
  Vocabulary,
} from './components';
import {
  BlockGroupContainer,
  ExemplarBlockContainer,
  LabelBlockContainer,
  MediaBlock,
  MultipleChoiceCheckpoint,
  RichTextCheckpoint,
  RubricBlockContainer,
  ShortAnswerCheckpoint,
  TextBlockContainer,
} from '../../components/Blocks';
import {
  BodyContainer,
  Layout,
  ResponsiveContainer,
  SectionGroupContainer,
  StepContainer,
  StepContent,
  StepHeader,
  StepNav,
} from '../../components/Layout';
import { FramedTable, FramedText } from '../../components/Frames';
import {
  GatesProvider,
  PositionProvider,
  ProgressProvider,
  SectionProvider,
  useGates,
  usePosition,
  useProgress,
  useSection,
  useSectionIdChooser,
  useVocabulary,
  VocabularyProvider,
} from './contexts';
import { ProjectProvider, useProject } from '../../contexts/ProjectContext';
import {
  useCheckpointAnswer,
  useRubricCategoryWeights,
  useTeacherProgress,
  useTextBlockFormats,
} from './hooks';
import { useHistory, useParams } from 'react-router-dom';
import Activity from './activity';
import api from '../../api';
import AppBarHeader from '../../components/AppBarHeader';
import ArchivedEntityAlertBar from '../../components/AlertBar';
import { AudioProvider } from '../../contexts/AudioContext';
import { FeedbackModal } from './feedback';
import { grade } from './grading';
import LoadingIndicator from '../../components/LoadingIndicator';
import { MigrationModal } from './migration';
import Modal from './modal';
import NavTeacher from '../../components/NavTeacher';
import ProjectSummary from './summary';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router';
import { useUser } from '../../contexts/AuthContext';

const Project = ({ history }) => {
  const user = useUser();
  const { sectionId, projectId } = useParams();

  const [isSummaryShown, setIsSummaryShown] = React.useState(false);

  const [loading, project, progress, controlValue] = api.load(
    api.project.get(projectId),
    api.project.progress.get(user.id, projectId),
    api.users.getProjectControl(user.id, sectionId, projectId)
  );
  const control = controlValue?.value;

  // Cleans up the progress cache when leaving project view
  React.useEffect(() => {
    if (loading) {
      return;
    }
    return progress?.cleanup;
  }, [loading, progress]);

  const isFirstLoad = React.useRef(true);
  React.useEffect(() => {
    if (loading || !isFirstLoad.current) {
      return;
    }
    isFirstLoad.current = false;

    // Don't show Summary for teachers, even for submitted projects
    if (user.isTeacherForProject(projectId)) {
      return;
    }

    // Only automatically show the project summary when the project has been
    // graded, or if the project is excused.
    const status = progress?.status ?? 'not_started';
    if (status !== 'not_started' && status !== 'in_progress') {
      setIsSummaryShown(true);
    }
    if (control === 'excused') {
      setIsSummaryShown(true);
    }
  }, [loading, control, progress, setIsSummaryShown, projectId, user]);

  if (loading) {
    return <LoadingIndicator />;
  }
  if (!project) {
    history.push(`/error`);
    return;
  }

  const tool = externalAccount.getToolForLanguage(project.language);
  if (tool && !externalAccount.isLoggedIn(tool)) {
    return (
      <Redirect
        to={{
          pathname: '/account-login',
          state: {
            tool: tool,
            continueTo: {
              type: 'project',
              path: history.location.pathname,
              state: history.location.state,
            },
          },
        }}
      />
    );
  }

  // Recursive function to build a project object copy
  // without teacher only content. This will remove both tips and teacher only sections
  const stripTeacherOnlyContent = object => {
    const isArray = Array.isArray(object);
    const obj = isArray ? [] : {};
    for (const attr in object) {
      if (attr === 'tip') continue;
      if (typeof object[attr] === 'object') {
        if (!object[attr]['teacher_only']) {
          if (isArray) {
            obj.push(stripTeacherOnlyContent(object[attr]));
          } else {
            obj[attr] = stripTeacherOnlyContent(object[attr]);
          }
        }
        continue;
      }
      obj[attr] = object[attr];
    }
    return obj;
  };

  // Remove Teacher Tips for non-teachers
  const updatedProject = user.isTeacherForProject(project.id)
    ? project
    : stripTeacherOnlyContent(project);

  const courseName = history.location.state?.courseName;

  const title = courseName ? `${courseName}: ${project.name}` : project.name;

  return (
    <SectionProvider sectionId={sectionId}>
      <ProjectProvider project={updatedProject}>
        <ProgressProvider progress={progress} studentId={user.id}>
          <AudioProvider>
            <PositionProvider>
              <GatesProvider>
                <VocabularyProvider>
                  <AppBarHeader
                    menu={
                      user.isTeacherForAnySections() && (
                        <NavTeacher selected="curriculum" />
                      )
                    }
                    title={title}
                  />
                  <Layout>
                    <Nav
                      isSummaryShown={isSummaryShown}
                      onSetShowSummary={setIsSummaryShown}
                    />
                    <Body
                      control={control}
                      isSummaryShown={isSummaryShown}
                      onSetShowSummary={setIsSummaryShown}
                    />
                  </Layout>
                  <Migration />
                  <Feedback />
                </VocabularyProvider>
              </GatesProvider>
            </PositionProvider>
          </AudioProvider>
        </ProgressProvider>
      </ProjectProvider>
    </SectionProvider>
  );
};
Project.propTypes = {
  history: PropTypes.object,
};
export default Project;

const Body = ({ control, isSummaryShown, onSetShowSummary }) => {
  const gates = useGates();
  const project = useProject();
  const courseSectionInfo = useSection();

  const { isUpdating, progress } = useProgress();
  const { saveFullProgress } = useTeacherProgress();
  const projectPosition = usePosition();
  const user = useUser();
  const { position, submit } = projectPosition;

  // State for the conditional modal
  const [isModalOpen, setModalOpen] = React.useState(false);

  const [bannerSettings, setBannerSettings] = React.useState({ show: false });

  const isTeacher = user.isTeacherForProject(project.id);

  const [loading, courseSection] = api.load(
    api.section.getTitle(courseSectionInfo.id)
  );

  React.useEffect(() => {
    if (loading) {
      return;
    }

    setBannerSettings({
      show: courseSection?.is_active === false,
    });
  }, [loading, courseSection]);

  const isFirstLoad = React.useRef(true);
  React.useEffect(() => {
    if (!isFirstLoad.current || !isTeacher) {
      return;
    }
    isFirstLoad.current = false;

    // Creates a complete progress object for teachers
    // when entering project view
    saveFullProgress();
  }, [isTeacher, project, saveFullProgress]);

  const group = project.section_groups.find(group =>
    group.sections.some(s => s.id === position.section)
  );
  const section = group.sections.find(
    section => section.id === position.section
  );

  const step = section?.steps.find(step => step.id === position.step);
  const hasInstructionalArea = !!step.block_groups;
  const activity = projects.getStepActivity(project, step);

  const [showCode, setShowCode] = React.useState(false);
  const showCodeToggle = {
    showCode: showCode,
    setShowCode: setShowCode,
    toggleEnabled:
      isTeacher &&
      activity &&
      activity.tool === 'pickcode' &&
      activity.action === 'render',
  };

  const handleSubmit = React.useCallback(async () => {
    // Submit with the grading section pre-populated for places where we can
    // automatically grade things.
    await submit(grade(project, progress));
    onSetShowSummary(true);
  }, [onSetShowSummary, project, progress, submit]);

  const stepBodyRef = React.useRef(null);

  const scrollToTop = () => {
    if (stepBodyRef.current) {
      stepBodyRef.current.scrollTo(0, 0);
    }
  };

  // Determine how far through the project we are.
  const numSteps = project.section_groups
    .flatMap(group => group.sections.flatMap(section => section.steps.length))
    .reduce((sum, n) => sum + n, 0);
  const numStepsComplete = Object.values(progress?.steps ?? {}).filter(
    complete => complete
  ).length;

  // Next Button will be enabled only if ANY
  // of the following requirements are fulfilled:
  // - User is a Teacher and there's no request in flight
  // - User can move forward (every required checkpoint is complete)
  const enableNext = (isTeacher && !isUpdating) || gates.canMoveForward;

  // Submit button is shown when a user is on the last step of the project
  // and the project is able to be submitted.  The button is only enabled for
  // students and when we're not applying an update to the progress.
  const showSubmit =
    projectPosition.hasSubmit &&
    control !== 'excused' &&
    !['grading', 'completed'].includes(progress.status);

  const enableSubmit =
    showSubmit && gates.canMoveForward && !isTeacher && !isUpdating;

  // Project summary button is shown when a student is on the last step of the
  // project and the project has already been submitted.  The text on the button
  // changes depending on whether the project has been graded and if it has
  // criteria.  The button is enabled when we're not applying an update to the
  // progress.
  const showSummary =
    !isTeacher &&
    projectPosition.hasSubmit &&
    ['grading', 'completed'].includes(progress.status);

  const enableSummary = showSummary && !isUpdating;

  const hasCriteria = project?.scoring?.criteria;
  let labelSummary, iconSummary;
  if (progress.status === 'grading') {
    labelSummary = 'submitted';
    iconSummary = 'checkmark';
  } else if (progress.status === 'completed' && !hasCriteria) {
    labelSummary = 'submitted';
    iconSummary = 'checkmark';
  } else if (progress.status === 'completed') {
    labelSummary = 'view score';
  }

  // This will evaluate the condition or return
  // the default value if the condition is not defined
  const evaluate = (condition, def) =>
    condition?.['progress:status']
      ? condition['progress:status'].includes(progress.status)
      : def;

  // This handler will intercept the click event on Next and Submit Buttons
  // and will show the Modal if the conditions are met
  const checkConditionalModal = next => () => {
    if (
      !step.modal ||
      !evaluate(step.modal.when, true) ||
      evaluate(step.modal.unless, false)
    ) {
      return next();
    }
    setModalOpen(true);
  };

  const handleModalConfirm = () => {
    setModalOpen(false);
    return projectPosition.hasSubmit ? handleSubmit() : projectPosition.next();
  };

  if (isSummaryShown) {
    return (
      <ResponsiveContainer>
        <ProjectSummary
          control={control}
          onClose={() => onSetShowSummary(false)}
        />
      </ResponsiveContainer>
    );
  }

  return (
    <ResponsiveContainer>
      {bannerSettings.show && (
        <ArchivedEntityAlertBar pathTo={`/courses`} entity={`course`} />
      )}
      <BodyContainer isActivityShown={activity}>
        {hasInstructionalArea && (
          <SectionGroup
            activity={activity}
            group={group}
            ref={stepBodyRef}
            showCodeToggle={showCodeToggle}
          />
        )}
        <Activity
          activity={activity}
          sectionTitle={section.title}
          showHeader={!hasInstructionalArea}
          stepTitle={step.title}
          showCode={showCodeToggle.showCode}
        />
      </BodyContainer>
      <StepNav
        hasProgress={!isTeacher}
        numSteps={numSteps}
        numStepsComplete={numStepsComplete}
        scrollToTop={scrollToTop}
        prev={{
          show: projectPosition.hasPrev,
          enabled: gates.canMoveBackward,
          onClick: projectPosition.prev,
        }}
        next={{
          show: projectPosition.hasNext,
          enabled: enableNext,
          onClick: checkConditionalModal(projectPosition.next),
        }}
        submit={{
          show: showSubmit,
          enabled: enableSubmit,
          onClick: checkConditionalModal(handleSubmit),
        }}
        summary={{
          show: showSummary,
          enabled: enableSummary,
          label: labelSummary,
          icon: iconSummary,
          onClick: () => onSetShowSummary(true),
        }}
      />
      <Modal
        content={step.modal?.content}
        isSubmitStep={projectPosition.hasSubmit}
        onClose={() => setModalOpen(false)}
        onConfirm={handleModalConfirm}
        show={isModalOpen}
      />
    </ResponsiveContainer>
  );
};

Body.propTypes = {
  control: PropTypes.string,
  isSummaryShown: PropTypes.bool,
  onSetShowSummary: PropTypes.func,
};

const SectionGroup = React.forwardRef(
  ({ activity, group, showCodeToggle }, ref) => {
    const { position } = usePosition();
    const section = group.sections.find(s => s.id === position.section);

    return (
      <SectionGroupContainer ref={ref} isActivityShown={!!activity}>
        <Section
          activity={activity}
          section={section}
          showCodeToggle={showCodeToggle}
        />
      </SectionGroupContainer>
    );
  }
);
SectionGroup.displayName = 'SectionGroup';

SectionGroup.propTypes = {
  activity: PropTypes.shape({
    action: PropTypes.string,
    tool: PropTypes.string,
    url: PropTypes.string,
  }),
  group: PropTypes.object,
};

const Section = ({ activity, section, showCodeToggle }) => {
  const { position } = usePosition();
  const step = section.steps.find(s => s.id === position.step);

  return (
    <Step
      activity={activity}
      sectionTitle={section.title}
      step={step}
      showCodeToggle={showCodeToggle}
    />
  );
};

Section.propTypes = {
  activity: PropTypes.shape({
    action: PropTypes.string,
    tool: PropTypes.string,
    url: PropTypes.string,
  }),
  section: PropTypes.shape({
    id: PropTypes.string,
    steps: PropTypes.array,
    title: PropTypes.string,
  }),
};

const Step = ({ activity, sectionTitle, step, showCodeToggle }) => {
  const { progress } = useProgress();

  // This will evaluate the condition or return
  // the default value if the condition is not defined
  const evaluate = (condition, def) =>
    condition?.['progress:status']
      ? condition['progress:status'].includes(progress.status)
      : def;

  const isVisible = bg =>
    evaluate(bg.when, true) && !evaluate(bg.unless, false);

  // Filter the block groups to only those that are visible
  // based on the conditions in 'when' and 'unless' attributes
  const blockGroups = step.block_groups.filter(bg => isVisible(bg));

  return (
    <StepContainer>
      <StepHeader
        section={sectionTitle}
        step={step.title}
        showCodeToggle={showCodeToggle}
      />
      <StepContent $hasActivity={!!activity}>
        {blockGroups.map((group, index) => {
          return (
            <BlockGroup
              key={group.id}
              group={group}
              isLast={index === blockGroups.length - 1}
            />
          );
        })}
      </StepContent>
    </StepContainer>
  );
};

Step.propTypes = {
  activity: PropTypes.shape({
    action: PropTypes.string,
    tool: PropTypes.string,
    url: PropTypes.string,
  }),
  sectionTitle: PropTypes.string,
  step: PropTypes.shape({
    block_groups: PropTypes.array,
    id: PropTypes.string,
    title: PropTypes.string,
  }),
};

const BlockGroup = ({ group, isLast }) => {
  return (
    <BlockGroupContainer className={group.variant}>
      {group.blocks.map((block, index) => {
        return (
          <Block
            key={block.id}
            block={block}
            isLast={isLast && index === group.blocks.length - 1}
          />
        );
      })}
    </BlockGroupContainer>
  );
};

BlockGroup.propTypes = {
  group: PropTypes.shape({
    blocks: PropTypes.any,
    id: PropTypes.string,
    variant: PropTypes.string,
  }),
  isLast: PropTypes.bool,
};

const BlockElement = ({ block }) => {
  switch (block.type) {
    case 'artifacts':
      return <ArtifactBlock block={block} />;

    case 'checkpoint':
      return <CheckpointBlock block={block} />;

    case 'code':
      return <CodeBlock block={block} />;

    case 'exemplar':
      return <ExemplarBlock block={block} />;

    case 'framed-table':
      return <FramedTableBlock block={block} />;

    case 'framed-text':
      return <FramedTextBlock block={block} />;

    case 'label':
      return <LabelBlock block={block} />;

    case 'media':
      return <MediaBlock block={block} />;

    case 'rubric':
      return <RubricBlock block={block} />;

    case 'text':
      return <TextBlock block={block} />;

    case 'vocabulary':
      return <VocabularyBlock block={block} />;

    default:
      throw new Error(
        `unrecognized block type, id: ${block.id}, type: ${block.type}`
      );
  }
};

const Block = ({ block, isLast }) => {
  // We monitor the last block of a step so that when it becomes visible on the
  // screen we can allow the user to move to the next step.
  const { setLastVisible } = useGates();

  // Getting projectId and courseName (if available)
  // for printable rubric links
  const { id: projectId } = useProject();
  const history = useHistory();
  const section = useSection();
  const courseName = history.location?.state?.courseName ?? null;

  const { onOpenModal } = useVocabulary();

  // We introduce a layer of indirection from directly calling setLastVisible
  // because when switching steps the GatesContext will change the value of the
  // gate to false.  If we don't have this indirection in place that will cause
  // our value to be lost.
  const [inView, setInView] = React.useState(false);
  React.useEffect(() => {
    if (inView) {
      setLastVisible(true);
    }
  }, [inView, setLastVisible]);

  const ref = React.useRef();

  // Return whether or not the reference element has become visible.  If it has
  // then the setLastVisible handler is invoked.
  const checkIsVisible = React.useCallback(() => {
    if (!ref.current) {
      return false;
    }

    // Determine if the referenced element has become at least 50% visible in
    // the viewport.  Do this by comparing the y-coordinate of the 50% mark of
    // the element to the bottom of the page (taking into account the height of
    // the step nav).
    const doc = document.documentElement.getBoundingClientRect();
    const elem = ref.current.getBoundingClientRect();
    const stepNavHeight = 64;
    if (elem.bottom - elem.height / 2 < doc.bottom - stepNavHeight) {
      setInView(true);
      return true;
    }

    return false;
  }, [ref]);

  const sectionIdChooser = useSectionIdChooser();
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    // Vocabulary anchors
    const REGEXP_CATEGORY_FROM_DATA = /^data-(.*)-vocab$/;
    for (const anchor of ref.current.querySelectorAll('a')) {
      for (const attribute of anchor.attributes) {
        const match = attribute.name.match(REGEXP_CATEGORY_FROM_DATA);
        if (!match) {
          continue;
        }

        const categoryId = match[1];
        const termId = attribute.value;
        anchor.onclick = () => onOpenModal(categoryId, termId);
      }
    }

    // Printable rubric
    for (const anchor of ref.current.querySelectorAll(
      'a[data-printable-rubric]'
    )) {
      let suffix = '';
      if (courseName) {
        suffix = `?course-name=${courseName}`;
      }

      anchor.href = `/project/${projectId}/rubric${suffix}`;
      anchor.target = '_blank';
    }

    // Project link
    for (const anchor of ref.current.querySelectorAll('a[data-project-id]')) {
      const targetCourseId = anchor.getAttribute('data-course-id');
      const targetProjectId = anchor.getAttribute('data-project-id');

      if (!targetCourseId) {
        // This is an anchor within the same course.  Reuse the section id from
        // the URL.
        anchor.href = section
          ? `/section/${section.id}/project/${targetProjectId}`
          : `/project/${targetProjectId}`;
        continue;
      }

      // This is an anchor to a different course.
      const targetSectionId = sectionIdChooser(targetCourseId);
      if (targetSectionId) {
        // We successfully found a section id to use for this course.
        anchor.href = `/section/${targetSectionId}/project/${targetProjectId}`;
        continue;
      }

      // We weren't able to find a section id to use for the course, so we won't
      // be able to navigate to it if the user clicks the anchor.  We'll add a
      // handler to the anchor that shows an error message.
      anchor.onclick = e => {
        e.stopPropagation();
        alert(
          `Unable to navigate to project because you're not enrolled in its course.`
        );
      };
    }
  }, [onOpenModal, projectId, courseName, ref, section, sectionIdChooser]);

  React.useEffect(() => {
    if (!isLast) {
      return;
    }

    // Check immediately if the block is visible since this is the usual
    // situation when we only have a few blocks in the step.  When this happens
    // we don't need to register a scroll handler;
    if (checkIsVisible()) {
      return;
    }

    // Debounce the handler in order to not call it too often.
    const handler = lodash.debounce(checkIsVisible, 250, { maxWait: 1000 });
    const options = { capture: true, passive: true };

    window.addEventListener('scroll', handler, options);
    return () => window.removeEventListener('scroll', handler);
  }, [isLast, checkIsVisible]);

  // We wrap this in a useMemo to prevent re-renders when the gates context
  // changes.  This is okay to do because we only use the setLastVisible
  // function from the context which is stable and none of the data in the
  // context impacts the rendered view at all.
  return React.useMemo(
    () => (
      <span key={block.id} ref={ref}>
        <BlockElement block={block} />
      </span>
    ),
    [block, ref]
  );
};

Block.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  isLast: PropTypes.bool,
};

//
// Label Blocks
//
const LabelBlock = ({ block }) => (
  <LabelBlockContainer text={block.text} variant={block.variant} />
);

LabelBlock.propTypes = {
  block: PropTypes.shape({
    text: PropTypes.string,
    variant: PropTypes.string,
  }),
};

//
// Exemplar Blocks
//

const ExemplarBlock = ({ block }) => (
  <ExemplarBlockContainer text={block.text} />
);

ExemplarBlock.propTypes = {
  block: PropTypes.shape({
    text: PropTypes.string,
  }),
};

//
// Text/Code Blocks
//

const TextBlock = ({ block }) => {
  const textBlockFormats = useTextBlockFormats();
  const textBlockFormat = textBlockFormats[block.id] ?? null;

  return (
    <TextBlockContainer
      audio={block.audio}
      format={textBlockFormat}
      teacherTip={block.tip}
      text={block.text}
    />
  );
};

TextBlock.propTypes = {
  block: PropTypes.shape({
    audio: PropTypes.string,
    id: PropTypes.string,
    tip: PropTypes.array,
    text: PropTypes.string,
    variant: PropTypes.string,
  }),
};

const CodeBlock = ({ block }) => {
  return <CodeBlockContainer code={block.code} language={block.variant} />;
};

CodeBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    variant: PropTypes.string,
  }),
};

//
// Checkpoint Blocks
//

const RubricCheckpointBlock = ({ block }) => {
  const project = useProject();
  const { isUpdating, progress } = useProgress();
  const { answer, save } = useCheckpointAnswer(block.id);
  const weights = useRubricCategoryWeights();
  const isStepComplete = progress?.steps?.[progress.position.step] ?? false;

  // Index the rubric by its categories.
  const index = Object.fromEntries(
    project.rubric.map(category => [category.type, category])
  );

  // Shrink the rubric down to only the categories that have been specified
  // in the block.  If no specific categories were specified then the entire
  // rubric is used.
  const categories = block.categories
    ? block.categories.map(category => index[category.type])
    : project.rubric;

  // Determine how many checkboxes are required for each category.  If no
  // requirement is specified for a category, then all of the checkboxes are
  // required.
  const required = Object.fromEntries(
    block.categories
      ? block.categories.map(category => [
          category.type,
          category.required ?? index[category.type].requirements.length,
        ])
      : categories.map(category => [
          category.type,
          category.requirements.length,
        ])
  );

  return (
    <RubricCheckpoint
      categories={categories}
      weights={weights}
      choices={answer.value}
      isUpdating={isUpdating}
      title={block.title}
      onChange={value => {
        // The step is already complete, no further changes are allowed.
        if (isStepComplete) {
          return;
        }

        // The checkpoint is complete when all categories have the required
        // number of checked checkboxes.
        let complete = true;
        for (const category of categories) {
          const count = category.requirements
            .map(requirement => requirement.id)
            .map(id => value?.[id])
            .filter(value => value === true).length;

          if (count < required[category.type]) {
            complete = false;
            break;
          }
        }

        save(value, complete);
      }}
      showModal={answer.show_modal}
      resetModal={() => save(answer.value, false)}
    />
  );
};
RubricCheckpointBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        required: PropTypes.number,
      })
    ),
  }),
};

const MultipleChoiceCheckpointBlock = ({ block }) => {
  const { answer, save } = useCheckpointAnswer(block.id);
  const { isUpdating } = useProgress();

  const numAttemptsAllowed = block?.attempts ?? 1;
  const numAttemptsRemaining =
    answer.value?.attempts_remaining ?? numAttemptsAllowed;

  const selected = answer.value?.selected ?? [];
  const previous = answer.value?.previous ?? [];
  const numChoicesNeeded = block.choices.filter(c => c.correct).length;

  const onChange = choice => {
    // If the answer is complete, clicking an option has no effect
    if (answer.complete) {
      return;
    }

    let value, isComplete;
    if (selected.includes(choice.id)) {
      // We clicked on an already clicked choice.  This unselects it.
      value = {
        attempts_remaining: numAttemptsRemaining,
        selected: selected.filter(c => c !== choice.id),
        previous: previous,
      };
      isComplete = false;
    } else if (selected.length + 1 < numChoicesNeeded) {
      // We clicked on a choice, but we haven't yet accumulated enough choices
      // to take an attempt.
      value = {
        attempts_remaining: numAttemptsRemaining,
        selected: selected.concat([choice.id]),
        previous: previous,
      };
      isComplete = false;
    } else {
      // We have enough selections to use an attempt.
      const isCorrect = block.choices
        .filter(c => c.id === choice.id || selected.includes(c.id))
        .every(c => c.correct);
      isComplete = isCorrect || numAttemptsRemaining - 1 === 0;

      if (isComplete) {
        // The checkpoint is complete (either because it was correct or we ran
        // out of attempts).  Save the last set of selected choices.
        value = {
          attempts_remaining: numAttemptsRemaining - 1,
          selected: selected.concat([choice.id]),
          previous: previous,
        };
      } else {
        // This wasn't the last attempt.  Save the current attempt and setup
        // for a new attempt.
        value = {
          attempts_remaining: numAttemptsRemaining - 1,
          selected: [],
          previous: previous.concat([selected.concat([choice.id])]),
        };
      }
    }

    save(value, isComplete);
  };

  return (
    <MultipleChoiceCheckpoint
      checkpoint={block}
      answer={answer}
      isUpdating={isUpdating}
      onChange={onChange}
    />
  );
};
MultipleChoiceCheckpointBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    attempts: PropTypes.number,
    question: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        // Text parts
        PropTypes.shape({
          type: PropTypes.string,
          text: PropTypes.string,
        }),
        // Media parts
        PropTypes.shape({
          type: PropTypes.string,
          media: PropTypes.shape({
            url: PropTypes.string,
            type: PropTypes.string,
            heigth: PropTypes.string,
          }),
        }),
        // Code parts
        PropTypes.shape({
          type: PropTypes.string,
          language: PropTypes.string,
          code: PropTypes.string,
        })
      ),
    ]),
    choices: PropTypes.arrayOf(
      PropTypes.oneOfType([
        // Text choices
        PropTypes.shape({
          id: PropTypes.string,
          text: PropTypes.string,
          correct: PropTypes.bool,
        }),
        // Code choices
        PropTypes.shape({
          id: PropTypes.string,
          language: PropTypes.string,
          code: PropTypes.string,
          correct: PropTypes.bool,
        }),
        // Image choices
        PropTypes.shape({
          type: PropTypes.string,
          media: PropTypes.shape({
            url: PropTypes.string,
            type: PropTypes.string,
            heigth: PropTypes.string,
          }),
          correct: PropTypes.bool,
        }),
      ])
    ),
  }),
};

export const CheckpointBlock = ({ block }) => {
  const project = useProject();
  const user = useUser();
  const { answer, save } = useCheckpointAnswer(block.id);
  const { isUpdating } = useProgress();

  const isTeacher = user.isTeacherForProject(project.id);

  const teacherTip = isTeacher ? block.tip : null;

  switch (block.variant) {
    case 'multiple_choice':
      return <MultipleChoiceCheckpointBlock block={block} />;

    case 'rubric':
      return <RubricCheckpointBlock block={block} />;

    case 'short_answer':
      return (
        <ShortAnswerCheckpoint
          checkpoint={block}
          answer={answer.value}
          isUpdating={isUpdating}
          onChange={value => save(value, true)}
        />
      );

    case 'rich_text':
      return (
        <RichTextCheckpoint
          answer={answer.value}
          isUpdating={isUpdating}
          onChange={value => save(value, true)}
        />
      );

    case 'link': {
      return (
        <LinkSubmissionCheckpoint
          audio={block.audio}
          errorMsg={block.errormsg}
          isUpdating={isUpdating}
          link={answer.value}
          onChange={value => save(value, true)}
          placeholder={block.placeholder}
          question={block.question}
          teacherTip={teacherTip}
          title={block.title}
          validation={block.validation}
        />
      );
    }

    default:
      throw new Error(`unsupported checkpoint variant: ${block.variant}`);
  }
};
CheckpointBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    variant: PropTypes.string,
  }),
};

//
// Framed blocks
//

const FramedTableBlock = ({ block }) => {
  return <FramedTable title={block.title} table={block.table} />;
};

FramedTableBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    table: PropTypes.array,
  }),
};

const FramedTextBlock = ({ block }) => (
  <FramedText
    audio={block.audio}
    teacherTip={block.tip}
    text={block.text}
    title={block.title}
  />
);

FramedTextBlock.propTypes = {
  block: PropTypes.shape({
    audio: PropTypes.string,
    id: PropTypes.string,
    tip: PropTypes.array,
    text: PropTypes.string,
    title: PropTypes.string,
  }),
};

//
// Artifact Block
//

const ArtifactBlock = ({ block }) => (
  <Artifacts title={block.title} rows={block.rows} />
);

ArtifactBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string,
          url: PropTypes.string,
        }),
        subtitle: PropTypes.string,
        icon: PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string,
        }),
      })
    ),
  }),
};

//
// Rubric Block
//

const RubricBlock = ({ block }) => {
  const project = useProject();
  const weights = useRubricCategoryWeights();

  return (
    <RubricBlockContainer
      title={block.title}
      rubric={project?.rubric}
      weights={weights}
    />
  );
};

RubricBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    rubric: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        title: PropTypes.string,
        weight: PropTypes.string,
        requirements: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            text: PropTypes.string,
          })
        ),
      })
    ),
  }),
};

//
// Vocabulary Block
//

const VocabularyBlock = ({ block }) => (
  <Vocabulary title={block.title} vocabulary={block.categories} />
);

VocabularyBlock.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    categories: PropTypes.array,
  }),
};

//
// Feedback modal
//

const Feedback = () => {
  const project = useProject();
  const { progress } = useProgress();
  const user = useUser();
  const [isFeedbackShown, setShowFeedback] = React.useState(false);

  React.useEffect(() => {
    // The feedback modal should be shown only if ALL of the following criteria
    // are met:
    //
    // 1. The project contains a feedback section.
    // 2. The user's project progress is in the grading or completed states.
    // 3. The user hasn't been excused from providing feedback for this project.
    // 4. The user has not previously submitted feedback for this project.
    // 5. The user does not teach a section containing this project.
    let show = true;
    if (!project?.feedback) {
      show = false;
    }
    if (progress?.status !== 'grading' && progress?.status !== 'completed') {
      show = false;
    }
    if (progress?.feedback?.status === 'excused') {
      show = false;
    }
    if (progress?.feedback?.status === 'completed') {
      show = false;
    }
    if (user.isTeacherForProject(project.id)) {
      show = false;
    }

    setShowFeedback(show);
  }, [project, progress, user]);

  if (!isFeedbackShown) {
    return null;
  }

  return <FeedbackModal close={() => setShowFeedback(false)} />;
};

//
// Migration modal
//

const Migration = () => {
  const user = useUser();
  const { sectionId, projectId } = useParams();
  const { progress, setProgress } = useProgress();
  const [isModalShown, setShowModal] = React.useState(false);

  const [loading, migrations] = api.load(
    api.project.progress.migrations.get(user.id, projectId, sectionId)
  );

  React.useEffect(() => {
    if (migrations?.cleanup) {
      return migrations.cleanup;
    }
  }, [migrations]);

  React.useEffect(() => {
    if (loading) {
      return;
    }

    setShowModal(migrations?.cospaces ?? false);
  }, [loading, migrations]);

  const onClose = React.useCallback(() => {
    setProgress({
      ...progress,
      section_id: sectionId,
    });
    setShowModal(false);
  }, [sectionId, progress, setProgress, setShowModal]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (isModalShown) {
    return (
      <MigrationModal
        close={onClose}
        migrations={migrations}
        workSectionId={progress.section_id}
        newSectionId={sectionId}
      />
    );
  }

  return null;
};
