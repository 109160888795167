import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: black;

  video {
    width: 100%;
    height: 100%;
    max-width: 1024px;
    max-height: 576px;
  }
`;

const LoadingIndicator = React.forwardRef((props, ref) => (
  <Container {...props} ref={ref}>
    <video
      autoPlay="autoplay"
      loop="loop"
      muted="muted"
      src="/static/assets/loading/hello-world-logo-loading.mp4?controls=0"
    />
  </Container>
));

LoadingIndicator.displayName = 'LoadingIndicator';

export default LoadingIndicator;
