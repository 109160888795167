import * as lodash from 'lodash';
import { orderBy as _orderBy, isEmpty, pickBy } from 'lodash';
import {
  BodyContainer,
  Layout,
  ResponsiveContainer,
} from '../../../components/Layout';
import { DashboardHeader, StatusIcon } from '../components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../api';
import AppBarHeader from '../../../components/AppBarHeader';
import ArchivedEntityAlertBar from '../../../components/AlertBar';
import ArrowIcon from '../../../components/icons/ArrowIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import CollapseIcon from '../../../components/icons/Collapse';
import FormControl from '@material-ui/core/FormControl';
import { getCriteriaScore } from '../../../hooks/useScoringSummary';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LoadingIndicator from '../../../components/LoadingIndicator';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '../../../components/icons/MoreIcon';
import NavTeacher from '../../../components/NavTeacher';
import PropTypes from 'prop-types';
import React from 'react';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { useUser } from '../../../contexts/AuthContext';

//
// Table Styles
//

const StyledTableWrapper = styled.div(
  ({ theme }) => `
    padding: ${theme.spacing(3)}px;
    overflow: auto;
  `
);

const StyledTableContainer = styled(TableContainer)(
  ({ theme }) => `
    height: 100%;
    table {
      background: white;
    }
    table.headers {
      margin-bottom: ${theme.spacing(1)}px;
      width: auto;
    }
    thead {
      background: ${theme.palette.background.level1};
      position: sticky;
      top: 0;
      z-index: 20;
    }
    .sticky {
      min-width: 160px;
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 10;
    }
    thead tr {
      border-bottom: 8px solid ${theme.palette.background.level3};
    }
    .cell {
      min-width: 160px;
      width: 200px;
      padding: 12px;
    }
    th {
      border-bottom: none;
      padding: ${theme.spacing(1.5)}px;
      vertical-align: top;
      .sorting-icons {
        visibility: hidden;
      }
      p {
        line-height: 18px;
      }
      :hover {
        .sorting-icons {
          visibility: visible;
        }
      }
    }
    td {
      border-bottom: 1px solid #E1E1E1;
    }
    .name h6 {
      color: ${theme.palette.text.secondary};
      font-weight: 400;
      font-size: 12px;
    }

    // Styles to force the scrollbars to be visible
    // and prevent MacOs from hiding them
    // This works in Chrome and Safari, 
    // we'll probably need a js based solution for Firefox
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 8px;
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: white;
      :hover {
        background: rgba(0,0,0,0.08);
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #c4c4c4;
      :hover {
        background-color: #908F8F;
      }
    }
  `
);

const StyledHeaderTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 600;
    user-select: none;
  `
);

const StyledIconWrapper = styled.div(
  () => `
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    a {
      display: flex;
    }
  `
);

const StyledInputBase = styled(InputBase)(
  ({ theme }) => `
    & input {
      box-sizing: border-box;
      color: ${theme.palette.text.secondary};
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      height: 28px;
      line-height: 20px;
      transition: width 300ms;
      width: 134px;
    }
    & root {
        display: flex;
        font-size: 14px;
        height: 28px;
        width: 100%;
    }
  `
);

const StyledNoResults = styled.div(
  ({ theme }) => `
    background: transparent;
    display: flex;
    justify-content: center;
    padding: ${theme.spacing(2)}px;
  `
);

//
// Enhanced Table Header
//

const StyledFormControl = styled(FormControl)`
  .MuiInputBase-root {
    align-self: flex-end;
    flex-grow: 1;
    max-width: 100%;
    :before,
    :after {
      border-bottom: none;
    }
  }
  .MuiSelect-select {
    padding: 0;
  }
  .MuiSelect-select:focus {
    background: none;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
`;

const useStyles = makeStyles(theme => ({
  dropdownStyle: {
    backgroundColor: theme.palette.background.level1,
    borderRadius: '5px',
    '& .MuiList-padding': {
      paddingBottom: '3px',
      paddingTop: '3px',
      width: '121px',
    },
  },
  menuItem: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    padding: '8px',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.level3,
    },
    '&.Mui-disabled': {
      color: '#908f8f',
      opacity: 1,
    },
  },
}));

const StyledHeader = styled.div(
  () => `
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    button {
      padding: 0;
    }
  `
);

const StyledSortControls = styled.div(
  () => `
    display: flex;
    height: 22px;
  `
);

const StyledSortIcons = styled.div(
  ({ theme }) => `
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 6px;
    width: 100%;
    svg {
      &:hover {
        fill: ${theme.palette.primary.main};
      }
    }
  `
);

const StyledMoreIconWrapper = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    height: 22px;
    width: 16px;
    &:hover {
      svg {
        fill: ${theme.palette.primary.main};
      }
    }
  `
);

const ScoreAverageTypography = styled(Typography)(
  () => `
    color: #485DDE;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  `
);

const ScoreAverageTableCell = styled(TableCell)(
  () => `
    padding: 8px 0;
    &:first-child div {
      border-radius: 5px 0 0 5px;
      margin-left: 8px;
    }
    &:last-child div {
      border-radius: 0 5px 5px 0;
      margin-right: 8px;
  `
);

const StyledSelectProjects = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    border-radius: 5px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    left: -${theme.spacing(17)}px;
    padding-bottom: ${theme.spacing(1)}px;
    position: absolute;
    top: ${theme.spacing(6)}px;
    width: ${theme.spacing(50)}px;

    > p.MuiTypography-body1 {
      color: #908F8F;
      padding: ${theme.spacing(1, 2)};
      text-align: left;
    }
    .MuiTypography-body1 {
      color: #4d4d4d;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
    span {
      padding: 0;
      user-select: none;
    }
    svg.collapse-icon {
      fill: #908F8F;
    }
    .MuiList-padding{
      padding: 0;
    }
    .MuiListItem-root {
      padding: ${theme.spacing(0.5, 2)};
    }
    .MuiListItemIcon-root {
      min-width: ${theme.spacing(4)}px; 
    }
  `
);

const StyledListContainer = styled.div(
  ({ theme }) => `
    max-height: calc(100vh - ${theme.spacing(50)}px);
    overflow-y: auto;
    .item:hover {
      background: #F2F2F2;
      span {
        font-weight: 600;
      }
      & > div svg {
        fill: #4D4D4D;
      }
      .checkbox span input:checked ~ svg {
        fill: currentColor;
      }
    }
    .level {
      padding-left: ${theme.spacing(6)}px;
    }
    .project {
      padding-left: ${theme.spacing(10)}px;
    }
  `
);

const ScoreAverageContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: #E5E5F4;
    display: flex;
    height: ${theme.spacing(6)}px;
    justify-content: center;
  `
);

const StyledCheckbox = styled(Checkbox)(
  () => `
    color: #C4C4C4;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `
);

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    maxWidth: theme.spacing(22),
  },
}))(Tooltip);

const SelectProjects = ({ levels, onChange, selectedProjects }) => {
  const { levelId } = useParams();
  const [showAll, setShowAll] = React.useState(true);
  const [menu, setMenu] = React.useState({});
  const [levelSelected, setLevelSelected] = React.useState({});
  const [selected, setSelected] = React.useState(selectedProjects);

  // Selects or unselects the Select All option
  // if all projects in the level are selected
  React.useEffect(() => {
    levels.forEach(level => {
      setLevelSelected(s => ({
        ...s,
        [level.id]: levels
          .find(l => l.id === level.id)
          ?.projects.every(p => selected.includes(p.id)),
      }));
    });
  }, [levels, selected]);

  const handleOpenLevel = level => () => {
    setMenu(s => ({ ...s, [level]: !s[level] }));
  };

  const handleSelectProject = (level, projectId) => e => {
    const { checked } = e.target;

    const newSelected = checked
      ? [...selected, projectId]
      : selected.filter(id => id !== projectId);

    onChange(newSelected);
    setSelected(newSelected);
  };

  const handleSelectAll = level => e => {
    const { checked } = e.target;
    const allSelected = levels
      .find(l => l.id === level)
      ?.projects.map(project => project.id);

    setLevelSelected(s => ({ ...s, [level]: !s[level] }));

    const newSelected = checked
      ? [...new Set([...selected, ...allSelected])]
      : selected.filter(id => !allSelected.includes(id));

    onChange(newSelected);
    setSelected(newSelected);
  };

  const filteredLevel = levelId ? levels.filter(l => l.id === levelId) : levels;

  return (
    <StyledSelectProjects>
      <Typography variant="body1">
        Select a project to calculate the Selected Projects Score:
      </Typography>
      <StyledListContainer>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {!levelId && (
            <ListItem
              button
              className="item"
              onClick={() => setShowAll(s => !s)}
            >
              <ListItemIcon>
                <CollapseIcon collapsed={!showAll} />
              </ListItemIcon>
              <ListItemText primary={'All Projects'} />
            </ListItem>
          )}
          <Collapse in={showAll} timeout="auto" unmountOnExit>
            {filteredLevel.map(level => (
              <React.Fragment key={level.id}>
                <ListItem
                  button
                  className="item level"
                  onClick={handleOpenLevel(level.id)}
                >
                  <ListItemIcon>
                    <CollapseIcon collapsed={!menu[level.id]} />
                  </ListItemIcon>
                  <ListItemText primary={level.title} />
                </ListItem>
                <Collapse in={menu[level.id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem className="item project">
                      <ListItemIcon>
                        <StyledCheckbox
                          checked={!!levelSelected[level.id]}
                          className="checkbox"
                          onChange={handleSelectAll(level.id)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={'Select All'} />
                    </ListItem>
                  </List>
                  {level.projects.map(project => (
                    <List key={project.id} component="div" disablePadding>
                      <ListItem className="item project">
                        <ListItemIcon>
                          <StyledCheckbox
                            checked={selected.includes(project.id)}
                            className="checkbox"
                            onChange={handleSelectProject(level.id, project.id)}
                          />
                        </ListItemIcon>
                        <ListItemText primary={project.title} />
                      </ListItem>
                    </List>
                  ))}
                </Collapse>
              </React.Fragment>
            ))}
          </Collapse>
        </List>
      </StyledListContainer>
    </StyledSelectProjects>
  );
};

SelectProjects.propTypes = {
  levels: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selectedProjects: PropTypes.array.isRequired,
};

const MoreMenuIcon = ({ active }) => (
  <StyledMoreIconWrapper>
    <MoreIcon active={active} />
  </StyledMoreIconWrapper>
);
MoreMenuIcon.propTypes = {
  active: PropTypes.bool,
};

const EnhancedTableHeader = ({
  levels,
  onSearchChange,
  onSortChange,
  order,
  orderBy,
  projects,
  searchText,
  sectionMetadata,
  onSelectProjects,
}) => {
  const [showSelectProjects, setShowSelectProjects] = React.useState(false);
  const [selectedProjects, setSelectedProjects] = React.useState(
    sectionMetadata.settings?.selected_projects || []
  );

  const classes = useStyles();

  const handleOrderByChange = e => {
    const { value: newOrderBy } = e.target;
    onSortChange(newOrderBy, order);
  };

  const handleOrderChange = newOrder => () => onSortChange(orderBy, newOrder);

  const handleUpdateSelectedProjects = selected =>
    setSelectedProjects(selected);

  const handleClickAway = () => {
    if (showSelectProjects) {
      setShowSelectProjects(false);
      onSelectProjects(selectedProjects);
    }
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className="header sticky name" align="left">
          <StyledHeader>
            <StyledHeaderTypography variant="body1">
              Students
            </StyledHeaderTypography>
            <StyledSortControls>
              <StyledSortIcons className="sorting-icons">
                <IconButton onClick={handleOrderChange('asc')}>
                  <ArrowIcon active={order === 'asc'} />
                </IconButton>
                <IconButton onClick={handleOrderChange('desc')}>
                  <ArrowIcon active={order === 'desc'} down />
                </IconButton>
              </StyledSortIcons>
              <StyledFormControl className="sorting-icons">
                <Select
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  onChange={handleOrderByChange}
                  renderValue={MoreMenuIcon}
                  value={orderBy}
                >
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    value=""
                    disabled
                  >
                    Sort by:
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    value={'first_name'}
                  >
                    First Name
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    value={'last_name'}
                  >
                    Last Name
                  </MenuItem>
                </Select>
              </StyledFormControl>
            </StyledSortControls>
          </StyledHeader>
          <StyledInputBase
            classes={{
              root: 'root',
              input: 'input',
            }}
            onChange={onSearchChange}
            placeholder="Search…"
            value={searchText}
          />
        </TableCell>
        <TableCell className="header cell" align="center">
          <StyledHeader>
            <StyledTooltip title="This score reflects each student's average score for all completed projects.">
              <StyledHeaderTypography variant="body1">
                Completed Projects Score
              </StyledHeaderTypography>
            </StyledTooltip>
          </StyledHeader>
        </TableCell>
        <TableCell className="header cell" align="center">
          <StyledHeader>
            <StyledTooltip title="This score reflects each student's average score for only the projects you select.">
              <StyledHeaderTypography variant="body1">
                Selected Projects Score
              </StyledHeaderTypography>
            </StyledTooltip>
            <ClickAwayListener onClickAway={handleClickAway}>
              <StyledFormControl>
                <IconButton onClick={() => setShowSelectProjects(s => !s)}>
                  <MoreMenuIcon active={showSelectProjects} />
                </IconButton>
                {showSelectProjects && (
                  <SelectProjects
                    levels={levels}
                    onChange={handleUpdateSelectedProjects}
                    selectedProjects={selectedProjects}
                  />
                )}
              </StyledFormControl>
            </ClickAwayListener>
          </StyledHeader>
        </TableCell>
        {projects.map(({ id, title }) => (
          <TableCell key={id} className="header cell" align="center">
            <StyledHeaderTypography variant="body1">
              {title}
            </StyledHeaderTypography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHeader.propTypes = {
  levels: PropTypes.array,
  onSearchChange: PropTypes.func,
  onSortChange: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  projects: PropTypes.array,
  searchText: PropTypes.string,
  sectionMetadata: PropTypes.shape({
    title: PropTypes.string,
    settings: PropTypes.shape({
      selected_projects: PropTypes.array,
    }),
    version: PropTypes.string,
  }),
  onSelectProjects: PropTypes.func,
};

const Gradebook = () => {
  const user = useUser();
  const history = useHistory();
  const { sectionId, courseId, levelId } = useParams();
  const [searchText, setSearchText] = React.useState('');
  const [studentsList, setStudentsList] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('first_name');
  const [order, setOrder] = React.useState('asc');
  const [version, setVersion] = React.useState(null);

  const [studentsScores, setStudentsScores] = React.useState({});
  const [selectedProjectsScore, setSelectedProjectsScore] = React.useState({});
  const [sectionAverages, setSectionAverages] = React.useState({});
  const [completedProjectsScore, setCompletedProjectsScore] = React.useState(
    []
  );

  const [bannerSettings, setBannerSettings] = React.useState({ show: false });

  // This is to store previous data to compare and see if it has changed
  const previousValues = React.useRef({ projectsObj: {}, studentsObj: {} });

  const [
    loading,
    metadata,
    projectsObject,
    studentsObject,
    sections,
    teacherEnrollments,
    allCourses,
  ] = api.load(
    api.section.getSectionCourseMetadata(sectionId, courseId),
    api.teacher.dashboards.gradebook.getProjects(sectionId, courseId, levelId),
    api.teacher.dashboards.gradebook.getSubmissions(sectionId, levelId),
    api.teacher.getSections(user.id),
    api.roster.sections.getTeacherEnrollments(),
    api.curriculum.courses.get()
  );

  const currentSection = React.useMemo(() => {
    if (loading) return {};
    const courseMap = new Map();
    allCourses.forEach(course => {
      courseMap.set(course.id, course);
    });
    let currentSection = {};
    teacherEnrollments.forEach(enrollment => {
      if (enrollment.id === sectionId) {
        currentSection = enrollment;
      }
      enrollment.courses = lodash.orderBy(
        enrollment.courses.map(c =>
          courseMap.get(c.course_id)
            ? {
                course_id: c.course_id,
                name: courseMap.get(c.course_id)?.name,
                sort_key: courseMap.get(c.course_id)?.sort_key,
                first_level_id: courseMap.get(c.course_id)?.levels[0].id,
              }
            : {
                course_id: c.course_id,
                name: '',
                sort_key: '',
                first_level_id: '',
              }
        ),
        ['sort_key'],
        ['asc']
      );
    });

    return currentSection;
  }, [loading, allCourses, teacherEnrollments, sectionId]);

  React.useEffect(() => {
    if (loading) {
      return;
    }

    setBannerSettings({
      show: currentSection.is_active === false,
    });
  }, [loading, currentSection]);

  // Cache cleanup on unmount for data that may have changed
  // when the user navigates away from the page
  React.useEffect(() => {
    if (loading) {
      return;
    }
    return studentsObject.cleanup;
  }, [loading, studentsObject]);

  const { course: courseMetadata, section: sectionMetadata } = metadata || {};

  // These projects are in the order they appear in the course.
  const projects = React.useMemo(
    () => projectsObject?.projects ?? [],
    [projectsObject]
  );

  // These students are in alphabetical order by last name.
  const students = React.useMemo(
    () => studentsObject?.students ?? [],
    [studentsObject]
  );

  // These are the project progress objects (status and grading portions only)
  // indexed by student/project_id.
  const progresses = React.useMemo(
    () => studentsObject?.progresses ?? {},
    [studentsObject]
  );

  // All the projects weights in this level
  const levelProjects = levelId
    ? courseMetadata?.levels.find(l => l.id === levelId)?.projects
    : courseMetadata?.levels.flatMap(l => l.projects);

  const projectWeights = React.useMemo(
    () =>
      levelProjects?.reduce((a, v) => ({ ...a, [v.id]: v.weight || 0 }), {}) ||
      {},
    [levelProjects]
  );

  // This function calculates the weighted score
  // given the students scores and a list of projects to score
  const calcWeightedScore = React.useCallback(
    (studentScores, projectsToScore) => {
      if (projectsToScore.length === 0) {
        return null;
      }

      const completedCount = projectsToScore.reduce(
        (a, v) => a + (studentScores[v] !== null ? 1 : 0),
        0
      );

      const weightedScoresSum = projectsToScore.reduce(
        (a, v) => a + projectWeights[v] * studentScores[v],
        0
      );

      const allWeightsSum = projectsToScore.reduce(
        (a, v) => a + projectWeights[v],
        0
      );

      return completedCount > 0 && allWeightsSum !== 0
        ? Math.round(weightedScoresSum / allWeightsSum)
        : null;
    },
    [projectWeights]
  );

  // This will build a list of all students scores
  // and will calculate the values to fill the columns:
  // - Section Average
  // - Completed Projects Score
  // - Selected Projects Score
  React.useEffect(() => {
    // This will check if the students list and projects list have changed
    // to update the scores and calculated values
    // TODO probably refactor this once we implement the new API
    if (
      !isEmpty(projects) &&
      !isEmpty(students) &&
      !isEmpty(sectionMetadata) &&
      previousValues.current.projectObj !== projects &&
      previousValues.current.studentsObj !== students
    ) {
      previousValues.current.projectObj = projects;
      previousValues.current.studentsObj = students;

      setVersion(sectionMetadata.version);
      const getScore = (studentId, projectId) => {
        // The project object here is a reduced version of the main project object
        const project = projects.find(p => p.id === projectId);
        const progress = progresses[studentId][projectId];

        // Convert the checkpoints in the gradebook project into a list of
        // objects that look similar to checkpoints from te project template.
        // This is in preparation to pass them into the getCriteriaScore
        // function.
        const checkpoints = Object.entries(project?.checkpoints ?? []).map(
          ([id, value]) => ({
            ...value,
            id: id,
          })
        );

        const criteria = project?.scoring_criteria ?? [];
        const scores = criteria.map(c =>
          getCriteriaScore(c, project.rubric, checkpoints, progress)
        );

        const total = scores.reduce(
          (prev, score) => ({
            numerator: prev.numerator + score.numerator,
            denominator: prev.denominator + score.denominator,
          }),
          { numerator: progress?.scoring?.adjustment ?? 0, denominator: 0 }
        );

        if (total.denominator === 0) {
          return null;
        }
        return Math.round((100 * total.numerator) / total.denominator);
      };

      // This will build an object with all available student scores
      const scores = {};
      for (const { id: studentId } of students) {
        scores[studentId] = {};
        for (const { id: projectId } of projects) {
          const isGraded =
            progresses[studentId][projectId].status === 'completed' &&
            progresses[studentId][projectId].control !== 'blocked' &&
            progresses[studentId][projectId].control !== 'excused';
          scores[studentId][projectId] = isGraded
            ? getScore(studentId, projectId)
            : null;
        }
      }
      setStudentsScores(scores);

      // This will calculate the section averages
      const averages = {};
      for (const { id: projectId } of projects) {
        const scored = Object.values(scores)
          .map(o => o[projectId])
          .filter(s => s !== null);
        if (scored.length === 0) {
          averages[projectId] = null;
          continue;
        }
        const sum = scored.reduce((acc, curr) => acc + curr, 0);
        averages[projectId] = Math.round(sum / scored.length);
      }
      setSectionAverages(averages);

      // This will calculate the completed projects score
      const completed = {};
      // Array of project Ids that are completed
      for (const { id: studentId } of students) {
        const studentScores = scores[studentId];
        const completedProjectsIds = Object.keys(studentScores).filter(
          p => studentScores[p] !== null
        );
        completed[studentId] = calcWeightedScore(
          studentScores,
          completedProjectsIds
        );
      }
      // Total for the Section Average row
      completed['total'] = calcWeightedScore(
        averages,
        Object.keys(averages).filter(p => averages[p] !== null)
      );
      setCompletedProjectsScore(completed);

      // This will calculate the selected projects score
      const selected = {};
      const selectedProj = sectionMetadata.settings?.selected_projects || [];
      const projectsToScore = selectedProj.filter(
        sp => !!projects.find(p => p.id === sp)
      );

      for (const { id: studentId } of students) {
        const studentScores = scores[studentId];
        let studentExcusedCourses =
          sectionMetadata.controls && sectionMetadata.controls['per-student']
            ? sectionMetadata.controls['per-student'][studentId]
            : null;
        if (studentExcusedCourses) {
          selected[studentId] = calcWeightedScore(
            studentScores,
            projectsToScore.filter(sp => !studentExcusedCourses[sp])
          );
        } else {
          selected[studentId] = calcWeightedScore(
            studentScores,
            projectsToScore
          );
        }
      }
      const sumValues = Object.values(pickBy(selected)).reduce(
        (a, b) => a + b,
        0
      );
      // Total for the Section Average row
      selected['total'] = Math.round(
        sumValues / Object.keys(pickBy(selected)).length
      );
      if (Number.isNaN(selected['total'])) {
        selected['total'] = null;
      }
      setSelectedProjectsScore(selected);
    }
  }, [calcWeightedScore, progresses, projects, sectionMetadata, students]);

  // This stores the students list in a local state
  // to be able to filter and sort them with the search field
  React.useEffect(() => {
    if (!students.length) {
      return;
    }
    // Get custom ordering from localStorage
    const gbConfig = JSON.parse(localStorage.getItem('gradebook'));

    // If custom ordering not found just save list as is
    if (!gbConfig) {
      setStudentsList(students);
      return;
    }
    // Apply custom ordering to students list
    setStudentsList(_orderBy(students, gbConfig.orderBy, gbConfig.order));
    setOrderBy(gbConfig.orderBy);
    setOrder(gbConfig.order);
  }, [students]);

  if (loading) {
    return <LoadingIndicator />;
  }

  const handleSearchChange = event => {
    const { value } = event.target;
    setSearchText(value);
    const regex = new RegExp(value, 'gi');
    setStudentsList(
      students.filter(s => regex.test(s.first_name) || regex.test(s.last_name))
    );
  };

  const handleChangeLevel = levelSelected => {
    if (levelSelected === 'all') {
      localStorage.removeItem(courseId + ':' + sectionId);
      history.push(
        `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}`
      );
    } else {
      localStorage.setItem(courseId + ':' + sectionId, levelSelected);
      history.push(
        `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}/level/${levelSelected}`
      );
    }
  };

  const handleChangeSection = (sectionSelected, firstCourseId, firstLevel) => {
    localStorage.setItem('selectedSection', sectionSelected);
    const selectedCourseId =
      localStorage.getItem('LastSelectedCourse:' + sectionSelected) ??
      firstCourseId;
    const selectedLevel =
      localStorage.getItem(selectedCourseId + ':' + sectionSelected) ??
      firstLevel;
    history.push(
      `/teacher/dashboards/gradebook/section/${sectionSelected}/course/${selectedCourseId}/level/${selectedLevel}`
    );
  };

  const handleChangeCourse = (selectedCourseId, firstLevel) => {
    localStorage.setItem('LastSelectedCourse:' + sectionId, selectedCourseId);
    const selectedLevel =
      localStorage.getItem(selectedCourseId + ':' + sectionId) ?? firstLevel;
    history.push(
      `/teacher/dashboards/gradebook/section/${sectionId}/course/${selectedCourseId}/level/${selectedLevel}`
    );
  };

  const handleSortStudents = (newOrderBy, newOrder) => {
    setStudentsList(s => _orderBy(s, newOrderBy, newOrder));
    setOrderBy(newOrderBy);
    setOrder(newOrder);
    localStorage.setItem(
      'gradebook',
      JSON.stringify({ order: newOrder, orderBy: newOrderBy })
    );
  };

  // This will update the selected projects score
  // when making changes in the selected projects dropdown
  const handleSelectProjects = async projectsSelected => {
    const selected = {};
    for (const { id: studentId } of students) {
      const studentScores = studentsScores[studentId];
      let studentExcusedCourses = sectionMetadata.controls
        ? sectionMetadata.controls['per-student'][studentId]
        : null;
      if (studentExcusedCourses) {
        selected[studentId] = calcWeightedScore(
          studentScores,
          projectsSelected.filter(sp => !studentExcusedCourses[sp])
        );
      } else {
        selected[studentId] = calcWeightedScore(
          studentScores,
          projectsSelected
        );
      }
    }
    const sumValues = Object.values(pickBy(selected)).reduce(
      (a, b) => a + b,
      0
    );
    // Total for the Section Average row
    selected['total'] = sumValues / Object.keys(pickBy(selected)).length;
    if (Number.isNaN(selected['total'])) {
      selected['total'] = null;
    }
    setSelectedProjectsScore(selected);

    // Save the selected projects in database
    const settings = {
      ...sectionMetadata.settings,
      selected_projects: projectsSelected,
    };
    const newVersion = await api.section.updateSettings(
      sectionId,
      sectionMetadata.course_id,
      settings,
      version
    );
    setVersion(newVersion);
  };

  const getFormattedStudentName = (first_name, last_name) =>
    orderBy === 'last_name'
      ? `${last_name}, ${first_name}`
      : `${first_name} ${last_name}`;

  return (
    <>
      <AppBarHeader
        menu={<NavTeacher selected="sections" />}
        title="Sections"
      />
      <Layout>
        <ResponsiveContainer>
          {bannerSettings.show && (
            <ArchivedEntityAlertBar
              pathTo={`/teacher/sections`}
              entity={`section`}
            />
          )}
          <BodyContainer noProgressBar>
            <DashboardHeader
              image={courseMetadata.media}
              levelId={levelId}
              levels={courseMetadata.levels}
              onChangeLevel={handleChangeLevel}
              sectionId={sectionId}
              sectionMetadata={sectionMetadata}
              sections={sections}
              onChangeSection={handleChangeSection}
              onChangeCourse={handleChangeCourse}
              numStudents={students.length}
              courses={currentSection.courses}
              courseId={courseId}
            />
            <StyledTableWrapper>
              <StyledTableContainer>
                <Table className="headers">
                  <EnhancedTableHeader
                    levels={courseMetadata.levels}
                    onSearchChange={handleSearchChange}
                    onSortChange={handleSortStudents}
                    order={order}
                    orderBy={orderBy}
                    projects={projects}
                    searchText={searchText}
                    sectionMetadata={sectionMetadata}
                    onSelectProjects={handleSelectProjects}
                  />
                  <TableBody>
                    <TableRow className="avg">
                      <ScoreAverageTableCell className="sticky">
                        <ScoreAverageContainer>
                          <ScoreAverageTypography variant="body1">
                            Section Average
                          </ScoreAverageTypography>
                        </ScoreAverageContainer>
                      </ScoreAverageTableCell>
                      <ScoreAverageTableCell align="center">
                        <ScoreAverageContainer>
                          <StatusIcon
                            isGroupedScore
                            isNotAvailable={
                              completedProjectsScore['total'] === null
                            }
                            score={completedProjectsScore['total']}
                            tooltipMsg={'Average Score'}
                          />
                        </ScoreAverageContainer>
                      </ScoreAverageTableCell>
                      <ScoreAverageTableCell align="center">
                        <ScoreAverageContainer>
                          <StatusIcon
                            isGroupedScore
                            isNotAvailable={
                              selectedProjectsScore['total'] === null
                            }
                            score={selectedProjectsScore['total']}
                            tooltipMsg={'Average Score'}
                          />
                        </ScoreAverageContainer>
                      </ScoreAverageTableCell>
                      {projects.map(({ id: projectId }, i) => (
                        <ScoreAverageTableCell key={i} align="center">
                          <ScoreAverageContainer>
                            <StatusIcon
                              isGroupedScore
                              isNotAvailable={
                                sectionAverages[projectId] === null
                              }
                              score={sectionAverages[projectId]}
                              tooltipMsg={'Average Score'}
                            />
                          </ScoreAverageContainer>
                        </ScoreAverageTableCell>
                      ))}
                    </TableRow>
                    {studentsList.map(
                      ({ first_name, id: studentId, last_name }) => (
                        <TableRow key={studentId}>
                          <TableCell className="name sticky">
                            <Typography variant="h6">
                              {getFormattedStudentName(first_name, last_name)}
                            </Typography>
                          </TableCell>
                          <TableCell className="cell">
                            <StyledIconWrapper>
                              <StatusIcon
                                isGroupedScore
                                isNotAvailable={
                                  completedProjectsScore[studentId] === null
                                }
                                score={completedProjectsScore[studentId]}
                                tooltipMsg={'Completed Projects'}
                              />
                            </StyledIconWrapper>
                          </TableCell>
                          <TableCell className="cell">
                            <StyledIconWrapper>
                              <StatusIcon
                                isGroupedScore
                                isNotAvailable={
                                  selectedProjectsScore[studentId] === null
                                }
                                score={selectedProjectsScore[studentId]}
                                tooltipMsg={'Selected Projects'}
                              />
                            </StyledIconWrapper>
                          </TableCell>
                          {projects.map(project => {
                            const projectId = project.id;

                            // A project is not scoreable if it has a zero
                            // weight (CoSpaces tutorial projects for example)
                            // in the course definition, or if it doesn't have,
                            // any criteria.
                            const isScoreable =
                              projectWeights[projectId] !== 0 &&
                              !!project?.scoring_criteria?.length;

                            // Show a N/A entry in the cell if the student has
                            // completed the project and it's not scoreable.
                            // Otherwise show the student's status icon.  Not
                            // always showing N/A regardless of a student's
                            // status allows a teacher to see a student's
                            // progress on a project as it transitions through
                            // the different statuses.
                            const control =
                              progresses[studentId]?.[projectId]?.control;
                            let status =
                              progresses[studentId]?.[projectId]?.status ??
                              'not_started';
                            if (
                              control === 'blocked' ||
                              control === 'excused'
                            ) {
                              status = control;
                            }

                            const isComplete =
                              status === 'grading' || status === 'completed';
                            const isNotAvailable = isComplete && !isScoreable;

                            return (
                              <TableCell
                                key={projectId}
                                className="cell"
                                align="center"
                              >
                                <StyledIconWrapper>
                                  <StatusIcon
                                    isNotAvailable={isNotAvailable}
                                    levelId={levelId}
                                    projectId={projectId}
                                    score={
                                      studentsScores[studentId]?.[projectId]
                                    }
                                    sectionId={sectionId}
                                    courseId={courseId}
                                    status={status}
                                    studentId={studentId}
                                  />
                                </StyledIconWrapper>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </StyledTableWrapper>
            {studentsList.length === 0 && (
              <StyledNoResults>
                <Typography variant="body1">No results</Typography>
              </StyledNoResults>
            )}
          </BodyContainer>
        </ResponsiveContainer>
      </Layout>
    </>
  );
};

export default Gradebook;
