import Dialog from '@material-ui/core/Dialog';
import { FrameCard } from '../../../components/Frames';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../../../components/Text';
import Typography from '@material-ui/core/Typography';
import { useRubricCategoryWeights } from '../../Project/hooks';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = withStyles({
  paper: {
    borderRadius: '10px',
    maxWidth: '992px',
    width: '50vw',
    minWidth: '400px',
  },
})(Dialog);

const FrameContainer = styled.div(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    border-radius: 10px;
    padding: ${theme.spacing(2)}px;
    position: relative; 
    width: 100%;
  `
);

const StyledOverlay = styled.div`
  background: black;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 200;
`;

//
// Rubric Summary
//

const RowGrid = styled(Grid)(
  ({ theme }) => `
    border-top: 4px solid rgba(0, 0, 0, 0.08);

    &:first-child {
      border-top: none;
      .category {
        // This removes the padding top for the first category div
        padding-top: 0;
      }
      
      .requirement:first-child > div{
        // This removes the padding top from the inner requirement items at the top
        padding-top: 0;
      }
    }

    &:last-child {
      .category {
        // This removes the padding bottom for the category div
        padding-bottom: 0;

        div{
          // This ensures the bottom left corner of the last row doesn't appear on
          // top of the frame's border.
          border-radius: 0 0 0 ${theme.spacing(1)}px;
        }
      }
      .requirement:last-child > div{
        // This removes the padding bottom from the inner requirement items at the bottom
        padding-bottom: 0;

        p {
          // This ensures the bottom right corner of the last row doesn't appear on
          // top of the frame's border.
          border-radius: 0 0 ${theme.spacing(1)}px 0;
        }
      }
    }
  `
);

const PaddedGrid = styled(Grid)(
  ({ $isFeatured, theme }) => `
    border-left: 4px solid rgba(0, 0, 0, 0.08);
    padding: ${theme.spacing(1)}px;
    z-index: ${$isFeatured ? '1000' : 'auto'};
    &.category {
      border-left: none;
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  `
);

const Category = styled.div(
  ({ theme }) => `
    align-items: center;
    background: ${theme.palette.background.level1};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
  `
);

const TitleTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    color: ${theme.palette.actions.main};
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    padding: ${theme.spacing(1)}px;
  `
);

const WeightTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.palette.background.level1};
    color: ${theme.palette.text.secondary};
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
  `
);

const RequirementsContainer = styled(Grid)(
  () => `
    display: flex;  
  `
);

const RequirementContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const RequirementTypography = styled(TextBlockTypography)(
  ({ $transparent, theme }) => `
    background: ${
      $transparent ? 'transparent' : theme.palette.background.level1
    };
    color: ${theme.palette.text.secondary};
    font-size: 16px;
    height: 100%;
    padding: ${theme.spacing(2)}px;
  `
);

const Row = React.forwardRef(
  ({ reqId, requirements, subtitle, title, weight }, ref) => {
    const unit = `point${weight !== 1 ? 's' : ''}`;

    // This will scroll the rubric to put the featured requirement
    // into view if needed
    React.useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ block: 'center' });
      }
    }, [ref]);

    return (
      <RowGrid container item xs={12}>
        <PaddedGrid className="category" container item xs={4}>
          <Category>
            <TitleTypography>{title}</TitleTypography>
            <WeightTypography>{weight && `${weight} ${unit}`}</WeightTypography>
          </Category>
        </PaddedGrid>
        <RequirementsContainer container item xs={8}>
          {subtitle && (
            <RequirementContainer>
              <PaddedGrid item xs={12}>
                <RequirementTypography
                  $transparent
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                />
              </PaddedGrid>
            </RequirementContainer>
          )}
          {requirements.map(req => (
            <RequirementContainer className="requirement" key={req.id}>
              <PaddedGrid
                item
                xs={12}
                $isFeatured={reqId === req.id}
                ref={reqId === req.id ? ref : null}
              >
                <RequirementTypography
                  dangerouslySetInnerHTML={{ __html: req.text }}
                />
              </PaddedGrid>
            </RequirementContainer>
          ))}
        </RequirementsContainer>
      </RowGrid>
    );
  }
);

Row.displayName = 'Row';

Row.propTypes = {
  reqId: PropTypes.string,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  weight: PropTypes.number,
};

export const RubricModal = ({ isOpen, onClose, reqId, rubric }) => {
  const weights = useRubricCategoryWeights();
  const featuredReqRef = React.useRef(null);

  // Filter the categories to show only the one
  // where the feature requirement is
  const filterCategories = cat =>
    reqId ? cat.requirements.some(r => r.id === reqId) : cat;

  return (
    <StyledDialog open={isOpen} onClose={() => onClose()}>
      <FrameContainer>
        <FrameCard title={'Scoring Rubric'} padding={{ top: 3, bottom: 0 }}>
          <Grid container>
            {rubric.filter(filterCategories).map((category, index) => (
              <Row
                key={index}
                reqId={reqId}
                ref={featuredReqRef}
                requirements={category.requirements}
                subtitle={category?.subtitle}
                title={category.title}
                weight={weights?.[category.type]}
              />
            ))}
          </Grid>
        </FrameCard>
        {reqId && <StyledOverlay />}
      </FrameContainer>
    </StyledDialog>
  );
};

RubricModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  reqId: PropTypes.string,
  rubric: PropTypes.array,
};
