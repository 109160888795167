import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)(
  ({ size }) => `
    color: transparent;
    height: ${size}px;
    width: ${size}px;
  `
);

const ClockGradient = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 36 36"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 35C27.3888 35 35 27.3888 35 18C35 8.61116 27.3888 1 18 1C8.61116 1 1 8.61116 1 18C1 27.3888 8.61116 35 18 35Z"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
    />
    <path
      d="M18.5 9H16V20.1475L24.75 26L26 23.7148L18.5 18.7541V9Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="18"
        y1="2"
        x2="18"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0B0F9" />
        <stop offset="1" stopColor="#5C71F2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="21"
        y1="9"
        x2="21"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B0B0F9" />
        <stop offset="1" stopColor="#5C71F2" />
      </linearGradient>
    </defs>
  </StyledSvgIcon>
));

ClockGradient.displayName = 'ClockGradient';

ClockGradient.propTypes = {
  size: PropTypes.number,
};

export default ClockGradient;
