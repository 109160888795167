import {
  CriteriaContainer,
  MultipleChoiceComponentContainer,
  QuestionComponentContainer,
  RubricComponentContainer,
} from './components/criteria';
import {
  MultipleChoiceComponentIndexProvider,
  useMultipleChoiceComponentIndex,
} from '../../../contexts/MultipleChoiceComponentIndexContext';
import { useCriteriaComponent, useCriteriaScore } from './hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { useProgress } from '../contexts';
import { useRubricCriteriaComponentRequirements } from '../../../hooks/useRubricCriteriaComponentRequirements';

export const Criteria = ({ criteria, expanded, onClick }) => {
  const score = useCriteriaScore(criteria);
  const { progress } = useProgress();
  const isEditable = progress?.status === 'grading';

  return (
    <CriteriaContainer
      title={criteria.title}
      score={score}
      expanded={expanded}
      onClick={onClick}
    >
      <MultipleChoiceComponentIndexProvider criteria={criteria}>
        {criteria.components.map(component => {
          switch (component.type) {
            case 'question':
              return (
                <QuestionComponent
                  key={component.id}
                  component={component}
                  isEditable={isEditable}
                />
              );
            case 'checkpoint':
              if (component?.variant === 'rubric') {
                return (
                  <RubricComponent
                    key={component.id}
                    component={component}
                    isEditable={isEditable}
                  />
                );
              } else {
                return (
                  <MultipleChoiceCheckpointComponent
                    key={component.id}
                    component={component}
                  />
                );
              }
            default:
              return <div>Unrecognized component type: {component.type}</div>;
          }
        })}
      </MultipleChoiceComponentIndexProvider>
    </CriteriaContainer>
  );
};
Criteria.propTypes = {
  criteria: PropTypes.shape({
    title: PropTypes.string,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        variant: PropTypes.string,
      })
    ),
  }),
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};

const QuestionComponent = ({ component, isEditable }) => {
  const { value, setValue, isUpdating } = useCriteriaComponent(component.id);
  const onChange = React.useCallback(() => setValue(!value), [value, setValue]);

  return (
    <QuestionComponentContainer
      question={component.question}
      value={value}
      onChange={onChange}
      isEditable={isEditable && !isUpdating}
    />
  );
};
QuestionComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    question: PropTypes.string,
    weight: PropTypes.number,
  }),
  isEditable: PropTypes.bool,
};

const MultipleChoiceCheckpointComponent = ({ component }) => {
  const index = useMultipleChoiceComponentIndex(component.id);
  const { value } = useCriteriaComponent(component.id);

  return <MultipleChoiceComponentContainer index={index} value={value} />;
};
MultipleChoiceCheckpointComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    weight: PropTypes.number,
  }),
};

const RubricComponent = ({ component, isEditable }) => {
  const { value, setValue, isUpdating } = useCriteriaComponent(component.id);
  const requirements = useRubricCriteriaComponentRequirements(component);

  const onChange = React.useCallback(
    requirement => {
      const newValue = !value?.[requirement.id];
      setValue({
        ...value,
        [requirement.id]: newValue,
      });
    },
    [value, setValue]
  );

  return (
    <RubricComponentContainer
      requirements={requirements}
      value={value}
      onChange={onChange}
      isEditable={isEditable && !isUpdating}
    />
  );
};
RubricComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    weight: PropTypes.number,
  }),
  isEditable: PropTypes.bool,
};
