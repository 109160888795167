import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const Minus = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 24 24"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="Minus"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <path
      d="M6.146 12.3232H18.5"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="1.5"
    />
    <path
      d="M6.146 12.3232H18.5"
      stroke="white"
      strokeLinecap="round"
      strokeWidth="1.5"
    />
  </StyledSvgIcon>
));

Minus.displayName = 'Minus';
Minus.propTypes = {
  size: PropTypes.number,
};

export default Minus;
