import CollegeBoardIcon from '../icons/CollegeBoardIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextBlockTypography from '../Text';

const StyledLabelBlock = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: #e9ecff;
    border-left-color: ${theme.palette.primary.main};
    border-left-style: solid;
    border-left-width: 4px;
    display: flex; 
    height: ${theme.spacing(3)}px;
    padding: ${theme.spacing(0, 1)};
    width: 100%;
    svg {
      margin-right: ${theme.spacing(1)}px;
    }
    h6 {
      font-weight: 400;
      line-height: ${theme.spacing(3)}px;
    }
    &.exemplar {
      background-color: #e4fcfc;
      border-left-color: #66d8e1;
    }
  `
);

export const LabelBlockContainer = ({ text, variant }) => (
  <StyledLabelBlock className={variant ?? ''}>
    {variant === 'college-board' && <CollegeBoardIcon />}
    <TextBlockTypography variant="h6">{text}</TextBlockTypography>
  </StyledLabelBlock>
);

LabelBlockContainer.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
};

export default LabelBlockContainer;
