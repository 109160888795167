import { BackCaret, Checkmark } from '../icons';
import Button from '@material-ui/core/Button';
import { CodeOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Resizable } from 're-resizable';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { ToggleButton } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

//
// Layout Components
//

export const Layout = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    height: calc(100vh - ${theme.spacing(8)}px);
    overflow: hidden;
    position: relative;
  `
);

Layout.displayName = 'Layout';

export const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: black;
  width: 100vw;
  overflow: hidden;
  transition: all 0.1s;
`;

ResponsiveContainer.displayName = 'ResponsiveContainer';

export const BodyContainer = styled.div(
  ({ isActivityShown, noProgressBar, theme }) => `
    background: ${theme.palette.background.level3};
    color: ${theme.palette.text.secondary};
    display: flex;
    flex-direction: ${isActivityShown ? 'row' : 'column'};
    height: calc(100vh - ${noProgressBar ? 0 : theme.spacing(16)}px);
    overflow: auto;

    .resizable {
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      max-height: 100%;
    }
  `
);

BodyContainer.displayName = 'BodyContainer';

//
// Step Header
//

const StepHeaderContainer = styled.div(
  ({ theme }) => `
    align-self: stretch;
    background: ${theme.palette.primary.gradient};
    color: ${theme.palette.text.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing(2, 4)};
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  `
);

const StepHeaderSectionTitle = styled(Typography)(
  ({ theme }) => `
    font-size: 14px;
    padding-bottom: ${theme.spacing(1)}px;
    text-transform: uppercase;
  `
);

const StepHeaderSubtitleContainer = styled.div(
  () => `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    .spacing {
      flex-shrink: 0;
      height: 24px;
      width: 0px;
    }
    h3 {
      font-size: 20px;
      font-weight: 800;
      margin-right: 50px;
    }
  `
);

const StyledSwitch = styled(Switch)(
  () => `
    bottom: -8px;
    position: absolute;
    right: -12px;
  `
);

export const StepHeader = ({
  section,
  step,
  switchChecked,
  onSwitchChange,
  showSwitch,
  showCodeToggle,
}) => {
  return (
    <StepHeaderContainer>
      {showCodeToggle?.toggleEnabled && (
        <Tooltip title={showCodeToggle.showCode ? 'Hide Code' : 'Show Code'}>
          <StyledToggleButton
            selected={showCodeToggle.showCode}
            onClick={() => {
              showCodeToggle.setShowCode(!showCodeToggle.showCode);
            }}
          >
            <CodeOutlined />
          </StyledToggleButton>
        </Tooltip>
      )}

      <StepHeaderSectionTitle variant="subtitle2">
        {section}
      </StepHeaderSectionTitle>
      <StepHeaderSubtitleContainer>
        <Typography variant="h3">{step}</Typography>
        {showSwitch && (
          <>
            <div className="spacing" />
            <StyledSwitch
              color="primary"
              checked={switchChecked}
              name="checkedA"
              onChange={onSwitchChange}
            />
          </>
        )}
      </StepHeaderSubtitleContainer>
    </StepHeaderContainer>
  );
};

StepHeader.propTypes = {
  section: PropTypes.string,
  step: PropTypes.string,
  switchChecked: PropTypes.bool,
  showSwitch: PropTypes.bool,
  onSwitchChange: PropTypes.func,
  showCodeToggle: PropTypes.shape({
    showCode: PropTypes.bool,
    setShowCode: PropTypes.func,
    toggleEnabled: PropTypes.bool,
  }),
};

//
// Step nav
//

const StepNavContainer = styled.div(
  ({ hasPrev, theme }) => `
    height: ${theme.spacing(8)}px;
    padding: ${theme.spacing(0, 4)};
    width: 100%;
    background-color: ${theme.palette.background.paper};
    display: flex;
    justify-content: ${hasPrev ? 'space-between' : 'flex-end'};
    align-items: center;
    
    #back {
      flex-shrink: 0;
      
      &.Mui-disabled {
        opacity: 30%;
      }

      .MuiSvgIcon-root {
        fill: ${theme.palette.text.primary};
      }
      
      .label {
        margin-left: ${theme.spacing(1)}px;
      }
      
      :hover {
        background-color: ${theme.palette.background.level1};
        color: ${theme.palette.primary.contrastText};
        
        .MuiSvgIcon-root {
          fill: ${theme.palette.primary.contrastText};
        }
      }
    }
    
    #next {
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.text.primary};
      flex-shrink: 0;
      
      &.Mui-disabled {
        opacity: 30%;
      }
      
      .MuiButton-label {
        gap: ${theme.spacing(1)}px;
      }
      
      .caret.MuiSvgIcon-root {
        transform: rotate(180deg);
      }
      
      :hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;   
      }
    }
    
    #progress {
      flex-shrink: 1;
      height: 100%;
      padding: ${theme.spacing(1, 6)};
      width: 100%;
      
      .title {
        font-family: 'IBM Plex Mono', monospace;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: ${theme.spacing(0.5)}px;
        text-align: center;
        text-transform: uppercase;
      }
      
      .indicator {
        display: flex;
        align-items: center;
        
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 100px;
        height: ${theme.spacing(2)}px;
        padding: ${theme.spacing(0.5)}px;
        width: 100%;
        
        .bar {
          background-color: #66d8e1;
          border-radius: 100px;
          height: ${theme.spacing(1)}px;
          transition: width 0.35s;
        }
      }
    }
  `
);

export const StepNav = ({
  prev,
  next,
  submit,
  summary,
  hasProgress,
  label,
  numSteps,
  numStepsComplete,
  scrollToTop,
}) => {
  const handleNavigate = fn => {
    // Scroll instructional area to top of step
    // when navigating forward or backward
    if (scrollToTop) {
      scrollToTop();
    }
    fn();
  };

  const percent = Math.floor(
    100 * (numSteps > 0 ? numStepsComplete / numSteps : 0)
  );

  return (
    <StepNavContainer hasPrev={prev.show}>
      {prev?.show && (
        <Button
          id="back"
          disabled={!prev.enabled}
          variant="outlined"
          onClick={() => handleNavigate(prev.onClick)}
        >
          <BackCaret className="caret" size={16} />
          <span className="label">{prev.label ? prev.label : 'Back'}</span>
        </Button>
      )}
      {hasProgress && (
        <div id="progress">
          <div className="title">{`${
            label || 'Project Progress'
          } - ${percent}%`}</div>
          <div className="indicator">
            <div className="bar" style={{ width: `${percent}%` }} />
          </div>
        </div>
      )}
      {next?.show && (
        <Button
          id="next"
          disabled={!next.enabled}
          variant="contained"
          onClick={() => handleNavigate(next.onClick)}
        >
          <span className="label">{next.label ? next.label : 'Next'}</span>
          <BackCaret className="caret" size={16} />
        </Button>
      )}
      {submit?.show && (
        <Button
          id="next"
          disabled={!submit.enabled}
          variant="contained"
          onClick={submit.onClick}
        >
          <span className="label">Submit</span>
          <BackCaret className="caret" size={16} />
        </Button>
      )}
      {summary?.show && (
        <Button
          id="next"
          disabled={!summary.enabled}
          variant="contained"
          onClick={summary.onClick}
        >
          <span className="label">{summary?.label ?? 'Summary'}</span>
          {summary?.icon === 'checkmark' && (
            <Checkmark className="checkmark" size={24} />
          )}
        </Button>
      )}
    </StepNavContainer>
  );
};
StepNav.propTypes = {
  prev: PropTypes.shape({
    show: PropTypes.bool,
    enabled: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
  next: PropTypes.shape({
    show: PropTypes.bool,
    enabled: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
  }),
  submit: PropTypes.shape({
    show: PropTypes.bool,
    enabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  summary: PropTypes.shape({
    show: PropTypes.bool,
    enabled: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
  }),
  hasProgress: PropTypes.bool,
  label: PropTypes.string,
  numSteps: PropTypes.number,
  numStepsComplete: PropTypes.number,
  scrollToTop: PropTypes.func,
};

const CollapseHandle = styled.div(
  ({ $isAreaHidden, theme }) => `
    align-items: center;
    backdrop-filter: blur(8px);
    background: rgba(77, 102, 255, 0.5);
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    display: flex;
    height: ${theme.spacing(6)}px;
    justify-content: center;
    opacity: ${$isAreaHidden ? 1 : 0};
    position: absolute;
    right: ${$isAreaHidden ? '-24px' : '-18px'};
    top: calc(50% + ${theme.spacing(4)}px);
    transform: translateY(${$isAreaHidden ? 'calc(-50% - 56px)' : '-50%'});
    transition: all 100ms;
    width: 24px;
    z-index: 100;
  
    :hover {
      background-color: ${theme.palette.primary.main};
    }
  
    .backCaret {
      transform: rotate(${$isAreaHidden ? '180deg' : 0})
    }
  `
);

const SectionGroupWrapper = styled.div(
  () => `
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    width: 100%;
  `
);

const ResizableWrapper = styled(Resizable)(
  () => `
    transition: width 100ms;
    &:hover ${CollapseHandle} {
      opacity: 1;
    }
  `
);

const StyledToggleButton = styled(ToggleButton)(
  () => `
    position: absolute; 
    top: 38px; 
    right: 35px;
    color: rgb(77, 77, 77);;
    background: white;
    padding: 4px;
    z-index: 100;
  `
);

export const SectionGroupContainer = React.forwardRef(
  ({ isActivityShown, children }, ref) => {
    const [isAreaHidden, setIsAreaHidden] = React.useState(false);

    // Managing state for the step area width
    const [width, setWidth] = React.useState(392);

    const getSize = () => {
      // When we don't have an activity, force the size of the step.
      // Otherwise hide the area or use the current size
      // of the resizable from the state.
      if (!isActivityShown) {
        return { width: '100%' };
      }
      return { width: isAreaHidden ? 0 : width };
    };

    const handleCollapseClick = () => setIsAreaHidden(!isAreaHidden);

    return (
      <ResizableWrapper
        // Only support resizing when there's an activity.
        className="resizable"
        defaultSize={{ width: '392px' }}
        enable={{
          top: false,
          right: isActivityShown && !isAreaHidden,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        handleStyles={{
          right: {
            display: !isActivityShown || isAreaHidden ? 'none' : 'inherit',
            width: '6px',
            position: 'relative',
            background: '#6b6b6b',
            right: '0',
          },
        }}
        maxWidth="100%"
        onResizeStop={(...[, , , d]) => setWidth(w => w + d.width)}
        size={getSize()}
      >
        {isActivityShown && (
          <CollapseHandle
            $isAreaHidden={isAreaHidden}
            onClick={handleCollapseClick}
          >
            <BackCaret className="backCaret" fill="white" />
          </CollapseHandle>
        )}
        <SectionGroupWrapper ref={ref}>{children}</SectionGroupWrapper>
      </ResizableWrapper>
    );
  }
);

SectionGroupContainer.displayName = 'SectionGroupContainer';

SectionGroupContainer.propTypes = {
  children: PropTypes.any,
  isActivityShown: PropTypes.bool,
};

export const StepContainer = styled.div`
  width: 100%;
`;

export const StepContent = styled.div(
  ({ $hasActivity, theme }) => `
    margin: 0 auto;
    width: ${$hasActivity ? '90%' : '75%'};
    max-width: ${theme.spacing(180)}px;
  `
);
