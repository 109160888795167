import NavMapItem from '../NavMapItem';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const GroupLabel = styled(Typography)(
  ({ theme }) => `
    font-size: 13px;
    font-weight: 500;
    padding: ${theme.spacing(0, 1, 0, 3)};
    user-select: none;
  `
);

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ theme }) => theme.spacing(7)}px;
`;

const ItemInfo = styled.div(
  ({ $isCurrent, theme }) => `
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    background-color: ${$isCurrent ? theme.palette.primary.main + '25' : ''};
    margin: ${theme.spacing(0, 1)};
  `
);

const NavMapGroup = ({ group, handleItemClick, mode }) => {
  return (
    <ItemInfo $isCurrent={group.isCurrent}>
      <GroupContainer>
        <GroupLabel key={group.id} variant="h6">
          {group.title}
        </GroupLabel>
      </GroupContainer>
      {group.items.map(item => (
        <NavMapItem
          inCurrentGroup={group.isCurrent}
          handleItemClick={handleItemClick}
          sectionId={group.id}
          item={item}
          key={item.id}
          mode={mode}
        />
      ))}
    </ItemInfo>
  );
};

NavMapGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array,
    isCurrent: PropTypes.bool,
  }),
  handleItemClick: PropTypes.func,
  mode: PropTypes.string,
};

export default NavMapGroup;
