import {
  Feedback,
  FeedbackBody,
  FeedbackButton,
  FeedbackFrame,
  FeedbackHeader,
  FeedbackRatingQuestion,
  FeedbackShortAnswerQuestion,
} from './components/feedback';
import PropTypes from 'prop-types';
import React from 'react';
import { useFeedback } from './hooks';
import { useHistory } from 'react-router-dom';
import { useProject } from '../../contexts/ProjectContext';

const Question = ({ question, value, onChange }) => {
  switch (question.type) {
    case 'rating': {
      return (
        <FeedbackRatingQuestion
          question={question}
          value={value}
          onChange={value => onChange(value, true)}
        />
      );
    }

    case 'short_answer': {
      return (
        <FeedbackShortAnswerQuestion
          question={question}
          value={value}
          onChange={value => onChange(value, value.length > 0)}
        />
      );
    }

    default:
      throw new Error(`Unrecognized feedback question type: ${question.type}`);
  }
};
Question.propTypes = {
  questions: PropTypes.shape({
    type: PropTypes.oneOf(['rating', 'short_answer']),
  }),
  value: PropTypes.oneOfType([
    // type === rating
    PropTypes.shape({
      rating: PropTypes.number,
    }),

    // type === short_answer
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
};

export const FeedbackModal = ({ close }) => {
  const history = useHistory();
  const course = history?.location?.state?.courseName;
  const level = history?.location?.state?.levelName;

  const project = useProject();

  // The in-memory state of the feedback object we'll write to the student's
  // progress object once we decide to save it.
  const [memory, setMemory] = React.useState({ status: 'not_started' });

  // Whether or not we've collected answers for all required questions.  This
  // controls whether or not we're allowed to save.
  const [isComplete, setComplete] = React.useState(false);
  React.useEffect(
    () =>
      setComplete(
        project.feedback.questions
          .filter(question => question.required)
          .map(question => memory?.questions?.[question.id]?.complete)
          .every(complete => complete)
      ),
    [project, memory]
  );

  const { save } = useFeedback();
  const saveAndClose = React.useCallback(() => {
    save({
      ...memory,

      // The save button is only enabled when the feedback is complete.
      status: 'completed',
    });
    close();
  }, [close, memory, save]);

  return (
    <Feedback>
      <FeedbackHeader
        course={course}
        level={level}
        project={project.title}
        media={project?.media}
      />
      <FeedbackBody>
        <FeedbackFrame title={project.feedback.title}>
          {project.feedback.questions.map(question => {
            // When this question's value changes propagate the new value into
            // the in-memory state of the feedback object.
            const onChange = (newValue, isComplete) =>
              setMemory(oldMemory => {
                return {
                  ...oldMemory,
                  questions: {
                    ...oldMemory?.questions,
                    [question.id]: {
                      value: newValue,
                      complete: isComplete,
                    },
                  },
                };
              });

            return (
              <Question
                key={question.id}
                question={question}
                value={memory?.questions?.[question.id]?.value}
                onChange={onChange}
              />
            );
          })}
        </FeedbackFrame>
        <FeedbackButton disabled={!isComplete} onClick={saveAndClose} />
      </FeedbackBody>
    </Feedback>
  );
};

FeedbackModal.propTypes = {
  close: PropTypes.func,
};
