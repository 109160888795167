import { Card, CardActionArea, Grid, Typography } from '@material-ui/core';
import Chip from '../../../components/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const HeadingStyles = styled.div(
  ({ theme }) => `
    margin: ${theme.spacing(4, 7, 0, 7)};
  `
);

export const Heading = ({ children }) => {
  return (
    <HeadingStyles>
      <Typography paragraph variant="h3">
        {children}
      </Typography>
    </HeadingStyles>
  );
};
Heading.propTypes = {
  children: PropTypes.node,
};

const CoursesGridStyles = styled.div(
  ({ theme }) => `
    .MuiGrid-container {
      padding: ${theme.spacing(0, 6, 4, 6)};
    }

    .MuiGrid-item {
      background-color: ${theme.palette.background.paper}
      cursor: pointer;
      margin: ${theme.spacing(3, 3, 1, 1)};
      width: ${theme.spacing(33)}px;
      height: ${theme.spacing(42)}px;
    }
    
    .MuiCardActionArea-root {
      box-shadow: 0 0px 32px 0 rgba(0, 0, 0, 0.7);
      height: 100%;
      width: 100%;
    }
    
    .MuiCard-root {
      height: 100%;
      width: 100%;
      
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)}px;      
      
      img {
        height: ${theme.spacing(24)}px;
      }
      
      .title {
        height: ${theme.spacing(7)}px;
        padding: ${theme.spacing(0, 2)};
        
        color: ${theme.palette.text.primary};
        font-family: 'Poppins', sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.235;
        opacity: 0.9;
      }

      .chips {
        height: ${theme.spacing(3.5)}px;
        padding: ${theme.spacing(0, 2)};

        display: flex;
        gap: ${theme.spacing(1)}px;
      }
    }
  `
);

const CourseCard = ({ course, onClick }) => {
  return (
    <CardActionArea onClick={onClick}>
      <Card>
        <img src={course.media.url} alt={`Image for course ${course.title}`} />
        <div className="title">{course.title}</div>
        <div className="chips">
          {course.chips?.map(chip => (
            <Chip key={chip} text={chip} />
          ))}
        </div>
      </Card>
    </CardActionArea>
  );
};
CourseCard.propTypes = {
  course: PropTypes.shape({
    title: PropTypes.string,
    chips: PropTypes.arrayOf(PropTypes.string),
    media: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
};

export const CoursesGrid = ({ courses, onClick }) => {
  return (
    <CoursesGridStyles>
      <Grid container>
        {courses.map(course => (
          <Grid item key={course.id}>
            <CourseCard course={course} onClick={() => onClick(course)} />
          </Grid>
        ))}
      </Grid>
    </CoursesGridStyles>
  );
};
CoursesGrid.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};
