import PropTypes from 'prop-types';
import React from 'react';
import ScoreSummary from '../../../components/ScoreSummary';
import { useProgress } from '../contexts';
import { useScoringSummary } from '../../../hooks/useScoringSummary';

export const ScoringSummary = ({ adjustment, onChangeAdjustment }) => {
  const { progress } = useProgress();
  const summary = useScoringSummary(progress, adjustment);

  return (
    <ScoreSummary
      criteria={summary.criteria}
      scores={summary.scores}
      adjustment={adjustment}
      total={summary.total}
      isOutOfBounds={summary.isOutOfBounds}
      isEditable={progress?.status === 'grading'}
      onChangeAdjustment={onChangeAdjustment}
    />
  );
};
ScoringSummary.propTypes = {
  adjustment: PropTypes.number,
  onChangeAdjustment: PropTypes.func,
};
