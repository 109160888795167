import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import { Pagination } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const StyledCheckpointSet = styled.div(
  () => `
    overflow-y: auto;
    width: 100%;
  `
);

const StyledPaginationBar = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.palette.background.level1};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    user-select: none;

    .MuiPagination-root li {
      align-items: center;
      display: flex;
      height: ${theme.spacing(5)}px;
    }
    .MuiPaginationItem-root {
      color: ${theme.palette.text.secondary};
      font-size: 14px;
      &.Mui-selected {
        background-color: #e1e1e1;
      }
      &.MuiPaginationItem-page:hover {
        background-color: #7d7d7d;
        color: ${theme.palette.text.primary};
      }
    }
  `
);

const StyledGoToInput = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    height: ${theme.spacing(5)}px;
    margin-left: ${theme.spacing(3)}px;
    margin-right: ${theme.spacing(1)}px;

    .MuiTypography-root {
      font-size: 14px;
      margin-right: ${theme.spacing(1)}px;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }
    input {
      border-radius: 2px;
      border: 1px solid #7d7d7d;
      color: ${theme.palette.text.secondary};
      font-size: 14px;
      height: ${theme.spacing(3)}px;
      max-width: 32px;
      padding: 0;
      text-align: center;
    }
    button {
      background-color: #7d7d7d;
      height: ${theme.spacing(3)}px;
      margin-left: ${theme.spacing(1)}px;
      min-width: ${theme.spacing(4)}px;
      padding: 0;
      text-align: center;
      :hover {
        background-color: ${theme.palette.text.secondary};
      }
    }
  `
);

const StyledContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    max-width: ${theme.spacing(180)}px;
    padding: ${theme.spacing(0, 2)};

    button.nav {
      background-color: ${theme.palette.background.level1};
      color: #7b7b7b;
      padding: 6px;

      &.Mui-disabled {
        visibility: hidden;
      }
    }
  `
);

const StyledCheckpointWrapper = styled.div(
  ({ theme }) => `
    flex-grow: 1;
    padding: 0 ${theme.spacing(2)}px;
  `
);

export const CheckpointSet = ({
  checkpoints,
  children,
  current,
  jump,
  next,
  prev,
}) => {
  const [goToInput, setGoToInput] = React.useState('');

  // Get the current page number from the current checkpoint id
  const page = checkpoints.findIndex(c => c.id === current) + 1;

  const handlePaginationChange = (_, value) => {
    jump(checkpoints[value - 1].id);
  };

  // Just numbers up to 2 digits and empty string
  const REGEXP_NUMBERS = /^$|^[0-9]{1,2}$/;

  const handleGoToPageClick = () => {
    const destPage = checkpoints[goToInput - 1]?.id;
    if (!destPage) {
      setGoToInput('');
      return;
    }
    jump(destPage);
  };

  const handleGoToPageChange = e => {
    const { value } = e.target;
    if (!REGEXP_NUMBERS.test(value)) {
      return;
    }
    setGoToInput(value);
  };

  const handleGoToPageEnter = e => {
    if (e.key === 'Enter') {
      handleGoToPageClick();
    }
  };

  return (
    <StyledCheckpointSet>
      <StyledPaginationBar>
        <Pagination
          count={checkpoints.length}
          onChange={handlePaginationChange}
          page={page}
          size="small"
        />
        <StyledGoToInput>
          <Typography variant="caption">go to question:</Typography>
          <TextField
            onChange={handleGoToPageChange}
            onKeyDown={handleGoToPageEnter}
            value={goToInput}
            variant="outlined"
          />
          <Button onClick={handleGoToPageClick}>GO</Button>
        </StyledGoToInput>
      </StyledPaginationBar>
      <StyledContainer className="activity-container">
        <IconButton className="nav" disabled={page === 1} onClick={prev}>
          <ChevronLeftIcon />
        </IconButton>
        <StyledCheckpointWrapper>{children}</StyledCheckpointWrapper>
        <IconButton
          className="nav"
          disabled={page === checkpoints.length}
          onClick={next}
        >
          <ChevronRightIcon />
        </IconButton>
      </StyledContainer>
    </StyledCheckpointSet>
  );
};

CheckpointSet.propTypes = {
  checkpoints: PropTypes.arrayOf(
    PropTypes.shape({
      complete: PropTypes.bool,
      id: PropTypes.string,
    })
  ),
  children: PropTypes.node,
  current: PropTypes.string,
  jump: PropTypes.func,
  next: PropTypes.func,
  prev: PropTypes.func,
};
