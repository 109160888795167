import PropTypes from 'prop-types';
import React from 'react';

//
// Project Context
//

const ProjectContext = React.createContext(undefined);

export const useProject = () => {
  const context = React.useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProject must be used within a ProjectProvider');
  }
  return context;
};

/**
 * A ProjectProvider provides the means to read the current project.  It does
 * not provide the ability to update the project in any way.
 */
export const ProjectProvider = ({ project, children }) => {
  return (
    <ProjectContext.Provider value={project}>
      {children}
    </ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  project: PropTypes.object,
  children: PropTypes.node,
};
