import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)``;

const BackArrow = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 16 16"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="BackArrow"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <path
      className="a"
      d="M8,0,6.545,1.455l5.506,5.506H0V9.039H12.052L6.545,14.545,8,16l8-8Z"
      transform="translate(16 16) rotate(180)"
    />
  </StyledSvgIcon>
));

BackArrow.displayName = 'BackArrow';

BackArrow.propTypes = {
  size: PropTypes.number,
};

export default BackArrow;
