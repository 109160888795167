import { Redirect, Route, Switch } from 'react-router-dom';
import App from '../../pages/App';
import CoSpacesMigration from '../../pages/CoSpacesMigration';
import Course from '../../pages/Course';
import Courses from '../../pages/Courses';
import Error from '../../pages/Error';
import ExternalAccountLogin from '../../pages/ExternalAccountLogin';
import HourOfCode from '../../pages/HourOfCode';
import Login from '../../pages/Login';
import LoginSection from '../../pages/LoginSection';
import LoginUser from '../../pages/LoginUser';
import { PrintRubric } from '../../pages/Project/Print';
import Profile from '../../pages/Profile';
import Project from '../../pages/Project';
import PropTypes from 'prop-types';
import React from 'react';
import Teacher from '../../pages/Teacher';
import TeacherDashboardControls from '../../pages/Teacher/Dashboards/Controls';
import TeacherDashboardGradebook from '../../pages/Teacher/Dashboards/Gradebook';
import TeacherDashboardPasswordImages from '../../pages/Teacher/Dashboards/PasswordImages';
import TeacherScoring from '../../pages/Teacher/Scoring';
import TeacherSections from '../../pages/Teacher/Sections';
import { useUser } from '../../contexts/AuthContext';

const ProtectedRoute = ({ component: Component, checks, ...other }) => {
  return (
    <Route
      {...other}
      render={renderProps => {
        const params = renderProps?.match?.params;
        const { isAllowed, redirect } = checks.reduce(
          (previous, check) => (!previous.isAllowed ? previous : check(params)),
          { isAllowed: true }
        );

        if (isAllowed) {
          return <Component {...renderProps} />;
        }

        return <Redirect to={redirect} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  checks: PropTypes.arrayOf(PropTypes.func),
};

const Routes = () => {
  const user = useUser();

  // These are the different checks we may do in order to determine when
  // a user is allowed to enter a route.  If the user isn't allowed to
  // enter a route then a redirect URL will also be returned.

  const isLoggedIn = () => {
    return {
      isAllowed: user !== null,
      redirect: '/login',
    };
  };

  const isTeacherForAnySection = () => {
    return {
      isAllowed: user !== null && user.isTeacherForAnySections(),
      redirect: '/courses',
    };
  };

  const isTeacherForSectionId = ({ sectionId }) => {
    return {
      isAllowed: user !== null && user.isTeacherForSection(sectionId),
      redirect: '/teacher/sections',
    };
  };

  return (
    <Switch>
      <ProtectedRoute
        component={TeacherSections}
        checks={[isLoggedIn, isTeacherForAnySection]}
        path="/teacher/sections"
      />
      <ProtectedRoute
        component={TeacherScoring}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/scoring/section/:sectionId/project/:projectId"
      />
      <ProtectedRoute
        component={TeacherScoring}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/scoring/section/:sectionId/course/:courseId/project/:projectId"
      />
      <ProtectedRoute
        component={TeacherDashboardGradebook}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/gradebook/section/:sectionId/course/:courseId/level/:levelId"
      />
      <ProtectedRoute
        component={TeacherDashboardGradebook}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/gradebook/section/:sectionId/course/:courseId"
      />
      <ProtectedRoute
        component={TeacherDashboardGradebook}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/gradebook/section/:sectionId/level/:levelId"
      />
      <ProtectedRoute
        component={TeacherDashboardGradebook}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/gradebook/section/:sectionId"
      />
      <ProtectedRoute
        component={TeacherDashboardControls}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/controls/section/:sectionId/course/:courseId/level/:levelId"
      />
      <ProtectedRoute
        component={TeacherDashboardControls}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/controls/section/:sectionId/course/:courseId"
      />
      <ProtectedRoute
        component={TeacherDashboardControls}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/controls/section/:sectionId/level/:levelId"
      />
      <ProtectedRoute
        component={TeacherDashboardControls}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/controls/section/:sectionId"
      />
      <ProtectedRoute
        component={TeacherDashboardPasswordImages}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher/dashboards/password-images/section/:sectionId"
      />
      <ProtectedRoute
        component={Teacher}
        checks={[isLoggedIn, isTeacherForAnySection, isTeacherForSectionId]}
        path="/teacher"
      />
      <ProtectedRoute
        component={Course}
        checks={[isLoggedIn]} // TODO: In the future check enrollment?
        path={[
          '/section/:sectionId/course/:courseId/level/:levelId',
          '/section/:sectionId/course/:courseId',
          '/course/:courseId/level/:levelId',
          '/course/:courseId',
        ]}
      />
      <ProtectedRoute
        component={CoSpacesMigration}
        checks={[isLoggedIn]}
        path="/migrations/cospaces"
      />
      <ProtectedRoute
        component={Courses}
        checks={[isLoggedIn]}
        path="/courses"
      />
      <ProtectedRoute
        component={ExternalAccountLogin}
        checks={[isLoggedIn]}
        path="/account-login"
      />
      <ProtectedRoute
        component={Profile}
        exact
        checks={[isLoggedIn]}
        path="/profile"
      />
      <ProtectedRoute
        component={PrintRubric}
        checks={[isLoggedIn]}
        path="/project/:projectId/rubric"
      />
      <ProtectedRoute
        component={Project}
        checks={[isLoggedIn]} // TODO: In the future check enrollment?
        path={['/project/:projectId', '/section/:sectionId/project/:projectId']}
      />
      <Route component={App} exact path="/" />
      <Route component={HourOfCode} path="/login/hour-of-code/:projectId" />
      <Route component={HourOfCode} path="/login/hour-of-code" />
      <Route component={LoginSection} path="/login/section/:sectionCode" />
      <Route component={LoginUser} path="/login/user/:userId" />
      <Route component={Login} path="/login" />
      <Route component={Error} path="/error" />
      <Route render={() => <Redirect to="/error" />} />
    </Switch>
  );
};

export default Routes;
