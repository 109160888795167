// These are the submission groups in the order they should appear.
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

const SUBMISSION_GROUPS = [
  { id: 'sg_scored', title: 'Scored' },
  { id: 'sg_submitted', title: 'Submitted' },
  { id: 'sg_sent_back', title: 'Sent Back' },
  { id: 'sg_in_progress', title: 'In Progress' },
  { id: 'sg_not_started', title: 'Not Started' },
  { id: 'sg_excused', title: 'Excused' },
  { id: 'sg_blocked', title: 'Blocked' },
];

// This is the mapping of project status to submission group id.
const PROJECT_STATUS_TO_SUBMISSION_GROUP_ID = {
  not_started: 'sg_not_started',
  in_progress: 'sg_in_progress',
  grading: 'sg_submitted',
  completed: 'sg_scored',
  sent_back: 'sg_sent_back',
  excused: 'sg_excused',
  blocked: 'sg_blocked',
};

/** Given a list of submissions, order them. */
export const getOrderedSubmissions = submissions => {
  const getGroupIndex = s => {
    const status = s.project_status;
    const control = s.control;

    const target =
      control === 'blocked' || control === 'excused'
        ? PROJECT_STATUS_TO_SUBMISSION_GROUP_ID[control]
        : PROJECT_STATUS_TO_SUBMISSION_GROUP_ID[status];
    return SUBMISSION_GROUPS.findIndex(g => g.id === target);
  };

  // Within the submitted group we want to sort submissions that are in the
  // grading status before ones that are in the completed status.  For other
  // groups this doesn't apply since there's only ever one project status in
  // them.
  const getStatusIndex = s => {
    return s.project_status !== 'completed' ? 0 : 1;
  };

  return sortBy(submissions, [
    getGroupIndex,
    getStatusIndex,
    'student_first_name',
    'student_last_name',
    'student_id',
  ]);
};

/** Given a list of submissions group them for rendering in the NavMap. */
export const getGroupedSubmissions = submissions => {
  const groups = groupBy(getOrderedSubmissions(submissions), submission => {
    const control = submission.control;
    const status = submission.project_status;

    return control === 'blocked' || control === 'excused'
      ? PROJECT_STATUS_TO_SUBMISSION_GROUP_ID[control]
      : PROJECT_STATUS_TO_SUBMISSION_GROUP_ID[status];
  });

  return (
    SUBMISSION_GROUPS
      // Filter out groups with no submissions
      .filter(group => !!groups[group.id])
      .map(group => {
        return {
          group: group,
          submissions: groups[group.id],
        };
      })
  );
};
