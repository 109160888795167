import PropTypes from 'prop-types';
import React from 'react';

const AudioContext = React.createContext(undefined);

export const AudioProvider = ({ children }) => {
  const [audio] = React.useState(new Audio());
  const cb = React.useRef(null);

  React.useEffect(() => {
    const onAudioEnd = () => {
      cb.current('stopped');
      cb.current = null;
    };

    audio.addEventListener('ended', onAudioEnd);

    return () => {
      audio.removeEventListener('ended', onAudioEnd);
    };
  }, [audio]);

  const play = (src, callback) => {
    // If another audio is currently playing
    // execute the callback with status of stopped and reset it
    if (src !== audio.src && cb.current) {
      cb.current('stopped');
      cb.current = null;
    }

    // Assign the src to the audio only if it's a new audio
    // because if not it will reset the current audio position
    // This will also make the current audio to stop
    if (src !== audio.src) {
      audio.src = src;
    }

    // Play or resume audio
    cb.current = callback;
    audio.play();
    cb.current('playing');
  };

  const pause = () => {
    audio.pause();
    if (cb.current) {
      cb.current('paused');
    }
  };

  const stop = () => {
    audio.src = '';
    cb.current = null;
  };

  const value = {
    play,
    pause,
    stop,
  };

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};

AudioProvider.propTypes = {
  children: PropTypes.node,
};

export const useAudio = () => {
  const context = React.useContext(AudioContext);
  if (context === undefined) {
    throw new Error('useAudio should be used within a AudioProvider');
  }
  return context;
};
