const ENV = (function () {
  const project = process.env.REACT_APP_GOOGLE_CLOUD_PROJECT;

  switch (project) {
    case undefined: // running locally
      return 'local';
    case 'helloworldcs-dev':
      return 'development';
    case 'helloworldcs-demo':
      return 'demo';
    case 'helloworldcs-b85f2':
      return 'production';
    default:
      throw new Error(`unrecognized google project: ${project}`);
  }
})();

const api_uri = {
  local: 'http://python-api.localhost',
  development: 'https://python-api-rvfvofdjza-uc.a.run.app',
  demo: 'https://python-api-mmgqwaw7ka-uc.a.run.app',
  production: 'https://python-api-k5et7rir6a-uc.a.run.app',
};

const config = {
  local: {
    env: 'local',
    api: {
      uri: 'http://api.localhost',
    },
    rosterApi: {
      uri: api_uri.local + '/rostering/me',
    },
    curriculumApi: {
      uri: api_uri.local + '/curriculum/view',
    },
    sectionActivityApi: {
      uri: api_uri.local + '/section_activity/external_accounts',
    },
    cospaces: {
      base_url: 'https://hw.cospaces.dev.delightex.com',
    },
    pickcode: {
      base_url: 'https://dev.pickcode.io',
      render_activity_user_id: 'cliap3mps0007or01kp6vvk2o',
      render_activity_user_external_id: 'render-activity-user',
    },
    firebase: {
      apiKey: 'AIzaSyB7D7qVDieSqAKgiMhpAS0cFzSc7MDIKDI',
      authDomain: 'helloworldcs-dev.firebaseapp.com',
      projectId: 'helloworldcs-dev',
      storageBucket: 'helloworldcs-dev.appspot.com',
      messagingSenderId: '252601700190',
      appId: '1:252601700190:web:99653eab822442b5c749cf',
    },
    openfeature: {
      domain: 'http://goff.localhost',
    },
  },
  development: {
    env: 'development',
    api: {
      uri: 'https://api-go.dev.helloworldcs.org',
    },
    rosterApi: {
      uri: api_uri.development + '/rostering/me',
    },
    curriculumApi: {
      uri: api_uri.development + '/curriculum/view',
    },
    sectionActivityApi: {
      uri: api_uri.development + '/section_activity/external_accounts',
    },
    cospaces: {
      base_url: 'https://hw.cospaces.dev.delightex.com',
    },
    pickcode: {
      base_url: 'https://app.pickcode.io',
      render_activity_user_id: 'clk3d818z008hnh01jho38j1m',
      render_activity_user_external_id: 'render-activity-user',
    },
    firebase: {
      apiKey: 'AIzaSyB7D7qVDieSqAKgiMhpAS0cFzSc7MDIKDI',
      authDomain: 'learn.dev.helloworldcs.org',
      projectId: 'helloworldcs-dev',
      storageBucket: 'helloworldcs-dev.appspot.com',
      messagingSenderId: '252601700190',
      appId: '1:252601700190:web:99653eab822442b5c749cf',
    },
    openfeature: {
      domain: 'https://feature-flag-service-rvfvofdjza-uc.a.run.app',
    },
  },
  demo: {
    env: 'demo',
    api: {
      uri: 'https://api-go.demo.helloworldcs.org',
    },
    rosterApi: {
      uri: api_uri.demo + '/rostering/me',
    },
    curriculumApi: {
      uri: api_uri.demo + '/curriculum/view',
    },
    sectionActivityApi: {
      uri: api_uri.demo + '/section_activity/external_accounts',
    },
    cospaces: {
      base_url: 'https://edu.cospaces.io',
    },
    pickcode: {
      base_url: 'https://app.pickcode.io',
      render_activity_user_id: 'clk3d818z008hnh01jho38j1m',
      render_activity_user_external_id: 'render-activity-user',
    },
    firebase: {
      apiKey: 'AIzaSyBvf65h7g8jVDddA1JQdAxEOaNThIkdQW0',
      authDomain: 'learn.demo.helloworldcs.org',
      projectId: 'helloworldcs-demo',
      storageBucket: 'helloworldcs-demo.appspot.com',
      messagingSenderId: '143561632955',
      appId: '1:143561632955:web:69f4e1ba905331be678d9e',
    },
    openfeature: {
      domain: 'https://feature-flag-service-mmgqwaw7ka-uc.a.run.app',
    },
  },
  production: {
    env: 'production',
    api: {
      uri: 'https://api-go.helloworldcs.org',
    },
    rosterApi: {
      uri: api_uri.production + '/rostering/me',
    },
    curriculumApi: {
      uri: api_uri.production + '/curriculum/view',
    },
    sectionActivityApi: {
      uri: api_uri.production + '/section_activity/external_accounts',
    },
    cospaces: {
      base_url: 'https://edu.cospaces.io',
    },
    pickcode: {
      base_url: 'https://app.pickcode.io',
      render_activity_user_id: 'clk3d818z008hnh01jho38j1m',
      render_activity_user_external_id: 'render-activity-user',
    },
    firebase: {
      apiKey: 'AIzaSyCJgH1pw1aAykIS1soGAuO-zZD9jTC8vu8',
      authDomain: 'learn.helloworldcs.org',
      databaseURL: 'https://helloworldcs-b85f2.firebaseio.com',
      projectId: 'helloworldcs-b85f2',
      storageBucket: 'helloworldcs-b85f2.appspot.com',
      messagingSenderId: '457595976002',
      appId: '1:457595976002:web:d3989fb6ad410843ca30db',
    },
    openfeature: {
      domain: 'https://feature-flag-service-k5et7rir6a-uc.a.run.app',
    },
  },
};

export default config[ENV];
