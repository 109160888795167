import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const Zoom = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 12 12"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="Zoom"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <path
      d="M8 0L9.53333 1.53333L7.60667 3.44667L8.55333 4.39333L10.4667 2.46667L12 4V0H8ZM0 4L1.53333 2.46667L3.44667 4.39333L4.39333 3.44667L2.46667 1.53333L4 0H0V4ZM4 12L2.46667 10.4667L4.39333 8.55333L3.44667 7.60667L1.53333 9.53333L0 8V12H4ZM12 8L10.4667 9.53333L8.55333 7.60667L7.60667 8.55333L9.53333 10.4667L8 12H12V8Z"
      fill="#4D4D4D"
    />
  </StyledSvgIcon>
));

Zoom.displayName = 'Zoom';

Zoom.propTypes = {
  size: PropTypes.number,
};

export default Zoom;
