import {
  BlockGroupContainer,
  NumberedTextBlock,
  TextBlock as TextContent,
} from '../../components/InlineBlocks';
import { BackCaret } from '../../components';
import Button from '@material-ui/core/Button';
import { MediaBlock } from '../../components/Blocks';
import PropTypes from 'prop-types';
import React from 'react';

export const Step1 = ({ oldSection, newSection, newCoSpaceUrl, next }) => {
  return (
    <>
      <BlockGroupContainer>
        <TextContent>
          Follow the steps to copy your work from <b>{oldSection.title}</b> to{' '}
          <b>{newSection.title}</b>:
        </TextContent>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={1}>
          <TextContent>
            Your work from <b>{oldSection.title}</b> is shown on the right of
            this page. This is where you will copy your objects and code.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={2}>
          <TextContent>
            Click{' '}
            <Button
              className="open"
              variant="contained"
              color="primary"
              onClick={() => window.open(newCoSpaceUrl, '_blank')}
            >
              <span>open cospace</span>
              <img
                src="/static/assets/icons/link-white.svg"
                alt="external link icon"
              />
            </Button>{' '}
            to open your CoSpace for <b>{newSection.title}</b>. This is where
            you will paste your objects and code.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={3}>
          <TextContent>
            Return to this page and click{' '}
            <Button
              className="next"
              variant="contained"
              color="primary"
              onClick={next}
            >
              <span>next</span>
              <BackCaret fill="white" id="nextCaret" size={16} />
            </Button>{' '}
            to continue.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
    </>
  );
};
Step1.propTypes = {
  oldSection: PropTypes.shape({
    title: PropTypes.string,
  }),
  newSection: PropTypes.shape({
    title: PropTypes.string,
  }),
  newCoSpaceUrl: PropTypes.string,
  next: PropTypes.func,
};

export const Step2 = ({ newSection, newCoSpaceUrl }) => {
  return (
    <>
      <BlockGroupContainer>
        <TextContent>
          <b>Copy and paste your objects.</b>
        </TextContent>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={1}>
          <TextContent>
            First, unlock all of the objects in your scene.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/unlock-objects.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={2}>
          <TextContent>
            Highlight all of your objects in your scene by pressing two keys on
            your keyboard at the same time: ctrl + a (cmd + a for Mac). This is
            a shortcut to select all objects.
            <br />
            Copy the selected objects by pressing ctrl + a (cmd + a for Mac) at
            the same time.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/select-and-copy-objects.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={3}>
          <TextContent>
            Next, open your CoSpace for <b>{newSection.title}</b>.{' '}
            <a href={newCoSpaceUrl} target="_blank" rel="noopener noreferrer">
              Click here
            </a>{' '}
            if it isn&apos;t open already.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={4}>
          <TextContent>
            Click on an empty area in your scene, then press two keys on your
            keyboard at the same time: ctrl + v (cmd + v for Mac). This will
            paste your objects into your CoSpace for <b>{newSection.title}</b>.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/paste-objects.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={5}>
          <TextContent>Repeat steps 1-4 for each of your scenes.</TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
    </>
  );
};
Step2.propTypes = {
  newSection: PropTypes.shape({
    title: PropTypes.string,
  }),
  newCoSpaceUrl: PropTypes.string,
};

export const Step3 = ({ newSection, newCoSpaceUrl }) => {
  return (
    <>
      <BlockGroupContainer>
        <TextContent>
          <b>Update your Environment.</b>
        </TextContent>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={1}>
          <TextContent>
            On this page, click the{' '}
            <img
              className="inline"
              src="/static/assets/cospaces-migration/environment-button.png"
              alt="environment"
            />{' '}
            button to open your Environment. Look at your Environment, Effects,
            and Filters.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/environment.mp4',
              },
            }}
          />
          <TextContent>
            If you uploaded items from your computer, you can upload them again
            in your CoSpace for <b>{newSection.title}</b>.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={2}>
          <TextContent>
            Next, open your CoSpace for <b>{newSection.title}</b>.{' '}
            <a href={newCoSpaceUrl} target="_blank" rel="noopener noreferrer">
              Click here
            </a>{' '}
            if it isn&apos;t open already.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={3}>
          <TextContent>
            Apply the same Environment, Effects, and Filters to that CoSpace.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/adding-the-environment.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={4}>
          <TextContent>Repeat steps 1-4 for each of your scenes.</TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
    </>
  );
};
Step3.propTypes = {
  newSection: PropTypes.shape({
    title: PropTypes.string,
  }),
  newCoSpaceUrl: PropTypes.string,
};

export const Step4 = ({ newSection, newCoSpaceUrl }) => {
  return (
    <>
      <BlockGroupContainer>
        <TextContent>
          <b>Copy and paste your code.</b>
        </TextContent>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={1}>
          <TextContent>
            On this page, click the{' '}
            <img
              className="inline"
              src="/static/assets/cospaces-migration/code-button.png"
              alt="code"
            />{' '}
            button to show your code.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/open-your-code.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={2}>
          <TextContent>
            Highlight your CoBlocks by pressing two keys at the same time: ctrl
            + a (cmd + a for Mac). This is the shortcut to select all objects.
            <br />
            Copy the code by pressing ctrl + c (cmd + c for Mac) at the same
            time.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/copy-your-code.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={3}>
          <TextContent>
            Next, open your CoSpace for <b>{newSection.title}</b>.{' '}
            <a href={newCoSpaceUrl} target="_blank" rel="noopener noreferrer">
              Click here
            </a>{' '}
            if it isn&apos;t open already.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={4}>
          <TextContent>
            Click the{' '}
            <img
              className="inline"
              src="/static/assets/cospaces-migration/code-button.png"
              alt="code"
            />{' '}
            button to show your code. Press two keys on your keyboard at the
            same time: ctrl + v (cmd + v for Mac) to paste your code. This will
            paste your code into the CoSpace for <b>{newSection.title}</b>.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/paste-your-code.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={5}>
          <TextContent>
            Click{' '}
            <img
              className="inline"
              src="/static/assets/cospaces-migration/play-button.png"
              alt="play"
            />{' '}
            to confirm that your code works.
            <br />
            If your code doesn&apos;t work, review your CoBlocks to make sure
            your code is correct.
          </TextContent>
          <MediaBlock
            block={{
              media: {
                type: 'video',
                url: '/static/assets/cospaces-migration/check-your-code.mp4',
              },
            }}
          />
        </NumberedTextBlock>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={6}>
          <TextContent>Repeat steps 1-5 for each of your scenes.</TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
    </>
  );
};
Step4.propTypes = {
  newSection: PropTypes.shape({
    title: PropTypes.string,
  }),
  newCoSpaceUrl: PropTypes.string,
};

export const Step5 = ({ finish, project, newSection }) => {
  return (
    <>
      <BlockGroupContainer>
        <TextContent>
          <b>You&apos;re almost done!</b>
        </TextContent>
      </BlockGroupContainer>
      <BlockGroupContainer>
        <NumberedTextBlock number={1}>
          <TextContent>
            After you&apos;ve copied your project, click{' '}
            <Button
              className="finish"
              variant="contained"
              color="primary"
              onClick={finish}
            >
              <span>continue to project</span>
              <BackCaret fill="white" size={16} />
            </Button>{' '}
            to open <b>{project.title}</b> in <b>{newSection.title}</b>.
          </TextContent>
        </NumberedTextBlock>
      </BlockGroupContainer>
    </>
  );
};
Step5.propTypes = {
  finish: PropTypes.func,
  project: PropTypes.shape({
    title: PropTypes.string,
  }),
  newSection: PropTypes.shape({
    title: PropTypes.string,
  }),
};
