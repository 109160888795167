import { usePosition, useSection, useVocabulary } from '../contexts';
import getProjectPlayability from './playability';
import NavMap from '../../../components/NavMap';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useProgress } from '../contexts';
import { useProject } from '../../../contexts/ProjectContext';
import { useUser } from '../../../contexts/AuthContext';

const getSectionGroupIcon = playability => {
  if (playability.isCurrent) return 'play';
  return null;
};

const getStepIcon = playability => {
  if (playability.isCurrent || playability.isPlayable) return 'play';
  if (playability.isComplete) return 'checkmark';
  return 'locked';
};

export const Nav = ({ isSummaryShown, onSetShowSummary }) => {
  const user = useUser();
  const location = useLocation();
  const section = useSection();
  const project = useProject();
  const { progress } = useProgress();
  const { goTo } = usePosition();
  const { onOpenModal } = useVocabulary();

  // Determine how far through the project we are.
  const numSteps = project.section_groups
    .flatMap(group => group.sections.flatMap(section => section.steps.length))
    .reduce((sum, n) => sum + n, 0);
  const numStepsComplete = Object.values(progress?.steps ?? {}).filter(
    complete => complete
  ).length;

  const isTeacher = user.isTeacherForProject(project.id);

  // Transform the project hierarchy into nav map section/items
  const playability = getProjectPlayability(project, progress);
  const sections = project.section_groups.map(group => {
    const groupPlayability = playability.section_groups[group.id];

    return {
      id: group.id,
      title: group.title,
      isCurrent: groupPlayability.isCurrent,
      isComplete: groupPlayability.isComplete,
      icon: getSectionGroupIcon(groupPlayability),
      groups: group.sections.map(section => {
        const sectionPlayability = groupPlayability.sections[section.id];

        // Add the list of items inside a section.
        // Only include items if this is the current section or it has already been completed.
        // This has the effect of making the sections look collapsed in the nav.
        const items =
          groupPlayability.isCurrent || groupPlayability.isComplete
            ? section.steps.map(step => {
                const stepPlayability = sectionPlayability.steps[step.id];

                // Detects if in this step there's a teacher tip in any block
                // to show the tip icon in the NavMap
                const isTipPresent = step.block_groups?.some(bg =>
                  bg.blocks.some(b => {
                    if (b.type === 'artifacts') {
                      return b.rows.some(r => r.artifacts.some(a => !!a.tip));
                    }
                    if (b.type === 'rubric') {
                      return project.rubric?.some(cat =>
                        cat.requirements.some(req => !!req.tip)
                      );
                    }
                    if (b.type === 'checkpoint' && Array.isArray(b.question)) {
                      return b.question.some(p => !!p.tip);
                    }
                    if (
                      b.type === 'checkpoint' &&
                      Array.isArray(b.explanation)
                    ) {
                      return b.explanation.some(p => !!p.tip);
                    }
                    return !!b.tip;
                  })
                );

                return {
                  id: step.id,
                  title: step.title,
                  isTeacherOnly: step.teacher_only,
                  isCurrent: stepPlayability.isCurrent,
                  isComplete: stepPlayability.isComplete,
                  isPlayable: stepPlayability.isPlayable,
                  icon: getStepIcon(stepPlayability),
                  showTipIcon: isTeacher && isTipPresent,
                };
              })
            : [];

        return {
          id: section.id,
          title: section.title,
          isCurrent: sectionPlayability.isCurrent,
          isComplete: sectionPlayability.isComplete,
          items,
        };
      }),
    };
  });

  const handleStepClick = (sectionId, stepId) => {
    goTo({ section: sectionId, step: stepId });
    onSetShowSummary(false);
  };

  const handleVocabularyButtonClick = () => onOpenModal();
  const handleProjectSummaryButtonClick = () => onSetShowSummary(s => !s);

  // The previous label/url is changes if we know the section and course
  // we're working on.  When a student bookmarks a project URL and returns
  // later we won't have that information so can only return them back to
  // the course selection page.
  let previous = { label: 'My Courses', url: '/courses' };
  if (section && location.state?.courseId) {
    previous = {
      label: location.state?.levelName ?? 'Level',
      url: `/section/${section.id}/course/${location.state.courseId}/level/${location.state.levelId}`,
    };
  } else if (location.state?.courseId) {
    previous = {
      label: location.state?.levelName ?? 'Level',
      url: `/course/${location.state.courseId}/level/${location.state.levelId}`,
    };
  }

  return (
    <NavMap
      handleItemClick={handleStepClick}
      isSummaryShown={isSummaryShown}
      isTeacher={isTeacher}
      mode={'project'}
      onVocabButtonClick={handleVocabularyButtonClick}
      onProjectSummaryButtonClick={handleProjectSummaryButtonClick}
      previous={previous}
      progressIndicator={{
        title: 'Project Progress',
        complete: numStepsComplete,
        total: numSteps,
      }}
      sections={sections}
    />
  );
};

Nav.propTypes = {
  isSummaryShown: PropTypes.bool,
  onSetShowSummary: PropTypes.func,
};
