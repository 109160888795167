import {
  Blocked,
  Clock,
  ClockGradient,
  Excused,
  Locked,
  Play,
  PlayGradient,
  SentBack,
  SentBackGradient,
  Star,
  Submitted,
} from '../../components/icons';
import CardActions from '@material-ui/core/CardActions';
import ProjectCard from '../ProjectCard';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const StatusIconTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.spacing(1.5),
    lineHeight: '18px',
    padding: theme.spacing(1),
  },
}))(Tooltip);

const StatusIcon = ({ hovering, progress }) => {
  const theme = useTheme();

  let icon;
  let title;
  if (progress.status === 'not_started' && progress.playable) {
    icon = !hovering ? <Play size={36} /> : <PlayGradient size={40} />;
    title = 'Available';
  } else if (progress.status === 'not_started' && !progress.playable) {
    icon = <Locked className="locked" size={36} />;
    title = 'Locked';
  } else if (progress.status === 'in_progress') {
    icon = !hovering ? <Clock size={36} /> : <ClockGradient size={36} />;
    title = 'In Progress';
  } else if (progress.status === 'grading') {
    icon = <Submitted $hovering={hovering} size={36} />;
    title = 'Submitted';
  } else if (progress.status === 'sent_back') {
    icon = !hovering ? <SentBack size={32} /> : <SentBackGradient size={32} />;
    title = 'Sent Back';
  } else if (progress.status === 'completed') {
    icon = <Star $hovering={hovering} size={36} />;
    title = 'Scored';
  } else if (progress.status === 'blocked') {
    // This is a slight hack because these icons are used on both the project
    // card and in the nav, but they need different ways of determining when
    // they're being hovered to change style.  Normally we'd just pass in the
    // hovering variable to the icon, but that doesn't work for the nav.  So we
    // use the hovering variable to apply a custom style to the icon.
    const style = {
      fill: hovering ? theme.palette.text.primary : theme.palette.text.icon,
    };

    icon = <Blocked style={style} size={32} />;
    title = 'Blocked. Contact your teacher';
  } else if (progress.status === 'excused') {
    // See note above about why a style is being applied.
    const style = {
      fill: hovering ? theme.palette.text.primary : theme.palette.text.icon,
    };

    icon = <Excused style={style} size={32} />;
    title = 'Excused';
  }

  return (
    <StatusIconTooltip title={title} placement="right">
      {icon}
    </StatusIconTooltip>
  );
};

StatusIcon.propTypes = {
  hovering: PropTypes.bool,
  progress: PropTypes.shape({
    complete: PropTypes.bool,
    playable: PropTypes.bool,
    status: PropTypes.string,
  }),
};

const StudentProjectCard = ({
  courseId,
  courseName,
  currentLevelName,
  id,
  isTeacher,
  levelId,
  metadata,
  progress,
  sectionId,
  type,
}) => {
  const [hovering, setHovering] = React.useState(false);

  const handleClick = history => {
    if (progress.complete || progress.playable) {
      // TODO: Remove this if once we always have a section id.
      if (sectionId) {
        history.push(`/section/${sectionId}/project/${id}`, {
          courseId,
          courseName,
          id,
          levelId,
          levelName: currentLevelName,
          type,
        });
      } else {
        history.push(`/project/${id}`, {
          courseId,
          courseName,
          id,
          levelId,
          levelName: currentLevelName,
          type,
        });
      }
    }
  };

  return (
    <Route
      render={({ history }) => (
        <ProjectCard
          image={metadata?.media?.url}
          isTeacher={isTeacher}
          name={metadata?.title}
          onClick={() => handleClick(history)}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          progress={progress}
          teacherGuide={metadata?.teacher_guide}
        >
          <CardActions>
            <StatusIcon hovering={!isTeacher && hovering} progress={progress} />
          </CardActions>
        </ProjectCard>
      )}
    />
  );
};

StudentProjectCard.propTypes = {
  courseId: PropTypes.string,
  courseName: PropTypes.string,
  currentLevelName: PropTypes.string,
  id: PropTypes.string,
  isTeacher: PropTypes.bool,
  levelId: PropTypes.string,
  metadata: PropTypes.object,
  progress: PropTypes.shape({
    complete: PropTypes.bool,
    playable: PropTypes.bool,
    status: PropTypes.string,
  }),
  sectionId: PropTypes.string,
  type: PropTypes.string,
};

export default StudentProjectCard;
