import authentication from './authentication';
import courses from './courses';
import curriculum from './curriculum';
import enrollments from './enrollments';
import external from './external';
import { load } from './client';
import project from './project';
import roster from './roster';
import section from './section';
import teacher from './teacher';
import users from './users';

export default {
  roster,
  curriculum,
  load,
  authentication,
  courses,
  enrollments,
  external,
  project,
  section,
  teacher,
  users,
};
