import * as externalAccount from '../utils/externalAccountLogin';
import { Redirect, useHistory } from 'react-router';
import api from '../api';
import AppBarHeader from '../components/AppBarHeader';
import { BackCaret } from '../components/icons';
import Button from '@material-ui/core/Button';
import Credential from '../components/Credential';
import LoadingIndicator from '../components/LoadingIndicator';
import NavTeacher from '../components/NavTeacher';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { useUser } from '../contexts/AuthContext';

const ExternalAccountLogin = () => {
  const history = useHistory();

  // We can arrive on this page without state being passed to the page if the
  // user were to inadvertently bookmark or copy the URL to this page.  When
  // this happens we don't know which course they were attempting to go to so
  // just redirect them to the courses page and let them navigate to their
  // course again.
  const continueTo = history.location.state?.continueTo;
  const tool = history.location.state?.tool;
  if (!continueTo || !tool) {
    return <Redirect to="/courses" />;
  }

  return <ExternalAccountLoginBody continueTo={continueTo} tool={tool} />;
};

const BUTTON_TITLES = {
  course: 'CONTINUE TO COURSE',
  project: 'CONTINUE TO PROJECT',
  profile: 'RETURN TO PROFILE',
};

const ExternalAccountLoginBody = ({ continueTo, tool }) => {
  const user = useUser();
  const history = useHistory();
  const toolTitle = tool === 'CoSpaces';
  const buttonTitle = BUTTON_TITLES?.[continueTo.type] ?? 'CONTINUE';

  const [loading, account] = api.load(api.external.account.get(tool, user.id));

  const handleClick = React.useCallback(
    e => {
      e.preventDefault();
      externalAccount.setLoggedIn(tool);
      history.replace(continueTo.path, continueTo?.state);
    },
    [continueTo, tool, history]
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AppBarHeader
        menu={user.isTeacherForAnySections() && <NavTeacher />}
        title={`${toolTitle} Login`}
        includeProfile={false}
      />
      <Container>
        <LoginContainer>
          <LoginBody $isWide={false}>
            <BlockGroupContainer>
              <NumberedTextBlock number={1}>
                <TextContent>
                  {tool === 'cospaces' && (
                    <Typography variant="body1">
                      Log into your CoSpaces account.
                    </Typography>
                  )}
                  {account?.username && (
                    <Credentials
                      username={account.username}
                      password={account.password}
                      tool={tool}
                    />
                  )}
                </TextContent>
              </NumberedTextBlock>
            </BlockGroupContainer>
            <BlockGroupContainer>
              <NumberedTextBlock number={2}>
                <TextContent>
                  <Typography variant="body1">
                    {`Confirm that you're logged into your own account.`}
                  </Typography>
                  {tool === 'cospaces' && (
                    <video
                      src="/static/assets/account-login/cospaces-account.mp4"
                      autoPlay
                      loop
                      muted
                    />
                  )}
                </TextContent>
              </NumberedTextBlock>
            </BlockGroupContainer>
          </LoginBody>
        </LoginContainer>
        {tool === 'cospaces' && (
          <IframeContainer>
            <iframe
              height="100%"
              width="100%"
              src="https://edu.cospaces.io/Auth"
              scrolling="yes"
              frameBorder="no"
            />
          </IframeContainer>
        )}
      </Container>
      <StepNav continueTitle={buttonTitle} handleClick={handleClick} />
    </>
  );
};

ExternalAccountLoginBody.propTypes = {
  continueTo: PropTypes.shape({
    type: PropTypes.string,
    path: PropTypes.string,
    state: PropTypes.object,
  }),
  tool: PropTypes.string,
};

const NumberedTextBlock = ({ children, number }) => (
  <NumberedTextBlockContainer>
    <TextBlockNumber>{number}</TextBlockNumber>
    {children}
  </NumberedTextBlockContainer>
);

NumberedTextBlock.propTypes = {
  children: PropTypes.node,
  number: PropTypes.number,
};

const StepNav = ({ continueTitle, handleClick }) => (
  <StepNavContainer>
    <Button variant="contained" color="primary" onClick={handleClick}>
      <span id="next">{continueTitle}</span>
      <BackCaret fill="white" id="nextCaret" size={16} />
    </Button>
  </StepNavContainer>
);

StepNav.propTypes = {
  continueTitle: PropTypes.string,
  handleClick: PropTypes.func,
};

const BlockGroupContainer = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    color: ${theme.palette.text.secondary};
    margin: ${theme.spacing(1)}px;
    padding: ${theme.spacing(1, 2)};
    width: 100%;

    p {
      font-weight: 400;
    }
    
    video {
      border-radius: 5px;
      filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.2));
      margin-top: ${theme.spacing(2)}px;
      margin-left: auto;
      margin-right: auto;
      width: 75%;
    }
  `
);

const Container = styled.div(
  ({ theme }) => `
    background: ${theme.palette.primary.gradient};
    display: flex;
    flex-direction: row;
    height: calc(100vh - ${theme.spacing(16)}px);
    overflow: hidden;
    width: 100%;
  `
);

const CredentialsStyles = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1.5)}px;
    
    margin: ${theme.spacing(2, 0, 0, 3)};
    padding: ${theme.spacing(2)}px;
    width: 90%;
    
    &.cospaces {
      background: linear-gradient(89.97deg, #CD6248 0.02%, #C75B5B 98.68%);
    }
  `
);

const Credentials = ({ username, password, tool }) => (
  <CredentialsStyles className={tool}>
    <Credential title="Username" content={username} />
    <Credential title="Password" content={password} />
  </CredentialsStyles>
);
Credentials.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  tool: PropTypes.string,
};

const IframeContainer = styled.div`
  height: calc(100vh - 128px);
  width: 100%;
`;

const LoginBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.spacing(92)}px;
  padding: ${({ $isWide, theme }) =>
    $isWide ? theme.spacing(1, 0) : theme.spacing(1, 8)};
  padding-bottom: ${({ theme }) => theme.spacing(5)}px;

  a {
    text-decoration: none;
  }
`;

const LoginContainer = styled.div`
  height: calc(100vh - ${({ theme }) => theme.spacing(16)}px);
  overflow-y: auto;
  width: 100%;

  #nextCaret {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
    transform: rotate(180deg);
  }

  button {
    color: #fff;
  }

  button:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const NumberedTextBlockContainer = styled.div`
  align-items: baseline;
  display: flex;
  margin: ${({ theme }) => theme.spacing(1, 0)};

  .MuiTypography-body1 {
    padding: ${({ theme }) => theme.spacing(0, 3)};
  }
`;

const TextBlockNumber = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.palette.background.level2};
    border-radius: 50%;
    color: ${theme.palette.primary.main};
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px; 
    font-weight: 500;
    justify-content: center;
    margin-right: ${theme.spacing(2)}px;
    margin: 0;
    min-height: ${theme.spacing(3)}px;
    min-width: ${theme.spacing(3)}px;
  `
);

const TextContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  img {
    align-self: center;
  }
`;

const StepNavContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.palette.background.paper};
    display: flex;
    height: ${theme.spacing(8)}px;
    justify-content: flex-end;
    padding: ${theme.spacing(0, 4)};
    width: 100%;

    #next {
      margin-right: ${theme.spacing(1)}px;
    }
    
    #nextCaret {
      transform: rotate(180deg);
    }
    
    .MuiButtonBase-root{
      color: ${theme.palette.text.primary};
    }

    .MuiButton-containedPrimary:hover {
      background-color: ${theme.palette.primary.main};
    }
  `
);

export default ExternalAccountLogin;
