import { useRenderState, useSubmissions } from '../contexts';
import { getGroupedSubmissions } from '../utils/submissions';
import NavMap from '../../../components/NavMap';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useProject } from '../../../contexts/ProjectContext';

const getScoringGuideSectionGroup = (project, currentItemId) => {
  const group =
    project?.scoring_guide_section_group ||
    project?.scoring?.guide?.section_group;
  if (!group) {
    return null;
  }

  return {
    id: group.id,
    title: group.title,
    isCurrent: false,
    isComplete: true,
    icon: null,
    groups: group.sections.map(section => {
      return {
        id: section.id,
        title: section.title,
        isCurrent: false,
        isComplete: true,
        items: section.steps.map(step => {
          return {
            id: step.id,
            title: step.title,
            isCurrent: step.id === currentItemId,
            isComplete: true,
            isPlayable: true,
            icon: 'play',
          };
        }),
      };
    }),
  };
};

const SUBMISSION_ICONS = {
  not_started: 'locked',
  in_progress: 'locked',
  grading: 'play',
  completed: 'checkmark',
  sent_back: 'sent-back',
  excused: 'none',
  blocked: 'none',
};

const getSubmissionsSectionGroup = (submissions, currentItemId) => {
  const groups = getGroupedSubmissions(submissions).map(entry => {
    return {
      id: entry.group.id,
      title: entry.group.title,
      isCurrent: false,
      isComplete: false,
      icon: null,
      items: entry.submissions.map(student => {
        const status =
          student.control === 'blocked' || student.control === 'excused'
            ? student.control
            : student.project_status;

        return {
          id: student.student_id,
          title: `${student.student_first_name} ${student.student_last_name}`,
          isCurrent: student.student_id === currentItemId,
          isComplete: status === 'completed',
          isPlayable:
            status === 'grading' ||
            status === 'completed' ||
            status === 'sent_back',
          icon: SUBMISSION_ICONS[status],
        };
      }),
    };
  });

  return {
    id: 'cbeafa96-dceb-4416-8bc8-725965266d6c', // TODO: Don't hardcode this.
    title: 'SUBMISSIONS',
    isCurrent: false,
    isComplete: true,
    icon: null,
    groups: groups,
  };
};

const getNavSections = (project, submissions, currentItemId) => {
  const groups = [];

  const scoring = getScoringGuideSectionGroup(project, currentItemId);
  if (scoring) {
    groups.push(scoring);
  }
  groups.push(getSubmissionsSectionGroup(submissions, currentItemId));
  return groups;
};

export const SideNav = ({ sectionId, courseId }) => {
  const history = useHistory();
  const project = useProject();
  const render = useRenderState();
  const { submissions } = useSubmissions();

  const levelId = history.location.state?.levelId;

  const sections = getNavSections(
    project,
    submissions,
    render.view.guide ? render.stepId : render.studentId
  );

  // Calculate the full list of scoring steps in the order that they appear.
  // If the project template doesn't have a scoring section group then this will
  // be an empty array.
  const steps = React.useMemo(() => {
    const group =
      project?.scoring_guide_section_group ||
      project?.scoring?.guide?.section_group;
    if (!group) {
      return [];
    }

    return Object.fromEntries(
      group.sections.flatMap(section =>
        section.steps.map(step => [step.id, true])
      )
    );
  }, [project]);

  const handleNavClick = React.useCallback(
    (_, itemId) => {
      if (steps[itemId]) {
        render.showScoringStep(itemId);
      } else {
        render.showStudentSubmission(itemId);
      }
    },
    [render, steps]
  );

  return (
    <NavMap
      mode="project"
      // The Gradbook URL to take the teacher to when the return button is
      // clicked, customized based on whether or not they previously had a level
      // selected.
      previous={{
        label: 'Gradebook',
        url: levelId
          ? `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}/level/${levelId}`
          : `/teacher/dashboards/gradebook/section/${sectionId}/course/${courseId}`,
      }}
      hideNavButtons // No project summary/vocab buttons at the top of the nav
      handleItemClick={handleNavClick}
      sections={sections}
    />
  );
};

SideNav.propTypes = {
  sectionId: PropTypes.string,
  courseId: PropTypes.string,
};
