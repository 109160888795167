import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../TeacherTip';
import TextBlockTypography from '../Text';
import { TextWithAudio } from '../Audio';

const Bullet = styled.div(
  ({ theme }) => `
    // Draw the circle
    background-color: ${theme.palette.background.level2};
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    min-width: ${theme.spacing(3)}px;
    min-height: ${theme.spacing(3)}px;

    // Format the text (the number in the circle)
    color: ${theme.palette.primary.main};
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;

    // Center the number in the circle 
    display: flex;
    align-items: center;
    justify-content: center;

    &.lettered {
      background-color: transparent;
      box-shadow: none;
      min-width: 0;
      :after {
        content:'.';
      }
    }
  `
);

const BulletContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    height: ${theme.spacing(3)}px;
    justify-content: center;
    position: absolute;
    top: 3px;
    width: ${theme.spacing(3)}px;
  `
);

const Row = styled.div(
  ({ theme }) => `
    align-items: flex-start;
    display: flex;
    width: 100%;
    &.numbered {
      margin-left: ${theme.spacing(5)}px;
    }
    &.lettered {
      margin-left: ${theme.spacing(4)}px;
    }
  `
);

const Container = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    position: relative;
    &.lettered {
      padding-left: ${theme.spacing(5)}px;
    }
  `
);

export const TextBlockContainer = ({ audio, format, teacherTip, text }) => {
  const bullet = format?.bullet;
  const classes = format?.indentation;

  return (
    <Container className={classes}>
      {bullet && (
        <BulletContainer>
          <Bullet className={classes}>{bullet}</Bullet>
        </BulletContainer>
      )}
      <Row className={classes}>
        <TextWithAudio audio={audio}>
          <TextBlockTypography
            dangerouslySetInnerHTML={{ __html: text }}
            variant="body1"
            display="inline"
          />
          {teacherTip && <TeacherTip content={teacherTip} />}
        </TextWithAudio>
      </Row>
    </Container>
  );
};

TextBlockContainer.propTypes = {
  audio: PropTypes.string,
  bullet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  format: PropTypes.shape({
    bullet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    indentation: PropTypes.string,
  }),
  teacherTip: PropTypes.array,
  text: PropTypes.string,
};
