import { getAuth, OAuthProvider, signInWithRedirect } from 'firebase/auth';
import { Alert } from '@material-ui/lab';
import api from '../api';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';
import SSOAuthButton from '../components/Button/OauthButton';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useAuth } from '../contexts/AuthContext';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { useHistory } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

export const LoginStyles = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.nav};
    height: 100vh;
    width: 100vw;
    padding: ${theme.spacing(10)}px;
    display: inline-flex;
    flex-direction: column;

    img {
      display: block;
      height: 220px;
      margin-left: auto;
      margin-right: auto;
    }

    .row {
      display: flex;
      justify-content: center;
      gap: ${theme.spacing(8)}px;
      padding-top: ${theme.spacing(10)}px;

      font-family: 'Poppins', sans-serif;
    }

    .or {
      font-size: 32px;
      font-weight: 600;
      padding:24px;
      align-self: center;
    }

    .section-code-login,
    .username-password-login {
      display: flex;
      flex-direction: column;

      align-self: flex-start;
      padding: ${theme.spacing(3)}px;

      &:hover {
        background-color: ${theme.palette.background.paper};
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: ${theme.spacing(2)}px;
      }

      .MuiTextField-root {
        padding-bottom: ${theme.spacing(2)}px;
        width: ${theme.spacing(40)}px;
        
        .MuiIconButton-label {
          color: ${theme.palette.text.secondary};
        }
      }

      .submit {
        align-self: flex-end;
        color: ${theme.palette.text.primary};
        font-size: 18px;
        height: ${theme.spacing(5)}px;

        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;   
        }

        svg {
          height: 18px;
          padding-left: ${theme.spacing(1)}px;
        }
      }

      .error {
        background-color: #FBECEF;
        border-radius: 4px;
        color: #B84848;
        font-size: 14px;
        margin-top: ${theme.spacing(2)}px;
        overflow-wrap: break-word;
        padding: ${theme.spacing(1)}px;
        inline-size: ${theme.spacing(40)}px;
      }
    }
  `
);

const SectionCodeLogin = ({ error, onSubmit }) => {
  const [code, setCode] = React.useState(null);

  return (
    <form
      className="section-code-login"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(code);
      }}
    >
      <Typography className="title">
        Enter your 6-letter section code:
      </Typography>
      <TextField
        label="Section Code"
        type="text"
        variant="outlined"
        onChange={e => setCode(e.target.value)}
      />
      <Button
        className="submit"
        color="primary"
        type="submit"
        variant="contained"
      >
        <span>GO</span>
        <ArrowForwardIos />
      </Button>
      {error && (
        <Typography className="error" variant="body2">
          {error}
        </Typography>
      )}
    </form>
  );
};
SectionCodeLogin.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

export const UsernamePasswordLogin = ({ error, onSubmit }) => {
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [authError, setAuthError] = React.useState(null);

  return (
    <form
      className="username-password-login"
      onSubmit={e => {
        e.preventDefault();
        onSubmit(username, password);
      }}
    >
      {authError && (
        <Alert severity="warning">
          We could not find a HelloWorldCS Account associated with your external
          account, if you have an existing account with a Username and Password
          please login with them and link your external account on the profile
          page. If you don&apos;t know your username and password, ask your
          teacher.
        </Alert>
      )}
      {useBooleanFlagValue('enable-google-single-sign-on', false) && (
        <SSOAuthButton
          provider="google"
          action="login"
          setAuthError={setAuthError}
        />
      )}
      <SSOAuthButton
        provider="clever"
        action="login"
        setAuthError={setAuthError}
      />
      <Typography className="title">Enter your username & password:</Typography>
      <TextField
        autoComplete="username"
        label="Username"
        type="text"
        variant="outlined"
        onChange={e => setUsername(e.target.value)}
      />
      <TextField
        autoComplete="password"
        label="Password"
        type={!showPassword ? 'password' : 'text'}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(prev => !prev)}
              >
                {showPassword ? (
                  <VisibilityOutlinedIcon />
                ) : (
                  <VisibilityOffOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={e => setPassword(e.target.value)}
      />
      <Button
        className="submit"
        color="primary"
        type="submit"
        variant="contained"
      >
        <span>LOGIN</span>
        <ArrowForwardIos />
      </Button>
      {error && (
        <Typography className="error" variant="body2">
          {error}
        </Typography>
      )}
    </form>
  );
};
UsernamePasswordLogin.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func,
};

const Login = () => {
  if (
    document.referrer === 'https://clever.com/' &&
    new URLSearchParams(window.location.search).get('code')
  ) {
    console.log('here');
    signInWithRedirect(getAuth(), new OAuthProvider('oidc.clever'));
  }
  const history = useHistory();
  const { user, signinWithEmailAndPassword, usernameAuthFailure } = useAuth();
  const [errors, setErrors] = React.useState({ username: usernameAuthFailure });
  React.useEffect(() => {
    if (user === null) {
      return;
    }

    // We have an authenticated user.  Redirect them to the correct landing
    // page depending on whether they're a teacher or a student.
    if (!user.isTeacherForAnySections()) {
      history.push('/courses');
    } else {
      history.push('/teacher/sections');
    }
  }, [user, history]);

  const onSubmitUsernamePassword = React.useCallback(
    async (username, password) => {
      if (!username?.trim() || !password?.trim()) {
        setErrors({
          username: 'Error: Username and password required.',
        });
        return;
      }

      const email = `${username}@helloworldcs.org`;
      await signinWithEmailAndPassword(email, password);
    },
    [signinWithEmailAndPassword, setErrors]
  );

  const onSubmitSectionCode = React.useCallback(
    async code => {
      code = code?.trim()?.toUpperCase();

      if (!code) {
        setErrors({
          code: 'Error: Section code required.',
        });
        return;
      }

      // Make sure the section code is well formed.  Currently all section codes
      // should be 6 characters long in groups of 3 separated by an optional
      // hyphen.
      const regex = /^[A-Z0-9]{3}-?[A-Z0-9]{3}$/;
      if (!regex.test(code)) {
        setErrors({
          code: 'Error: Invalid section code.',
        });
        return;
      }

      // Canonicalize the section code.
      if (code.charAt(3) !== '-') {
        code = `${code.slice(0, 3)}-${code.slice(3)}`;
      }

      // Validate this code by attempting to load metadata for the section it
      // corresponds to.
      try {
        await api.authentication.getSectionMetadata(code);
      } catch {
        setErrors({
          code: `Could not find a section with code '${code}'.`,
        });
        return;
      }

      // This is a valid section code, redirect to the section login page.
      setErrors({});
      history.push(`/login/section/${code}`);
    },
    [history, setErrors]
  );

  return (
    <LoginStyles>
      <img src="/static/assets/hello-world-logo-full.png" />
      <Box display="flex" flexDirection="row" justifyContent="center">
        <div className="row">
          <SectionCodeLogin
            error={errors?.code}
            onSubmit={onSubmitSectionCode}
          />
          <div className="or">OR</div>
          <UsernamePasswordLogin
            error={errors?.username}
            onSubmit={onSubmitUsernamePassword}
          />
        </div>
      </Box>
    </LoginStyles>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
