import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  .a {
    fill: none;
    stroke: ${({ theme }) => theme.palette.text.icon};
    stroke-width: 2px;
    filter: drop-shadow(0px 0px 56px rgba(0, 0, 0, 0.502));
  }
  .b {
    fill: none;
  }
  .c {
    fill: ${({ theme }) => theme.palette.text.icon};
  }
  .d {
    stroke: none;
  }
`;

const Locked = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 40 40"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <g className="a">
      <circle className="d" cx="20" cy="20" r="20" />
      <circle className="b" cx="20" cy="20" r="18.5" />
    </g>
    <g transform="translate(8 7.621)">
      <path className="b" d="M0,0H24V24H0Z" />
      <path
        className="c"
        d="M18,8H17V6A5,5,0,0,0,7,6V8H6a2.006,2.006,0,0,0-2,2V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V10A2.006,2.006,0,0,0,18,8Zm-6,9a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,17Zm3.1-9H8.9V6a3.1,3.1,0,0,1,6.2,0Z"
      />
    </g>
  </StyledSvgIcon>
));

Locked.displayName = 'Locked';

Locked.propTypes = {
  size: PropTypes.number,
};

export default Locked;
