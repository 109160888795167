import { DialogContent, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

// This component provides HWCS styling on top of the standard material ui dialog
export const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
    background-color: ${theme.palette.primary.main};
    
    h2 {
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
    }
  `
);

export const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    color: ${theme.palette.text.secondary};
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    
    button {
      height: 35px;
      color: white;
      font-size: 14px;
      font-weight: 400;
      
      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;
      }
    }
    
    div {
      display: flex;
      padding: 10px;
      justify-content: center;
    } 
  `
);
