import PropTypes from 'prop-types';
import React from 'react';
import { ScoringFeedbackContainer } from './components/feedback';
import { useProgress } from '../contexts';

export const ScoringFeedback = ({ feedback, onChange }) => {
  const { progress } = useProgress();

  return (
    <ScoringFeedbackContainer
      feedback={feedback}
      isEditable={progress?.status === 'grading'}
      onChange={onChange}
    />
  );
};
ScoringFeedback.propTypes = {
  feedback: PropTypes.string,
  onChange: PropTypes.func,
};
