import { memoize, useAxiosClient } from './client';

/**
 * Asynchronously loads the sections that a teacher is teaching along with
 * basic information about that section.
 */
const getSections = memoize(teacherId => {
  const client = useAxiosClient();

  return client
    .get(`/teachers/${teacherId}/sections/metadata`)
    .then(response => response.data);
});

/**
 * Asynchronously loads the ids of the CoSpaces classes that a teacher is able to
 * score work from.
 */
const getScorableCoSpaceClasses = memoize(teacherId => {
  const client = useAxiosClient();

  return client
    .get(`/teachers/${teacherId}/scorable/cospaces`)
    .then(response => response.data);
});

/**
 * Asynchronously loads the scoring status on a project for students in a
 * section.
 */
const getScoringStudents = memoize((sectionId, projectId) => {
  const client = useAxiosClient();

  return client
    .get(`/sections/${sectionId}/projects/${projectId}/submissions/status`)
    .then(response => response.data);
});

const getGradebookProjects = memoize((sectionId, courseId, levelId) => {
  const client = useAxiosClient();

  return client
    .get(
      levelId
        ? `/sections/${sectionId}/courses/${courseId}/levels/${levelId}/projects`
        : `/sections/${sectionId}/courses/${courseId}/projects`
    )
    .then(response => {
      const projects = response.data ?? [];
      return {
        projects: projects,
      };
    });
});

const getGradebookSubmissions = memoize((sectionId, levelId) => {
  const client = useAxiosClient();

  return client
    .get(
      levelId
        ? `/sections/${sectionId}/levels/${levelId}/submissions`
        : `/sections/${sectionId}/submissions`
    )
    .then(response => {
      const objects = response.data ?? [];

      return {
        students: objects.map(o => o.student),
        progresses: Object.fromEntries(
          objects.map(o => [o.student.id, o.progresses])
        ),
      };
    });
});

export default {
  getSections: getSections,
  getScorableCoSpaceClasses: getScorableCoSpaceClasses,
  scoring: {
    getStudents: getScoringStudents,
  },
  dashboards: {
    gradebook: {
      getProjects: getGradebookProjects,
      getSubmissions: getGradebookSubmissions,
    },
  },
};
