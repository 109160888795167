import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const ButtonsStyles = styled.div(
  ({ theme }) => `
    display: flex;
    gap: ${theme.spacing(2)}px;
    justify-content: center;
    padding: ${theme.spacing(2, 0, 4, 0)};

    button {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      min-width: 120px;

      &:hover {
        opacity: 0.8;
      }

      &.Mui-disabled {
        filter: none;
        opacity: 0.5;
      }

      &.edit-score {
        background-color: ${theme.palette.actions.main};
        color: ${theme.palette.text.primary};
      }

      &.send-back {
        background-color: ${theme.palette.background.level1};
        color: #303030;
      }

      &.finalize {
        background-color: ${theme.palette.actions.main};
        color: ${theme.palette.text.primary};
      }
    }
  `
);

export const ButtonsContainer = ({
  isEditing,
  isDisabled,
  onEditScore,
  onSendBack,
  onFinalize,
}) => {
  if (!isEditing) {
    return (
      <ButtonsStyles>
        <Button className="edit-score" onClick={onEditScore}>
          EDIT SCORE
        </Button>
      </ButtonsStyles>
    );
  }

  return (
    <ButtonsStyles>
      <Button className="send-back" disabled={isDisabled} onClick={onSendBack}>
        SEND BACK
      </Button>
      <Button className="finalize" disabled={isDisabled} onClick={onFinalize}>
        FINALIZE
      </Button>
    </ButtonsStyles>
  );
};
ButtonsContainer.propTypes = {
  isEditing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onEditScore: PropTypes.func,
  onSendBack: PropTypes.func,
  onFinalize: PropTypes.func,
};

const SelectAllStyles = styled.div(
  ({ theme }) => `
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 500;
    
    .text {
      align-self: center;
      color: ${theme.palette.text.secondary};
      padding: ${theme.spacing(0, 2)};
    }
    
    .checkbox {
      .MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: ${theme.palette.secondary.main};
      }
      .MuiCheckbox-root.Mui-disabled {
        color: #dddddd;
      }
    }
  `
);

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    maxWidth: theme.spacing(20),
    padding: theme.spacing(1),
  },
}))(Tooltip);

export const SelectAllContainer = ({
  isEditing,
  isDisabled,
  state,
  onChange,
}) => {
  const isChecked = state !== 'indeterminate' && state === 'checked';
  const isIndeterminate = state === 'indeterminate';

  // Because the checkbox disables when it's clicked the tooltip doesn't always
  // go away like desired.  To overcome this we'll manually manage the state of
  // the tooltip so that we can close it when the checkbox is clicked.
  const [isOpen, setOpen] = React.useState(false);
  const onCheckboxClick = React.useCallback(
    e => {
      setOpen(false);
      onChange(e);
    },
    [onChange]
  );

  return (
    <SelectAllStyles>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item className="text">
          Select All
        </Grid>
        <Grid item className="checkbox">
          <StyledTooltip
            title="Select All"
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
          >
            <Checkbox
              checked={isChecked}
              indeterminate={isIndeterminate}
              onChange={onCheckboxClick}
              disabled={isDisabled || !isEditing}
            />
          </StyledTooltip>
        </Grid>
      </Grid>
    </SelectAllStyles>
  );
};
SelectAllContainer.propTypes = {
  isEditing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  state: PropTypes.string,
  onChange: PropTypes.func,
};
