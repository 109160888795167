import * as lodash from 'lodash';
import { memoize, noeslint, useAxiosClient } from './client';

class User {
  isTeacherForAnySections() {
    const sections = this?.teacher?.sections ?? [];
    return sections.length > 0;
  }

  isTeacherForSection(sectionId) {
    const sections = this?.teacher?.sections ?? [];
    return sections.indexOf(sectionId) !== -1;
  }

  isTeacherForCourse(courseId) {
    const courses = this?.teacher?.courses ?? [];
    return courses.indexOf(courseId) !== -1;
  }

  isTeacherForProject(projectId) {
    const projects = this?.teacher?.projects ?? [];
    return projects.indexOf(projectId) !== -1;
  }
}

/**
 * Asynchronously load a user.
 */
const getUser = memoize(userId => {
  const client = useAxiosClient();

  return client
    .get(`/users/${userId}`)
    .then(response => response.data)
    .then(data => lodash.create(User.prototype, data));
});

/**
 * Asynchronously load all project controls and statuses for a given user,
 * section, and course.
 */
const getSectionProjectControlsAndStatuses = memoize(
  (userId, sectionId, courseId) => {
    const client = useAxiosClient();

    return client
      .get(
        `/users/${userId}/sections/${sectionId}/courses/${courseId}/projects/statuses`
      )
      .then(response => response.data);
  }
);

/**
 * Asynchronously load the project control for a given user, section, and
 * project.
 */
const getProjectControl = memoize((userId, sectionId, projectId) => {
  const client = useAxiosClient();

  return (
    client
      .get(
        `/users/${userId}/sections/${sectionId}/projects/${projectId}/control`
      )

      // Because we attach callbacks to the returned value, it needs to
      // always be an object.
      .then(response => ({ value: response.data }))
  );
});

/**
 * Asynchronously load all sections that a given user is part of.
 */
const getSectionsMetadata = memoize(userId => {
  const client = useAxiosClient();

  return client
    .get(`/users/${userId}/sections/metadata`)
    .then(response => response.data);
});

/**
 * Asynchronously attempt to authenticate as a user.  Must be called by an admin
 * user.
 */
const authenticateAsUser = noeslint(userId => {
  const client = useAxiosClient();
  return client
    .post(`/users/${userId}/authentication`)
    .then(response => response.data);
});

/**
 * Asynchronously attempt to onboard a user for Hour of Code.
 */
const onboardForHourOfCode = noeslint(userId => {
  const client = useAxiosClient();
  return client
    .post(`/users/${userId}/onboarding/hour-of-code`)
    .then(response => response.data);
});

export default {
  authenticateAsUser,
  getProjectControl,
  getSectionProjectControlsAndStatuses,
  getUser,
  getSectionsMetadata,
  onboardForHourOfCode,
};
