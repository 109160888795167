import * as lodash from 'lodash';
import {
  BodyContainer,
  Layout,
  ResponsiveContainer,
} from '../../../../components/Layout';
import { DashboardHeader, StudentProjectTable } from '../../components';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { SectionMetadataProvider, useSectionMetadata } from './contexts';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../api';
import AppBarHeader from '../../../../components/AppBarHeader';
import { LoadingIndicator } from '../../../../components';
import NavTeacher from '../../../../components/NavTeacher';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { useUser } from '../../../../contexts/AuthContext';

const ControlsStyles = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacing(2)}px;
    overflow: hidden;

    font-family: 'Poppins', sans-serif;
    color: ${theme.palette.text.secondary};
    padding: ${theme.spacing(2, 4)};
    
    .title {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }   
    
    .cell {
      padding: ${theme.spacing(0, 4)};
      width: 100%;

      .MuiInputBase-root {
        color: ${theme.palette.text.secondary};
        font-size: 12px;
        font-weight: 400;
      }
      
      .MuiInput-underline:after {
        border-bottom: 0;
      }
      
      .MuiSelect-icon {
        color: #c4c4c4;
      }
    }
    
    .cell:hover {
      .MuiInputBase-root, .MuiSelect-icon {
        color: #36b5bf;
        font-weight: 600;
      }
    }
  `
);

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(1),
    maxWidth: theme.spacing(22),
  },
}))(Tooltip);

const CONTROL_DISPLAY_NAMES = {
  available: 'Available',
  blocked: 'Blocked',
  excused: 'Excused',
};

const STATUS_DISPLAY_NAMES = {
  completed: 'Completed',
  grading: 'Ready to Score',
  in_progress: 'In Progress',
  not_started: 'Not Started',
  sent_back: 'Sent Back',
};

const STATUS_TOOLTIPS = {
  completed: {
    header: 'Scored:',
    text: 'This project has been submitted and scored.',
  },
  grading: {
    header: 'Ready to Score:',
    text: 'The student has submitted this project and it is ready to score.',
  },
  in_progress: {
    header: 'In Progress:',
    text: 'This student has already started this project.',
  },
  not_started: {
    header: 'Not Started:',
    text: 'This student will be able to access this project when they reach it.',
  },
  sent_back: {
    header: 'Sent Back:',
    text: 'This project has been sent back to the student to be resubmitted.',
  },
};

const CONTROL_TOOLTIPS = {
  excused: {
    header: 'Excuse:',
    text: 'If selected, this student can skip this project. This project can be reviewed or completed, but not submitted.',
  },
  blocked: {
    header: 'Block:',
    text: 'If selected, this student will not be able to access this project.',
  },
  available: {
    header: 'Available:',
    text: 'If selected, this student will be able to access this project and submit it, even if they haven’t reached it yet.',
  },
};

const TooltipWithHeader = React.forwardRef(({ tooltip, children }, ref) => {
  const title = (
    <>
      <div style={{ fontWeight: 600, textAlign: 'center' }}>
        {tooltip.header}
      </div>
      <div>{tooltip.text}</div>
    </>
  );
  return (
    <StyledTooltip ref={ref} placement="right-start" title={title}>
      <div style={{ width: '100%' }}>{children}</div>
    </StyledTooltip>
  );
});
TooltipWithHeader.displayName = 'TooltipWithHeader';
TooltipWithHeader.propTypes = {
  tooltip: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
  }),
  children: PropTypes.node,
};

// These styles have to live here instead of in a styled component because the
// dropdown and corresponding menu items are placed in the DOM by MaterialUI
// outside the above ControlsStyles component.
const useStyles = makeStyles(theme => ({
  dropdownStyle: {
    backgroundColor: theme.palette.background.level1,
    color: theme.palette.text.secondary,
    borderRadius: '5px',
    '& .MuiList-padding': {
      paddingBottom: '3px',
      paddingTop: '3px',
      width: '100%',
    },
  },
  menuItem: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    padding: '8px',
    width: '100%',
    '&.Mui-selected:hover, &:hover': {
      backgroundColor: theme.palette.background.level3,
    },
    '&.Mui-disabled': {
      color: '#908f8f',
      opacity: 1,
    },
  },
}));

const EditableCell = ({ control, status, onChange }) => {
  const classes = useStyles();
  const { isUpdating } = useSectionMetadata();

  const label =
    control !== 'none'
      ? CONTROL_DISPLAY_NAMES[control]
      : STATUS_DISPLAY_NAMES[status];

  return (
    <FormControl className="cell">
      <Select
        value={control}
        renderValue={() => label}
        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        onChange={e => onChange(e.target.value)}
        disabled={isUpdating}
      >
        <MenuItem value="none" classes={{ root: classes.menuItem }}>
          <TooltipWithHeader value="none" tooltip={STATUS_TOOLTIPS[status]}>
            <div>{STATUS_DISPLAY_NAMES[status]}</div>
          </TooltipWithHeader>
        </MenuItem>
        <MenuItem value="excused" classes={{ root: classes.menuItem }}>
          <TooltipWithHeader
            value="excused"
            tooltip={CONTROL_TOOLTIPS['excused']}
          >
            <div>Excuse</div>
          </TooltipWithHeader>
        </MenuItem>
        <MenuItem value="blocked" classes={{ root: classes.menuItem }}>
          <TooltipWithHeader
            value="blocked"
            tooltip={CONTROL_TOOLTIPS['blocked']}
          >
            <div>Block</div>
          </TooltipWithHeader>
        </MenuItem>
        {status === 'not_started' && (
          <MenuItem value="available" classes={{ root: classes.menuItem }}>
            <TooltipWithHeader
              value="available"
              tooltip={CONTROL_TOOLTIPS['available']}
            >
              <div>Available</div>
            </TooltipWithHeader>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
EditableCell.propTypes = {
  control: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func,
};

const Controls = ({
  sectionId,
  courseId,
  levelId,
  sections,
  submissions,
  currentSection,
}) => {
  const history = useHistory();
  const { metadata, setControls } = useSectionMetadata();

  const onChangeSection = React.useCallback(
    (newSectionId, newCourseId, newLevelId) => {
      history.push(
        `/teacher/dashboards/controls/section/${newSectionId}/course/${newCourseId}/level/${newLevelId}`
      );
    },
    [history]
  );

  const onChangeCourse = React.useCallback(
    (newCourseId, newLevelId) => {
      history.push(
        `/teacher/dashboards/controls/section/${sectionId}/course/${newCourseId}/level/${newLevelId}`
      );
    },
    [history, sectionId]
  );

  const onChangeLevel = React.useCallback(
    newLevelId => {
      if (newLevelId === 'all') {
        history.push(
          `/teacher/dashboards/controls/section/${sectionId}/course/${courseId}`
        );
        return;
      }

      history.push(
        `/teacher/dashboards/controls/section/${sectionId}/course/${courseId}/level/${newLevelId}`
      );
    },
    [history, sectionId, courseId]
  );

  const onChangeStudentControl = React.useCallback(
    (studentId, projectId, control) => {
      setControls({
        ...metadata.section.controls,
        'per-student': {
          ...metadata.section.controls?.['per-student'],
          [studentId]: {
            ...metadata.section.controls?.['per-student']?.[studentId],
            [projectId]: control,
          },
        },
      });
    },
    [metadata.section, setControls]
  );

  const students = React.useMemo(
    () =>
      (submissions.students ?? []).map(student => ({
        id: student.id,
        title: `${student.first_name} ${student.last_name}`,
      })),
    [submissions]
  );

  const projects = React.useMemo(
    () =>
      (metadata.course?.levels ?? []).flatMap(level => {
        if (levelId && levelId !== level.id) {
          return [];
        }

        return (level?.projects ?? []).map(project => ({
          id: project.id,
          title: project.title,
        }));
      }),
    [levelId, metadata]
  );

  const getCell = React.useCallback(
    (studentId, projectId) => {
      const controls = metadata.section.controls;
      const control =
        controls?.['per-student']?.[studentId]?.[projectId] ?? 'none';
      const status =
        submissions?.progresses?.[studentId]?.[projectId]?.status ??
        'not_started';

      return (
        <EditableCell
          control={control}
          status={status}
          onChange={control =>
            onChangeStudentControl(studentId, projectId, control)
          }
        />
      );
    },
    [metadata, submissions, onChangeStudentControl]
  );

  return (
    <>
      <AppBarHeader
        menu={<NavTeacher selected="sections" />}
        title="Sections"
      />
      <Layout>
        <ResponsiveContainer>
          <BodyContainer noProgressBar>
            <DashboardHeader
              image={metadata.course.media}
              levelId={levelId}
              levels={metadata.course.levels}
              onChangeLevel={onChangeLevel}
              sectionId={sectionId}
              sectionMetadata={metadata.section}
              sections={sections}
              onChangeSection={onChangeSection}
              onChangeCourse={onChangeCourse}
              isControls
              courses={currentSection.courses}
              courseId={courseId}
            />
            <ControlsStyles>
              <div className="title">
                Choose which projects your students can access.
              </div>
              <StudentProjectTable
                students={students}
                projects={projects}
                getCell={getCell}
              />
            </ControlsStyles>
          </BodyContainer>
        </ResponsiveContainer>
      </Layout>
    </>
  );
};
Controls.propTypes = {
  sectionId: PropTypes.string,
  levelId: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section_id: PropTypes.string,
      section_title: PropTypes.string,
    })
  ),
  submissions: PropTypes.shape({
    students: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      })
    ),
    progresses: PropTypes.object,
  }),
};

const ControlsOuter = () => {
  const user = useUser();
  const { sectionId, courseId, levelId } = useParams();

  const [loading, sections, submissions, , teacherEnrollments, allCourses] =
    api.load(
      api.teacher.getSections(user.id),
      api.teacher.dashboards.gradebook.getSubmissions(sectionId, levelId),

      // HACK: When transitioning back to the grade book from this page if the
      // section has been changed the grade book will flicker because it first
      // renders with old, cached data and then immediately re-renders with
      // updated data from the API.  This happens because it doesn't have as good
      // of a data propagation design as the controls page does.  To prevent this
      // flickering, we're repeating the below API calls whenever the section or
      // level changes so that the data is ready to go when the grade book is
      // returned to.  This eliminates the flickering, but is a hack because the
      // real fix would be to make the grade book have a better data propagation
      // design.
      api.teacher.dashboards.gradebook.getProjects(
        sectionId,
        courseId,
        levelId
      ),
      api.roster.sections.getTeacherEnrollments(),
      api.curriculum.courses.get()
    );

  const currentSection = React.useMemo(() => {
    if (loading) return {};
    const courseMap = new Map();
    allCourses.forEach(course => {
      courseMap.set(course.id, course);
    });
    let currentSection = {};
    teacherEnrollments.forEach(enrollment => {
      if (enrollment.id === sectionId) {
        currentSection = enrollment;
      }
      enrollment.courses = lodash.orderBy(
        enrollment.courses.map(c =>
          courseMap.get(c.course_id)
            ? {
                course_id: c.course_id,
                name: courseMap.get(c.course_id)?.name,
                sort_key: courseMap.get(c.course_id)?.sort_key,
                first_level_id: courseMap.get(c.course_id)?.levels[0].id,
              }
            : {
                course_id: c.course_id,
                name: '',
                sort_key: '',
                first_level_id: '',
              }
        ),
        ['sort_key'],
        ['asc']
      );
    });

    return currentSection;
  }, [loading, allCourses, teacherEnrollments, sectionId]);

  React.useEffect(() => {
    if (loading) {
      return;
    }

    return submissions?.cleanup;
  }, [loading, submissions]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <SectionMetadataProvider sectionId={sectionId} courseId={courseId}>
      <Controls
        sectionId={sectionId}
        courseId={courseId}
        levelId={levelId}
        sections={sections}
        submissions={submissions}
        currentSection={currentSection}
      />
    </SectionMetadataProvider>
  );
};

export default ControlsOuter;
