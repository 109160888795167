/**
 * Helper to determine the status of an entity given the statuses of its
 * children.  This is only ever called for non-projects, so controls don't
 * come into play here.
 */
const getStatus = (entries, required) => {
  const statuses = Object.values(entries).map(entry => entry.status);

  const numComplete = statuses.filter(
    status => status === 'grading' || status === 'completed'
  ).length;

  if (numComplete >= required) {
    return 'completed';
  }

  const hasStarted = !statuses.every(
    status => status === 'not_started' || status === 'excused'
  );
  if (hasStarted) {
    return 'in_progress';
  }

  return 'not_started';
};

const getProjectProgress = (projectId, statuses, controls, found) => {
  const control = controls[projectId] ?? 'none';

  // Allow the control to override the status.  We don't override the status
  // for available projects because that control only impacts the playability
  // of a project.
  let status = statuses[projectId] ?? 'not_started';
  if (control === 'blocked' || control === 'excused') {
    status = control;
  }

  // A project is playable under certain conditions:
  // 1. It's already been started
  // 2. It's been sent back by a teacher/grader
  // 3. It's not been started, and we haven't found our first playable project
  // 4. It's been excused, and we haven't found our first playable project
  const isPlayable =
    control === 'available' ||
    status === 'in_progress' ||
    status === 'sent_back' ||
    (status === 'not_started' && !found) ||
    (status === 'excused' && !found);

  // Blocked and excused projects don't influence the first playable project
  // calculation.  Also, available projects that have been completed also don't
  // influence the first playable project calculation.
  const updateFound =
    control === 'none' ||
    (control === 'available' && status !== 'grading' && status !== 'completed');
  if (updateFound) {
    found |= isPlayable;
  }

  const progress = {
    status: status,
    complete: status === 'completed' || status === 'grading',
    playable: isPlayable,
  };
  return [progress, found];
};

const getProjectGroupProgress = (group, statuses, controls, found) => {
  const projects = {};
  const founds = {};
  for (const { id: projectId } of group.projects) {
    // When calculating project progress in an unordered project group we need
    // to do things differently.  When calculating progress for the projects
    // in an unordered project group we won't let an earlier seen project from
    // the group influence whether or not the current project is playable.
    const groupFound = !group.ordered
      ? found
      : found || Object.values(founds).some(found => found);

    [projects[projectId], founds[projectId]] = getProjectProgress(
      projectId,
      statuses,
      controls,
      groupFound
    );
  }

  // Determine a "credit" that should be applied to the number of required
  // projects.  This credit will be used to relax the number of required
  // projects in order to ensure that the project group can still be completed
  // even though some projects may have been blocked or excused.
  const numProjects = Object.keys(projects).length;
  const numBlockedOrExcused = Object.values(projects).filter(
    project => project.status === 'blocked' || project.status === 'excused'
  ).length;
  const credit =
    numProjects - numBlockedOrExcused >= group.required
      ? 0
      : group.required - numProjects + numBlockedOrExcused;
  const status = getStatus(projects, group.required - credit);

  // We've found a first playable project only if this group is not complete.
  // If the group is complete but we've discovered a playable project, then
  // this is an independent project group and we've already completed enough
  // projects to move forward.
  found |= status !== 'completed' && Object.values(founds).some(found => found);

  const progress = {
    status: status,
    projects: projects,
    complete: status === 'completed',
    playable: Object.values(projects).some(progress => progress.playable),
  };
  return [progress, found];
};

const getLevelProgress = (level, statuses, controls, found) => {
  const groups = {};
  for (const group of level.project_groups) {
    [groups[group.id], found] = getProjectGroupProgress(
      group,
      statuses,
      controls,
      found
    );
  }

  const status = getStatus(groups, Object.keys(groups).length);
  const progress = {
    status: status,
    project_groups: groups,
    complete: status === 'completed',
    playable: Object.values(groups).some(progress => progress.playable),
  };

  return [progress, found];
};

/**
 * Given the course definition as well as statuses for individual projects,
 * definition, determine the playability state for every level, project group,
 * and project within the course.
 *
 * This method will return the course hierarchy where each level contains three
 * fields.  The 'status' field contains the string based status of that entity.
 * The 'complete' field is a boolean that determines whether or not the entity
 * is complete.  Lastly, the 'playable' field is a boolean that determines
 * whether or not the entity is playable.
 */
export const getCourseProgress = (
  course,
  statuses,
  controls,
  found = false
) => {
  const levels = {};
  for (const level of course.levels) {
    [levels[level.id], found] = getLevelProgress(
      level,
      statuses,
      controls,
      found
    );
  }

  const status = getStatus(levels, Object.keys(levels).length);
  return {
    status: status,
    levels: levels,
    complete: status === 'completed',
    playable: status !== 'completed',
  };
};

// This is a variant from getCourseProgress but it allows full playability access
// for projects inside a course, for example to a course's teacher
export const getCourseProgressFullAccess = (course, statuses) => {
  const fullAccess = {
    status: 'completed',
    complete: true,
    playable: true,
  };

  const levels = {};
  for (const level of course.levels) {
    const groups = {};
    for (const group of level.project_groups) {
      const projects = {};
      for (const { id: projectId } of group.projects) {
        projects[projectId] = {
          ...fullAccess,
          status: statuses[projectId] ?? 'not_started',
        };
      }
      groups[group.id] = {
        ...fullAccess,
        projects: projects,
      };
    }
    levels[level.id] = {
      ...fullAccess,
      project_groups: groups,
    };
  }

  return {
    ...fullAccess,
    levels: levels,
  };
};
