import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ChipContainer = styled.div(
  ({ theme }) => `
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 4px;
    font-family: 'IBM Plex Mono', monospace;
    font-size: 14px;
    height: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2)}px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    overflow: hidden;
    white-space: nowrap;
  `
);

const Chip = props => {
  const { text } = props;

  return <ChipContainer>{text}</ChipContainer>;
};

Chip.propTypes = {
  text: PropTypes.string,
};

export default Chip;
