import { BlockGroupContainer, MediaBlock } from '../../components/Blocks';
import { CoSpacesActivity, PickcodeActivity } from '../../components/Activity';
import { useProgress, useSection } from './contexts';
import { CheckpointBlock } from './index';
import { CheckpointSet } from './components';
import CodeResponse from '../../components/Activity/code-response';
import PropTypes from 'prop-types';
import React from 'react';
import { StepHeader } from '../../components/Layout';
import styled from 'styled-components';
import { useCheckpointAnswer } from './hooks';
import { useProject } from '../../contexts/ProjectContext';
import { useUser } from '../../contexts/AuthContext';
import WrittenResponse from '../../components/Activity/written-response';

const CheckpointSetActivity = ({ activity }) => {
  // Build a mapping of metadata about each checkpoint indexed by the
  // checkpoint's id.
  const { progress } = useProgress();

  const metadata = React.useMemo(() => {
    const checkpoints = activity.checkpoints;

    return Object.fromEntries(
      checkpoints.map((cp, index) => [
        cp.id,
        {
          id: cp.id,
          block: cp,
          index: index,
          complete: progress?.checkpoints?.[cp.id]?.complete ?? false,
          prev: checkpoints?.[index - 1]?.id ?? null,
          next: checkpoints?.[index + 1]?.id ?? null,
        },
      ])
    );
  }, [activity, progress]);

  // Build a list of the checkpoint metadata in the order that they'll be
  // shown to the student.  This is suitable for sharing with a presentational
  // component because it contains nothing about blocks.
  const checkpoints = React.useMemo(() => {
    return Object.values(metadata)
      .sort(cp => cp.index)
      .map(cp => {
        return {
          id: cp.id,
          complete: cp.complete,
        };
      });
  }, [metadata]);

  const [current, setCurrent] = React.useState(() => {
    // We'll start off on the first non-answered checkpoint or if all of the
    // checkpoints are answered then the first one in the set.
    return checkpoints.find(cp => !cp.complete)?.id ?? checkpoints[0].id;
  });

  const jump = id => {
    if (!id) {
      return;
    }

    setCurrent(id);
  };

  const prev = () => {
    jump(metadata[current].prev);
  };

  const next = () => {
    jump(metadata[current].next);
  };

  // This will add the current and total numbers of questions
  // to the Checkpoint title like '<BlockTitle> <current> of <total>'
  const titledBlock = {
    ...metadata[current].block,
    title: `
      ${metadata[current].block.title ?? 'Question'} 
      ${metadata[current].index + 1} of ${checkpoints.length}
    `,
  };

  return (
    <CheckpointSet
      checkpoints={checkpoints}
      current={current}
      jump={jump}
      next={next}
      prev={prev}
    >
      <BlockGroupContainer>
        <CheckpointBlock block={titledBlock} />
      </BlockGroupContainer>
    </CheckpointSet>
  );
};

CheckpointSetActivity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
    checkpoints: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        variant: PropTypes.string,
      })
    ),
  }),
};

const WrittenResponseActivity = ({ activity }) => {
  const { answer, save } = useCheckpointAnswer(activity?.id);

  return (
    <WrittenResponse
      key={activity?.id}
      onChange={text => save(text, true)}
      text={answer.value}
    />
  );
};

WrittenResponseActivity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const CodeResponseActivity = ({ activity }) => {
  const { answer, save } = useCheckpointAnswer(activity?.id);

  return (
    <CodeResponse
      key={activity?.id}
      onChange={text => save(text, true)}
      text={answer.value}
    />
  );
};
CodeResponseActivity.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
  }),
};

const StyledActivity = styled.div(
  ({ $fullWidth }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    .activity-container {
      width: ${$fullWidth ? '75%' : '100%'};
    }
  `
);

const ActivityBody = ({ activity, showCode }) => {
  const user = useUser();
  const project = useProject();
  const section = useSection();

  switch (activity.tool) {
    case 'code-response':
      return <CodeResponseActivity activity={activity} />;

    case 'checkpoint-set':
      return <CheckpointSetActivity key={activity.id} activity={activity} />;

    case 'cospaces':
      return (
        <CoSpacesActivity
          activity={activity}
          sectionId={section.id}
          projectId={project.id}
        />
      );

    case 'media':
      return <MediaBlock block={activity} />;

    case 'pickcode':
      return (
        <PickcodeActivity
          activity={activity}
          projectId={project.id}
          ownerId={user.id}
          includeCode={showCode}
        />
      );

    case 'written-response':
      return <WrittenResponseActivity activity={activity} />;

    default:
      throw new Error(`unrecognized activity tool: ${activity.tool}`);
  }
};

const Activity = ({
  activity,
  sectionTitle,
  showHeader,
  stepTitle,
  showCode,
}) => {
  if (!activity) {
    return null;
  }

  return (
    <StyledActivity $fullWidth={showHeader}>
      {showHeader && <StepHeader section={sectionTitle} step={stepTitle} />}
      <ActivityBody activity={activity} showCode={showCode} />
    </StyledActivity>
  );
};

Activity.propTypes = {
  activity: PropTypes.shape({
    action: PropTypes.string,
    id: PropTypes.string,
    tool: PropTypes.string,
    url: PropTypes.string,
  }),
  sectionTitle: PropTypes.string,
  showHeader: PropTypes.bool,
  stepTitle: PropTypes.string,
  showCode: PropTypes.bool,
};

export default Activity;
