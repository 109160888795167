import { useRenderState, useSubmissions } from '../contexts';
import React from 'react';
import { StepNav } from '../../../components/Layout';

export const ProgressNav = () => {
  const render = useRenderState();
  const { submissions } = useSubmissions();

  const completed = submissions.filter(s => s.project_status === 'completed');
  const available = submissions.filter(
    s => s.control !== 'blocked' && s.control !== 'excused'
  );

  return (
    <StepNav
      // It's okay to enable the buttons all of the time in the teacher
      // experience because navigating doesn't make any external API calls.
      // TODO: we'll probably want to disable the next/prev buttons when we're
      // saving student progress to the database
      prev={{
        show: render.hasPrev,
        enabled: true,
        onClick: render.prev,
      }}
      next={{
        show: render.hasNext,
        enabled: true,
        onClick: render.next,
      }}
      hasProgress
      label="Scoring Progress"
      numStepsComplete={completed.length}
      numSteps={available.length}
    />
  );
};
