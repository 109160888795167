import Button from '@material-ui/core/Button';
import { FrameCard } from '../../Frames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TeacherTip from '../../TeacherTip';
import TextField from '@material-ui/core/TextField';
import { TextWithAudio } from '../../Audio';
import Typography from '@material-ui/core/Typography';

const ShortAnswerCheckpointStyles = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)}px;
    padding: ${theme.spacing(2, 4)};
    
    .question {
      display: inline;  // needed to ensure audio icon positions properly
      font-family: 'IBM Plex Mono', monospace;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }
    
    .MuiTextField-root {
      padding: ${theme.spacing(3, 0, 0, 0)};
      
      textarea {
        color: ${theme.palette.text.secondary};
        font-family: 'IBM Plex Mono', monospace;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;

        // Show the lines
        background-image: repeating-linear-gradient(
          transparent,
          transparent 31px,
          rgba(112, 112, 112, 0.19) 31px,
          rgba(112, 112, 112, 0.19) 32px
        );
      }
    }
      
    .MuiInput-underline {
      border-bottom: none;
      &:hover::before {
        border-bottom: none;
      }
      &::before {
        border-bottom: none;
      }
      &::after {
        border-bottom: none;
      }
    }
    
    .MuiButton-root {
      align-self: flex-end;
      width: ${theme.spacing(8)}px;
    }
  `
);

export const ShortAnswerCheckpoint = ({
  checkpoint,
  answer,
  onChange,
  isUpdating,
  readOnly = false,
}) => {
  const [value, setValue] = React.useState(answer ?? '');
  const isButtonDisabled = isUpdating || readOnly || !value || value === answer;

  return (
    <FrameCard
      title={checkpoint?.title ?? 'Checkpoint'}
      padding={{ top: 4, bottom: 0 }}
    >
      <ShortAnswerCheckpointStyles>
        <TextWithAudio audio={checkpoint?.audio}>
          <Typography className="question" variant="body1">
            {checkpoint.question}
          </Typography>
          {checkpoint?.tip && <TeacherTip content={checkpoint.tip} />}
        </TextWithAudio>
        <TextField
          multiline
          minRows={3}
          placeholder={value !== '' ? value : 'Type your answer here'}
          value={value}
          onChange={event => setValue(event.target.value)}
          disabled={readOnly}
        />
        <Button
          color="primary"
          variant="outlined"
          disabled={isButtonDisabled}
          onClick={() => onChange(value)}
        >
          SAVE
        </Button>
      </ShortAnswerCheckpointStyles>
    </FrameCard>
  );
};

ShortAnswerCheckpoint.propTypes = {
  checkpoint: PropTypes.shape({
    title: PropTypes.string,
    question: PropTypes.string,
    audio: PropTypes.string,
    tip: PropTypes.array,
  }),
  answer: PropTypes.string,
  isUpdating: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
