import * as externalAccount from '../../utils/externalAccountLogin';
import { getCourseProgress, getCourseProgressFullAccess } from './playability';
import React, { useEffect, useMemo } from 'react';
import { useParams, useRouteMatch, withRouter } from 'react-router-dom';
import api from '../../api';
import { ArchivedCoursesModal } from './components/modal';
import ArchivedEntityAlertBar from '../../components/AlertBar';
import CourseProvider from '../../contexts/CourseContext';
import Level from '../Level';
import LoadingIndicator from '../../components/LoadingIndicator';
import Nav from './components/Nav';
import NavTeacher from '../../components/NavTeacher';
import Page from '../../components/Page';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { StepNav } from '../../components/Layout';
import styled from 'styled-components';
import useCourse from '../../hooks/useCourse';
import { useUser } from '../../contexts/AuthContext';

const CourseContainer = styled.div(
  () => `
    justify-content: start;
    height: 100%;
    overflow: hidden;
  `
);

const CourseBase = ({ history }) => {
  const user = useUser();
  const { isLoading, course, currentLevelId, setCurrentLevelId } = useCourse();
  const {
    params: { courseId, sectionId, levelId },
  } = useRouteMatch();
  const [modalSettings, setModalSettings] = React.useState({ show: false });
  const [bannerSettings, setBannerSettings] = React.useState({ show: false });

  const [loading, projectsMetadata, section, statusesAndControls] = api.load(
    api.courses.getProjectsMetadata(courseId),
    api.section.getTitle(sectionId),
    api.users.getSectionProjectControlsAndStatuses(user.id, sectionId, courseId)
  );

  // Cleans up the progress cache when leaving project view
  React.useEffect(() => {
    if (loading) {
      return;
    }
    return statusesAndControls?.cleanup;
  }, [loading, statusesAndControls]);

  // Compute the course progress
  const progress = useMemo(() => {
    if (isLoading || loading) {
      return;
    }

    const statuses = Object.fromEntries(
      Object.entries(statusesAndControls)
        .filter(([, sc]) => sc?.status !== undefined)
        .map(([projectId, sc]) => [projectId, sc.status])
    );
    const controls = Object.fromEntries(
      Object.entries(statusesAndControls)
        .filter(([, sc]) => sc?.control !== undefined)
        .map(([projectId, sc]) => [projectId, sc.control])
    );

    // If user is the course teacher we give full playability access
    // to projects inside the course
    return user.isTeacherForCourse(course.id)
      ? getCourseProgressFullAccess(course, statuses)
      : getCourseProgress(course, statuses, controls);
  }, [course, loading, statusesAndControls, isLoading, user]);

  useEffect(() => {
    if (isLoading || !course || !progress) {
      return;
    }

    if (currentLevelId) {
      return;
    }
    // Check if the level id in the url is valid
    const isLevelIdValid =
      levelId && course.levels?.some(l => l.id === levelId);

    // If there's a valid level id in the url make it the current one
    if (levelId && isLevelIdValid) {
      setCurrentLevelId(levelId);
      return;
    }

    // If the level id doesn't exist or is not valid,
    // make the first playable level the current one.
    for (const level of course.levels) {
      if (progress.levels[level.id].playable) {
        setCurrentLevelId(level.id);
        return;
      }
    }

    // If we reach this point then all levels were completed.  Use the last
    // level as the current one.
    const level = course.levels[course.levels.length - 1];
    setCurrentLevelId(level.id);
  }, [course, progress, setCurrentLevelId, isLoading, currentLevelId, levelId]);

  useEffect(() => {
    if (isLoading || !section) {
      return;
    }

    if (section.is_active === false) {
      setModalSettings({
        show: true,
      });

      setBannerSettings({
        show: true,
      });
    }
  }, [section, isLoading]);

  useEffect(() => {
    if (!isLoading && !loading && currentLevelId) {
      // TODO: Remove this if once we always have a section id.
      if (sectionId) {
        return history.push(
          `/section/${sectionId}/course/${courseId}/level/${currentLevelId}`
        );
      } else {
        return history.push(`/course/${courseId}/level/${currentLevelId}`);
      }
    }
  }, [loading, currentLevelId, history, isLoading, courseId, sectionId]);

  // Don't proceed further until everything we need is fully loaded.
  if (loading || isLoading) {
    return <LoadingIndicator />;
  }

  // Determine the title of each project in this course.
  const projectTitles = Object.fromEntries(
    course.levels.flatMap(level =>
      level.project_groups.flatMap(group =>
        group.projects.map(({ id }) => [id, projectsMetadata[id].title])
      )
    )
  );

  // Because of the way the courses page works this needs to be done after
  // all effects are defined.
  //
  // This code will also cause a console warning about performing a react state
  // update on an unmounted component.  That warning appears regardless of
  // whether we do the redirect in an effect or not and regardless of whether
  // we use react router or history.push to do the redirect.
  //
  // When we convert this page over to use APIs and the corresponding load
  // method like the project view uses the warning should go away.
  const tool = externalAccount.getToolForLanguage(course.language);
  if (tool && !externalAccount.isLoggedIn(tool)) {
    return (
      <Redirect
        to={{
          pathname: '/account-login',
          state: {
            tool: tool,
            continueTo: {
              type: 'course',
              path: history.location.pathname,
              state: history.location.state,
            },
          },
        }}
      />
    );
  }

  const currentLevelIndex = course.levels.indexOf(
    course.levels.find(level => level.id === currentLevelId)
  );
  const nextLevel =
    currentLevelIndex < course.levels.length - 1
      ? course.levels[currentLevelIndex + 1]
      : null;
  const prevLevel =
    currentLevelIndex > 0 ? course.levels[currentLevelIndex - 1] : null;
  const nextLevelPlayable = nextLevel
    ? progress.levels[nextLevel.id].playable
    : false;

  return (
    <Page
      menu={
        user.isTeacherForAnySections() && <NavTeacher selected="curriculum" />
      }
      sideNav={
        <Nav
          course={course}
          progress={progress}
          projectTitles={projectTitles}
          currentLevelId={currentLevelId}
          history={history}
          setCurrentLevelId={setCurrentLevelId}
          sectionId={sectionId}
        />
      }
      title={course?.name}
    >
      <CourseContainer>
        {bannerSettings.show && (
          <ArchivedEntityAlertBar pathTo={`/courses`} entity={`course`} />
        )}
        <CourseLevel
          courseName={course?.name}
          courseProgress={progress}
          currentLevelId={currentLevelId}
          sectionId={sectionId}
        />
        <StepNav
          hasProgress={false}
          prev={{
            show: prevLevel,
            enabled: true,
            onClick: () => {
              setCurrentLevelId(prevLevel.id);
            },
            label: 'Previous Level',
          }}
          next={{
            show: nextLevel,
            enabled: nextLevelPlayable,
            onClick: () => {
              setCurrentLevelId(nextLevel.id);
            },
            label: 'Next Level',
          }}
          submit={{
            show: false,
          }}
          summary={{
            show: false,
          }}
        />
      </CourseContainer>
      <ArchivedCoursesModal
        settings={modalSettings}
        onClose={() => setModalSettings(() => ({ show: false }))}
      />
    </Page>
  );
};

CourseBase.propTypes = {
  history: PropTypes.object,
};

const CourseLevel = ({
  courseName,
  courseProgress,
  currentLevelId,
  sectionId,
}) => {
  const { levelId } = useParams();
  const progress = courseProgress?.levels?.[levelId];

  return (
    <Level
      courseName={courseName}
      currentLevelId={currentLevelId}
      progress={progress}
      sectionId={sectionId}
    />
  );
};

CourseLevel.propTypes = {
  courseName: PropTypes.string,
  courseProgress: PropTypes.shape({
    levels: PropTypes.object,
  }),
  currentLevelId: PropTypes.string,
  sectionId: PropTypes.string,
};

const ComposedCourse = props => (
  <CourseProvider>
    <CourseBase {...props} />
  </CourseProvider>
);

const Course = withRouter(ComposedCourse);

export default Course;
