import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const Plus = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 20 20"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="Plus"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <path
      d="M4.146 10.3232H16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10.3232 16.5V4.146"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </StyledSvgIcon>
));

Plus.displayName = 'Plus';
Plus.propTypes = {
  size: PropTypes.number,
};

export default Plus;
