import {
  BlockGroupContainer,
  CodeBlockContainer,
  MediaBlock,
  RubricBlockContainer,
  TextBlockContainer,
} from '../../../components/Blocks';
import { StepProvider, useRubricModal } from '../contexts';
import PropTypes from 'prop-types';
import React from 'react';
import { useTextBlockFormats } from '../hooks';

//
// Code Block
//

const CodeBlock = ({ block }) => (
  <CodeBlockContainer code={block.code} language={block.variant} />
);
CodeBlock.propTypes = {
  block: PropTypes.shape({
    code: PropTypes.text,
    variant: PropTypes.text,
  }),
};

//
// Text Block
//

const TextBlock = ({ block }) => {
  const textBlockFormats = useTextBlockFormats();
  const textBlockFormat = textBlockFormats[block.id] ?? null;

  return <TextBlockContainer format={textBlockFormat} text={block.text} />;
};
TextBlock.propTypes = {
  block: PropTypes.object,
};

//
// Block
//

const Block = ({ block }) => {
  const ref = React.useRef();
  const { onOpenRubricModal } = useRubricModal();

  // Here we add the click handlers for the Rubric Modal links
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const anchors = ref.current.querySelectorAll('a');
    anchors.forEach(anchor => {
      const { attributes } = anchor;
      for (const attribute of attributes) {
        const isRubricLink = attribute.name.startsWith('data-rubric');
        if (isRubricLink) {
          const reqId = attribute.value;
          anchor.onclick = () => onOpenRubricModal(reqId);
        }
      }
    });
  }, [onOpenRubricModal, ref]);

  const BlockElement = ({ block }) => {
    switch (block.type) {
      case 'code':
        return <CodeBlock block={block} />;

      case 'media':
        return <MediaBlock block={block} />;

      case 'rubric':
        return <RubricBlockContainer block={block} />;

      case 'text':
        return <TextBlock block={block} />;

      default:
        throw new Error(
          `unrecognized block type, id: ${block.id}, type: ${block.type}`
        );
    }
  };

  return (
    <div ref={ref}>
      <BlockElement block={block} />
    </div>
  );
};

Block.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }),
  number: PropTypes.number,
};

export const StepGuide = ({ step }) => (
  <StepProvider step={step}>
    {step.block_groups.map(group => (
      <BlockGroupContainer key={group.id} className={group.variant}>
        {group.blocks.map(block => (
          <Block key={block.id} block={block} />
        ))}
      </BlockGroupContainer>
    ))}
  </StepProvider>
);

StepGuide.propTypes = {
  step: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    block_groups: PropTypes.array,
  }),
};
