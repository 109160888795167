import { memoize, useRosterApiClient } from '../client';

// Gets the sections the authenticated user is enrolled in as a student
const getStudentEnrollments = memoize(() => {
  const client = useRosterApiClient();

  return client.get(`/enrollments/student`).then(response => response.data);
});

// Gets the sections the authenticated user is enrolled in as a teacher
const getTeacherEnrollments = memoize(() => {
  const client = useRosterApiClient();

  return client.get(`/enrollments/teacher`).then(response => response.data);
});

export default {
  getStudentEnrollments: getStudentEnrollments,
  getTeacherEnrollments: getTeacherEnrollments,
};
