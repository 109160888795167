import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

//
// Rich Text checkpoints
//

const StyledRichTextWrapper = styled.div(
  ({ theme }) => `
    background-color: ${theme.palette.background.level1};
    border-radius: 10px;
    border: 2px solid ${theme.palette.background.level3};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(3)}px;

    button {
      align-self: flex-end;
      margin-top: ${theme.spacing(2)}px;
    }
  `
);

const StyledRichTextText = styled(TextField)(
  ({ theme }) => `
    background-attachment: local;

    // This background creates the lines for the letter rows,
    // this way it makes the background scrollable
    background-image:
      repeating-linear-gradient(
        white,
        white 31px,
        #e1e1e1 31px,
        #e1e1e1 32px
      );
    background-position-y: -7px;
    
    border: none;
    color: #7d7d7d;
    display: flex;
    flex-direction: column;
    min-height: ${theme.spacing(16)}px;
    resize: none;
    width: 100%;

    .MuiInputBase-multiline {
      padding: 0;
    }

    .MuiInput-underline {
      border-bottom: none;

      &:hover::before {
        border-bottom: none;
      }

      &::before {
        border-bottom: none;
      }

      &::after {
        border-bottom: none;
      }
    }

    textarea {
      color: #7d7d7d;
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: ${theme.spacing(4)}px;
    }

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  `
);

export const RichTextCheckpoint = ({
  answer,
  isUpdating,
  onChange,
  readOnly = false,
}) => {
  const [value, setValue] = React.useState(answer ?? '');
  const isButtonDisabled = isUpdating || readOnly || !value || value === answer;

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <StyledRichTextWrapper>
      <StyledRichTextText
        multiline
        onChange={handleChange}
        value={value}
        disabled={readOnly}
      />
      {!isButtonDisabled && (
        <Button
          color="primary"
          onClick={() => onChange(value)}
          variant="outlined"
        >
          <span id="back">SAVE</span>
        </Button>
      )}
    </StyledRichTextWrapper>
  );
};

RichTextCheckpoint.propTypes = {
  answer: PropTypes.string,
  isUpdating: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
