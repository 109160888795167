import * as lodash from 'lodash';
import LRUCache from 'lru-cache';
import React from 'react';

// A reference to the global axios client to use for making API calls.  This
// client instance is bound to the currently logged in user.  If no user is
// logged in then it will be null.
let axios = null;
let rosterApiClient = null;
let curriculumApiClient = null;
let sectionActivityApiAxios = null;

/**
 * SetAxiosClient updates the global client to use for making API calls.
 *
 * NOTE: This method should only be invoked by the part of the application that
 * handles user signin/signout.
 */
export const setAxiosClient = c => {
  axios = c;
};

export const setSectionActivityApiAxiosClient = c => {
  sectionActivityApiAxios = c;
};

export const setRosterApiClient = c => {
  rosterApiClient = c;
};

export const setCurriculumApiClient = c => {
  curriculumApiClient = c;
};

/**
 * UseAxiosClient is a helper function that looks like a normal hook function,
 * but returns the global axios client for the current user.  While technically
 * not a hook, this method behaves like a normal react hook.
 */
export const useAxiosClient = () => {
  if (axios === null) {
    throw new Error('useAxiosClient must be used when a user is logged in');
  }
  return axios;
};

export const useRosterApiClient = () => {
  if (rosterApiClient === null) {
    throw new Error('rosterApiClient must be used when a user is logged in');
  }
  return rosterApiClient;
};

export const useCurriculumApiClient = () => {
  if (curriculumApiClient === null) {
    throw new Error(
      'curriculumApiClient must be used when a user is logged in'
    );
  }
  return curriculumApiClient;
};

export const useSectionActivityApiAxiosClient = () => {
  if (sectionActivityApiAxios === null) {
    throw new Error(
      'useSectionActivityApiAxiosClient must be used when a user is logged in'
    );
  }
  return sectionActivityApiAxios;
};

export const noeslint = func => {
  return lodash.wrap(func, (f, ...args) => {
    return f(...args);
  });
};

const memoizeCaches = [];

/**
 * Function to clear all cached data from memoized functions.
 */
export const clearMemoizeCache = () => {
  memoizeCaches.forEach(c => c.reset());
};

/**
 * Memoize creates a new function that wraps an existing one and caches the
 * results of invoking it.  This means that repeated calls to the wrapped
 * function with the same arguments will only invoke the underlying function
 * one time.  Arguments to the function must be convertible to JSON.
 *
 * Previous results are stored in a LRU cache with a fixed size, so more recent
 * calls will be preserved while older ones will be forgotten.  The size of the
 * cache can be controlled with the `size` parameter.
 */
export const memoize = (func, size = 1) => {
  const cache = new LRUCache({ max: size });
  memoizeCaches.push(cache);

  return lodash.wrap(func, (f, ...args) => {
    const key = JSON.stringify(args);

    const cached = cache.get(key);
    if (cached) {
      return cached;
    }

    const value = f(...args);
    cache.set(key, value);
    return value.then(response => {
      // Adding a cleanup function to delete the key from the cache
      response.cleanup = () => cache.del(key);
      return response;
    });
  });
};

// The remaining helpers in this file are built to act like react hooks, but are
// not actually react hooks.  They often behave differently than a react hook
// might when it comes to things like referential equality checking.  Because of
// this they are intentionally not named in the same way a hook would be named
// with a 'use' prefix.  Consequently we're going to disable the
// react-hooks/rules-of-hooks linter in the rest of this file so that it doesn't
// complain that these methods are improperly named.
/* eslint-disable react-hooks/rules-of-hooks */

//
// New helpers for communication with REST APIs
//

/**
 * Load is a helper function that consolidates multiple promises together into
 * a single loading variable as well as the data that each promise produces,
 * when the promise finally resolves/rejects.  The intention is that this helper
 * will be used on pages that need to make multiple simultaneous requests for
 * data before they are able to render.
 *
 * The returned value is an array of elements the first of which is a
 * consolidated loading variable.  When the loading variable has a value of
 * <tt>true</tt> it means that one or more of the promises is still in flight
 * and hasn't yet resolved or rejected.
 *
 * The remaining values in the returned array are the values that each promise
 * passed into the helper resolved to.  If a promise hasn't yet resolved then
 * its value will be null.  If a promise rejected then it's value will be
 * undefined.  This distinction between null and undefined allows the caller to
 * detect rejection in case that's important to the page.
 *
 */
export const load = (...promises) => {
  const [values, setValues] = React.useState([
    true, // loading flag
    ...promises.map(() => null),
  ]);

  React.useEffect(() => {
    // Update the loading flag to true.
    setValues(values => [true, ...values.slice(1)]);

    Promise.allSettled(promises).then(vs => {
      setValues([
        false, // loading flag
        // This handles both the case where a promise is resolved or rejected.
        // When resolved there will be a value field containing whatever value
        // the promise was resolved with.  When a promise is rejected, there
        // will be a reason field containing the rejection.
        ...vs.map(v => v?.value),
      ]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, ...promises]);

  return values;
};
