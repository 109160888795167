import {
  BackArrow,
  BackCaret,
  Checkmark,
  Minus,
  Play,
  Plus,
} from '../../components/icons';
import { Link, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import NavBarButtons from '../NavBarButtons';
import NavMapGroup from '../NavMapGroup';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const TABLET_BREAKPOINT = 768;

const CollapseHandle = styled.div(
  ({ $isHidden, theme }) => `
    align-items: center;
    background-color: ${theme.palette.background.nav}40;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    justify-content: center;
    left: ${$isHidden ? '0' : '100%'};
    margin-bottom: -19%;
    //this is a little counter-intuitive; we want the handle to show if the rest of the NavMap is hidden
    opacity: ${$isHidden ? 1 : 0}; 
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.1s;
    width: 24px;
  
    :hover {
      background-color: ${theme.palette.background.nav}80;
    }
  
    .backCaret {
      transform: rotate(${$isHidden ? '180deg' : 0})
    }
  `
);

export const NavMapContainer = styled.div(
  ({ $isHidden, theme }) => `
    background-color: ${theme.palette.background.nav};
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    height: 100%;
    transition: width 100ms ease;
    width: ${$isHidden ? '0' : theme.spacing(32)}px;
    z-index: 10;
    
    :hover {
      ${CollapseHandle} {
        opacity: 1;
      }
    }
    @media (max-width: ${TABLET_BREAKPOINT}px) {
      position: absolute;
    }      
  `
);

const Line = styled.div(
  ({ theme }) => `
    border-top: 1px solid ${theme.palette.text.divider};
    width: 100%;
  `
);

const ReturnComponent = styled(Link)(
  ({ theme, $isHidden }) => `
    align-items: center;
    color: ${theme.palette.text.hint};
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: start;
    padding: ${theme.spacing(2.5)}px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 100ms ease;
    visibility: ${$isHidden ? 'hidden' : 'visible'};
    
    :hover {
      color: ${theme.palette.text.primary};
    }
  `
);

const ReturnTitle = styled(Typography)(
  ({ theme, $isHidden }) => `
    margin-left: ${theme.spacing(2)}px;
    display: ${$isHidden ? 'none' : 'inherit'};
  `
);

const StyledList = styled(List)`
  height: calc(100vh - ${({ theme }) => theme.spacing(16)}px);
  overflow: overlay;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 10vh;

  :hover {
    visibility: visible;

    ::-webkit-scrollbar {
      width: ${({ theme }) => theme.spacing(1)}px;
    }
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.spacing(3)}px;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    border: ${({ theme }) => theme.spacing(1)}px solid transparent;
  }
`;

const SectionTitle = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.background.nav};
  color: ${({ theme }) => theme.palette.primary.light};
  padding-right: ${({ theme }) => theme.spacing(4)}px;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;

  // Ideally we'd put these into a typography variant in our theme provider,
  // but material ui 4.x doesn't support custom variants.  Once 5.x is released
  // it should support them and this should be relocated into the theme at that
  // point.
  font-family: 'IBM Plex Mono', monospace;
  font-size: 13px;
  font-weight: 400;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  background: ${({ theme }) => theme.palette.background.nav};
  box-shadow: ${({ $isCurrent }) => {
    return $isCurrent ? '0 4px 4px rgba(0, 0, 0, 0.25)' : 'none';
  }};
  cursor: ${({ $mode, $isCurrent, $isComplete, $isPlayable }) => {
    if ($mode === 'project') {
      return 'default';
    }

    return $isCurrent || $isComplete || $isPlayable ? 'pointer' : 'default';
  }};
  height: ${({ theme }) => theme.spacing(8)}px;
  padding: ${({ theme }) => theme.spacing(3, 1, 3, 1)};
  position: sticky;
  top: 0;
  z-index: 1;

  & .checkmarkIcon {
    transition: all 100ms ease;
    fill: ${({ theme }) => theme.palette.text.icon};
  }

  & .collapse {
    transition: all 100ms ease;
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    display: none;
  }

  :hover {
    & .checkmarkIcon {
      fill: ${({ theme }) => theme.palette.text.primary};
    }

    & .collapse {
      display: block;
    }

    ${SectionTitle} {
      transition: color 100ms ease;
      color: ${({ $mode, $isCurrent, $isComplete, $isPlayable, theme }) => {
        if ($mode === 'project') {
          return theme.palette.primary.light;
        }

        // In course/level view, only highlight the title if this section is
        // able to be navigated to.
        return $isCurrent || $isComplete || $isPlayable
          ? theme.palette.text.primary
          : theme.palette.primary.light;
      }};
    }

    ${Line} {
      transition: visibility 100ms ease;
      visibility: ${({ $mode, $isCurrent, $isComplete, $isPlayable }) => {
        if ($mode === 'project') {
          return 'visible';
        }

        // In course/level view, only hide the line if this section is able to
        // be navigated to.
        return $isCurrent || $isComplete || $isPlayable ? 'hidden' : 'visible';
      }};
    }
  }
`;

/**
 * SectionHeader renders the header for a section including it's icon, title,
 * and collapse/expand buttons (if the section is collapsible).
 */
const SectionHeader = ({
  section,
  isCollapsed,
  isCollapsible,
  onCollapseToggle,
}) => {
  const showIcon = isCollapsed;
  const showPlus = isCollapsible && isCollapsed;
  const showMinus = isCollapsible && !isCollapsed;
  const onClick = () => onCollapseToggle(section);

  return (
    <>
      {showIcon && <SectionIcon name={section.icon} />}
      <SectionTitle>{section.title}</SectionTitle>
      <Line />
      {showPlus && <Plus size={16} className="collapse" onClick={onClick} />}
      {showMinus && <Minus size={16} className="collapse" onClick={onClick} />}
    </>
  );
};

SectionHeader.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
  }),
  isCollapsed: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  onCollapseToggle: PropTypes.func,
};

const ListContent = styled.div`
  visibility: visible;
`;

const IconContainer = styled.span`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  min-width: 20px;
`;

const SectionIcon = ({ name }) => {
  let icon;
  switch (name) {
    case 'play':
      icon = <Play className="playIcon" $fillColor="#ffffff" size={19} />;
      break;
    case 'checkmark':
      icon = <Checkmark className="checkmarkIcon" size={20} />;
      break;
    default:
      return null;
  }

  return <IconContainer>{icon}</IconContainer>;
};

SectionIcon.propTypes = {
  name: PropTypes.string,
};

/**
 * NavMap will render a hierarchical view on the screen.  The view is
 * declarative and defined using the sections parameter.  Sections looks like:
 *
 * <pre>
 *   [
 *     {
 *       id: "<uuid>,
 *       title: "<human readable title>",
 *       icon: "play|checkmark",
 *       isCurrent: true|false,   // is this the currently focus?
 *       isComplete: true|false,  // is this complete?
 *       isPlayable: true|false,  // can be chosen, but not the current focus
 *       groups: [
 *         {
 *           id: "<uuid>",
 *           title: "<human readable title>",
 *           isCurrent: true|false,
 *           items: [
 *             {
 *               id: "<uuid>",
 *               title: "<human readable title>",
 *               icon: "play|checkmark|locked",
 *               isCurrent: true|false,
 *               isComplete: true|false,
 *               isPlayable: true|false
 *             }
 *           ]
 *         },
 *         ...
 *       ]
 *     },
 *     ...
 *   ]
 * </pre>
 *
 * Generally speaking the <tt>isCurrent</tt>, <tt>isComplete</tt>, and
 * <tt>isPlayable</tt> fields on an entry control how the entry is rendered
 * on the screen.
 *
 * When <tt>isCurrent</tt> is <tt>true</tt> the nav map will render the entry
 * with a highlight background and icon to draw the user to the entry.  More
 * than one entry at a level may be marked as current -- all will be
 * highlighted.  This is especially useful in level view when all projects in
 * an independent project group are active at the same time.
 *
 * When <tt>isPlayable</tt> is <tt>true</tt> the nav map will render the entry
 * as it normally would a non-current and non-complete entry, but will support
 * interacting with the entry with the mouse.  On hover it will display the
 * entry's icon as well as allow it to be clicked.  This is especially useful
 * project view when a user has moved to a previous step in the project.  The
 * earlier step will have a <tt>isCurrent</tt> value of <tt>true</tt>, but the
 * latest step they were on will be playable.  This allows the user to hover
 * over it and see the play icon as well as click it to return to where they
 * were in the project.
 */

const NavMap = ({
  handleItemClick,
  hideCollapseHandle,
  hideNavButtons,
  isSummaryShown,
  isTeacher,
  mode,
  onProjectSummaryButtonClick,
  onVocabButtonClick,
  previous,
  progressIndicator,
  sections,
  setCurrentId,
}) => {
  // Whether or not the entire nav is hidden
  const [isHidden, setIsHidden] = React.useState(false);

  // Whether or not the current section should be collapsed
  const [collapsed, setCollapsed] = React.useState(false);

  React.useEffect(() => {
    // Cretaes a MediaQueryList to listen for screen size change
    const mql = window.matchMedia(`(max-width: ${TABLET_BREAKPOINT}px)`);

    // Add mql listener to trigger show/hide the NavMap
    // on screen size change if it hits the breakpoint
    const onBreakpoint = e => setIsHidden(e.matches);
    mql.addEventListener('change', onBreakpoint);

    // Check mql on component load
    if (mql.matches) {
      setIsHidden(true);
    }

    // Remove mql listener on unmount
    return () => mql.removeEventListener('change', onBreakpoint);
  }, []);

  const handleSectionClick = section => {
    // If we clicked the current section then ignore the click.
    if (section.isCurrent) {
      return;
    }

    // Otherwise switch to the new section and make sure it's expanded.
    if (setCurrentId) {
      setCurrentId(section.id);
    }
    setCollapsed(false);
  };

  return (
    <NavMapContainer $isHidden={isHidden}>
      {!hideCollapseHandle && (
        <CollapseHandle
          $isHidden={isHidden}
          onClick={() => setIsHidden(!isHidden)}
        >
          <BackCaret className="backCaret" fill="white" />
        </CollapseHandle>
      )}
      {previous && (
        <ReturnComponent to={previous.url} $isHidden={isHidden}>
          <BackArrow className="icon" size={16} />
          <ReturnTitle $isHidden={isHidden} variant="subtitle2">
            {previous.label}
          </ReturnTitle>
        </ReturnComponent>
      )}
      {!hideNavButtons && (
        <NavBarButtons
          isHidden={isHidden}
          isSummaryShown={isSummaryShown}
          isTeacher={isTeacher}
          mode={mode}
          onVocabularyClick={onVocabButtonClick}
          onProjectSummaryClick={onProjectSummaryButtonClick}
          progressIndicator={progressIndicator}
        />
      )}
      {sections && (
        <StyledList className="scrollList">
          <ListContent>
            {sections.map(section => {
              const isCollapsed =
                mode === 'course' && (collapsed || !section.isCurrent);
              const isCollapsible =
                mode === 'course' &&
                (section.isCurrent || section.isComplete || section.isPlayable);

              return (
                <React.Fragment key={section.id}>
                  <Section
                    $mode={mode}
                    $isCurrent={section.isCurrent}
                    $isComplete={section.isComplete}
                    $isPlayable={section.isPlayable}
                    $open={!isCollapsed}
                    button
                    key={section.id}
                    onClick={() => {
                      if (
                        section.isCurrent ||
                        section.isComplete ||
                        section.isPlayable
                      ) {
                        handleSectionClick(section);
                      }
                    }}
                  >
                    <SectionHeader
                      section={section}
                      isCollapsed={isCollapsed}
                      isCollapsible={isCollapsible}
                      onCollapseToggle={() => setCollapsed(prev => !prev)}
                    />
                  </Section>
                  {!isCollapsed && (
                    <>
                      {section.groups.map(group => {
                        return (
                          <NavMapGroup
                            group={group}
                            handleItemClick={handleItemClick}
                            key={group.id}
                            mode={mode}
                          />
                        );
                      })}
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </ListContent>
        </StyledList>
      )}
    </NavMapContainer>
  );
};

NavMap.propTypes = {
  handleItemClick: PropTypes.func,
  hideCollapseHandle: PropTypes.bool,
  hideNavButtons: PropTypes.bool,
  isSummaryShown: PropTypes.bool,
  isTeacher: PropTypes.bool,
  mode: PropTypes.oneOf(['course', 'project']),
  onProjectSummaryButtonClick: PropTypes.func,
  onVocabButtonClick: PropTypes.func,
  previous: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  progressIndicator: PropTypes.shape({
    title: PropTypes.string,
    complete: PropTypes.number,
    total: PropTypes.number,
  }),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      groups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              status: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
  setCurrentId: PropTypes.func,
  showHandle: PropTypes.bool,
};

export default withRouter(NavMap);
