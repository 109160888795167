import {
  InMemoryProvider,
  OpenFeature,
  OpenFeatureProvider,
} from '@openfeature/react-sdk';
import { AuthProvider } from '../../contexts/AuthContext';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../Routes';
import ThemeProvider from '../../components/ThemeProvider';

const flagConfig = {
  'enable-google-single-sign-on': {
    disabled: false,
    variants: {
      Enabled: true,
      Disabled: false,
    },
    defaultVariant: 'Disabled',
  },
};

OpenFeature.setProvider(new InMemoryProvider(flagConfig)); // Attach the provider to OpenFeature

const AppWrapper = () => (
  <ThemeProvider>
    <AuthProvider>
      <OpenFeatureProvider>
        <Router>
          <Routes />
        </Router>
      </OpenFeatureProvider>
    </AuthProvider>
  </ThemeProvider>
);

export default AppWrapper;
