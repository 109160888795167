import { useHistory, useLocation, useParams } from 'react-router-dom';
import { LoadingIndicator } from '../components';
import React from 'react';
import { useAuth } from '../contexts/AuthContext';

const LoginUser = () => {
  const history = useHistory();
  const params = useParams();
  const query = new URLSearchParams(useLocation().search);
  const { signinWithCustomToken, signout } = useAuth();

  const userId = params?.userId;
  const token = query.get('token');

  React.useEffect(() => {
    const signin = async () => {
      if (!token) {
        history.push('/error');
        return;
      }

      await signout();
      await signinWithCustomToken(userId, token);

      history.push('/login');
    };

    signin();
  }, [history, signinWithCustomToken, signout, userId, token]);

  return <LoadingIndicator />;
};

export default LoginUser;
