import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const MediaContainer = styled.div(
  () => `
    cursor: pointer;
    display: flex;
    justify-content: center;

    img,
    video {
      border-radius: 12px;
      display: block;
      filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.2));
      height: auto;
      max-width: 624px;
      width: 100%;
    }
  `
);

const StyledDialog = styled(Dialog)(
  ({ theme }) => `
    .MuiDialog-paper {
      background-color: transparent;
      border-radius: 20px;
      box-shadow: none;
      filter: drop-shadow(0 10px 14px rgba(0, 0, 0, 0.25));
      margin: ${theme.spacing(7.5)}px auto;
      max-width: 880px;
      width: 90%;
    }
  `
);

const MediaDialog = ({ dialogOpen, handleClose, isYoutube, type, url }) => (
  <StyledDialog onClose={handleClose} maxWidth="lg" open={dialogOpen}>
    {type === 'image' && <MediaImage url={url} onClick={handleClose} />}
    {type === 'video' && !isYoutube && (
      <MediaVideo url={url} onClick={handleClose} />
    )}
  </StyledDialog>
);

MediaDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  isYoutube: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string,
};

const MediaImage = ({ onClick, url }) => (
  <img alt="step instructions" onClick={onClick} src={url} />
);

MediaImage.propTypes = {
  onClick: PropTypes.func,
  url: PropTypes.string,
};

const MediaVideo = ({ onClick, url }) => (
  <video
    autoPlay="autoplay"
    loop="loop"
    onClick={onClick}
    muted="muted"
    src={`${url}?controls=0`}
  />
);

MediaVideo.propTypes = {
  onClick: PropTypes.func,
  url: PropTypes.string,
};

const YoutubeWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; // this preserves a 16:9 aspect ratio
  height: 0;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const MediaYoutube = ({ url }) => (
  <YoutubeWrapper>
    <iframe
      src={url}
      frameBorder="0"
      width="560"
      height="349"
      allowFullScreen
    />
  </YoutubeWrapper>
);

MediaYoutube.propTypes = {
  url: PropTypes.string,
};

//
// Media Blocks
//

export const MediaBlock = ({ block }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const isYoutube = block?.media?.url?.includes('youtube');

  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleOpen = () => {
    setDialogOpen(true);
  };

  const MediaElement = () => {
    switch (block.media.type) {
      case 'image':
        return <MediaImage url={block.media.url} onClick={handleOpen} />;

      case 'video':
        if (isYoutube) {
          return <MediaYoutube url={block.media.url} onClick={handleOpen} />;
        } else {
          return <MediaVideo url={block.media.url} onClick={handleOpen} />;
        }

      default:
        throw new Error(`unsupported media type: ${block.media.type}`);
    }
  };

  return (
    <MediaContainer>
      <MediaElement />
      <MediaDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        isYoutube={isYoutube}
        type={block.media.type}
        url={block.media.url}
      />
    </MediaContainer>
  );
};

MediaBlock.propTypes = {
  block: PropTypes.shape({
    media: PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};
