import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

// This legacy typography object is meant to encapsulate the styles needed by
// our old projects.  Once we no longer support students on these projects we
// will remove this style entirely and only keep the main one below.
const LegacyTextBlockTypography = styled(Typography)(
  ({ theme }) => `
    .refDialog {
      color: #2979FF;
      cursor: initial;
      font-weight: 400;
      text-decoration: underline;
    }
    
    .codeblock {
      background-color: ${theme.palette.background.level3};
      margin: ${theme.spacing(2)}px;
      padding: ${theme.spacing(2)}px;
      color: ${theme.palette.text.code};
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .inline-code {
      background-color: ${theme.palette.background.level3};
      color: ${theme.palette.text.code};
    }
  
    .comment {
      color: ${theme.palette.text.secondary};
    }
  `
);

const TextBlockTypography = styled(LegacyTextBlockTypography)(
  ({ theme }) => `
    // General styles
    font-weight: 400;
    line-height: ${theme.spacing(4)}px;
    width: 100%;

    // This is needed for legacy projects
    // (some legacy projects have embedded p tags)
    p {
      display: inline;
    }

    li {
      padding: 0;
      margin: 0;
    }

    a {
      color: #2979FF;
      cursor: pointer;
      font-weight: 400;
      text-decoration: underline;
    }

    a[target="_blank"] {
      color: #5C71F2;
      font-weight: 600;
      &:after {
        content: url('/static/assets/icons/link.svg');
        margin: 0 6px;
      }
    }
    
    b {
      font-weight: 600;
    }
    
    // CoBlocks styles
    samp[data-coblocks-type] {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }
    samp[data-coblocks-type="actions"] {
      color: rgb(150, 100, 193);
    }
    samp[data-coblocks-type="control"] {
      color: rgb(255, 144, 67);
    }
    samp[data-coblocks-type="data"] {
      color: rgb(255, 89, 108);
    }
    samp[data-coblocks-type="events"] {
      color: rgb(255, 182, 44);
    }
    samp[data-coblocks-type="functions"] {
      color: rgb(219, 72, 73);
    }
    samp[data-coblocks-type="items"] {
      color: rgb(96, 102, 214);
    }
    samp[data-coblocks-type="operators"] {
      color: rgb(74, 170, 82);
    }
    samp[data-coblocks-type="physics"] {
      color: rgb(232, 107, 93);
    }
    samp[data-coblocks-type="transform"] {
      color: rgb(88, 167, 218);
    }
    samp[data-coblocks-type="debug"] {
      color: rgb(232, 185, 131);
    }
    
    // Java/Python styles, currently we don't have a need for colors
    code[data-java-type],
    code[data-python-type] {
      font-family: 'IBM Plex Mono', monospace;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }
    code[data-java-type][variant='college-board'] {
      font-family: 'Courier Prime';
      line-height: 20px;
    }

    table[variant='college-board'] {
      th, td {
        border: 1px solid #4d4d4d;
      }
      th {
        font-family: 'Tinos', serif;
        font-size: 14px;
        font-weight: 700;
        padding: 0 4px;
      }
      td {
        line-height: 20px;
        padding: 6px;
      }
    }
  `
);

export default TextBlockTypography;
