import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;

  .circle {
    fill: none;
    stroke: ${({ $hovering, theme }) =>
      $hovering ? theme.palette.text.primary : theme.palette.text.icon};
  }

  .star {
    fill: ${({ $hovering, theme }) =>
      $hovering ? theme.palette.text.primary : theme.palette.text.icon};
  }
`;

const Star = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 36 36"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="circle"
      d="M17.9995 34.9999C27.3884 34.9999 34.9995 27.3888 34.9995 17.9999C34.9995 8.6111 27.3884 0.999939 17.9995 0.999939C8.61067 0.999939 0.999512 8.6111 0.999512 17.9999C0.999512 27.3888 8.61067 34.9999 17.9995 34.9999Z"
      strokeWidth="2"
    />
    <path
      className="star"
      d="M18 23.27L24.18 27L22.54 19.97L28 15.24L20.81 14.63L18 8L15.19 14.63L8 15.24L13.46 19.97L11.82 27L18 23.27Z"
    />
  </StyledSvgIcon>
));

Star.displayName = 'Star';

Star.propTypes = {
  size: PropTypes.number,
};

export default Star;
