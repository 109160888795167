import 'firebase/auth';
import * as axios from 'axios';
import * as externalAccount from '../../utils/externalAccountLogin';
import {
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  clearMemoizeCache,
  setAxiosClient,
  setCurriculumApiClient,
  setRosterApiClient,
  setSectionActivityApiAxiosClient,
} from '../../api/client';
import api from '../../api';
import config from '../../config';
import ExpiredStorage from 'expired-storage';
import { initializeApp } from 'firebase/app';
import { LoadingIndicator } from '../../components';
import { OpenFeature } from '@openfeature/react-sdk';
import PropTypes from 'prop-types';
import React from 'react';

initializeApp(config.firebase);
const fb = getAuth();

// This is the legacy user id field that existed when we only supported login
// via firebase.  This is no longer used, but this field is preserved so that
// we can clean up to avoid confusion.
const LEGACY_USER_ID_KEY = 'firebase:user_id';

// When a user logs in we'll set this key to their user id to indicate that
// we've seen a user before and that they'll likely be able to log in again
// without specifying a password.  This is just a heuristic, it doesn't mean
// that because this key is present we'll assume the user is logged in or even
// use the user id in this key -- we just won't immediately send them to the
// login page and will instead show them a loading indicator while we determine
// if they're actually logged in.
const USER_ID_KEY = 'hello-world:user-id';

// The prefix that appears on user ids to indicate that they are anonymous users
// and not present in Firebase.
const ANONYMOUS_USER_ID_PREFIX = 'anon:';

// The duration (in seconds) for how long an anonymous user's id should be
// remembered for.
const ANONYMOUS_USER_ID_TTL = 365 * 24 * 60 * 60;

const storage = new ExpiredStorage();

/**
 * SetupAxiosForUser creates and configures an Axios client that includes
 * authorization headers for a specific user.
 */
const setupAxiosForUser = getToken => {
  const client = axios.create({
    baseURL: config.api.uri,
  });
  client.interceptors.request.use(async config => {
    const token = await getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  });
  setAxiosClient(client);

  const rosterApiClient = axios.create({
    baseURL: config.rosterApi.uri,
  });
  rosterApiClient.interceptors.request.use(async config => {
    const token = await getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  });
  setRosterApiClient(rosterApiClient);

  const curriculumApiClient = axios.create({
    baseURL: config.curriculumApi.uri,
  });
  curriculumApiClient.interceptors.request.use(async config => {
    const token = await getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  });
  setCurriculumApiClient(curriculumApiClient);

  const sectionActivityApiClient = axios.create({
    baseURL: config.sectionActivityApi.uri,
  });
  sectionActivityApiClient.interceptors.request.use(async config => {
    const token = await getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  });
  setSectionActivityApiAxiosClient(sectionActivityApiClient);
};

const AuthContext = React.createContext(undefined);

/**
 * The useAuth hook returns information about the authenticated user as well
 * as helpers to sign in/out users.
 *
 * NOTE: This hook should not be generally used within the application.  Only
 * the portions of the application that deal with sign in/out should need to
 * use this hook.  All other places should instead use the useUser hook.
 */
export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

/**
 * The useUser hook returns information about the authenticated user.  If no
 * user is currently logged in then null will be returned.
 */
export const useUser = () => {
  const auth = useAuth();
  return auth.user;
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = React.useState(() => {
    // Cleanup the old key.  This can eventually be removed after running for
    // a while.
    storage.removeItem(LEGACY_USER_ID_KEY);

    const userId = storage.getItem(USER_ID_KEY);

    // Choose our default value for the method based on whether we know of a
    // user or not.  If we do then inspect their user id and determine what
    // type of user they are, otherwise assume firebase.
    let method = null;
    if (userId) {
      method = 'firebase';

      if (userId?.startsWith(ANONYMOUS_USER_ID_PREFIX)) {
        method = 'anonymous';
      }
    }

    // If we don't know of a user, control the method based on which page
    // we're on.
    // TODO: Do this better in the future, this approach isn't scalable.
    if (!method) {
      method = 'firebase';

      const path = document.location.pathname ?? '';
      if (path.startsWith('/login/hour-of-code')) {
        method = 'anonymous';
      }
    }

    return {
      // Loading indicates if we believe we're waiting for an API to return and
      // should be showing the loading indicator.  Initially we are going to be
      // loading via an API if we have a user, or if we're using the anonymous
      // login method (because we'll be calling an API to create the account).
      loading: userId !== null || method === 'anonymous',

      // The method indicates which signup method we're using.  It can be either
      // 'firebase' or 'anonymous'.
      //
      // When a method of 'firebase' is specified we will register a state
      // change handler with the firebase auth client to be informed when a user
      // has logged in or out.
      //
      // When a method of 'anonymous' is specified we will ignore any events
      // from firebase, and use our API to create and login a user if there
      // isn't currently one.
      method: method,

      // The logged-in user.
      user: null,
    };
  });

  const signinWithEmailAndPassword = React.useCallback(
    async (email, password) => {
      // Ensure the firebase auth state change listener is running.
      setState(prev => {
        return {
          ...prev,
          loading: true,
          usernameAuthFailure: '',
          method: 'firebase',
        };
      });
      try {
        await setPersistence(fb, browserLocalPersistence);
        await signInWithEmailAndPassword(fb, email, password);
        // The rest will be handled by the firebase auth state change listener
        // that's registered in doFirebaseLogin.
      } catch (e) {
        console.log(e);
        // In case sign in results in a 4xx response from firebase, an exception will be thrown and the loading
        // indicator will not be reset, so we reset it here
        setState(prev => {
          return {
            ...prev,

            loading: false,
            usernameAuthFailure: 'Error: Invalid Username or password.',
          };
        });
      }
    },
    []
  );

  const signinWithCustomToken = React.useCallback(async (userId, token) => {
    const method = !userId.startsWith(ANONYMOUS_USER_ID_PREFIX)
      ? 'firebase'
      : 'anonymous';

    if (method === 'firebase') {
      // Ensure the firebase auth state change listener is running.
      setState(prev => {
        return {
          ...prev,
          loading: true,
          usernameAuthFailure: '',
          method: 'firebase',
        };
      });

      await setPersistence(fb, browserLocalPersistence);
      await signInWithCustomToken(fb, token);
      // The rest will be handled by the firebase auth state change listener
      // that's registered in doFirebaseLogin.
    }

    if (method === 'anonymous') {
      // TODO: This code path repeats some of the code in the doAnonymousLogin
      // callback because we don't need to create a new user.  In the future it
      // could probably be refactored to be a bit more DRY.
      storage.setItem(USER_ID_KEY, userId, ANONYMOUS_USER_ID_TTL);
      setupAxiosForUser(async () => token);

      const user = await api.users.getUser(userId);

      setState(prev => {
        return {
          ...prev,
          loading: false,
          method: 'anonymous',
          user: user,
        };
      });
    }
  }, []);

  const signout = React.useCallback(async () => {
    externalAccount.logout();
    storage.removeItem(USER_ID_KEY);
    clearMemoizeCache();
    await fb.signOut();

    setupAxiosForUser(async () => null);
    setState(prev => {
      return {
        ...prev,

        // We intentionally switch to firebase based auth when signing out so
        // that we don't immediately create a new anonymous user.
        method: 'firebase',
        user: null,
      };
    });
  }, []);

  const doFirebaseLogin = React.useCallback(() => {
    setState(prev => {
      return {
        ...prev,
        loading: true,
        usernameAuthFailure: '',
        method: 'firebase',
      };
    });

    return fb.onAuthStateChanged(async fbUser => {
      if (!fbUser) {
        // There is no user logged in.
        setupAxiosForUser(async () => null);
        clearMemoizeCache();

        OpenFeature.setContext({ targetingKey: 'anonymous' });

        setState(prev => {
          return {
            ...prev,
            loading: false,
            user: null,
          };
        });
        return;
      }

      // Set up the ability to make API calls as this user.
      setupAxiosForUser(async () => {
        return fbUser.getIdToken();
      });

      // Load the user object from our API.
      const user = await api.users.getUser(fbUser.uid);

      OpenFeature.setContext({
        targetingKey: user.id,
        username: user.username,
      });

      // Remember this user's ID for future logins.
      storage.setItem(USER_ID_KEY, user.id);

      setState(prev => {
        return {
          ...prev,

          loading: false,
          user: user,
        };
      });
    });
  }, []);

  const doAnonymousLogin = React.useCallback(async () => {
    let userId = storage.getItem(USER_ID_KEY);
    let user = null;

    setState(prev => {
      return {
        ...prev,
        loading: true,
        usernameAuthFailure: '',
      };
    });

    // Make sure this user still exists on the server side.
    if (userId) {
      const token = await api.authentication.getAnonymousUserAuthToken(userId);
      setupAxiosForUser(async () => token);

      // Load the user object from our API.
      // const user = await api.users.getUser(userId);
      try {
        user = await api.users.getUser(userId);
      } catch (e) {
        // The anonymous user no longer exists in HelloWorldCS. We will reset the userId to null so that a new user is created
        userId = null;
      }
    }

    // We don't have a user, need to generate one.
    if (!userId) {
      userId = await api.authentication.createHourOfCodeUser();

      // At this point we should have a userId.  Set up the ability to make API
      // calls as this user.
      const token = await api.authentication.getAnonymousUserAuthToken(userId);
      setupAxiosForUser(async () => token);

      user = await api.users.getUser(userId);

      // Remember this user's ID for future logins.
      storage.setItem(USER_ID_KEY, userId, ANONYMOUS_USER_ID_TTL);
    }

    setState(prev => {
      return {
        ...prev,

        loading: false,
        user: user,
      };
    });
  }, []);

  React.useEffect(() => {
    if (state.method === 'firebase') {
      return doFirebaseLogin();
    }

    // We shouldn't run doAnonymousLogin if we already have a user since it will
    // create a new user.  In the situation where we logged in with a custom
    // token we already have a user and don't need one to be created.
    if (state.method === 'anonymous' && !state.user) {
      (async () => await doAnonymousLogin())();
    }
  }, [state.method, state.user, doFirebaseLogin, doAnonymousLogin]);

  const value = React.useMemo(() => {
    return {
      ...state,

      signinWithEmailAndPassword: signinWithEmailAndPassword,
      signinWithCustomToken: signinWithCustomToken,
      signout: signout,
    };
  }, [state, signinWithEmailAndPassword, signinWithCustomToken, signout]);

  // If we're still waiting for authentication or to load the user from the API
  // then show the loading indicator.
  if (state.loading) {
    return <LoadingIndicator />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthProvider.propTypes = {
  children: PropTypes.node,
};
