import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const ProgressRing = styled.circle(
  ({ r, $percentage, $C = 2 * Math.PI * r }) => `
    stroke-dasharray: ${$C} ${$C};
    stroke-dashoffset: ${$C * (1 - $percentage)};
    stroke-width: 4;
    stroke-linecap: round;
  `
);

const ProgressIndicatorContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    ${ProgressRing} {
      transition: 0.35s stroke-dashoffset;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      fill: rgba(255, 255, 255, 0.1);
      stroke: ${theme.palette.secondary.main}
    }
    .progressText {
      fill: ${theme.palette.text.primary};
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 20px;
      margin-top: -49px;
      position: relative;
      text-align: center;
    }
  `
);

const ProgressIconCircleContainer = styled.div(
  ({ theme }) => `
    height: ${theme.spacing(8)}px; 
    width: ${theme.spacing(8)}px; 
  `
);

const ButtonContainer = styled.div(
  () => `
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `
);

const VocabularyIconCircleContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: ${theme.spacing(7)}px; 
    justify-content: center;
    margin-bottom: 2px;
    margin-top: 6px;
    width: ${theme.spacing(7)}px; 
    &:hover {
      background: rgba(255, 255, 255, 0.3);
      border: 1px solid #FFFFFF;
    }
  `
);

const ButtonTextBox = styled.div(
  () => `
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: center;
  `
);

const ButtonTypography = styled(Typography)(
  () => `
    font-size: 11px;
    text-align: center;
  `
);

const NavBarButtonContainer = styled.div(
  ({ isActive, theme }) => `
    background: ${
      isActive
        ? 'linear-gradient(90deg, rgba(176, 176, 249, .5) 27.08%, rgba(92, 113, 242, .5) 93.12%)'
        : `${theme.palette.primary.main}25`
    };
    flex-basis: 0;
    flex-grow: 1;
    padding: 10px;
  `
);

const NavBarButtonsContainer = styled.div(
  () => `
    display: flex;
    justify-content: space-around;
    width: 100%;
  `
);

const ProgressIndicator = ({ numItems, numComplete, title }) => {
  const percentage = numItems > 0 ? numComplete / numItems : 0;

  return (
    <ProgressIndicatorContainer>
      <ProgressIconCircleContainer>
        <svg className="progress-ring" width="64" height="64">
          <ProgressRing r={28} cx={30} cy={33} $percentage={percentage} />
        </svg>
        <div className="progressText">{Math.floor(100 * percentage)}%</div>
      </ProgressIconCircleContainer>
      <ButtonTextBox>
        <ButtonTypography align="center" variant="caption">
          {title}
        </ButtonTypography>
      </ButtonTextBox>
    </ProgressIndicatorContainer>
  );
};

ProgressIndicator.propTypes = {
  numItems: PropTypes.number,
  numComplete: PropTypes.number,
  title: PropTypes.string,
};

const NavBarButton = ({ iconSrc, isActive, onClick, title }) => (
  <NavBarButtonContainer isActive={isActive}>
    <ButtonContainer>
      <VocabularyIconCircleContainer onClick={onClick}>
        <img src={iconSrc} />
      </VocabularyIconCircleContainer>
      <ButtonTextBox>
        <ButtonTypography variant="caption">{title}</ButtonTypography>
      </ButtonTextBox>
    </ButtonContainer>
  </NavBarButtonContainer>
);

NavBarButton.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const StyledNavBarHorizontalButton = styled.div(
  ({ theme }) => `
    align-items: center;
    background: #222640;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: ${theme.spacing(0, 1)};
    padding: ${theme.spacing(2, 1)};
    width: 100%;
    &:hover div:first-child {
      background: rgba(255, 255, 255, 0.2);
      border: 1px solid #FFFFFF;
    }
  `
);

const StyledIconCircleContainer = styled.div(
  ({ theme }) => `
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: ${theme.spacing(4)}px; 
    justify-content: center;
    width: ${theme.spacing(4)}px; 
  `
);

const StyledButtonTypography = styled(Typography)(
  ({ theme }) => `
    font-size: 15px;
    margin-left: ${theme.spacing(2)}px;
  `
);

const NavBarHorizontalButton = ({ iconSrc, onClick, title }) => (
  <StyledNavBarHorizontalButton onClick={onClick}>
    <StyledIconCircleContainer>
      <img src={iconSrc} />
    </StyledIconCircleContainer>
    <StyledButtonTypography variant="caption">{title}</StyledButtonTypography>
  </StyledNavBarHorizontalButton>
);

NavBarHorizontalButton.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const NavBarButtons = ({
  isHidden,
  isSummaryShown,
  isTeacher,
  mode,
  onVocabularyClick,
  onProjectSummaryClick,
  progressIndicator,
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <NavBarButtonsContainer>
      {mode === 'course' && (
        <NavBarButtonContainer>
          <ProgressIndicator
            numItems={progressIndicator.total}
            numComplete={progressIndicator.complete}
            title={progressIndicator.title}
          />
        </NavBarButtonContainer>
      )}
      {mode === 'project' && (
        <>
          {isTeacher ? (
            <NavBarHorizontalButton
              iconSrc={'/static/assets/icons/book-small.svg'}
              onClick={onVocabularyClick}
              title="Vocabulary"
            />
          ) : (
            <>
              <NavBarButton
                iconSrc={'/static/assets/icons/project-summary.svg'}
                isActive={isSummaryShown}
                onClick={onProjectSummaryClick}
                title="Project Summary"
              />
              <Divider orientation="vertical" />
              <NavBarButton
                iconSrc={'/static/assets/icons/book.svg'}
                onClick={onVocabularyClick}
                title="Vocabulary"
              />
            </>
          )}
        </>
      )}
    </NavBarButtonsContainer>
  );
};

NavBarButtons.propTypes = {
  isHidden: PropTypes.bool,
  isSummaryShown: PropTypes.bool,
  isTeacher: PropTypes.bool,
  mode: PropTypes.oneOf(['course', 'project']),
  onVocabularyClick: PropTypes.func,
  onProjectSummaryClick: PropTypes.func,
  progressIndicator: PropTypes.shape({
    total: PropTypes.number,
    complete: PropTypes.number,
    title: PropTypes.string,
  }),
};

export default NavBarButtons;
