import * as projects from '../../../utils/projects';
import { getCriteriaScore } from '../../../hooks/useScoringSummary';
import React from 'react';
import { useProgress } from '../contexts';
import { useProject } from '../../../contexts/ProjectContext';

//
// useCriteriaComponent
//

/**
 * useCriteriaComponent is a hook that provides the current value of a criteria
 * component from the progress as well as a means to change it to a new value.
 */
export const useCriteriaComponent = id => {
  const { progress, setProgress, isUpdating } = useProgress();

  const value = React.useMemo(() => {
    if (!progress.version) {
      return null;
    }

    return progress?.scoring?.components?.[id];
  }, [id, progress]);

  const setValue = React.useCallback(
    newValue => {
      if (newValue === value) {
        return;
      }

      setProgress({
        ...progress,
        scoring: {
          ...progress?.scoring,
          components: {
            ...progress?.scoring?.components,
            [id]: newValue,
          },
        },
      });
    },
    [id, value, progress, setProgress]
  );

  return {
    value: value,
    setValue: setValue,
    isUpdating: isUpdating,
  };
};

//
// useCriteriaScore hook
//

/**
 * useCriteriaScore is a hook that provides the score for a specific criteria.
 * The score is returned as a rational number with a numerator and denominator.
 */
export const useCriteriaScore = criteria => {
  const project = useProject();
  const { progress } = useProgress();

  const rubric = React.useMemo(() => {
    return project.rubric;
  }, [project]);

  const checkpoints = React.useMemo(() => {
    return projects.findCheckpoints(project, 'rubric');
  }, [project]);

  return React.useMemo(() => {
    return getCriteriaScore(criteria, rubric, checkpoints, progress);
  }, [criteria, rubric, checkpoints, progress]);
};
