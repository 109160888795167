import * as lodash from 'lodash';
import { memoize, useAxiosClient } from './client';

// Which fields should the enrolled courses be ordered by.
const SORT_FIELDS = ['sort_key', 'title', 'id'];

/**
 * Asynchronously loads the metadata about the courses a user is enrolled in.
 * Returns a promise.
 */
const getEnrolledCoursesMetadata = memoize(userId => {
  const client = useAxiosClient();

  return client
    .get(`/users/${userId}/courses/enrollments/metadata`)
    .then(response => response.data)
    .then(courses => lodash.sortBy(courses, SORT_FIELDS));
});

export default {
  getEnrolledCoursesMetadata: getEnrolledCoursesMetadata,
};
