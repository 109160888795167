import { findCheckpoints, getSections } from '../../utils/projects';
import { useCurrentStep, useProgress } from './contexts';
import { isRubricCheckpointComplete } from './utils/rubric-checkpoints';
import React from 'react';
import { useProject } from '../../contexts/ProjectContext';

export const useCheckpointAnswer = id => {
  const { progress } = useProgress();

  const answer = progress?.checkpoints?.[id] ?? {
    value: null,
    complete: false,
  };

  return answer;
};

export const useGrading = () => {
  const project = useProject();
  const { progress, setProgress } = useProgress();

  // Index each requirement in the rubric to the checkpoint it is graded in.
  const requirementCheckpointIds = React.useMemo(() => {
    if (!project.rubric) {
      return {};
    }

    // First index each category type to the checkpoint that the category is
    // graded in.
    const categoryCheckpoints = Object.fromEntries(
      findCheckpoints(project, 'rubric').flatMap(checkpoint => {
        const categories = checkpoint.categories ?? project.rubric;
        return categories.map(category => [category.type, checkpoint.id]);
      })
    );

    return Object.fromEntries(
      project?.rubric.flatMap(category =>
        category.requirements
          .map(r => [r.id, categoryCheckpoints?.[category.type]])
          .filter(([, cId]) => cId !== null)
      )
    );
  }, [project]);

  const rubricCheckpoints = findCheckpoints(project, 'rubric');

  const setCategoryGrading = scoredCategory => {
    const entries = Object.entries(scoredCategory ?? {});
    if (entries.length === 0) {
      return;
    }

    const checkpointId = requirementCheckpointIds[entries[0][0]];

    setProgress({
      ...progress,
      grading: {
        ...progress.grading,
        checkpoints: {
          ...progress.grading?.checkpoints,
          [checkpointId]: {
            ...progress.grading?.checkpoints[checkpointId],
            ...scoredCategory,
          },
        },
      },
    });
  };

  const setGrading = (status, adjustment, feedback) => {
    // When grading a submission different things need to happen depending on
    // whether or not the submission is being marked as completed or sent back.
    //
    // When a submission needs to be edited ('Edit score' button), we should:
    // 1. Transition the project status to 'grading', to be able to grade again.
    //
    // When a submission is completed we should:
    // 1. Update the grading portion of the progress to reflect the grade given.
    // 2. Transition the project status to 'completed'.
    //
    // When a submission is sent back we should:
    // 1. Update the grading portion of the progress to reflect the grade given.
    // 2. Transition the project status to 'sent_back'.
    // 3. Update the progress for each rubric checkpoint
    //   - If the teacher didn't agree that the student completed all
    //     requirements for a category of the checkpoint then the checkpoint
    //     should have 'complete' as false and 'show_modal' as true.  The value
    //     of the checkpoint should reflect which requirements the teacher say
    //     were completed.
    //   - If the teacher marked all requirements for the checkpoint categories
    //     as completed then the checkpoint progress shouldn't be changed.
    // 4. The step flag for each step containing an incomplete rubric checkpoint
    //    should be set to false.
    // 5. Any steps after an incomplete rubric checkpoint should also have their
    //    step flags set to false.  This prevents students from using the nav to
    //    skip passed the incomplete step.
    // 6. If there are incomplete rubric checkpoint(s) the student's position
    //    should be updated to the earliest step in the project containing an
    //    incomplete checkpoint.

    const scoring = {
      ...progress.scoring,
      adjustment,
      feedback,
    };

    if (status === 'completed' || status === 'grading') {
      setProgress({
        ...progress,
        status,
        scoring,
      });
      return;
    }

    // Find rubric checkpoints that the teacher didn't sign off on all of the
    // requirements for.
    const incomplete = rubricCheckpoints.filter(
      checkpoint =>
        !isRubricCheckpointComplete(
          checkpoint,
          scoring?.components?.[checkpoint.id] ?? {},
          project.rubric
        )
    );

    const incompleteProgresses = Object.fromEntries(
      incomplete.map(checkpoint => [
        checkpoint.id,
        {
          complete: false,
          show_modal: true,
          value: scoring.components[checkpoint.id],
        },
      ])
    );

    // For each rubric checkpoint that's incomplete, determine the position that
    // corresponds to it.  It's possible that a given step will have multiple
    // rubric checkpoints in it.  When that happens make sure we only count
    // that step a single time.
    //
    // If a step occurs later in the project than an already incomplete step
    // then that step is also considered incomplete.
    const incompletePositions = [];
    for (const section of getSections(project)) {
      const seen = {};

      for (const step of section.steps) {
        // This will ignore steps that don't have any rubric checkpoints in them.
        // like steps with checkpoint set in activity
        if (!step.block_groups) continue;
        for (const bg of step.block_groups) {
          for (const block of bg.blocks) {
            if (seen[step.id]) {
              continue;
            }

            const isIncomplete = incompleteProgresses[block.id];
            if (!isIncomplete && incompletePositions.length === 0) {
              continue;
            }

            incompletePositions.push({
              section: section.id,
              step: step.id,
            });
            seen[step.id] = true;
          }
        }
      }
    }

    setProgress({
      ...progress,
      position: incompletePositions?.[0] ?? progress.position,
      steps: {
        ...progress.steps,
        ...Object.fromEntries(incompletePositions.map(p => [p.step, false])),
      },
      checkpoints: {
        ...progress.checkpoints,
        ...incompleteProgresses,
      },
      scoring: scoring,
      status,
    });
  };

  return {
    setCategoryGrading,
    setGrading,
  };
};

const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];

export const useTextBlockFormats = () => {
  const step = useCurrentStep();

  const blocksFormat = {};
  let numbersIndex = 0;
  let lettersIndex = 0;
  for (const block_group of step.block_groups) {
    for (const block of block_group.blocks) {
      if (block.type !== 'text') {
        continue;
      }

      if (block.variant === 'numbered') {
        lettersIndex = 0;
        blocksFormat[block.id] = {
          bullet: ++numbersIndex,
          indentation: 'numbered',
        };
        continue;
      }

      if (block.variant === 'lettered') {
        blocksFormat[block.id] = {
          bullet: LETTERS[lettersIndex++],
          indentation: 'lettered',
        };
        continue;
      }

      if (lettersIndex > 0) {
        blocksFormat[block.id] = { indentation: 'lettered' };
      }

      if (numbersIndex > 0 && lettersIndex === 0) {
        blocksFormat[block.id] = { indentation: 'numbered' };
      }
    }
  }

  return blocksFormat;
};
