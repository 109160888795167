import { createSvgIcon } from '@material-ui/core';
import React from 'react';

const Clever = createSvgIcon(
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1280 1352.49"
  >
    <path
      fillRule="evenodd"
      fill="#1464ff"
      d="M1280,348.16C1167.69,138.18,943.07,0,675.9,0,302.22,0,0,301.88,0,675.9s302.22,675.91,675.9,676.58c267.16,0,491.78-138.17,604.1-348.16l-232.11-128.99c-77.25,134.43-202.49,219.18-371.98,219.18-228.36,0-407.04-189.57-407.04-418.27s178.68-418.27,407.04-418.27c169.49,0,294.73,84.74,371.98,219.18l232.11-128.99Z"
    />
  </svg>,
  'Clever'
);

export default Clever;
