import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)(
  ({ size, fill }) => `
    cursor: pointer;
    fill: ${fill};
    height: ${size}px;
    width: ${size}px;
  `
);

const Collapse = ({ collapsed, fill = '#C4C4C4', size = 24 }) => (
  <StyledSvgIcon
    fill={fill}
    size={size}
    viewBox="0 0 24 24"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M19 5H5V19H19V5ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.1 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3Z"
      fillRule="evenodd"
    />
    <path d="M17 13H12.7143H11.2857H7V11H11.2857H12.7143H17V13Z" />
    {collapsed && (
      <path d="M13 7V11.2857V12.7143V17H11V12.7143V11.2857V7H13Z" />
    )}
  </StyledSvgIcon>
);

Collapse.displayName = 'CollapseIcon';

Collapse.propTypes = {
  collapsed: PropTypes.bool,
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default Collapse;
