import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  filter: drop-shadow(0px 0px 56px rgba(0, 0, 0, 0.502));
`;

const PlayGradient = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    $fillColor={props.$fillColor}
    ref={ref}
    viewBox="-2 0 40 40"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <defs />
    <path
      fill="none"
      stroke="url(#paint0_linear)"
      strokeWidth="2"
      d="M18 35c9.3888 0 17-7.6112 17-17 0-9.38884-7.6112-17-17-17C8.61116 1 1 8.61116 1 18c0 9.3888 7.61116 17 17 17z"
    />
    <path
      fill="url(#paint1_linear)"
      stroke="url(#paint2_linear)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M23.1892 18.0052l-10.3784-6.387v12.7732l10.3784-6.3862z"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="18"
        x2="18"
        y1="2"
        y2="34"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".533186" stopColor="#B0B0F9" />
        <stop offset="1" stopColor="#5C71F2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="18"
        x2="18"
        y1="11.6182"
        y2="24.3914"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".533186" stopColor="#B0B0F9" />
        <stop offset="1" stopColor="#5C71F2" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="18"
        x2="18"
        y1="11.6182"
        y2="24.3914"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".533186" stopColor="#B0B0F9" />
        <stop offset="1" stopColor="#5C71F2" />
      </linearGradient>
    </defs>
  </StyledSvgIcon>
));

PlayGradient.displayName = 'PlayGradient';
PlayGradient.propTypes = {
  size: PropTypes.number,
  $fillColor: PropTypes.string,
};

export default PlayGradient;
