import ExpiredStorage from 'expired-storage';

// The prefix for all keys storing a user's login status for external accounts.
const PREFIX = 'external_account';

// The duration for how long the application should remember that a user
// has confirmed they've logged into an external account.
const TTL = 7200;

const storage = new ExpiredStorage();

/**
 * Check if the user has already confirmed that they've logged into the
 * specified external account.  This has the side effect of extending the
 * duration until they need to reconfirm that they've logged in.
 */
export const isLoggedIn = tool => {
  const key = `${PREFIX}:${tool}`;
  const value = storage.getItem(key);

  // Since the user is actively interacting with our application, extend their
  // duration for needing to confirm.  This has the effect of not over asking
  // students to confirm they're logged in during a long work session.
  if (value !== null) {
    storage.updateExpiration(key, TTL);
  }

  return value;
};

/**
 * Indicate that the user has confirmed that they're logged into the specified
 * external account.
 */
export const setLoggedIn = tool => {
  const key = `${PREFIX}:${tool}`;
  storage.setItem(key, true, TTL);
};

/**
 * Indicate that the user is no longer logged in to the specified external
 * account.
 */
export const clearLoggedIn = tool => {
  const key = `${PREFIX}:${tool}`;
  storage.removeItem(key);
};

/**
 * Clear all saved external account login statuses for a user.
 */
export const logout = () => {
  storage
    .keys(true)
    .filter(key => key.startsWith(PREFIX + ':'))
    .forEach(key => storage.removeItem(key));
};

/**
 * Determine which tool is used for a given project language.
 */
export const getToolForLanguage = language => {
  // In the future we really shouldn't make these decisions based on the
  // project or course language -- instead we should decide based on the
  // activities.  This is because we have multiple tools for some languages.
  // For now, we're going to assume everything is pickcode based and not report
  // any tools being needed.
  switch (language?.toLowerCase()) {
    default:
      return null;
  }
};
