import {
  memoize,
  useAxiosClient,
  useSectionActivityApiAxiosClient,
} from './client';

/**
 * Asynchronously loads a user's external account information. Returns a
 * promise.
 */
const getExternalAccount = memoize((accountId, userId) => {
  const client = useAxiosClient();

  return client
    .get(`/users/${userId}/external-accounts/${accountId}`)
    .then(response => response.data);
}, 2);

// TODO: Determine if this is still used.
/**
 * Asynchronously loads the CoSpaces IDs for a user's project.  Returns a
 * promise.
 */
const getCoSpacesIDs = memoize((userId, projectId) => {
  const client = useAxiosClient();

  return client
    .get(`/users/${userId}/external-accounts/cospaces/ids/${projectId}`)
    .then(response => response.data);
});

/**
 * Asynchronously loads the CoSpaces Auth token for a user.  Returns a
 * promise.
 */
const getCoSpacesAuthToken = memoize(userId => {
  const client = useAxiosClient();

  return (
    client
      .get(`/users/${userId}/external-accounts/cospaces/token`)

      // Because we attach callbacks to the returned value, it needs to
      // always be an object.
      .then(response => ({ value: response.data }))
  );
});

/**
 * Asynchronously creates the CoSpaces assignment for a section and project.
 * Returns a promise.
 */
const createCoSpacesAssigment = memoize((sectionId, projectId) => {
  const client = useSectionActivityApiAxiosClient();
  return (
    client
      .post(
        `/cospaces/create-assignment?section_id=${sectionId}&project_id=${projectId}`
      )

      // Because we attach callbacks to the returned value, it needs to
      // always be an object.
      .then(response => response.data)
  );
});

/**
 * Asynchronously loads the Pickcode lesson IDs for a project.  Returns a
 * promise.
 */
const getPickcodeIDs = memoize(projectId => {
  const client = useAxiosClient();

  return client
    .get(`/projects/${projectId}/external-ids/pickcode`)
    .then(response => response.data);
});

/**
 * Asynchronously loads the Pickcode Auth token for a user.  Returns a
 * promise.
 */
const getPickcodeAuthToken = memoize(userId => {
  const client = useAxiosClient();

  return (
    client
      .get(`/users/${userId}/external-accounts/pickcode/token`)

      // Because we attach callbacks to the returned value, it needs to
      // always be an object.
      .then(response => ({ value: response.data }))
  );
});

export default {
  account: {
    get: getExternalAccount,

    cospaces: {
      ids: {
        get: getCoSpacesIDs,
      },
      token: {
        get: getCoSpacesAuthToken,
      },
      create_assignment: {
        create: createCoSpacesAssigment,
      },
    },

    pickcode: {
      ids: {
        get: getPickcodeIDs,
      },
      token: {
        get: getPickcodeAuthToken,
      },
    },
  },
};
