import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-height: ${({ theme }) => theme.spacing(14)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 32px;
    text-align: center;
  }
`;

const LoginButton = styled(Button)`
  margin-top: 16px !important;
  text-decoration: none;
  width: 200px;
`;

const UnderscoreFlicker = styled.span`
  margin-right: 8px;
  animation-name: terminal;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes terminal {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

const Error = () => {
  return (
    <Container>
      <img alt="error" src="/static/assets/error/error-shrug.png" />
      <Login>
        <h2>Oops! Something Went Wrong!</h2>
        <Link style={{ textDecoration: 'none' }} to="/login">
          <LoginButton color="primary" variant="outlined">
            &gt;<UnderscoreFlicker>_ </UnderscoreFlicker> Go Back
          </LoginButton>
        </Link>
      </Login>
    </Container>
  );
};

export default Error;
