import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { FrameCard } from '../../../components/Frames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = withStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.level1,
    borderRadius: '20px',
    maxWidth: '992px',
    width: '75vw',
    minWidth: '500px',
  },
}))(
  styled(Dialog)(
    ({ theme }) => `
      #header {
        background-color: ${theme.palette.background.level3};
        border-bottom: 3px solid ${theme.palette.background.level1};
        height: ${theme.spacing(19)}px;
      }
      
      #body {
        border: 0;
        margin: 0;
        padding: 0;
      }
      
      #frame {
        padding: ${theme.spacing(2.5)}px;
        
        #contents {
          padding-left: ${theme.spacing(3.5)}px;
          padding-right: ${theme.spacing(3.5)}px;
        }
      }
      
      .question {
        margin: ${theme.spacing(4)}px 0;
        
        .MuiFormGroup-root {
          background-color: ${theme.palette.background.level1};
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        
        .MuiFormControlLabel-root {
          flex-basis: 0;
          flex-grow: 1;
        }
        
        .MuiTextField-root {
          background-color: ${theme.palette.background.level1};
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      
      #submit {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.text.primary};
        margin: 0 auto ${theme.spacing(6)}px auto;
        
        &.Mui-disabled {
          opacity: 30%;
        }
        
        :hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #5C71F2;   
        }
      }
    `
  )
);

export const Feedback = ({ children }) => {
  return (
    <StyledDialog open={true} scroll="paper">
      {children}
    </StyledDialog>
  );
};
Feedback.propTypes = {
  children: PropTypes.node,
};

const ProjectImage = styled.img(
  ({ theme }) => `
    border-radius 6px;
    margin: ${theme.spacing(3)}px;
    height: ${theme.spacing(13)}px;
    width: auto;
  `
);

const CourseLevelTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    line-height: ${theme.spacing(5)}px;
  `
);

const ProjectTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: ${theme.spacing(5)}px;
  `
);

export const FeedbackHeader = ({ media, course, level, project }) => (
  <Box id="header" display="flex">
    <ProjectImage src={media.url} alt="project card" />
    <Grid container direction="column" justifyContent="center">
      {course && level && (
        <CourseLevelTypography>
          {course}: {level}
        </CourseLevelTypography>
      )}
      <ProjectTypography>{project}</ProjectTypography>
    </Grid>
  </Box>
);
FeedbackHeader.propTypes = {
  media: PropTypes.shape({
    type: PropTypes.oneOf(['image']),
    url: PropTypes.string,
  }),
  course: PropTypes.string,
  level: PropTypes.string,
  project: PropTypes.string,
};

export const FeedbackBody = ({ children }) => (
  <DialogContent id="body">{children}</DialogContent>
);
FeedbackBody.propTypes = {
  children: PropTypes.node,
};

export const FeedbackFrame = ({ title, children }) => (
  <div id="frame">
    <FrameCard title={title ?? 'Your Feedback'} padding={{ bottom: 0, top: 0 }}>
      <div id="contents">{children}</div>
    </FrameCard>
  </div>
);
FeedbackFrame.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const QuestionPromptTypography = styled(Typography)(
  ({ theme }) => `
    color: #141313;
    font-family: 'IBM Plex Mono', monospaced;
    font-size: 18px;
    font-weight: 400;
    line-height: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(2)}px;
  `
);

const RatingQuestionLabelTypography = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
    font-family: 'IBM Plex Mono', monospaced;
    font-size: 14px;
    font-weight: 400;
    line-height: ${theme.spacing(3)}px;
    margin-top: ${theme.spacing(0.5)}px;
    text-align: center;
    text-overflow: clip;
    white-space: normal;
  `
);

export const FeedbackRatingQuestion = ({ question, value, onChange }) => {
  const prompt = question.required
    ? `${question.prompt}`
    : `(Optional) ${question.prompt}`;

  const choices = Array(question.choices ?? 5)
    .fill()
    .map((_, i) => i + 1);

  let labels = Array(choices.length).fill(null);

  // For questions with labels in the first and last choices only
  if (question.labels?.length === 2) {
    labels[0] = question.labels?.[0];
    labels[labels.length - 1] = question.labels?.[1];
  }

  // For questions with labels in all choices
  if (question.labels?.length > 2) {
    labels = labels.map((_, i) => question.labels[i]);
  }

  return (
    <div className="question">
      <QuestionPromptTypography>{prompt}</QuestionPromptTypography>
      <RadioGroup
        row
        onChange={e => onChange({ rating: parseInt(e.target.value) })}
      >
        <Box
          display="flex"
          flexGrow="1"
          flexShrink="1"
          justifyContent="space-evenly"
          overflow="auto"
        >
          {choices.map((choice, index) => (
            <FormControlLabel
              key={index}
              value={choice}
              label={
                <RatingQuestionLabelTypography>
                  {labels[index]}
                </RatingQuestionLabelTypography>
              }
              labelPlacement="top"
              control={
                <Radio checked={value?.rating === choice} color="primary" />
              }
            />
          ))}
        </Box>
      </RadioGroup>
    </div>
  );
};
FeedbackRatingQuestion.propTypes = {
  question: PropTypes.shape({
    prompt: PropTypes.string,
    required: PropTypes.bool,
    choices: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
  value: PropTypes.shape({
    rating: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

const ShortAnswerQuestionTextField = styled(TextField)(
  ({ theme }) => `
    width: 100%;
    
    .MuiInput-multiline {
      color: ${theme.palette.text.secondary};
      font-family: 'IBM Plex Mono', monospaced;
      font-size: 16px;
      font-weight: 400;
      padding: ${theme.spacing(1)}px;
    }
  `
);

export const FeedbackShortAnswerQuestion = ({ question, value, onChange }) => {
  const prompt = question.required
    ? `${question.prompt}`
    : `(Optional) ${question.prompt}`;

  return (
    <div className="question">
      <QuestionPromptTypography>{prompt}</QuestionPromptTypography>
      <ShortAnswerQuestionTextField
        multiline
        minRows={5}
        placeholder={'Type your answer here'}
        value={value ?? ''}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};
FeedbackShortAnswerQuestion.propTypes = {
  question: PropTypes.shape({
    prompt: PropTypes.string,
    required: PropTypes.bool,
  }),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export const FeedbackButton = ({ disabled, onClick }) => (
  <Grid container justifyContent="center">
    <Button
      id="submit"
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      Submit Feedback
    </Button>
  </Grid>
);
FeedbackButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
