import PropTypes from 'prop-types';
import React from 'react';

const MultipleChoiceComponentIndexContext = React.createContext(undefined);
export const MultipleChoiceComponentIndexProvider = ({
  criteria,
  children,
}) => {
  const indices = React.useMemo(() => {
    return Object.fromEntries(
      criteria.components
        .filter(c => c.type === 'checkpoint' && c.variant !== 'rubric')
        .map((c, index) => [c.id, index + 1])
    );
  }, [criteria]);

  return (
    <MultipleChoiceComponentIndexContext.Provider value={indices}>
      {children}
    </MultipleChoiceComponentIndexContext.Provider>
  );
};
MultipleChoiceComponentIndexProvider.propTypes = {
  criteria: PropTypes.shape({
    title: PropTypes.string,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        variant: PropTypes.string,
      })
    ),
  }),
  children: PropTypes.node,
};

/**
 * useMultipleChoiceComponentIndex is a hook that provides the index number of
 * a multiple choice component within a criteria.  This is useful for numbering
 * the multiple choice components since they don't generally have a reliable
 * user visible title to identify them.
 */
export const useMultipleChoiceComponentIndex = id => {
  const context = React.useContext(MultipleChoiceComponentIndexContext);
  if (context === undefined) {
    throw new Error(
      'useMultipleChoiceComponentIndex should be used within a MultipleChoiceComponentIndexProvider'
    );
  }

  return context?.[id] ?? 0;
};
