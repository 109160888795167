import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  .a {
    fill: #4b4800;
  }
  .a,
  .b {
    stroke: #fff61c;
  }
  .b {
    fill: #fff61c;
  }
`;

const Graded = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    ref={ref}
    viewBox="0 0 21 21"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    titleAccess="Graded"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <g transform="translate(0.5 0.5)">
      <circle className="a" cx="10" cy="10" r="10" />
      <path
        className="b"
        d="M7,10.125,10.125,12l-1-3.75L12,5.75,8.375,5.375,7,2,5.625,5.375,2,5.75l2.625,2.5L3.875,12Z"
        transform="translate(3 3)"
      />
    </g>
  </StyledSvgIcon>
));

Graded.displayName = 'Graded';

Graded.propTypes = {
  size: PropTypes.number,
};

export default Graded;
