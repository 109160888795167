/** Given a project return all section groups within it. */
export const getSectionGroups = project => {
  return project?.section_groups ?? [];
};

/** Given a project return all sections within it. */
export const getSections = project => {
  return getSectionGroups(project).flatMap(sg => sg?.sections ?? []);
};

/** Given a project return all steps within it. */
export const getSteps = project => {
  return getSections(project).flatMap(s => s?.steps ?? []);
};

/** Given a project return all block groups within it. */
export const getBlockGroups = project => {
  return getSteps(project).flatMap(s => s?.block_groups ?? []);
};

/** Given a project return all blocks within it. */
export const getBlocks = project => {
  return getBlockGroups(project).flatMap(bg => bg?.blocks ?? []);
};

/** Given a project return all activities within it. */
export const getActivities = project => {
  return getSteps(project)
    .filter(step => !!step?.['activity'] || !!step?.['activity_id'])
    .map(step => step?.activity || project?.activities?.[step?.activity_id]);
};

/** Given a project find all blocks of a given type and optional variant. */
export const findBlocks = (project, type, variant = null) => {
  return getBlocks(project)
    .filter(block => block.type === type)
    .filter(block => variant === null || block?.variant === variant);
};

/**
 * Given a project find all checkpoints of a given variant, both inside blocks
 * and activities.
 */
export const findCheckpoints = (project, variant = null) => {
  const steps = findBlocks(project, 'checkpoint', variant);

  const sets = getActivities(project)
    .filter(activity => activity?.tool === 'checkpoint-set')
    .flatMap(activity => activity.checkpoints ?? [])
    .filter(cp => variant === null || cp?.variant === variant);

  const writtenResponses = getActivities(project)
    .filter(activity => activity?.tool === 'written-response')
    .map(activity => ({ id: activity.id, variant: 'written-response' }))
    .filter(activity => variant === null || activity.variant === variant);

  return steps.concat(sets).concat(writtenResponses);
};

/** Given a project find a specific checkpoint by its id. */
export const findCheckpoint = (project, id) => {
  const checkpoints = findCheckpoints(project).filter(cp => cp.id === id);
  return checkpoints?.[0];
};

// TODO: Update this once all activities are migrated over to ids.
export const getStepActivity = (project, step) => {
  if (step.activity) {
    return step.activity;
  }

  const id = step?.['activity_id'];
  if (id) {
    return project?.activities?.[id];
  }
};
