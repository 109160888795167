import api from '../../../../api';
import { LoadingIndicator } from '../../../../components';
import PropTypes from 'prop-types';
import React from 'react';

//
// Section metadata context
//

const SectionMetadataContext = React.createContext(undefined);
export const useSectionMetadata = () => {
  const context = React.useContext(SectionMetadataContext);
  if (context === undefined) {
    throw new Error(
      'useSectionMetadata must be used within a SectionMetadataProvider'
    );
  }
  return context;
};

/**
 * A SectionMetadataProvider provides the means to read a section's metadata
 * and update the section's controls.
 */
export const SectionMetadataProvider = ({ sectionId, courseId, children }) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [metadata, setMetadata] = React.useState();

  const [loading, loaded] = api.load(
    api.section.getSectionCourseMetadata(sectionId, courseId)
  );

  React.useEffect(() => {
    if (loading) {
      return;
    }

    setMetadata(loaded);
    return loaded?.cleanup;
  }, [loading, loaded]);

  // Update a controls object.
  const updateControls = React.useCallback(
    async newControls => {
      setIsUpdating(true);

      try {
        const oldVersion = metadata.section.version;
        const newVersion = await api.section.updateControls(
          sectionId,
          metadata.section.course_id,
          newControls,
          oldVersion
        );

        setMetadata({
          ...metadata,
          section: {
            ...metadata.section,
            controls: newControls,
            version: newVersion,
          },
        });
      } finally {
        setIsUpdating(false);
      }
    },
    [sectionId, metadata]
  );

  // Show the loading indicator while request is in progress.
  if (loading || !metadata) {
    return <LoadingIndicator />;
  }

  const value = {
    metadata: metadata,
    setControls: updateControls,
    isUpdating: isUpdating,
  };

  return (
    <SectionMetadataContext.Provider value={value}>
      {children}
    </SectionMetadataContext.Provider>
  );
};
SectionMetadataProvider.propTypes = {
  sectionId: PropTypes.string,
  courseId: PropTypes.string,
  children: PropTypes.node,
};
