import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

const StyledSvgIcon = styled(SvgIcon)`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  filter: drop-shadow(0px 0px 56px rgba(0, 0, 0, 0.502));

  .a {
    fill: ${({ $fillColor }) =>
      $fillColor ? $fillColor + '03' : 'transparent'};
    stroke-width: 2px;
  }
  .a,
  .b {
    stroke: ${({ $fillColor, theme }) =>
      $fillColor ? $fillColor : theme.palette.text.primary};
  }
  .b {
    fill: ${({ $fillColor, theme }) =>
      $fillColor ? $fillColor : theme.palette.text.primary};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
  .c {
    stroke: none;
  }
  .d {
    fill: none;
  }
`;

const Play = React.forwardRef((props, ref) => (
  <StyledSvgIcon
    $fillColor={props.$fillColor}
    ref={ref}
    viewBox="0 0 40 40"
    xlinkHref="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${props.size}px`, width: `${props.size}px` }}
    {...props}
  >
    <g className="a">
      <circle className="c" cx="20" cy="20" r="20" />
      <circle className="d" cx="20" cy="20" r="18.5" />
    </g>
    <path
      className="b"
      d="M12,7.385,0,0V14.769Z"
      transform="translate(14 12.621)"
    />
  </StyledSvgIcon>
));

Play.displayName = 'Play';
Play.propTypes = {
  size: PropTypes.number,
  $fillColor: PropTypes.string,
};

export default Play;
