import {
  getAuth,
  GoogleAuthProvider,
  linkWithPopup,
  OAuthProvider,
  signInWithRedirect,
  unlink as unlinkProvider,
} from 'firebase/auth';
import Button from '@material-ui/core/Button';
import Clever from '../icons/Clever';
import Google from '../icons/Google';
import PropTypes from 'prop-types';
import React from 'react';

const auth = getAuth();

export default function SSOAuthButton(props) {
  const providers = {
    google: {
      provider: new GoogleAuthProvider(),
      icon: <Google />,
      label: 'Google',
    },
    clever: {
      provider: new OAuthProvider('oidc.clever'),
      icon: <Clever />,
      label: 'Clever',
    },
  };

  const actions = {
    login: {
      label: `Login With ${providers[props.provider]['label']}`,
      action: async () => {
        await signInWithRedirect(
          auth,
          providers[props.provider].provider
        ).catch(error => {
          if (error.code === 'auth/admin-restricted-operation') {
            props.setAuthError(error.code);
          }
        });
      },
    },
    link: {
      label: `Link ${providers[props.provider].label} account`,
      action: async () =>
        await linkWithPopup(
          auth.currentUser,
          providers[props.provider].provider
        ).then(() => {
          window.location.reload();
        }),
    },
    unlink: {
      label: `Unlink ${providers[props.provider].label} account`,
      action: async () =>
        await unlinkProvider(
          auth.currentUser,
          providers[props.provider].provider.providerId
        ).then(() => {
          window.location.reload();
        }),
    },
  };

  return (
    <Button
      variant="outlined"
      onClick={actions[props.action].action}
      startIcon={providers[props.provider].icon}
    >
      {actions[props.action].label}
    </Button>
  );
}

SSOAuthButton.propTypes = {
  provider: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};
